import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Reaggregation position |||| Reaggregation positions',
      fields: {
        'data.uit_uitu': 'UIT/UITU',
      }
    }
  }
}