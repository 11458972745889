import resourceConfig from '../config';

export default {
  pages: {
    [resourceConfig.name]: {
      name: 'Interface configuration',
      fields: {
        enableMetrics: 'Show metrics on dashboards',
        enableNotifications: 'Show notifications (menu and dashboard)',
        enableBalance: 'Show company balance',
      },
    }
  }
}
