import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Товар |||| Товары',
      actions: {
        loadProducts: {
          name: 'Выгрузить товары',
          successMessage: 'Запрос на выгрузку товаров успешно отправлен',
          failureMessage: 'При запросе на выгрузку товаров произошла ошибка',
        },
        showCodes: {
          name: 'Показать коды',
        },
      },
      fields: {
        name: 'Наименование',
        gtin: 'GTIN',
        vendor_code: 'Код поставщика',
        tn_ved_code: 'ТН ВЭД',
        tn_ved_code_10: 'ТН ВЭД 10',
        'data.brand': 'Бренд',
        'data.fullName': 'Полное наименование',
        'data.inn': 'ИНН',

        'data': 'Статус',
      },
      statusMessages: {
        notSync: 'Товар не синхронизирован с ГИСМТ',
        isPublished: 'Карточка товара опубликована',
        notPublished: 'Карточка товара в статусе "%{status}"',
        isReadyForMarking: 'Товар готов к маркировке',
        notReadyForMarking: 'Товар не готов к маркировке',
        isSigned: 'Карточка товара подписана',
        notSigned: 'Карточка товара не подписана',
        isTurnover: 'Товар готов к обороту',
        notTurnover: 'Товар не готов к обороту',
      },
    }
  }
}
