import authProvider from "../network/authProvider";
import request from "../network/request";

export default async () => {
  let currentUser = null;
  let companies = null;
  let currentCompany = null;
  const companyIdInStorage = parseInt(localStorage.getItem('companyId')) || null;

  try {
    currentUser = await authProvider.checkAuth();
    if (currentUser !== null) {
      companies = await request({
        path: '/cm/company/company/'
      }).then(x => x.data.results);

      if (companies.length > 0) {
        if (companyIdInStorage !== null) {
          currentCompany = companies.find(x => x.id === companyIdInStorage) || null;
        }
        if (currentCompany === null) {
          currentCompany = companies[0];
        }
      }

      if (currentCompany === null && companyIdInStorage !== null) {
        localStorage.removeItem('companyId')
      } else if (currentCompany !== null && currentCompany.id !== companyIdInStorage) {
        localStorage.setItem('companyId', currentCompany.id);
      }
    }
  } catch(e) {}

  return {
    currentUser,
    currentCompany,
    companies,
  }
}