import React from 'react';

const DynamicPropsField = (props) => {
  const {
    getProps,
    Component,
    nextProps,
    ...restProps
  } = props;

  const dynamicProps = getProps(restProps.record);

  return (
    <Component
      {...nextProps}
      {...restProps}
      {...dynamicProps}
    />
  );
}

DynamicPropsField.defaultProps = {
  addLabel: true
};

export default DynamicPropsField;
