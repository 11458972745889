import React from 'react';
import { connect } from 'react-redux';
import { Layout as RALayout, Sidebar } from '../Admin';
import AppBar from '../AppBar';
import lightTheme from '../../theme/lightTheme';
import darkTheme from '../../theme/darkTheme';

const CustomSidebar = props => (
  <Sidebar
    size={280}
    {...props}
    style={{
      zIndex: 1000,
      marginRight: 20,
    }}
  />
);
const Layout = props => (
  <RALayout
    {...props}
    title=""
    appBar={AppBar}
    sidebar={CustomSidebar}
    style={{
      boxSizing: 'border-box',
    }}
  />
);

export default connect(
  state => ({
    theme: state.app.theme === 'dark' ? darkTheme.mui : lightTheme.mui,
  }),
  {}
)(Layout);
