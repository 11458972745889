import React from 'react';
import { Tooltip } from '@material-ui/core';
import getValue from '../../utils/getValue';

export default (props) => {
  const {
    source,
    record,
    emptyText,
  } = props;

  const guid = getValue(record, source) || '';
  let part = guid;
  if (guid.length > 20 && guid.indexOf('-') !== -1) {
    const parts = guid.split('-');
    part = parts[0];
  }
  return (
    <Tooltip title={guid} placement="right" arrow interactive>
      <span>{part || emptyText}</span>
    </Tooltip>
  );
}