import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Document |||| All documents',
      fields: {
        extra_name: 'Name',
      }
    }
  }
}
