export default {
  name: 'marking/enums/remarkingCause',
  enums: [
    'KM_SPOILED',
    'DESCRIPTION_ERRORS',
    'RETAIL_RETURN',
    'REMOTE_SALE_RETURN',
    'LEGAL_RETURN',
    'INTERNAL_RETURN',
    'EEC_EXPORT_RETURN',
  ],
};
