import Icon from '@material-ui/icons/NoteAdd';
import createDocWithGenerator from '../../services/marking-service/modules/createDocWithGenerator';

export default ({ precessName, order = 30, name = 'documents.actions.createNextDoc' } = {}) => ({
  name,
  order,
  Icon,
  isDisabled: ({ record }) => record.status === 'draft',
  getTransitionUrl: ({ record }) => createDocWithGenerator.getTransitionUrl({
    params: {
      document_id: record.id,
    }
  }),
})
