import ResourceIcon from '@material-ui/icons/Input';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';

const name = 'marking/input-import-child';
const parentResourceName = 'marking/input-import';
const apiBasePath = '/bp/processes/input_import/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  schema: {
    type: 'object',
    properties: {
      'data.uit_code': {
        type: 'string',
        defaultValue: null,
      },
      'data.uitu_code': {
        type: 'string',
        defaultValue: null,
      },
      'data.tnved_code': {
        type: 'string',
        defaultValue: null,
      },
      'data.certificate_document': {
        type: 'string',
        defaultValue: null,
      },
      'data.certificate_document_number': {
        type: 'string',
        defaultValue: null,
      },
      'data.certificate_document_date': {
        type: 'date',
        defaultValue: null,
      },
    }
  },
  listFields: [
    'data.uit_code',
    'data.uitu_code',
    'data.tnved_code',
  ],
});