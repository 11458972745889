import { downloadCSV } from 'react-admin';
import download from 'downloadjs';
import jsonExport from 'jsonexport/dist';

export const exportFormats = [
  'csv-semicolon',
  'csv-comma',
  'csv-tab',
  'json'
]

export default async (records, fetchRelatedRecords, proxy, name, params = {}) => {
  const {
    format = 'csv-semicolon',
  } = params;

  if (format === 'csv-semicolon') {
    const csv = await jsonExport(records, {
      rowDelimiter: ';',
      forceTextDelimiter: true,
    });
    downloadCSV(csv, name);
    return;
  }
  if (format === 'csv-comma') {
    const csv = await jsonExport(records, {
      rowDelimiter: ',',
      forceTextDelimiter: true,
    });
    downloadCSV(csv, name);
    return;
  }
  if (format === 'csv-tab') {
    const csv = await jsonExport(records, {
      rowDelimiter: '\t',
      forceTextDelimiter: true,
    });
    downloadCSV(csv, name);
    return;
  }
  if (format === 'json') {
    download(JSON.stringify(records, null, 2), `${name}.json`, null);
    return;
  }
  throw new Error(`Unknown format "${format}"`);
}
