import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        CONFORMITY_CERTIFICATE: 'Сертификат соответвия',
        CONFORMITY_DECLARATION: 'Декларация соответвия',
        STATE_REGISTRATION_CERTIFICATE: 'Свидетельство о гос. регистрации',
      },
    }
  }
}
