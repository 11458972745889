import React, { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useTranslate } from 'react-admin';
import TransitionLink from '../TransitionLink';

const ActionMenuItem = memo((props) => {
  let {
    action,
    record,
    disabled = false,
    onClick,
  } = props;

  const translate = useTranslate();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(action);
    }
  }, [onClick, action])

  const nextProps = {

  }
  if (action.getUrl) {
    nextProps.component = Link;
    nextProps.to = action.getUrl({ record });
  } else if (action.getTransitionUrl) {
    nextProps.component = TransitionLink;
    try {
      nextProps.transitionTo = action.getTransitionUrl({ record });
    } catch (e) {
      console.warn('action.getTransitionUrl error', e);
    }
  }

  if (action.isDisabled && typeof action.isDisabled === 'function') {
    try {
      disabled = action.isDisabled({ record });
    } catch (e) {
      console.warn('action.isDisabled error', e);
      disabled = true;
    }
  }

  return (
    <MenuItem
      {...nextProps}
      disabled={disabled}
      onClick={handleClick}
    >
      {action.Icon && (
        <ListItemIcon>
          <action.Icon fontSize="small" />
        </ListItemIcon>
      )}
      {translate(action.name)}
    </MenuItem>
  );
});

export default ActionMenuItem;
