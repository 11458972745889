import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'FOREIGN': 'Ввезен в РФ',
        'LOCAL': 'Произведен в РФ',
        'REMAINS': 'Остатки',
        'CROSSBORDER': 'Ввезен в РФ из стран ЕАЭС',
        'REMARK': 'Перемаркировка',
      },
    }
  }
}