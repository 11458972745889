export default (response, body) => {
  const result = {
    message: 'Unknown error',
    status: response.status,
    body,
  };

  if (result.status === 401) {
    result.message = 'Authentication failed';
  } else if (typeof body === 'string') {
    result.message = body;
  } else if (Array.isArray(body) && body.length > 0) {
    result.message = body[0];
  } else if (typeof body === 'object') {
    const data = {
      ...body.data,
      ...body
    };
    if (data.exMessage) {
      const errorMatch = data.exMessage.match(/RuntimeException: ([^\n]*)/);
      if (errorMatch !== null && errorMatch[1]) {
        result.message = errorMatch[1];
      }
    } else if (data.detail) {
      result.message = data.detail;
    } else {
      const messages = [];
      for (let key in data) {
        const value = data[key];
        if (typeof value === 'string') {
          messages.push(`${key}: ${value}`);
        } else if (Array.isArray(value)) {
          messages.push(`${key}: ${value.join('; ')}`);
        }
      }
      result.message = messages.join(`\n`);
    }

  }

  console.error({
    response,
    result,
  });

  return result;
}
