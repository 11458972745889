import React from "react";
import { SvgIcon } from "@material-ui/core";

export default (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M12 1C5.93263 1 1 5.93263 1 12C1 18.0674 5.93263 23 12 23C18.0674 23 23 18.0674 23 12C23 5.93263 18.0674 1 12 1ZM12 18.9937C8.13263 18.9937 5.00631 15.8674 5.00631 12C5.00631 8.13263 8.13263 5.00631 12 5.00631C15.8674 5.00631 18.9937 8.13263 18.9937 12C18.9937 15.8674 15.8674 18.9937 12 18.9937Z" />
    <path d="M9.70736 12.1158L6.55789 11.0968C6.51158 11.3979 6.48842 11.6989 6.48842 12C6.48842 13.5516 7.11368 14.9411 8.13263 15.9368L10.0779 13.2505C9.86948 12.9263 9.73052 12.5326 9.70736 12.1158Z" />
    <path d="M11.1895 9.84632V6.53474C9.38316 6.81264 7.85474 7.94737 7.06737 9.5221L10.2168 10.5411C10.4716 10.24 10.7958 10.0084 11.1895 9.84632Z" />
    <path d="M12 14.2926C11.7916 14.2926 11.5832 14.2695 11.3979 14.2231L9.45263 16.9095C10.2168 17.3032 11.0737 17.5347 12 17.5347C12.9263 17.5347 13.7832 17.3032 14.5474 16.9095L12.6021 14.2231C12.4168 14.2695 12.2084 14.2926 12 14.2926Z" />
    <path d="M12.8105 6.53474V9.84632C13.181 9.98527 13.5284 10.24 13.7832 10.5411L16.9326 9.5221C16.1453 7.94737 14.64 6.81264 12.8105 6.53474Z" />
    <path d="M13.9221 13.2505L15.8674 15.9368C16.8863 14.9411 17.5116 13.5284 17.5116 12C17.5116 11.6989 17.4884 11.3979 17.4421 11.0968L14.2926 12.1158C14.2695 12.5326 14.1305 12.9263 13.9221 13.2505Z" />
    <path d="M12 13.2274C12.6779 13.2274 13.2274 12.6779 13.2274 12C13.2274 11.3221 12.6779 10.7726 12 10.7726C11.3221 10.7726 10.7726 11.3221 10.7726 12C10.7726 12.6779 11.3221 13.2274 12 13.2274Z" />
  </SvgIcon>
);
