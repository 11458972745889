import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Receipt |||| Receipt',
      fields: {
        'meta.status': 'Status',
        'meta.created': 'Created date'
      }
    }
  }
}