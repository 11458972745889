import { Avatar, Tooltip, Box, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DisabledIcon from '@material-ui/icons/NotInterested';
import CheckIcon from '@material-ui/icons/Check';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import React from 'react';
import { useTranslate } from 'react-admin';
import getValue from '../../../../../utils/getValue';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: `2px solid ${theme.palette.background.default}`,
    '& + &': {
      marginLeft: -theme.spacing(1),
    },
  },
  icon: {
    fontSize: 12,
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.background.default,
  },
  success: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.background.default,
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.background.default,
  },
}));

const StatusItem = (props) => {
  const {
    icon: Icon,
    hint,
    color,
  } = props;

  const classes = useStyles();

  const classNames = [classes.root];

  if (color in classes) {
    classNames.push(classes[color]);
  }

  return (
    <Tooltip arrow title={hint}>
      <Avatar className={classNames.join(' ')}>
        <Icon className={classes.icon}/>
      </Avatar>
    </Tooltip>
  )
}

const ProductStatusField = (props) => {
  const {
    source,
    record,
    translatePrefix = '',
  } = props;

  const t = useTranslate();

  const value = getValue(record, source) || '';

  const items = [];
  if (value === null || !value.id) {
    items.push({
      hint: t(`${translatePrefix}notSync`),
      color: 'error',
      icon: CloudOffIcon,
    });
  } else {
    if (value.goodStatus === 'published') {
      items.push({
        hint: t(`${translatePrefix}isPublished`),
        color: 'success',
        icon: CheckIcon,
      });
    } else {
      items.push({
        hint: t(`${translatePrefix}notPublished`, { status: value.goodStatus || 'null'}),
        color: 'warning',
        icon: EditIcon,
      });
    }

    if (value.goodMarkFlag) {
      items.push({
        hint: t(`${translatePrefix}isReadyForMarking`),
        color: 'success',
        icon: CheckIcon,
      });
    } else {
      items.push({
        hint: t(`${translatePrefix}notReadyForMarking`),
        color: 'warning',
        icon: DisabledIcon,
      });
    }

    if (value.goodSignedFlag) {
      items.push({
        hint: t(`${translatePrefix}isSigned`),
        color: 'success',
        icon: CheckIcon,
      });
    } else {
      items.push({
        hint: t(`${translatePrefix}notSigned`),
        color: 'warning',
        icon: DisabledIcon,
      });
    }

    if (value.goodTurnFlag) {
      items.push({
        hint: t(`${translatePrefix}isTurnover`),
        color: 'success',
        icon: CheckIcon,
      });
    } else {
      items.push({
        hint: t(`${translatePrefix}notTurnover`),
        color: 'warning',
        icon: DisabledIcon,
      });
    }
  }

  return (
    <Box component="span" display="flex">
      {items.map((item, index) => (
        <StatusItem key={index} {...item} />
      ))}
    </Box>
  );
}

ProductStatusField.defaultProps = {
  addLabel: true
};

export default ProductStatusField;
