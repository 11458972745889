class AppStorage {
  ACCESS_TOKEN = 'accesstoken';
  STOCK_ID = 'stockId';

  companyId = null;

  updateCompanyId = (id = null) => {
    this.companyId = id;
  }

  setAccessToken = (value = null) => {
    if (value === null) {
      this.removeAccessToken();
    } else {
      localStorage.setItem(this.ACCESS_TOKEN, value);
    }
  }
  getAccessToken = () => {
    return localStorage.getItem(this.ACCESS_TOKEN) || null;
  }
  removeAccessToken = () => {
    localStorage.removeItem(this.ACCESS_TOKEN);
  }


  _getStockIdStorageKey = (companyId = this.companyId) => {
    if (companyId === null) {
      throw new Error('Company ID is null')
    }
    return `${this.STOCK_ID}:${companyId}`;
  }

  setStockId = (value = null, companyId) => {
    if (value === null) {
      this.removeAccessToken(companyId);
    } else {
      const key = this._getStockIdStorageKey(companyId);
      localStorage.setItem(key, value);
    }
  }
  getStockId = (companyId) => {
    const key = this._getStockIdStorageKey(companyId);
    return localStorage.getItem(key) || null;
  }
  removeStockId = (companyId) => {
    const key = this._getStockIdStorageKey(companyId);
    localStorage.removeItem(key);
  }
}

export default AppStorage;
