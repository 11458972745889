import { MarkingInputIcon } from '../../../../components/ui/Icon';
import inputFromContractChild from './inputFromContractChild';
import markingType from '../../enums/markingType';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';

const name = 'marking/input-from-contract';
const precessName = 'input_from_contract';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: MarkingInputIcon,
  precessName,
  apiBasePath,
  childResource: inputFromContractChild,
  allowUpdateAction: true,
  group: 'input',
  menuConfig: {
    text: `resources.${name}.subMenuName`,
  },
  schema: {
    type: 'object',
    properties: {
      'data.producer_inn': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
          format: 'inn',
        }
      },
      'data.owner_inn': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
          format: 'inn',
        }
      },
      'data.marking_type': {
        type: 'string',
        defaultValue: null,
        componentInput: markingType.Input,
        componentField: markingType.Field,
        validation: {
          required: true,
        }
      },
      'data.production_order': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        }
      },
      'data.production_date': {
        type: 'date',
        defaultValue: null,
        validation: {
          required: true,
        }
      },
    }
  },
  createFields: [
    'data.producer_inn',
    'data.owner_inn',
    'data.marking_type',
    'data.production_order',
    'data.production_date',
  ],
});
