import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Log |||| Logs',
      fields: {
        level: 'Type',
        task_id: 'Task ID',
        request_id: 'Request ID',
        datetime: 'Date',
        message: 'Message',
        shortMessage: 'Message',
        args: 'Arguments'
      }
    }
  }
}