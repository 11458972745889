import ResourceIcon from '@material-ui/icons/AccountBalanceWallet';
import RefreshIcon from '@material-ui/icons/Refresh';
import request from '../../../../network/request';
import { fillUrl } from '../../../../utils/url';
import productGroupEnum from '../../enums/productGroupEnum';

const name = 'report/balance';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/cm/company/balance/',
    mapping: {
      inputItem: (data) => {
        data.id = data.product_group;
        if (data.balance) {
          data.balance = new Intl.NumberFormat("ru-RU", {
            style: "currency",
            currency: "RUB",
          }).format(data.balance);
        }
        return data;
      }
    }
  },
  itemToString: x => x.product_group || `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      product_group: {
        type: 'string',
        componentField: productGroupEnum.Field,
        componentInput: productGroupEnum.Input,
        readOnly: true,
      },
      updated: {
        type: 'dateTime',
      },
      balance: {
        type: 'string',
      }
    }
  },
  allowList: true,
  allowCreate: false,
  allowEdit: false,
  allowShow: false,
  allowDelete: false,
  showInMenu: true,
  group: 'reports',
  listFields: [
    'product_group',
    'updated',
    'balance',
  ],
  listActions: [
    {
      name: `resources.${name}.actions.update.name`,
      Icon: RefreshIcon,
      handler: async () => {
        const path = fillUrl('/cm/company/company/{{companyId}}/update-balance/');

        let response = null;
        try {
          response = await request({
            path,
            method: 'POST',
          });
        } catch (e) { }

        if (response && response.ok) {
          return {
            notification: {
              type: 'success',
              message: `resources.${name}.actions.update.successMessage`
            }
          }
        }

        return {
          notification: {
            type: 'error',
            message: `resources.${name}.actions.update.failureMessage`
          }
        }
      }
    }
  ],
};
