import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {
    translate,
    MenuItemLink,
    Responsive,
    Menu as RAMenu,
} from 'react-admin';
import { withRouter } from 'react-router-dom';
import SubMenu from './SubMenu';
import styles from './styles';
import { Divider } from '@material-ui/core';


const MenuItem = ({ item, parent=null, sidebarOpen, handleToggle, isOpen, onMenuClick, dense, translate}) => {
  let text = null;
  if (item.text) {
    text = translate(item.text);
  } else {
    text = translate(item.name, { smart_count: 2 });
  }

  if (item.items && item.items.length > 0) {
    return (
      <SubMenu
        handleToggle={() => handleToggle(item.name)}
        isOpen={isOpen}
        name={text}
        icon={item.icon && <item.icon />}
        dense={dense}
        sidebarIsOpen={sidebarOpen}
      >
        {item.items.map(subItem => (
          <MenuItem
            key={item.name}
            item={subItem}
            parent={item}
            sidebarOpen={sidebarOpen}
            handleToggle={handleToggle}
            isOpen={isOpen}
            onMenuClick={onMenuClick}
            dense={dense}
            translate={translate}
            disabled={item.disabled}
          />
        ))}
      </SubMenu>
    );
  }
  if (item.url) {
    let style = styles.menuItem;
    if (parent !== null) {
      style = sidebarOpen ? styles.menuSubItemFull : styles.menuSubItemShort;
    }
    let Icon = item.icon;
    if (!Icon && item.type === 'back') {
      Icon = ArrowBackIcon
    }
    return (
      <MenuItemLink
        style={style}
        to={item.url}
        exact={item.exact}
        primaryText={(
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {text}
          </span>
        )}
        onClick={onMenuClick}
        disabled={item.disabled}
        leftIcon={Icon && <Icon />}
      />
    )
  }

  return null;
}


class Menu extends Component {

  state = {
    currentItem: null
  }

  handleToggle = (name) => {
    this.setState(state => ({
      currentItem: state.currentItem === name ? null : name
    }));
  }

  render() {
    const {
      items = [],
      sidebarOpen,
      onMenuClick,
      dense,
      logout,
      translate,
    } = this.props;

    return (
      <RAMenu>
        <div style={styles.root}>
            {items.map((item, index) => (
              <Fragment key={item.name}>
                {(item.separate && index > 0) && (
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                )}
                <MenuItem
                  item={item}
                  sidebarOpen={sidebarOpen}
                  handleToggle={this.handleToggle}
                  isOpen={this.state.currentItem === item.name}
                  onMenuClick={onMenuClick}
                  dense={dense}
                  translate={translate}
                  disabled={item.disabled}
                />
              </Fragment>
            ))}
            <Responsive xsmall={logout} medium={null} />
        </div>
      </RAMenu>
    );
  }
}

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  logout: PropTypes.object,
};


const enhance = compose(
    withRouter,
    connect(
        state => ({
            theme: state.theme,
            // locale: state.i18n.locale,
            sidebarOpen: state.admin.ui.sidebarOpen,
        }),
        {}
    ),
    translate
);

export default enhance(Menu);
