import React from 'react';
import moment from 'moment';
import { useLocale, useSetLocale } from 'react-admin';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TranslateIcon from '@material-ui/icons/Translate';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import { setLangToStorage } from '../../i18n';
import { Tooltip } from '@material-ui/core';
import { translate } from 'react-admin';

const styles = {
};

const LanguageSelect = (props) => {
  const {
    translate
  } = props;
  const locale = useLocale();
  const raSetLocale = useSetLocale();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setLocale = (locale) => {
    setAnchorEl(null);
    raSetLocale(locale);
    setLangToStorage(locale);
    moment.locale(locale);
  }

  return (
    <div>
      <Tooltip title={translate('languageSelect.tooltip')}>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          color="inherit"
        >
          <TranslateIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          disabled={locale === 'en'}
          onClick={() => setLocale('en')}
        >
          English
          </MenuItem>
        <MenuItem
          disabled={locale === 'ru'}
          onClick={() => setLocale('ru')}
        >
          Русский
          </MenuItem>
      </Menu>
    </div>
  );
}


export default compose(
  translate,
  withStyles(styles)
)(LanguageSelect);
