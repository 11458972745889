import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        ACCEPT_ALL: 'Принять все',
        REJECT_ALL: 'Отклонить все',
        ACCEPT_OTHER: 'Принять остольное',
        REJECT_OTHER: 'Отклонить остальное',
        AUTO: 'Автоматически',
      },
    }
  }
}
