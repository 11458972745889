export default {
  name: 'marking/enums/dropoutReasonType',
  enums: [
    'DEFECT',
    'EXPIRY',
    'QA_SAMPLES',
    'PRODUCT_RECALL',
    'COMPLAINTS',
    'PRODUCT_TESTING',
    'DEMO_SAMPLES',
    'OTHER',
    'DAMAGE_LOSS',
    'DESTRUCTION',
    'LIQUIDATION',
    'CONFISCATION',
  ],
};
