import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'PRODUCED_IN_RF': 'Произеден в РФ',
        'IMPORTED_INTO_RF': 'Ввезен в РФ',
      },
    }
  }
}
