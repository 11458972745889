import { getResourceNameByDoctype } from "./mapDocProcess";
import getResourceUrl from "../admin/getResourceUrl";

export default (doctype, id, pageType = 'EDIT') => {
  const resourceName = getResourceNameByDoctype(doctype);
  if (resourceName === null) {
    return null;
  }
  return getResourceUrl({
    resourceName: `marking/${resourceName}`,
    pageType,
    id,
  });
}