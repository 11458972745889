import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        KM_SPOILED: 'Label with CM is damaged or lost',
        DESCRIPTION_ERRORS: 'Product description errors identified',
        RETAIL_RETURN: 'Return of goods with damaged Label / without Label during retail sale (return from a retail buyer)',
        REMOTE_SALE_RETURN: 'Return of goods with damaged Label / without Label in case of distance selling (return in case of distance sale)',
        LEGAL_RETURN: 'Return from the end customer (legal entity / individual entrepreneur)',
        INTERNAL_RETURN: 'Decision on the sale of goods purchased for purposes not related to their sale',
        EEC_EXPORT_RETURN: 'Return of items previously exported to the EAEU',
      },
    }
  }
}
