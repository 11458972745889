import * as Sentry from '@sentry/browser';
import appConfig from '../appConfig';

class ErrorTracking {
  ready = false;

  init() {
    if (this.ready || !appConfig.data.sentryDSN) {
      return;
    }
    const sentryEnv = appConfig.data.sentryEnvironment || appConfig.data.environment;
    Sentry.init({
      dsn: appConfig.data.sentryDSN,
      environment: sentryEnv,
    });

    this.ready = true;
  }

  captureException(error) {
    if (!this.ready) {
      return;
    }
    Sentry.captureException(error);
  }

  captureRequestError(response) {
    if (!this.ready) {
      return;
    }
    // console.log('captureRequestError', response)
    if (response.status < 400) {
      return;
    }
    // TODO: доделать
    // Sentry.captureException(new Error(response));
  }
}

export default new ErrorTracking();

