import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Товар - отчет о нанесении |||| Товары - отчет о нанесении',
      fields: {
        'data.code': 'Полный код маркировки',
      }
    }
  }
}
