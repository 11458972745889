import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        DEFECT: 'Defect',
        EXPIRY: 'Expiry',
        QA_SAMPLES: 'QA samples',
        PRODUCT_RECALL: 'Product recall',
        COMPLAINTS: 'Complaints',
        PRODUCT_TESTING: 'Product testing',
        DEMO_SAMPLES: 'Demo samples',
        OTHER: 'Other',
        DAMAGE_LOSS: 'Damage loss',
        DESTRUCTION: 'Destruction',
        LIQUIDATION: 'Liquidation',
        CONFISCATION: 'Confiscation',
      },
    }
  }
}
