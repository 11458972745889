import React, { Component } from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import { DateRangePicker } from "react-date-range";
import { Button, Toolbar } from "@material-ui/core";
import { ru } from 'date-fns/locale';
import {
  translate as translateRA,
} from "react-admin";
import {
  defaultStaticRanges,
  defaultInputRanges
} from "react-date-range/dist/defaultRanges";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const styles = (theme) => {
  const basePadding = theme.spacing(2);
  return {
    editor: {
      height: "300px !important",
      width: "100% !important",
      marginTop: basePadding,
      fontFamily: "monospace",
    },
    editorFullSize: {
      height: "500px !important",
      width: `calc(100% + ${basePadding * 2}px) !important`,
      margin: `0 -${basePadding}px -${basePadding}px`,
      fontFamily: "monospace",
    },
  };
};

class DateRangeInput extends Component {
  state = {
    anchorEl: null,
    range: null,
  };
  constructor(props) {
    super(props);

    const { value } = props;

    this.state = {
      anchorEl: null,
      range: {
        startDate: value[0],
        endDate: value[1],
        key: "selection",
      },
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setValueToRange();
    }
  }

  setValueToRange = () => {
    const { value } = this.props;
    this.setState({
      range: {
        startDate: value[0],
        endDate: value[1],
        key: "selection",
      },
    })
  }

  setAnchorEl = (anchorEl) => this.setState({ anchorEl });

  handleOpen = (event) => this.setAnchorEl(event.currentTarget);

  handleClose = () => this.setAnchorEl(null);

  handleChange = (item) => {
    this.setState({ range: item.selection });
  };

  save = () => {
    const { onChange } = this.props;
    const { range } = this.state;
    onChange([range.startDate, range.endDate]);
    this.handleClose();
  }

  getPrettyValue = () => {
    const { range } = this.state;
    if (range === null) {
      return "-";
    }

    const from = range.startDate.toLocaleDateString();
    const to = range.endDate.toLocaleDateString();

    return `${from} - ${to}`;
  };

  translateRange = (range) => {
    const { translate } = this.props;
    return range.map(x => ({ ...x, label: translate(`dateRange.${x.label}`) }));
  }

  render() {
    const {
      label,
      disabled,
      translate,
      locale,
    } = this.props;
    const { anchorEl, range } = this.state;

    const isOpen = Boolean(anchorEl);

    const prettyValue = this.getPrettyValue();

    return (
      <div>
        <TextField
          label={label}
          disabled={disabled}
          value={prettyValue}
          onClick={this.handleOpen}
        />

        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={isOpen}
          onClose={this.handleClose}
          den
        >
          <Toolbar variant="dense">
            <Button variant="contained" color="primary" style={{ marginLeft: 'auto '}} onClick={this.save}>{translate('dateRange.Apply')}</Button>
          </Toolbar>
          <DateRangePicker
            onChange={this.handleChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[range]}
            direction="horizontal"
            color="#FFD256"
            rangeColors={['#FFD256']}
            locale={locale === 'ru' ? ru : undefined}
            staticRanges={this.translateRange(defaultStaticRanges)}
            inputRanges={this.translateRange(defaultInputRanges)}
          />
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.app.theme,
});

const DateRangeInputConnected = compose(
  translateRA,
  connect(mapStateToProps),
  withStyles(styles)
)(DateRangeInput);

DateRangeInputConnected.defaultProps = {
  addLabel: true,
};

export default DateRangeInputConnected;
