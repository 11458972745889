import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Acceptance |||| Acceptance',
      actions: {
        loadCodes: {
          name: 'Load codes',
          successMessage: 'Request sent',
          failureMessage: 'Request failed',
        },
      },
      fields: {
        'meta.status': 'Status',
        'meta.created': 'Created date'
      }
    }
  }
}
