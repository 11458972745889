import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Export',
      title: 'Export',
      executeAction: 'Start',
      errorMessage: 'Error',
      successMessage: 'Success',
      loadingData: 'Downloading data...',
      prepareFile: 'File formation...',
      csvWarinig1: 'This export method uses the formation of a file in the browser.',
      csvWarinig2: 'When using CSV format and a large number of records, the browser tab may hang.',
      csvWarinig3: 'It is recommended to reduce the limit to 30,000 or use JSON format.',
      fields: {
        limit: 'Limit',
        offset: 'Offset',
        format: 'File Format',
        fileName: 'File Name',
      },
      result: {
        title: 'Export completed',
        total: 'Downloaded records',
        hasNext: 'There are more records.',
        loadNext: 'Next Part',
      },
    }
  }
}
