import ResourceIcon from '@material-ui/icons/Input';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';

const name = 'marking/input-from-contract-child';
const parentResourceName = 'marking/input-from-contract';
const apiBasePath = '/bp/processes/input_from_contract/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  itemToString: x => x.uit || `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      'data.uit': {
        type: 'string',
        defaultValue: null,
      },
      'data.tnved_code': {
        type: 'string',
        defaultValue: null,
      },
      'data.production_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.certificate_document': {
        type: 'string',
        defaultValue: null,
      },
      'data.certificate_document_number': {
        type: 'string',
        defaultValue: null,
      },
      'data.certificate_document_date': {
        type: 'date',
        defaultValue: null,
      },
    }
  },
  listFields: [
    'data.uit',
    'data.tnved_code',
    'data.production_date',
  ],
});