import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        CIS: 'CIS',
        GTIN: 'GTIN',
        EAN: 'EAN',
        DOCUMENT_ID: 'Document',
        VENDOR_CODE: 'Vendor code',
        CODE_ID: 'Code ID',
      },
    }
  }
}
