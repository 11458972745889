import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Товар - заказ на эмиссию |||| Товары - заказ на эмиссию',
      fields: {
        'data.gtin': 'GTIN',
        'data.quantity': 'Количество',
        'data.serial_number_type': 'Тип генерации серийного номера',
        'data.serial_numbers': 'Список серийных номеров',
        'data.template_id': 'Номер шаблона кода маркировки',
        'data.cis_type': 'Тип кода маркировки',
        'data.exporter_taxpayer_id': 'ИНН/УНБ (или аналог) экспортера',
        'data.exp_date': 'Срок годности (Дата)',
        'data.exp_date_72': 'Срок годности (Дата и время)',
      }
    }
  }
}