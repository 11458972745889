import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Ввод в оборот (Производство) |||| Ввод в оборот (Производство)',
      fields: {
        'data.producer_inn': 'ИНН производителя',
        'data.owner_inn': 'ИНН собственника',
        'data.uit_code': 'КИ',
        'data.uitu_code': 'КИТУ',
        'data.tnved_code': 'ТН ВЭД (10 символов)',
        'data.marking_type': 'Тип маркировки',
        'data.production_date': 'Дата производства',
      }
    }
  }
}
