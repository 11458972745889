import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Создать документ',
      title: 'Создание нового документа',
      invalid: 'Неверные параметры запроса',
      cancelAction: 'Отмена',
    }
  }
}
