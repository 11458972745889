import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Ввод в оборот (Производство) |||| Ввод в оборот (Производство)',
      subMenuName: 'Производство',
      fields: {
        'data.participant_inn': 'ИНН участника',
        'data.producer_inn': 'ИНН производителя',
        'data.owner_inn': 'ИНН собственника',
        'data.marking_type': 'Тип маркировки',
        'data.production_type': 'Тип производственного заказа',
        'data.production_date': 'Дата производства товара',
      }
    }
  }
}