import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        ADDING: 'Добавление',
        REMOVING: 'Изъятие',
      },
    }
  }
}
