import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Transfer item |||| Transfer items',
      fields: {
        'data.code': 'UIT/UITU',
      },
    }
  }
}