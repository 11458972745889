import ResourceIcon from '@material-ui/icons/Input';
import genderType from '../../../enums/genderType';
import remainsReleaseMethodType from '../../../enums/remainsReleaseMethodType';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';

const name = 'marking/remains-description-child';
const parentResourceName = 'marking/remains-description';
const apiBasePath = '/bp/processes/ost_description/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  schema: {
    type: 'object',
    properties: {
      'data.product_gender': {
        type: 'string',
        componentInput: genderType.Input,
        componentField: genderType.Field,
        validation: {
          required: true,
        }
      },
      'data.release_method': {
        type: 'string',
        componentInput: remainsReleaseMethodType.Input,
        componentField: remainsReleaseMethodType.Field,
        validation: {
          required: true,
        }
      },
      'data.tnved_code_2': {
        type: 'string',
        defaultValue: '64',
        validation: {
          required: true,
          minLength: 2,
          maxLength: 2,
        }
      },
    }
  },
  listFields: [
    'data.product_gender',
    'data.release_method',
    'data.tnved_code_2',
  ],
});