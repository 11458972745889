import React from 'react';
import {
  translate as tWrap,
} from '../../components/Admin';


export default (data) => {
  const {
    name,
    itemToString = x => `#${x.id}`,
  } = data;

  return tWrap(({ record, translate }) => (
    <span>
        {translate(`resources.${name}.name`, { smart_count: 1 })} &quot;{itemToString(record)}&quot;
    </span>
  ))
}