import {
  camelCase,
  capitalCase,
  constantCase,
  dotCase,
  headerCase,
  noCase,
  paramCase,
  pascalCase,
  pathCase,
  sentenceCase,
  snakeCase,
  // swapCase,
  // isLowerCase,
  // isUpperCase,
  // lowerCase,
  // lowerCaseFirst,
  // upperCase,
  // upperCaseFirst,
} from 'change-case';
import inflection from 'inflection';

const caseEnums = {
  CAMEL_CASE: 'CAMEL_CASE', // testTest
  CAPITAL_CASE: 'CAPITAL_CASE', // Test Test
  CONSTANT_CASE: 'CONSTANT_CASE', // TEST_TEST
  DOT_CASE: 'DOT_CASE', // test.test
  HEADER_CASE: 'HEADER_CASE', // Test-Test
  NO_CASE: 'NO_CASE', // test test
  PARAM_CASE: 'PARAM_CASE', // test-test
  PASCAL_CASE: 'PASCAL_CASE', // TestTest
  PATH_CASE: 'PATH_CASE', // test/test
  SENTENCE_CASE: 'SENTENCE_CASE', // Test test
  SNAKE_CASE: 'SNAKE_CASE', // test_test
  AS_IS: 'AS_IS',
  TITLE_CASE: 'TITLE_CASE', // Test test-test
  // SWAP_CASE: 'SWAP_CASE',
  // LOWER_CASE: 'LOWER_CASE',
  // LOWER_CASE_FIRST: 'LOWER_CASE_FIRST',
  // UPPER_CASE: 'UPPER_CASE',
  // UPPER_CASE_FIRST: 'UPPER_CASE_FIRST',
}

const asIs = x => x;

const titleCase = (str) => {
  if (typeof str !== 'string') {
    return str;
  }
  // #TODO: добавить обработку для аббревиатур, например "DownloadCSV"
  const allUpper = str.toUpperCase() === str;
  return inflection.humanize(inflection.underscore(str, allUpper));
};

const enumHandlers = {
  [caseEnums.CAMEL_CASE]: camelCase,
  [caseEnums.CAPITAL_CASE]: capitalCase,
  [caseEnums.CONSTANT_CASE]: constantCase,
  [caseEnums.DOT_CASE]: dotCase,
  [caseEnums.HEADER_CASE]: headerCase,
  [caseEnums.NO_CASE]: noCase,
  [caseEnums.PARAM_CASE]: paramCase,
  [caseEnums.PASCAL_CASE]: pascalCase,
  [caseEnums.PATH_CASE]: pathCase,
  [caseEnums.SENTENCE_CASE]: sentenceCase,
  [caseEnums.SNAKE_CASE]: snakeCase,
  [caseEnums.AS_IS]: asIs,
  [caseEnums.TITLE_CASE]: titleCase,
  // [caseEnums.SWAP_CASE]: swapCase,
  // [caseEnums.LOWER_CASE]: lowerCase,
  // [caseEnums.LOWER_CASE_FIRST]: lowerCaseFirst,
  // [caseEnums.UPPER_CASE]: upperCase,
  // [caseEnums.UPPER_CASE_FIRST]: upperCaseFirst,
}

const changeCase = (caseName, text) => {
  if (!(caseName in enumHandlers)) {
    throw new Error(`Unknown text case "${caseName}"`);
  }
  if (typeof text !== 'string') {
    return String(text);
  }
  return enumHandlers[caseName](text);
}

export default {
  ...caseEnums,
  changeCase,

  camelCase,
  capitalCase,
  constantCase,
  dotCase,
  headerCase,
  noCase,
  paramCase,
  pascalCase,
  pathCase,
  sentenceCase,
  snakeCase,
  asIs,
  titleCase,
  // swapCase,
  // isLowerCase,
  // isUpperCase,
  // lowerCase,
  // lowerCaseFirst,
  // upperCase,
  // upperCaseFirst,
};


window.cases = {
  ...caseEnums,
  changeCase,

  camelCase,
  capitalCase,
  constantCase,
  dotCase,
  headerCase,
  noCase,
  paramCase,
  pascalCase,
  pathCase,
  sentenceCase,
  snakeCase,
  asIs,
  titleCase,
  // swapCase,
  // isLowerCase,
  // isUpperCase,
  // lowerCase,
  // lowerCaseFirst,
  // upperCase,
  // upperCaseFirst,
}
