import React from "react";
import { Typography } from '@material-ui/core';
import getValue from "../../../../../utils/getValue";

const ProductTnVedField = (props) => {
  const { record, emptyText } = props;

  let value =
    getValue(record, "tn_ved_code_10") || getValue(record, "tn_ved_code");

  if (!value && emptyText) {
    value = emptyText;
  }

  return (
    <Typography component="span" variant="body2">
      {value}
    </Typography>
  );
};

ProductTnVedField.defaultProps = {
  addLabel: true,
};

export default ProductTnVedField;
