import ResourceIcon from '@material-ui/icons/Input';
import remarkingCause from '../../../enums/remarkingCause';
import certDocumentType from '../../../enums/certDocumentType';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';
import remarkDocumentType from '../../../enums/remarkDocumentType';

const name = 'marking/remarking-child';
const parentResourceName = 'marking/remarking';
const apiBasePath = '/bp/processes/remark_remark/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  schema: {
    type: 'object',
    properties: {
      'data.new_uin': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        }
      },
      'data.last_uin': {
        type: 'string',
        defaultValue: null,
      },
      'data.remarking_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.remarking_cause': {
        type: 'string',
        componentField: remarkingCause.Field,
        componentInput: remarkingCause.Input,
        defaultValue: null,
      },
      'data.certificate_document': {
        type: 'string',
        componentField: certDocumentType.Field,
        componentInput: certDocumentType.Input,
        defaultValue: null,
      },
      'data.certificate_document_number': {
        type: 'string',
        defaultValue: null,
      },
      'data.certificate_document_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.primary_document_type': {
        type: 'string',
        defaultValue: null,
      },
      'data.primary_document_custom_name': {
        type: 'string',
        defaultValue: null,
      },
      'data.primary_document_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.primary_document_number': {
        type: 'string',
        componentField: remarkDocumentType.Field,
        componentInput: remarkDocumentType.Input,
        defaultValue: null,
      },
      'data.tnved_10': {
        type: 'string',
        defaultValue: null,
      },
      'data.production_country': {
        type: 'string',
        defaultValue: null,
      },
      'data.color': {
        type: 'string',
        defaultValue: null,
      },
      'data.product_size': {
        type: 'string',
        defaultValue: null,
      },
    }
  },
  listFields: [
    'data.new_uin',
    'data.last_uin',
    'data.remarking_cause',
  ],
});









