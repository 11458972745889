import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'ON_ORDER': 'При заказе',
        'ON_INPUT': 'При вводе в оборот',
      },
    }
  }
}
