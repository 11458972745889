import ResourceIcon from '@material-ui/icons/Category';
import productGroupEnum from '../../enums/productGroupEnum';

const name = 'settings/productGroup';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/cm/company/pg/',
  },
  itemToString: x => x.product_group || `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      product_group: {
        type: 'string',
        componentField: productGroupEnum.Field,
        componentInput: productGroupEnum.Input,
        readOnly: true,
      },
      is_enabled: {
        type: 'boolean',
      },
    }
  },
  allowList: true,
  allowCreate: false,
  allowEdit: true,
  allowShow: false,
  allowDelete: false,
  showInMenu: true,
  group: 'settings',
  editFields: [
    'id',
    'product_group',
    'is_enabled',
  ],
  listFields: [
    'id',
    'product_group',
    'is_enabled',
  ],
};
