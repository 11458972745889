import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        APPLIED: 'Applied',
        EMITTED: 'Emitted',
        INTRODUCED: 'Introduced',
        RETIRED: 'Retired',
        DISAGGREGATION: 'Disaggregated',
      },
    }
  }
}