import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Company |||| Companies',
      fields: {
        'is_testing': 'Test company',
        'info.inn': 'INN',
        'info.contact_person': 'Contact Person',
        'info.address': 'Company Address',
      }
    }
  }
}
