import React, { PureComponent } from "react";
import { addField, AutocompleteInput } from "react-admin";
import request from "../../../../../network/request";

class ProductAutocomplete extends PureComponent {
  state = {
    value: "",
    choices: [],
  };

  constructor(props) {
    super(props);
    this.state.choices = this.getChoices();
  }

  componentDidMount() {
    this.loadProducts();
  }

  getChoices = (items = [], query) => {
    const {
      input: { value },
    } = this.props;

    if (value && items.findIndex((x) => x.id === value) === -1) {
      items = items.filter((x) => !x.initial);
      const custom = { id: value, name: value, initial: true };
      items = [custom, ...items];
    }

    if (query && items.findIndex((x) => x.id === query) === -1) {
      const custom = { id: query, name: query, custom: true };
      items = [custom, ...items];
    }

    return items;
  };

  loadProducts = async (query = "") => {
    const filters = {};
    if (query) {
      filters.gtin__contains = query;
    }

    let response = null;
    try {
      response = await request({
        path: `/bp/product/products/`,
        method: "GET",
        urlParams: {
          limit: 100,
          ...filters,
        },
      });
    } catch (e) {
      console.error(e);
    }

    let items = [];
    if (response === null || !response.ok) {
      // if (response !== null) {
      //   error = response.data
      // }
    } else {
      items = response.data.results.map((x) => ({
        id: x.gtin,
        name: `${x.gtin} - ${x.name}`,
      }));
    }

    const choices = this.getChoices(items, query);

    this.setState({
      choices,
    });
  };

  setFilter = (value) => {
    let { choices } = this.state;
    choices = this.getChoices(choices);
    const customIndex = choices.findIndex((x) => !x.custom);
    if (customIndex !== -1 && choices[customIndex].id === value) {
      return;
    }
    if (customIndex === -1 && !value) {
      return;
    }
    let newChoices = choices;
    if (customIndex !== -1) {
      newChoices = choices.filter((x) => !x.custom);
    }
    if (value) {
      newChoices = [{ id: value, name: value, custom: true }, ...newChoices];
    }

    this.setState({
      choices: newChoices,
    });
  };

  matchSuggestion = (query, item) => {
    return item.id.includes(query);
  };

  render() {
    const { ...restProps } = this.props;
    let { choices } = this.state;

    return (
      <AutocompleteInput
        {...restProps}
        setFilter={this.setFilter}
        matchSuggestion={this.matchSuggestion}
        choices={choices}
        translateChoice={false}
      />
    );
  }
}

export default addField(ProductAutocomplete);
