import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RECEIPT: 'Кассовый чек',
        SALES_RECEIPT: 'Товарный чек',
        OTHER: 'Прочее',
      },
    }
  }
}
