import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Пользователь |||| Пользователи',
      fields: {
        preferred_username: 'Имя пользователя',
        email: 'Email',
        given_name: 'Имя',
        family_name: 'Фамилия',
      },
    }
  }
}