import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { Link } from 'react-router-dom';
import { linkToRecord, Record } from 'ra-core';

import { IconButton } from '@material-ui/core';

const ShowButton = ({
    basePath = '',
    label = 'ra.action.show',
    record,
    icon = defaultIcon,
    ...rest
}) => (
    <IconButton
        component={Link}
        size="small"
        color="default"
        to={`${linkToRecord(basePath, record && record.id)}/show`}
        onClick={stopPropagation}
        {...rest}
    >
        {icon}
    </IconButton>
);

const defaultIcon = <ImageEye />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

interface Props {
    basePath?: string;
    record?: Record;
    icon?: ReactElement;
}

ShowButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
};

const enhance = shouldUpdate(
    (props: Props, nextProps: Props) =>
        (props.record &&
            nextProps.record &&
            props.record.id !== nextProps.record.id) ||
        props.basePath !== nextProps.basePath ||
        (props.record == null && nextProps.record != null)
);

export default enhance(ShowButton);
