import ResourceIcon from '@material-ui/icons/EventAvailable';
import CodeInput from '../../../../components/CodeInput';
import request from '../../../../network/request';
import productGroupEnum from '../../enums/productGroupEnum';
import scheduledTaskEnum from '../../enums/scheduledTaskEnum';

const name = 'settings/scheduled-task';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/scheduling-service/task/tasks/',
    idField: 'pk',
    mapping: {
      outputItemAfter: (output) => {
        if (output && output.data) {
          try {
            output.data = JSON.parse(output.data);
          } catch {
            throw new Error('Data JSON invalid')
          }
        } else {
          output.data = {};
        }

        if (output && output.crontab) {
          try {
            output.crontab = JSON.parse(output.crontab);
          } catch {
            throw new Error('Crontab JSON invalid')
          }
        } else {
          output.crontab = {};
        }

        return output;
      },
      inputItemAfter: (input) => {
        if (input && input.data) {
          input.data = JSON.stringify(input.data, null, 2);
        } else {
          input.data = '{}';
        }

        if (input && input.crontab) {
          input.crontab = JSON.stringify(input.crontab, null, 2);
        } else {
          input.crontab = '{}';
        }

        return input;
      },
    },
  },
  itemToString: x => x.name || `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        validation: {
          required: true,
        }
      },
      task_name: {
        type: 'string',
        componentInput: scheduledTaskEnum.Input,
        componentField: scheduledTaskEnum.Field,
        validation: {
          required: true,
        }
      },
      enabled: {
        type: 'boolean',
        defaultValue: true,
      },
      last_run_at: {
        type: 'dateTime',
        readOnly: true,
      },
      total_run_count: {
        type: 'number',
        readOnly: true,
      },
      one_off: {
        type: 'boolean',
        defaultValue: false,
      },
      'meta.product_group': {
        type: 'string',
        componentField: productGroupEnum.Field,
        componentInput: productGroupEnum.Input,
      },
      data: {
        type: 'string',
        componentInput: CodeInput,
        defaultValue: '{}',
        options: {
          fullWidth: true,
          codeLanguage: 'json',
        },
      },
      crontab: {
        type: 'string',
        componentInput: CodeInput,
        defaultValue: `{
  "minute": "0",
  "hour": "*",
  "day_of_week": "*",
  "day_of_month": "*",
  "month_of_year": "*",
  "timezone": "Europe/Moscow"
}`,
        options: {
          fullWidth: true,
          codeLanguage: 'json',
        },
      },
    }
  },
  allowList: true,
  allowCreate: true,
  allowEdit: true,
  allowShow: false,
  allowDelete: true,
  showInMenu: true,
  group: 'settings',
  createFields: [
    'name',
    'task_name',
    'enabled',
    'meta.product_group',
    'crontab',
    'data',
    'one_off',
  ],
  editFields: [
    'id',
    'name',
    'task_name',
    'last_run_at',
    'total_run_count',
    'enabled',
    'one_off',
    'meta.product_group',
    'crontab',
    'data',
  ],
  listFields: [
    'id',
    'meta.product_group',
    'name',
    'task_name',
    'enabled',
    'last_run_at',
    'total_run_count',
  ],
  itemActions: [
    {
      name: `resources.${name}.actions.execute.name`,
      order: 10,
      handler: async ({ record }) => {
        const path = `/scheduling-service/task/tasks/${record.id}/execute/`;

        let response = null;
        try {
          response = await request({
            path,
            method: 'POST',
          });
        } catch (e) { }

        if (response && response.ok) {
          return {
            notification: {
              type: 'success',
              message: `resources.${name}.actions.execute.successMessage`
            }
          }
        }

        return {
          notification: {
            type: 'error',
            message: `resources.${name}.actions.execute.failureMessage`
          }
        }
      }
    }
  ],
};
