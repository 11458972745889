import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Запрос на печать |||| Запросы на печать',
      actions: {
        printPDF: 'Печать PDF',
        printCSV: 'Печать CSV',
        print: 'Печать',
      },
    }
  }
}
