import ResourceIcon from '@material-ui/icons/List';
import GuidField from '../../../../components/GuidField';
import getResourceUrl from '../../../../utils/admin/getResourceUrl';
import JsonField from '../../../../components/JsonField';


const name = 'report/task';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/bp/processes/{{document_process}}/{{document_id}}/task/',
    disableSorting: true,
  },
  itemToString: x => `#${x.id}`,
  schema: {
    type: 'object',
    properties: {

    }
  },
  allowList: true,
  allowCreate: false,
  allowEdit: false,
  allowShow: false,
  allowDelete: false,
  showInMenu: false,
  group: 'reports',
  listRowActions: [{
    name: `resources.${name}.actions.showLogs`,
    inline: true,
    getUrl: ({ record }) => record && getResourceUrl({
      resourceName: 'report/logs',
      pageType: 'LIST',
      params: {
        filter: {
          task_id: record.id,
        }
      }
    })
  }],
  listExpandFields: [
    { name: 'request', component: JsonField, options: { fullWidth: true }},
    { name: 'response', component: JsonField, options: { fullWidth: true }},
  ],
  listFilters: [
    { name: 'document_process', options: { alwaysOn: true}, readOnly: true },
    { name: 'document_id', options: { alwaysOn: true}, readOnly: true, },
  ],
  listDefaultSort: { field: 'datetime', order: 'DESC' },
  listFields: [
    { name: 'id', component: GuidField, sortable: false, },
    { name: 'document_id', sortable: false },
    { name: 'status', sortable: false },
  ],
};
