export default {
  name: 'marking/enums/receiptDocumentType',
  enums: [
    'RECEIPT',
    'SALES_RECEIPT',
    'CUSTOMS_DECLARATION',
    'CONSIGNMENT_NOTE',
    'UTD',
    'DESTRUCTION_ACT',
    'DESTRUCTION',
    'OTHER',
  ],
};

