import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        APPLIED: 'Нанесен',
        EMITTED: 'Эмитирован',
        INTRODUCED: 'Введен в оборот',
        RETIRED: 'Выбыл',
        DISAGGREGATION: 'Дизагрегрегирован'
      },
    }
  }
}
