import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RECEIPT: 'Cash register receipt',
        SALES_RECEIPT: 'Sales receipt',
        CONSIGNMENT_NOTE: 'Packing list',
        UTD: 'Universal transfer document',
        OTHER: 'Other (with manual indication of the name)',
      },
    }
  }
}
