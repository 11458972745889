import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RECEIPT: 'Кассовый чек',
        SALES_RECEIPT: 'Товарный чек',
        CUSTOMS_DECLARATION: 'Таможенная декларация на товары',
        CONSIGNMENT_NOTE: 'Товарная накладная',
        UTD: 'Универсальный передаточный документ',
        DESTRUCTION_ACT: 'Акт уничтожения (утраты/утилизации)',
        DESTRUCTION: 'Акт уничтожения (утраты/утилизации)',
        OTHER: 'Прочее (с указанием наименования вручную)',
      },
    }
  }
}
