import history from '../core/history';
import authProvider from '../network/authProvider';
import restProvider from '../network/rest';
import createStore from "./createStore";




const store = createStore({
  authProvider,
  dataProvider: restProvider,
  history,
});

export default store;
