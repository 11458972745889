import ResourceIcon from '@material-ui/icons/Input';
import markingType from '../../../enums/markingType';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';
import CompanyDataInput from '../../../../../components/CompanyDataInput';
import TnvedSelect from '../../../../../components/TnvedSelect/TnvedSelect';

const name = 'marking/input-self-made-child';
const parentResourceName = 'marking/input-self-made';
const apiBasePath = '/bp/processes/input_self_made/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  allowAgregation: true,
  schema: {
    type: 'object',
    properties: {
      'data.producer_inn': {
        type: 'string',
        defaultValue: null,
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
        validation: {
          required: false,
          format: 'inn',
        }
      },
      'data.owner_inn': {
        type: 'string',
        defaultValue: null,
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
        validation: {
          required: false,
          format: 'inn',
        }
      },
      'data.uit_code': {
        type: 'string',
        defaultValue: null,
      },
      'data.uitu_code': {
        type: 'string',
        defaultValue: null,
      },
      'data.tnved_code': {
        type: 'string',
        componentInput: TnvedSelect,
        defaultValue: null,
      },
      'data.marking_type': {
        type: 'string',
        componentInput: markingType.Input,
        componentField: markingType.Field,
        defaultValue: null,
      },
      'data.production_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.certificate_document': {
        type: 'string',
        defaultValue: null,
      },
      'data.certificate_document_number': {
        type: 'string',
        defaultValue: null,
      },
      'data.certificate_document_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.vsd_number': {
        type: 'string',
        defaultValue: null,
        options: {
          forProductGroups: ['milk'],
        },
      },
    }
  },
  listFields: [
    'data.uit_code',
    'data.uitu_code',
    'data.tnved_code',
  ],
});
