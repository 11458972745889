import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Приемка |||| Приемка',
      actions: {
        loadCodes: {
          name: 'Выгрузить коды',
          successMessage: 'Запрос на выгрузку кодов успешно отправлен',
          failureMessage: 'При запросе на выгрузку кодов произошла ошибка',
        },
      },
      fields: {
        'data.release_order_number': 'Идентификатор отгрузки',
        'data.trade_sender_inn': 'ИНН Отправителя',
        'data.trade_sender_name': 'Наименование Отправителя',
        'data.trade_recipient_inn': 'ИНН Получателя',
        'data.acceptance_date': 'Дата приемки',
        'data.document_date': 'Дата первичного документа',
        'data.document_number': 'Номер первичного документа',
        'data.turnover_type': 'Вид товарооборота',
        'data.transfer_date': 'Дата отгрузки товара',
        'data.exporter_name': 'Наименование экспортёра',
        'data.exporter_taxpayer_id': 'Уникальный идентификатор экспортёра в национальной системе учета налогоплательщиков',
        'data.release_method': 'Код способа ввода в оборот',
        'data.reject_all': 'Отклонить все',
        'data.accept_all': 'Принять все ',
      }
    }
  }
}
