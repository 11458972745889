import React from "react";
import locales from "./locales";
import config from "./config";
import { fillUrl } from "../../../../utils/url";
import company from "../../../common-service/resources/company";
import appCore from "../../../../appCore";

export default {
  ...config,
  resourceName: company.name,
  locales,
  PageComponent: (props) => {
    const id = appCore.getCompanyId();
    const baseUrl = fillUrl("/c/{{companyId}}/s/company");
    const redirectUrl = `${baseUrl}/settings/company`;
    return (
      <company.Edit
        {...props}
        id={id}
        basePath={baseUrl}
        redirectUrl={redirectUrl}
      />
    );
  },
};
