import React, { Component } from 'react';
import { connect } from 'react-redux';
import { colors } from '@material-ui/core';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import ChartCard from '../ChartCard';
import request from '../../network/request';
import { combineMetrics, getRangeParams } from '../../utils/ui/metrics';
import ChartSetSelect from '../ChartCard/ChartSetSelect';


class DocumentsMetricsWidget extends Component {
  state = {
    chartSetId: 'amounts',
    chartItems: [],
  }

  chartSets = [{
    id: 'amounts',
    metrics: [
      { 
        field: 'draft',
        name: 'Draft',
        color: colors.grey[300],
        stackId: 'docStatus',
        requestPath: 'document_info_total',
        requestStatuses: ['draft'],
      },
      {
        field: 'request_created',
        name: 'Request created',
        color: colors.amber[300],
        stackId: 'docStatus',
        requestPath: 'document_info_total',
        requestStatuses: ['new', 'created', 'processing', 'request_created', 'checking_document_status'],
      },
      { 
        field: 'completed',
        name: 'Completed',
        color: colors.green[300],
        stackId: 'docStatus',
        requestPath: 'document_info_total',
        requestStatuses: ['completed', 'ready', 'printed', 'WaitAcceptance', 'CheckedOk'],
      },
      {
        field: 'failed',
        name: 'Failed',
        color: colors.red[300],
        stackId: 'docStatus',
        requestPath: 'document_info_total',
        requestStatuses: ['cancelled', 'failed'],
      },
    ]
  },{
    id: 'events',
    metrics: [
      { 
        field: 'created',
        name: 'Created',
        color: colors.grey[300],
        requestPath: 'document_model_inserts_total',
        requestStatuses: [],
      },
      { 
        field: 'updated',
        name: 'Updated',
        color: colors.amber[300],
        requestPath: 'document_model_updates_total',
        requestStatuses: [],
      },
      { 
        field: 'deleted',
        name: 'Deleted',
        color: colors.red[300],
        requestPath: 'document_model_deletes_total',
        requestStatuses: [],
      },
    ]
  }]

  chartOptions = {
    xName: 'date',
    syncId: 'metricsWidgets',
  }
  
  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.viewVersion !== prevProps.viewVersion
      || this.props.metricsRange !== prevProps.metricsRange
      || this.state.chartSetId !== prevState.chartSetId
    ) {
      this.loadData();
    }
  }

  loadData = async () => {
    const {
      metricsRange,
    } = this.props;
    const {
      chartSetId,
    } = this.state;

    const metricsData = {};
    const chartSet = this.chartSets.find(x => x.id === chartSetId);

    const promisses = chartSet.metrics.map(async ({ field, requestStatuses, requestPath }) => {
      try {
        const path = `/bp/metrics/${requestPath}/`;
        const rangeParams = getRangeParams(metricsRange);
        const { data } = await request({
          path,
          method: 'POST',
          contentType: 'json',
          body: {
            ...rangeParams,
            labels__status: requestStatuses,
          }
        });

        if (data) {
          metricsData[field] = data;
        }
      } catch (e) { }
    })

    await Promise.all(promisses);

    const result = combineMetrics(metricsData);
    this.setState({
      chartItems: result,
    });
  }

  onChangeChartSet = (chartSetId) => {
    this.setState({
      chartSetId
    });
  }

  render() {
    const {
      translate,
    } = this.props;
    const {
      chartItems,
      chartSetId,
    } = this.state;
    const chartSet = this.chartSets.find(x => x.id === chartSetId);

    return (
      <ChartCard
        title={translate('widgets.documentsMetrics.title')}
        chartItems={chartItems}
        chartOptions={{
          ...this.chartOptions,
          yMetrics: chartSet.metrics,
        }}
        actions={[
          <ChartSetSelect 
            value={chartSetId}
            items={this.chartSets.map(x => ({ value: x.id }))}
            translatePrefix={'widgets.documentsMetrics.sets.'}
            onChange={this.onChangeChartSet}
          />
        ]}
      />
    );
  }
}

const mapStateToProps = state => ({
  metricsRange: state.metrics.metricsRange,
  viewVersion: state.admin.ui.viewVersion,
});

export default compose(
  connect(
    mapStateToProps,
  ),
  translate,
)(DocumentsMetricsWidget);
