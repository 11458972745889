import React, { memo, useState } from 'react';
import { useTranslate } from '../Admin';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import { ListItemText,ListItemIcon } from '@material-ui/core';
import useWatchConnectedEncodingService from '../../hooks/useWatchConnectedEncodingService';
import { CheckCircle as SuccessIcon, Error as ErrorIcon, HourglassFull as InitializingIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { fillUrl } from '../../utils/url';

const useStyles = makeStyles((theme) => ({
  initializingIcon: {
    color: 'inherit',
  },
  warinigIcon: {
    color: `${theme.palette.warning.main} !important`,
  },
  successIcon: {
    color: `${theme.palette.success.main} !important`,
  },
}));


const StateIcon = memo(({ state }) => {
  const classes = useStyles();
  if (state === 'initialazing') {
    return (<InitializingIcon className={classes.initializingIcon} />);
  }
  if (state === 'connected') {
    return (<SuccessIcon className={classes.successIcon} />);
  }
  if (state === 'error' || state === 'disconnected') {
    return (<ErrorIcon className={classes.warinigIcon} />);
  }
  return null;
});

const ItemRow = memo((props) => {
  const {
    label,
    state,
    messages,
  } = props;
  const translate = useTranslate();
  const message = messages[state];
  return (
    <MenuItem
      component={Link}
      to={fillUrl('/c/{{companyId}}/s/company/settings/encoding-server')}
    >
      <ListItemIcon style={{ minWidth: 35 }}>
        <StateIcon state={state}/>
      </ListItemIcon>
      <ListItemText
        primary={translate(label)}
        secondary={message && translate(message)}
      />
    </MenuItem>
  );
});

const CompanyStatusMenu = memo((props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const connectedEncoding = useWatchConnectedEncodingService();
    const translate = useTranslate();
    const label = 'companyStatusMenu.label'

    const open = Boolean(anchorEl);

    const handleMenu = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);


    const mainState = connectedEncoding;

    return (
        <div>
            <Tooltip title={translate(label, { _: label })}>
                <IconButton
                    aria-label={translate(label, { _: label })}
                    aria-owns={open ? 'status-menu' : null}
                    aria-haspopup={true}
                    onClick={handleMenu}
                >
                    <StateIcon state={mainState}/>
                </IconButton>
            </Tooltip>
            <Menu
                id="status-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
            >
              <ItemRow
                state={connectedEncoding}
                label={'companyStatusMenu.encodingRunner.label'}
                messages={{
                  initialazing: 'companyStatusMenu.encodingRunner.initialazing',
                  connected: 'companyStatusMenu.encodingRunner.connected',
                  disconnected: 'companyStatusMenu.encodingRunner.disconnected',
                  error: 'companyStatusMenu.encodingRunner.error',
                }}
              />
            </Menu>
        </div>
    );
});

export default CompanyStatusMenu;
