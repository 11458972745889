import ResourceIcon from '@material-ui/icons/SyncAlt';
import transferLocalChild from './transferLocalChild';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import stock from '../../../company-service/resources/stock';


const name = 'marking/transfer-local';
const precessName = 'transfer/local';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: ResourceIcon,
  precessName,
  apiBasePath,
  childResource: transferLocalChild,
  showExternalStatus: false,
  showExternalId: false,
  allowShowCodesAction: false,
  schema: {
    type: 'object',
    properties: {
      'data.stock': {
        type: 'string',
        componentInput: stock.Input,
        validation: {
          required: false,
        }
      },
      'data.stock_slug': {
        type: 'string',
        validation: {
          required: false,
        }
      },
    }
  },
  createFields: [
    'data.stock',
    'data.stock_slug',
  ],
});