import React, { useCallback, useState } from "react";
import {
  addField,
  useTranslate,
} from '../Admin';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SectionSeparator from "../ui/SectionTitle";
import SectionTitle from "../ui/SectionSeparator";
import CodeInput from "../CodeInput";


const ChildGeneratorSection = (props) => {
  const {
    input: {
      value,
      onChange,
    }
  } = props;

  const hasValue = Boolean(value);
  const [enabled, setEnabled] = useState(hasValue);
  const [strValue, setStrValue] = useState(hasValue ? JSON.stringify(value, null, 4) : `{
    "query": "",
    "count": null
}`);
  const translate = useTranslate();

  const handleChangeEnabled = useCallback((event) => {
    const checked = event.target.checked;
    setEnabled(event.target.checked);
    if (checked) {
      try {
        onChange({
          target: {
            value: JSON.parse(strValue),
          }
        });
      } catch(e) {}
    } else {
      onChange({
        target: {
          value: null,
        }
      });
    }
  }, [setEnabled]);

  const handleChangeValue = useCallback((event) => {
    setStrValue(event.target.value);
    try {
      const obj = JSON.parse(event.target.value);
      onChange({
        target: {
          value: obj,
        }
      });
    } catch (e) {}
  }, [setStrValue]);

  return (
    <div>
      <SectionSeparator />
      <SectionTitle label={translate('documents.childGenerator.sectionTitle')} />
      <FormControlLabel
        control={
          <Switch
            checked={enabled}
            onChange={handleChangeEnabled}
            name="childGeneratorEnabled"
            color="primary"
          />
        }
        label={translate('documents.childGenerator.fields.toggle')}
      />
      {enabled && (
        <div>
          <CodeInput
            fullWidth
            codeLanguage="json"
            input={{
              value: strValue,
              onChange: handleChangeValue,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default addField(ChildGeneratorSection);
