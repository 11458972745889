import * as React from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useListContext, Identifier } from "react-admin";

const useStyles = makeStyles((theme) => ({
  gridList: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
  },
  item: {
    width: '24%',
    minWidth: 200,
  },
}));

const times = (nbChildren: number, fn: (key: number) => any) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = () => {
  const classes = useStyles();
  return (
    <Box className={classes.gridList}>
      {times(8, (key) => (
        <Skeleton variant="rect" width="24%" height={200} />
      ))}
    </Box>
  );
};

const LoadedGridList = (props) => {
  const { ItemComponent } = props;
  const { ids, data } = useListContext();
  const classes = useStyles();

  if (!ids || !data) return null;

  return (
    <Box className={classes.gridList}>
      {ids.map((id: Identifier) => (
        <div className={classes.item}>
          <ItemComponent key={id} record={data[id]} />
        </div>
      ))}
    </Box>
  );
};

export const ListCardsView = (props) => {
  const { loaded } = useListContext();
  return loaded ? (
    <LoadedGridList {...props} />
  ) : (
    <LoadingGridList {...props} />
  );
};
