import ResourceShowPage from '../../components/ResourceShowPage';
import getFieldsFromSchema from './getFieldsFromSchema';


export default (options = {}) => {
  let {
    schema = null,
    showFields = null,
    showActions = null,
    allowCreate = true,
    allowEdit = true,
    allowShow = true,
    allowDelete = true,
    allowList = true,
    title,
  } = options;

  if (!allowShow || schema === null) {
    return null;
  }

  let withTabs = false;
  if (showFields && showFields.type) {
    if (showFields.type === 'tabbed') {
      withTabs = true;
    }
    showFields = showFields.items;
  }

  

  return (props) => (
    getFieldsFromSchema({
      schema,
      fields: showFields,
      Component: ResourceShowPage,
      validate: false,
      editable: false,
      allowCreate,
      allowEdit,
      allowDelete,
      allowList,
      actions: showActions,
      withTabs,
      title,
      ...props
    })
  )
}