import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Encoding server |||| Encoding servers',
      actions: {
        test: {
          name: 'Test connection',
          successMessage: 'Connection test successful',
          failureMessage: 'Connection test failed: "%{error}"',
        },
        ping: {
          name: 'Ping connection',
          successMessage: 'Ping request send',
          failureMessage: 'Ping request failed: "%{error}"',
        },
      },
      fields: {
        is_connected: 'Status',
      }
    }
  }
}
