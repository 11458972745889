import textCaseHelper from "../../../../utils/textCaseHelper";

export default {
  name: 'marking/enums/labelTemplateFileType',
  externalTextCase: textCaseHelper.SNAKE_CASE,
  enums: [
    'PDF',
    'HTML',
    'XLSX',
    'CSV',
    'XML',
    'JSON',
    'TXT',
  ],
};
