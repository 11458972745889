import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        COMPLETED: 'Обработан',
        REQUEST_CREATED: 'В обработке',
        PROCESSING: 'Обработка',
        CHECKING_DOCUMENT_STATUS: 'Проверка статуса',
        FAILED: 'Ошибка',
        DRAFT: 'Черновик',
        READY: 'Готов',
        WAIT_ACCEPTANCE: 'Ожидает приемки',
        EXECUTE_CANCELED: 'Отправка отменена',
      },
    }
  }
}
