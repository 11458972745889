import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Рабочий процесс |||| Рабочие процессы',
      fields: {
      }
    }
  }
}