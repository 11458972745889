import ResourceIcon from '@material-ui/icons/Bookmark';
import cancelCodesChild from './cancelCodesChild';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import CompanyDataInput from '../../../../components/CompanyDataInput';
import cancellationReason from '../../enums/cancellationReason';


const name = 'marking/cancel-codes';
const precessName = 'cancel_not_applied';
const apiBasePath = `/bp/processes/${precessName}/`;


export default docConfigCreator({
  name,
  icon: ResourceIcon,
  precessName,
  apiBasePath,
  group: 'cancel',
  childResource: cancelCodesChild,
  allowUpdateAction: true,
  menuConfig: {
    text: `resources.${name}.subMenuName`,
  },
  schema: {
    type: 'object',
    properties: {
      'data.trade_participant_inn': {
        type: 'string',
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
        validation: {
          format: 'inn',
        }
      },
      'data.cancellation_reason': {
        type: 'string',
        componentInput: cancellationReason.Input,
        componentField: cancellationReason.Field,
        validation: {
          required: true,
        }
      },
      'data.cancellation_doc_number': {
        type: 'string',
        validation: {
          required: true,
        }
      },
      'data.cancellation_doc_date': {
        type: 'date',
        validation: {
          required: true,
        },
      },
    }
  },
  createFields: [
    'data.trade_participant_inn',
    'data.cancellation_reason',
    'data.cancellation_doc_number',
    'data.cancellation_doc_date',
  ],
});
