import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'OPERATOR': 'Operator',
        'SELF_MADE': 'Self made',
      },
    }
  }
}