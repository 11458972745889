import authResourcesPermissions from "./authResourcesPermissions";

type AuthPermissionParams = {
  resource?: string,
  productGroup?: string,
  serviceName?: string,
  companyId?: string,
  stockId?: string,
}

export default (params: AuthPermissionParams) => {
  const {
    resource = null,
    productGroup = null,
    // serviceName = null,
    // companyId = null,
    // stockId = null,
  } = params;

  const permissions = authResourcesPermissions();
  let availableResources = permissions.baseResources;
  if (productGroup && productGroup in permissions.pgResources) {
    availableResources = [
      ...availableResources,
      ...permissions.pgResources[productGroup],
    ];
  }

  if (resource !== null) {
    if (availableResources.includes(resource)) {
      return [
        'create',
        'edit',
        'view',
        'viewOne',
        'delete'
      ];
    }
    return [];
  }

  return availableResources;
}