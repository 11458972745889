import ResourceIcon from '@material-ui/icons/UndoRounded';
import returnsChild from './returnsChild';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import CompanyDataInput from '../../../../components/CompanyDataInput';
import returnsType from '../../enums/returnsType';
import certDocumentType from '../../enums/certDocumentType';
import returnsDocumentType from '../../enums/returnsDocumentType';


const name = 'marking/returns';
const precessName = 'returns_return';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: ResourceIcon,
  precessName,
  apiBasePath,
  childResource: returnsChild,
  allowUpdateAction: true,
  schema: {
    type: 'object',
    properties: {
      'data.trade_participant_inn': {
        type: 'string',
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
        validation: {
          required: true,
          format: 'inn',
        }
      },
      'data.return_type': {
        type: 'string',
        defaultValue: null,
        componentInput: returnsType.Input,
        componentField: returnsType.Field,
        validation: {
          required: true,
        },
      },
      'data.primary_document_date': {
        type: 'date',
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.primary_document_number': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.primary_document_type': {
        type: 'string',
        defaultValue: null,
        componentInput: returnsDocumentType.Input,
        componentField: returnsDocumentType.Field,
        validation: {
          required: true,
        },
      },
      'data.primary_document_custom_name': {
        type: 'string',
        defaultValue: null,
        options: {
          showCondition: (data) => data.data && data.data.primary_document_type === 'OTHER',
        }
      },
      'data.certificate_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.certificate_number': {
        type: 'string',
        defaultValue: null,
      },
      'data.certificate_type': {
        type: 'string',
        defaultValue: null,
        componentInput: certDocumentType.Input,
        componentField: certDocumentType.Field,
      },
      'data.paid': {
        type: 'boolean',
        defaultValue: false,
      },
    }
  },
  createFields: [
    'data.trade_participant_inn',
    'data.return_type',
    'data.primary_document_date',
    'data.primary_document_number',
    'data.primary_document_type',
    'data.primary_document_custom_name',
    'data.certificate_date',
    'data.certificate_number',
    'data.certificate_type',
    'data.paid',
  ],
});
