import deepmerge from 'deepmerge';
import { createMuiTheme } from '@material-ui/core';
import baseTheme from '../baseTheme';
import palette from './palette';


export default createMuiTheme(deepmerge(baseTheme.mui, {
  palette,
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: palette.primary.contrastText,
        },
      },
    },
    
    RaPaginationActions: {
      actions: {
        '& .MuiButton-textPrimary': {
          color: palette.text.primary,
        },
        '& .MuiButton-textPrimary:hover': {
          backgroundColor: palette.action.hover,
        },
      }
    },
    RaFilter: {
      button: {
        '& .MuiButton-textPrimary': {
          color: palette.text.primary,
        },
        '& .MuiButton-textPrimary:hover': {
          backgroundColor: palette.action.hover,
        },
      }
    },
    RaSaveButton: {
      button: {
        '& .MuiCircularProgress-colorPrimary': {
          color: palette.primary.contrastText,
        }
      }
    },
  }
}));
