import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'list-codes': 'Load Codes',
        'list-documents': 'Load Documents',
        'search-company-products': 'Search Company Products',
        'update-all-codes': 'Update All Codes',
        'update-company-codes': 'Update Company Codes',
        'process-auto-balance-codes': 'Autobalance codes',
        'update-nk-products': 'Update products from NC',
        'delete-crypto-hash-from-codes': 'Clear crypto hash from codes',
      },
    }
  }
}
