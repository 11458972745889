import deepmerge from 'deepmerge';
import createResourceFromConfig from './createResourceFromConfig';

const fieldsListName = [
  'listFields',
  'listFilters',
  'listActions',
  'bulkActions',
  'itemActions',
  'listRowActions',
  'showActions',
  'editActions',
  'createFields',
]

const mergeFieldsListByName = (originalFields, newFields) => {
  if (!Array.isArray(originalFields) || !Array.isArray(newFields)) {
    return newFields;
  }
  const result = [...originalFields];
  newFields.forEach(field => {
    const name = typeof field === 'string' ? field : field.name;
    const existIndex = result.findIndex(x => ((typeof x === 'string' ? x : x.name) === name));
    if (existIndex !== -1) {
      result.splice(existIndex, 1)
    }
    result.push(field);
  })

  return result;
}

export default (resourceConfig, overrideData) => {
  const data = deepmerge.all([resourceConfig, overrideData]);
  const originalName = resourceConfig.name;
  const cloneName = overrideData.name;

  if (!cloneName || !originalName) {
    throw new Error('No name of resource');
  }

  if (resourceConfig.locales) {
    for(let lang in resourceConfig.locales) {
      if (resourceConfig.locales[lang].resources && originalName in resourceConfig.locales[lang].resources) {
        const resourceLocales = data.locales[lang].resources[originalName];
        delete data.locales[lang].resources[originalName];
        data.locales[lang].resources[cloneName] = resourceLocales;
      }
    }
  }

  fieldsListName.forEach(name => {
    if (name in resourceConfig && name in overrideData) {
      data[name] = mergeFieldsListByName(resourceConfig[name], overrideData[name]);
    }
  })

  return createResourceFromConfig(data);
}
