import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Ввод в оборот (ФТС) |||| Ввод в оборот (ФТС)',
      fields: {
        'data.cis': 'КИ/КИТУ',
        'data.pack_type': 'Тип упаковки',
        'data.color': 'Цвет',
        'data.product_size': 'Размер',
        'data.children': '',
      }
    }
  }
}
