import React from 'react';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const ChartSetSelect = (props) => {
  const {
    translate,
    translatePrefix = '',
    value,
    items,
    onChange,
  } = props;

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const label = translate('widgets.metrics.chartSetInputLabel')
  return (
    <FormControl variant="outlined" size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        label={label}
      >
        {items.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {translate(`${translatePrefix}${item.value}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}


export default compose(
  translate,
)(ChartSetSelect);