import React from 'react';
import { useTranslate } from  'react-admin';
import Button from '@material-ui/core/Button';
import ExportIcon from '@material-ui/icons/GetApp';

import TransitionLink from '../../../../components/TransitionLink';
import { dictionaryToPath } from '../../../../utils';
import resourceConfig from './config';

type OpenButtonProps = {
  [key: string]: any;
};

const OpenButton = (props: OpenButtonProps) => {
  const t = useTranslate();
  const {
    disabled,
    sort,
    resource,
    filterValues,
  } = props;
  const urlParams = {
    params: JSON.stringify({
      sort,
      resource,
      filter: filterValues,
    })
  };
  const label = t(`resources.${resourceConfig.name}.openAction`);

  return (
    <Button
      size={'small'}
      color="default"
      startIcon={<ExportIcon />}
      {...props}
      component={TransitionLink}
      disabled={disabled}
      transitionTo={{
        params: {
          [resourceConfig.urlParamName]: dictionaryToPath(urlParams)
        }
      }}
    >
      {label}
    </Button>
)
};


export default OpenButton;
