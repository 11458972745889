import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'LP_INTRODUCE_GOODS_AUTO': 'Производство РФ',
        'LP_GOODS_IMPORT_AUTO': 'Импорт',
      },
    }
  }
}
