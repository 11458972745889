import React, { PureComponent } from 'react';
import { addField, AutocompleteInput, TextInput } from 'react-admin';
import appCore from '../../appCore';
import request from '../../network/request';


class TnvedSelect extends PureComponent {

  state = {
    value: '',
    choices: []
  }

  componentDidMount() {
    this.loadChoices();
  }

  loadChoices = async () => {
    let response = null;
    const pg = appCore.getProductGroup();
    try {
      response = await request({
        path: `/workflow-service/crpt/proxy/oms/api/v2/${pg}/providers`,
        method: "GET",
      });
    } catch (e) {
      console.error(e);
    }

    let choices = [];
    if (response && response.ok) {
      choices = response.data.providers.map((x) => ({
        id: x.serviceProviderId,
        name: `${x.taxIdentificationNumber} | ${x.providerName} | ${x.serviceProviderId}`,
      }));
    }

    this.setState({
      choices,
    });
  }

  onInput = (value) => {
    this.setState({
      value,
    })
  }

  render() {
    const {
      ...restProps
    } = this.props;
    let {
      value,
      choices,
    } = this.state;

    let useAutocomplete = true;

    // if (window.location.host === 'ui.cf.markinghub.ru' && appCore.getCompanyId() === '2' && appCore.getProductGroup() === 'water') {
    //   useAutocomplete = true;
    // }

    if (!useAutocomplete) {
      return (
        <TextInput
          {...restProps}
        />
      );
    }

    if (value !== '' && choices.findIndex(x => x.id === value) === -1) {
      choices = [
        { id: value, name: value },
        ...choices,
      ];
    }

    return (
      <AutocompleteInput
        {...restProps}
        setFilter={this.onInput}
        choices={choices}
        translateChoice={false}
        options={{
          suggestionsContainerProps: {
            style: {
              maxWidth: 500,
            }
          }
        }}
      />
    );
  }
}

export default addField(TnvedSelect);
