import Keycloak from 'keycloak-js'
import appConfig from '../appConfig';

class Auth {
  keycloak = null;

  init = () => {
    this.keycloak = new Keycloak({
      url: appConfig.data.authBaseUrl,
      realm: appConfig.data.authRealm,
      clientId: appConfig.data.authClientId,
    })

    return this.keycloak.init({
      checkLoginIframe: false,
    });
  }

  login = () => {
    this.keycloak.login({
      redirectUri: window.location.href
    })
  }

  logout = () => this.keycloak.logout()

  isAutorized = async () => this.keycloak.authenticated; // Boolean(this.keycloak.token) // && !auth.keycloak.isTokenExpired()

  getToken = async () => {
    if (this.keycloak.isTokenExpired()) {
      // TODO: проверять результат апедейта
      await this.updateToken(1);
    }

    return this.keycloak.token || null
  };

  _updateTokenPromise = null
  updateToken = () => {
    if (this._updateTokenPromise === null) {
      this._updateTokenPromise = this.keycloak.updateToken()
    }
    return this._updateTokenPromise;
  }

  getAccountUrl = () => this.keycloak.createAccountUrl()
}

const auth = new Auth();


export default auth;
