import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        KM_SPOILED: 'Испорчен',
        KM_LOST: 'Утерян',
        KM_DESTROYED: 'Уничтожен',
      },
    }
  }
}
