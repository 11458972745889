import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {
  translate,
  SimpleForm,
  Toolbar,
} from "react-admin";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import compose from "recompose/compose";
import qs from "qs";
import { withRouter } from "react-router";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import resourceConfig from "./config";
import { pathToDictionary, generateGUID } from "../../../../utils";
import Button from "../../../../components/Button";
import appCore from "../../../../appCore";
import getResourceUrl from "../../../../utils/admin/getResourceUrl";
import Snackbar from '../../../../components/Snackbar';
import printRequest from "../../resources/printRequest";

const styles = {
  label: { width: "10em", display: "inline-block" },
  button: { margin: "1em" },
  toolbar: { background: "none" },
  loader: {
    marginLeft: 15,
  },
};

const FormToolbar = translate(({ translate, onCancel, ...props }) => (
  <Toolbar style={styles.toolbar} {...props}>
    <Button
      // size={'small'}
      color="default"
      onClick={onCancel}
    >
      {translate(`resources.${resourceConfig.name}.cancelAction`)}
    </Button>
  </Toolbar>
));

class AppModule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      invalid: false,
      resources: [],
    };
  }

  componentDidMount = () => {
    this.checkLocation();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.checkLocation();
    }
  };

  resetState = () => {
    this.setState({
      show: false,
    });
    setTimeout(this.afterClose, 500);
  }

  checkLocation = () => {
    if (this.props.location.search.length < 2) {
      this.resetState();
      return;
    }
    const params = qs.parse(this.props.location.search.slice(1));
    if (!(resourceConfig.urlParamName in params)) {
      this.resetState();
      return;
    }
    if (this.state.show) {
      return;
    }
    const data = pathToDictionary(params[resourceConfig.urlParamName]);
    let query = null;
    if (data.document_id) {
      query = `documents.document_id = "${data.document_id}"`;
    } else if (data.code_ids) {
      query = `id in (${data.code_ids.split(',').join(', ')})`;
    }


    const resources = [];

    if (query !== null) {
      appCore.resources.filter(x => x.isDocument && (x.allowCreateWithGenerator || x.name === printRequest.name)).forEach(x => {
        let params = null;
        if (x.name === printRequest.name) {
          // Fix for print-request
          const children = [];
          if (data.document_id) {
            children.push({
              data: {
                selector_type: 'document_id',
                selector_value: data.document_id,
                quantity: null,
              }
            });
          } else if (data.code_ids) {
            data.code_ids.split(',').forEach(x => {
              children.push({
                data: {
                  selector_type: 'code_id',
                  selector_value: x,
                  quantity: 1,
                }
              });
            });
          }

          params = {
            children: JSON.stringify(children),
          };
        } else if (query !== null) {
          params = {
            children_generator: JSON.stringify({
              query,
              count: null,
            })
          }
        }

        resources.push({
          resourceName: x.name,
          title: `resources.${x.name}.name`,
          Icon: x.Icon,
          url: getResourceUrl({
            resourceName: x.name,
            pageType: 'CREATE',
            params,
          })
        });
      });
    }


    this.setState({
      show: true,
      invalid: query === null,
      resources,
    });
  };

  afterClose = () => {
    this.setState({
      items: [],
      sent: false,
      invalid: false,
      key: generateGUID(),
      params: {
        ...this.defaultParams,
      },
    });
  };

  handleCloseClick = () => {
    this.props.history.goBack();
  };

  render() {
    const { translate } = this.props;

    const { resources, invalid } = this.state;

    return (
      <Drawer
        anchor={"right"}
        open={this.state.show}
        onClose={this.handleCloseClick}
      >
        <SimpleForm
          toolbar={<FormToolbar onCancel={this.handleCloseClick} />}
          style={{ width: 600, maxWidth: '100%' }}
        >
          <Typography
            variant={'title'}
          >
            {translate(`resources.${resourceConfig.name}.title`)}
          </Typography>

          {invalid && (
            <Snackbar
              style={{ marginTop: 20, marginBottom: 40, maxWidth: '100%' }}
              showIcon={false}
              variant="error"
              message={translate(`resources.${resourceConfig.name}.invalid`)}
              fullWidth
            />
          )}

          {!invalid && (
            <List>
              {resources.map((item, index) => (
                <ListItem
                  button
                  component={Link}
                  to={item.url}
                  key={item.resourceName}
                  divider={index < resources.length - 1}
                >
                  <ListItemIcon>
                    {item.Icon && <item.Icon size={25} />}
                  </ListItemIcon>
                  <ListItemText
                    primary={translate(item.title, { smart_count: 1 })}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </SimpleForm>
      </Drawer>
    );
  }
}

export default compose(
  withRouter,
  translate,
)(AppModule);
