import React, { Component } from 'react';
import { addField, TextInput } from '../Admin';
import rest from '../../network/rest';
import appCore from '../../appCore';
import company from '../../services/common-service/resources/company';
import searchInObject from '../../utils/searchInObject';

const cachedData = {
  lastDate: null,
  companyId: null,
  data: null,
}
const maxCacheTime = 60000;

class CompanyDataInput extends Component {

  componentDidMount() {
    if (this.props.input.value) {
      return;
    }
    this.setDefaultValue();
  }

  setDefaultValue = async () => {
    const {
      companyField,
      input,
    } = this.props;

    if (!input || !companyField) {
      return;
    }

    const data = await this.getData();

    if (data !== null && companyField) {
      const value = searchInObject(data, companyField)
      if (value) {
        input.onChange({
          target: {
            value
          }
        })
      }
    }
  }

  getData = async () => {
    const companyId = appCore.currentUrlParams.companyId || null;
    if (companyId === null) {
      return;
    }
    if (cachedData.companyId === companyId && cachedData.data !== null && Date.now() - cachedData.lastDate <= maxCacheTime) {
      return await cachedData.data;
    }
    cachedData.companyId = companyId;
    cachedData.lastDate = Date.now();
    cachedData.data = this.loadCompanyData(companyId);
    return await cachedData.data;
  }

  loadCompanyData = async (id) => {
    const data = await rest.getOne(company.name, { id });
    if (data && data.data) {
      return data.data;
    }
    return null;
  }

  render() {
    const {
      ...restProps
    } = this.props;
    return (
      <TextInput 
        {...restProps}
      />
    );
  }
}


export default addField(CompanyDataInput);
