import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'list-codes': 'Выгрузка новых кодов из ГИС МТ',
        'list-documents': 'Выгрузка новых документов из ГИС МТ',
        'search-company-products': 'Выгрузка товаров из ГИС МТ',
        'update-all-codes': 'Обновление всех кодов',
        'update-company-codes': 'Обновление кодов компании',
        'process-auto-balance-codes': 'Автобаланс кодов',
        'update-nk-products': 'Обновление товаров из НК',
        'delete-crypto-hash-from-codes': 'Очистка крипто хвоста у кодов',
      },
    }
  }
}
