import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Вывод из оборота |||| Вывод из оборота',
      fields: {
        'meta.status': 'Статус',
        'meta.created': 'Дата создания',
        'data.action': 'Причина вывода из оборота',
        'data.action_date': 'Дата вывода из оборота',
        'data.document_type': 'Вид первичного документа',
        'data.document_number': 'Номер первичного документа',
        'data.document_date': 'Дата первичного документа',
        'data.primary_document_custom_name': 'Наименование первичного документа',
        'data.kkt_number': 'Номер кассы',
      }
    }
  }
}
