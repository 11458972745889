import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';


const documentRowStyle = (record, index, defaultStyle = {}) => {
  const saturate = 300;
  let color = null;
  let borderWidth = 5;

  const colors = {
    'draft': grey[saturate],

    'request_created': amber[saturate],
    'processing': amber[saturate],

    'completed': green[saturate],
    'ready': green[saturate],
    'printed': green[saturate],

    'failed': red[saturate],
    'execute_canceled': red[saturate],
    'cancelled': red[saturate],

    'wait_acceptance': blue[saturate],
  };

  if (record && 'status' in record) {
    if (record.status in colors) {
      color = colors[record.status];
    }
  }

  return {
      ...defaultStyle,
      boxShadow: `inset ${borderWidth}px 0 0 ${color}`,
  }
}

export default documentRowStyle;
