import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        MALE: 'Male',
        FEMALE: 'Female',
        BABY: 'Baby',
        UNISEX: 'Unisex',
      },
    }
  }
}