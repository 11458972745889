import { useEffect } from 'react';

const setPageTitle = (appBarTitle: HTMLElement) => {
  try {
    document.title = `MarkingHub | ${appBarTitle.textContent}`
  } catch (e) {}
}

const useWatchTitleHack = (appBarTitleRef: React.MutableRefObject<HTMLElement>) => {
  useEffect(() => {
    if (!appBarTitleRef.current) {
      return;
    }
    setPageTitle(appBarTitleRef.current);

    var raTitle = appBarTitleRef.current.querySelector('#react-admin-title');

    var observer = new MutationObserver(function(mutations) {
      setPageTitle(appBarTitleRef.current);
    });

    var config = { childList: true, characterData: true };

    observer.observe(raTitle, config);

    return () => {
      observer.disconnect();
    }
  }, [appBarTitleRef.current])

  return null;
};

export default useWatchTitleHack;
