import React from 'react';

export default ({ className, style, Component = 'div', children, ...props }) => (
  <Component className={className} style={style}>
    {React.Children.map(children, child => {
      if (!child) {
        return null;
      }
      return React.cloneElement(child, props);
    })}
  </Component>
);