import React from 'react';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ranges } from '../../utils/ui/metrics';
import { setMetricsRange as setMetricsRangeAction} from '../../redux/metrics/actions';


const ChartRangeSelect = (props) => {
  const {
    translate,
    metricsRange,
    setMetricsRange,
  } = props;

  const handleChange = (event) => {
    setMetricsRange(event.target.value);
  };

  const label = translate('widgets.metrics.rangeInputLabel')
  return (
    <FormControl variant="outlined" size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        value={metricsRange}
        onChange={handleChange}
        label={label}
      >
        {ranges.map(range => (
          <MenuItem key={range.value} value={range.value}>
            {translate(`widgets.metrics.ranges.${range.value}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}


const mapStateToProps = state => ({
  metricsRange: state.metrics.metricsRange,
});

export default compose(
  connect(
    mapStateToProps,
    {
      setMetricsRange: setMetricsRangeAction,
    }
  ),
  translate,
)(ChartRangeSelect);