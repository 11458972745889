import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Товар - свободный документ |||| Товары - свободный документ',
      fields: {
        'data.code': 'КИ/КИТУ',
      },
    }
  }
}