import ResourceIcon from '@material-ui/icons/AssignmentTurnedIn';
import acceptanceChild from './acceptanceChild';
import turnoverType from '../../enums/turnoverType';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import request from '../../../../network/request';


const name = 'marking/acceptance';
const precessName = 'accept_accept';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: ResourceIcon,
  precessName,
  apiBasePath,
  showDirection: true,
  childResource: acceptanceChild,
  allowUpdateAction: true,
  schema: {
    type: 'object',
    properties: {
      'data.release_order_number': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.trade_sender_inn': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.trade_sender_name': {
        type: 'string',
        defaultValue: null,
      },
      'data.trade_recipient_inn': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.acceptance_date': {
        type: 'date',
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.document_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.document_number': {
        type: 'string',
        defaultValue: null,
      },
      'data.turnover_type': {
        type: 'string',
        componentInput: turnoverType.Input,
        componentField: turnoverType.Field,
        defaultValue: null,
      },
      'data.transfer_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.exporter_name': {
        type: 'string',
        defaultValue: null,
      },
      'data.exporter_taxpayer_id': {
        type: 'string',
        defaultValue: null,
      },
      'data.release_method': {
        type: 'string',
        defaultValue: null,
      },
      'data.reject_all': {
        type: 'boolean',
        defaultValue: false,
      },
      'data.accept_all': {
        type: 'boolean',
        defaultValue: false,
      },
    }
  },
  createFields: [
    'data.release_order_number',
    'data.trade_sender_inn',
    'data.trade_sender_name',
    'data.trade_recipient_inn',
    'data.acceptance_date',
    'data.document_date',
    'data.document_number',
    'data.turnover_type',
    'data.transfer_date',
    'data.exporter_name',
    'data.exporter_taxpayer_id',
    'data.release_method',
    'data.reject_all',
    'data.accept_all',
  ],
  itemActions: [
    {
      name: `resources.${name}.actions.loadCodes.name`,
      isDisabled: ({ record }) => !(record.status === 'completed' && record.direction === 'Out'),
      handler: async ({ record }) => {
        const path = `/bp/processes/accept_accept/${record.id}/load_codes/`;

        let response = null;
        try {
          response = await request({
            path,
            method: 'POST',
          });
        } catch (e) { }

        if (response && response.ok) {
          return {
            notification: {
              type: 'success',
              message: `resources.${name}.actions.loadCodes.successMessage`
            }
          }
        }

        return {
          notification: {
            type: 'error',
            message: `resources.${name}.actions.loadCodes.failureMessage`
          }
        }
      }
    }
  ]
});

