import React from 'react';
import { ArrayInput, SimpleFormIterator, TextInput } from '../../../../../components/Admin';
import ResourceIcon from '@material-ui/icons/Input';
import childCreateOptionsCreator from '../../../../../utils/admin/childCreateOptionsCreator';
import childEditOptionsCreator from '../../../../../utils/admin/childEditOptionsCreator';
import ArrayField from '../../../../../components/ArrayField';
import { createSendTestNotificationActionCreator } from '../itemActionCreators';


const name = 'settings/notification-group-email';
const parentResourceName = 'settings/notification-group';

export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/notification-service/notification-group/{{parent_id}}/email/settings/',
    disableSorting: true,
    mapping: {
      outputItemAfter: (output) => {
        if (output && output.emails) {
          output.emails = output.emails.map(x => x.email);
        } else {
          output.emails = [];
        }
        return output;
      },
      inputItemAfter: (input) => {
        if (input && input.emails) {
          input.emails = input.emails.map(x => ({ email: x }));
        } else {
          input.emails = [];
        }
        return input;
      },
    },
  },
  itemToString: x => `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      is_active: {
        type: 'boolean',
      },
      active_till: {
        type: 'dateTime',
      },
      created: {
        type: 'dateTime',
        readOnly: true,
      },
      updated: {
        type: 'dateTime',
        readOnly: true,
      },
      emails: {
        type: 'array',
        validation: {
          required: true,
        },
        componentField: ArrayField,
        componentInput: (props) => (
          <ArrayInput {...props} fullWidth source="emails">
              <SimpleFormIterator>
                <TextInput source="email" />
              </SimpleFormIterator>
          </ArrayInput>
        ),
        options: {
          itemNameField: 'email',
        }
      },
    }
  },
  allowList: true,
  allowCreate: true,
  allowEdit: true,
  allowShow: false,
  allowDelete: true,
  showInMenu: false,
  group: 'settings',
  itemActions: [
    createSendTestNotificationActionCreator({ groupType: 'email', resourceName: name, parentResourceName }),
  ],
  createGetOptions: childCreateOptionsCreator({ parentResourceName, tabPath: 'email' }),
  editGetOptions: childEditOptionsCreator({ parentResourceName, tabPath: 'email' }),
  createFields: [
    'emails',
  ],
  editFields: [
    'id',
    'emails',
    'is_active',
    'active_till',
    'created',
    'updated',
  ],
  listFields: [
    'id',
    'emails',
    'active_till',
    'is_active',
  ],
  listFilters: [],
};
