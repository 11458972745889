import React from 'react';
import ResourceIcon from '@material-ui/icons/Fingerprint';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import { showNotification } from '../../../../components/Admin';
import store from '../../../../redux/store';
import GuidField from '../../../../components/GuidField';
import ConnectionStatusField from './ConnectionStatusField';
import request from '../../../../network/request';

const name = 'settings/encoding-server';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/encoding/runner/',
    afterCreate: ({ data }) => {
      store.dispatch(
        showNotification((
          <span>
            Save server key please:
            <b style={{ padding: '0 5px 0 10px'}}>{data.password}</b>
            <CopyToClipboard text={data.password} onCopy={() => window.document.body.click()}>
              <IconButton
                size="small"
                color="inherit"
              >
                <CopyIcon />
              </IconButton>
            </CopyToClipboard>
          </span>
        ), 'info', { autoHideDuration: 10000000 })
      );
    },
  },
  itemToString: x => x.name || `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        validation: {
          required: true,
        }
      },
      unique_string: {
        type: 'string',
      },
      is_active: {
        type: 'boolean',
      },
      is_connected: {
        type: 'boolean',
        readOnly: true,
        component: ConnectionStatusField,
        options: {
          translatePrefix: `resources.${name}.connectionStatus.`
        }
      },
      created: {
        type: 'dateTime',
        readOnly: true,
      },
      updated: {
        type: 'dateTime',
        readOnly: true,
      },
    }
  },
  allowList: true,
  allowCreate: true,
  allowEdit: true,
  allowShow: false,
  allowDelete: true,
  showInMenu: true,
  group: 'settings',
  createFields: [
    'name',
    'unique_string',
    'is_active'
  ],
  editFields: [
    'id',
    'is_connected',
    'updated',
    'created',
    'name',
    'unique_string',
    'is_active'
  ],
  listFields: [
    {name: 'id', component: GuidField },
    'name',
    'is_active',
    'is_connected',
  ],
  itemActions: [
    {
      name: `resources.${name}.actions.test.name`,
      order: 10,
      handler: async ({ record, translate }) => {
        const path = `/encoding/runner/${record.id}/test/`;
        let response = null;
        let error = null;
        try {
          response = await request({
            path,
            method: 'GET',
          });
        } catch (e) {
          if (e.message) {
            error = e.message;
          } else {
            error = String(e);
          }
        }

        if (response && response.ok) {
          if (response.data.result) {
            return {
              notification: {
                type: 'success',
                message: `resources.${name}.actions.test.successMessage`
              }
            }
          } else if (response.data.error) {
            error = response.data.error;
          }
        }

        return {
          notification: {
            type: 'error',
            message: translate(`resources.${name}.actions.test.failureMessage`, {  error })
          }
        }
        // error
      }
    },
    {
      name: `resources.${name}.actions.ping.name`,
      order: 20,
      handler: async ({ record, translate }) => {
        const path = `/encoding/runner/${record.id}/ping/`;

        let response = null;
        let error = null;
        try {
          response = await request({
            path,
            method: 'POST',
          });
        } catch (e) {
          if (e.message) {
            error = e.message;
          } else {
            error = String(e);
          }
        }

        if (response && response.ok && response.data.result) {
          if (response.data.result) {
            return {
              notification: {
                type: 'success',
                message: `resources.${name}.actions.ping.successMessage`
              }
            }
          } else if (response.data.error) {
            error = response.data.error;
          }
        }

        return {
          notification: {
            type: 'error',
            message: translate(`resources.${name}.actions.test.failureMessage`, {  error })
          }
        }
      }
    }
  ],
};
