import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'История изменений кода',
      fields: {
        parent_cis: 'КИТУ Агрегата',
        parent: 'Агрегат',
        task_id: 'ID задачи',
        stock_id: 'Склад',
        additional_status: 'Дополнительный статус',
      }
    }
  }
}