import SettingsIcon from '@material-ui/icons/Settings';
import ReportsIcon from '@material-ui/icons/PieChart';
import locales from './locales';

import companyCredentialPage from './pages/companyCredentialPage';
import stock from './resources/stock';
import labelTemplates from './resources/labelTemplates';
import task from './resources/task';
import logs from './resources/logs';
import encodingServer from './resources/encodingServer';
import dashboardPage from './pages/dashboardPage';
import setMenuOrder from '../../utils/setMenuOrder';
import workflow from './resources/workflow';
import labelTemplateType from './enums/labelTemplateType';
import userRole from './resources/userRole';
import member from './resources/member';
import memberRoles from './resources/member/memberRoles';
import notificationGroup from './resources/notificationGroup';
import notificationMessageType from './enums/notificationMessageType';
import notificationGroupTelegram from './resources/notificationGroup/notificationGroupTelegram';
import notificationGroupEmail from './resources/notificationGroup/notificationGroupEmail';
import notification from './resources/notification';
import scheduledTask from './resources/scheduledTask';
import scheduledTaskEnum from './enums/scheduledTaskEnum';
import autobalanceCode from './resources/autobalanceCode';
import productGroup from './resources/productGroup';
import productGroupEnum from './enums/productGroupEnum';
import labelTemplateFileType from './enums/labelTemplateFileType';
import notificationGroupWebhooks from './resources/notificationGroup/notificationGroupWebhooks';
import codesReportPage from './pages/codesReportPage';
import editCompanyPage from './pages/editCompanyPage';
import balance from './resources/balance';

const resources = [
  balance,
  productGroup,
  stock,
  labelTemplates,
  task,
  logs,
  encodingServer,
  workflow,
  member,
  memberRoles,
  userRole,
  notification,
  notificationGroup,
  notificationGroupEmail,
  notificationGroupTelegram,
  notificationGroupWebhooks,
  scheduledTask,
  scheduledTaskEnum,
  autobalanceCode,

  labelTemplateType,
  labelTemplateFileType,
  notificationMessageType,
  productGroupEnum,
];

const name = 'company';

export default {
  name,
  locales,
  resources,
  icon: SettingsIcon,
  pages: [
    setMenuOrder(dashboardPage, 0),
    setMenuOrder(editCompanyPage, 0),
    setMenuOrder(companyCredentialPage, 1),
    setMenuOrder(codesReportPage, 0),
  ],
  menuGroups: [{
    name: 'settings',
    text: `services.${name}.groups.settings`,
    icon: SettingsIcon,
    order: 1,
  }, {
    name: 'reports',
    text: `services.${name}.groups.reports`,
    icon: ReportsIcon,
    order: 2,
  }],
};
