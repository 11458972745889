import React from 'react';
import { Tab, FormTab } from '../../components/Admin';
import ProxyContainer from "../../components/ProxyContainer";
import getFieldComponent from './getFieldComponent';
import getFirstValue from '../getFirstValue';
import memoizeCacheKey from '../memoizeCacheKey';

const cachedChildren = {};

const getCacheKey = memoizeCacheKey([
  'schema',
  'fields',
  'editable',
  'validate',
  'fieldProps',
  'path',
])

const getFieldsFromSchema = (options = {}) => {
  let {
    useFor = null,
    schema = null,
    fields = null,
    editable = false,
    validate = false,
    Component = ProxyContainer,
    fieldProps,
    ...restProps
  } = options;

  let children = null;

  const cacheId = getCacheKey(options);
  if (cacheId in cachedChildren) {
    children = cachedChildren[cacheId];
  }

  if (children === null) {
    children = []

    const properties = schema !== null && schema.properties ? schema.properties : {};
    if (fields === null) {
      fields = Object.keys(properties);
    }

    fields.forEach(field => {
      if (typeof field === 'string') {
        field = {
          name: field
        }
      }


      if (field.type === 'tab') {
        const fieldFromSchema = getFieldsFromSchema({
          schema,
          fields: field.items,
          editable,
          validate,
          Component: editable ? FormTab : Tab,
          label: field.label,
          path: field.path,
          // key: field.path || field.label,
        });
        children.push(fieldFromSchema)
        return;
      }

      const fieldSchema = field.name in properties ? properties[field.name] : {};

      const type = field.type || fieldSchema.type || 'string';
      const fieldEditable = getFirstValue([field.editable, fieldSchema.editable, editable]);
      const readOnly = getFirstValue([field.readOnly, fieldSchema.readOnly, false]);
      const validation = {
        ...(fieldSchema.validation || {}),
        ...(field.validation || {}),
      }
      const fieldOptions = {
        ...(fieldSchema.options || {}),
        ...(field.options || {}),
      }
      const component = field.component || fieldSchema.component || null;
      const componentInput = field.componentInput || fieldSchema.componentInput || null;
      const componentField = field.componentField || fieldSchema.componentField || null;

      const fieldParams = {
        ...field,
        name: field.name,
        editable: fieldEditable,
        readOnly,
        type,
        options: fieldOptions,
        validation: validate ? validation : null,
        component,
        componentInput,
        componentField,
      };

      if (useFor === 'LIST') {
        // Отключение сортировки по умолчанию
        fieldParams.sortable = field.sortable || false;
      }

      const fieldComponent = getFieldComponent(fieldParams);

      if (fieldComponent.Component !== null) {
        children.push(
          <fieldComponent.Component
            {...fieldProps}
            {...fieldComponent.props}
          />
        )
      }
    })

    cachedChildren[cacheId] = children;
  }

  return (
    <Component
      {...restProps}
    >
      {children}
    </Component>
  )
}

export default getFieldsFromSchema;