export type GetInitialsOptions = {
  max?: number;
  escapeQuotes?: boolean;
  delimeters?: string[];
  camelCase?: boolean;
};

export const getInitials = (str: string, options: GetInitialsOptions = {}): string => {
  const {
    max = 2,
    escapeQuotes = false,
    delimeters = ['\\s', '-', '_'],
    camelCase = true,
  } = options;

  let fixedStr = str;

  // TODO: отрефакторить. Код дальше не очень)

  if (escapeQuotes) {
    let match = /"(.+?)"/.exec(str);
    if (!match) {
      match = /'(.+?)'/.exec(str);
    }
    if (!match) {
      match = /`(.+?)`/.exec(str);
    }
    if (!match) {
      match = /«(.+?)»/.exec(str);
    }
    if (!match) {
      match = /[‟”](.+?)[‟”]/.exec(str);
    }

    if (match) {
      fixedStr = match[0];
    }
  }

  fixedStr = fixedStr.replaceAll(/[^A-Za-zА-Яа-я0-9\s-_]/g, '');
  if (fixedStr.length === 0) {
    return '';
  }
  let words = fixedStr.split(new RegExp(`[${delimeters.join('')}]`, 'g'));
  words = words.map((x) => x.replaceAll(/[-_]/g, '')).filter(x => x.length > 0);
  if (words.length === 1 && camelCase) {
    let camelWords = fixedStr.replace(/([a-zа-я])([A-ZА-Я])/g, '$1 $2').split(' ');
    camelWords = camelWords.map((x) => x.replaceAll(/[-_]/g, '')).filter(x => x.length > 0);
    if (camelWords.length > 1) {
      words = camelWords;
    }
  }

  if (words.length === 0) {
    return '';
  }

  let result = words.slice(0, max).map(x => x[0]);


  return result.join('').toUpperCase();
};
