import React, { memo } from 'react';
import {
  Create,
  Toolbar,
  SaveButton,
  SimpleForm,
  TabbedForm,
} from 'react-admin';
import { commonStyles } from '../../theme';
import NotFoundPage from '../../pages/NotFoundPage';
import checkPermissions from '../../utils/checkPermissions';
import useQueryParams from '../../hooks/useQueryParams';


const CreateToolbar = ({ redirectUrl = null, allowEdit = false, allowShow = false, ...props }) => (
  <Toolbar
    {...props}
    style={commonStyles.toolbar}
  >
    <SaveButton
      style={commonStyles.toolbarButton}
      label="actions.create"
      redirect={redirectUrl || 'list'}
      submitOnEnter={true}
    />
    {(redirectUrl === null && allowEdit) && (
      <SaveButton
        style={commonStyles.toolbarButton}
        label="actions.createAndGoToEdit"
        redirect="edit"
        submitOnEnter={false}
        variant="text"
        color="default"
      />
    )}
  </Toolbar>
);

const prepareInitialValues = (data = {}) => {
  const result = {};

  for(let key in data) {
    const parts = key.split('.');
    if (parts.length === 1) {
      result[parts[0]] = data[key];
    } else {
      let context = result;
      parts.forEach((x, index) => {
        if (index === parts.length - 1) {
          context[x] = data[key];
          return;
        }
        context[x] = context[x] || {};
        context = context[x];
      })
    }
  }

  return result;
};

const ResourceCreatePage = memo((props) => {
  let {
    children,
    initialValues = {},
    withTabs = false,
    getOptions = null,
    redirectUrl = null,
    permissions,
    ...restProps
  } = props;

  const params = useQueryParams();

  if ('children_generator' in params) {
    try {
      initialValues = {
        ...initialValues,
        children_generator: JSON.parse(decodeURIComponent(params.children_generator)),
      };
    } catch (e) {
      console.error('Parse children_generator error: ', e);
    }
  }

  if ('children' in params) {
    try {
      initialValues = {
        ...initialValues,
        children: JSON.parse(decodeURIComponent(params.children)),
      };
    } catch (e) {
      console.error('Parse children error: ', e);
    }
  }

  const FormComponent = withTabs ? TabbedForm : SimpleForm;

  if (getOptions !== null) {
    const options = getOptions(props);
    if (options.error) {
      return options.error;
    }
    if (options.initialValues) {
      initialValues = {
        ...initialValues,
        ...options.initialValues,
      };
    }
    if (options.redirectUrl) {
      redirectUrl = options.redirectUrl;
    }
  }

  if (permissions === undefined) {
    return null; // LOADING
  }

  if (!checkPermissions('create', permissions)) {
    return (
      <NotFoundPage />
    )
  }

  return (
    <Create
      {...restProps}
    >
      <FormComponent
        variant="standard"
        margin="normal"
        initialValues={prepareInitialValues(initialValues)}
        redirectUrl={redirectUrl || undefined}
        toolbar={(
          <CreateToolbar
            initialValues={initialValues}
            redirectUrl={redirectUrl}
            allowEdit={checkPermissions('edit', permissions)}
            allowShow={checkPermissions('showOne', permissions)}
          />
        )}
      >
        {children}
      </FormComponent>
    </Create>
  );
});

export default ResourceCreatePage;
