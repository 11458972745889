export default {
  name: 'marking/enums/documentExternalStatus',
  enums: [
    // GISMT
    'IN_PROGRESS',
    'CHECKED_OK',
    'CHECKED_NOT_OK',
    'PROCESSING_ERROR',
    'UNDEFINED',
    'CANCELLED',
    'ACCEPTED',
    'WAIT_ACCEPTANCE',
    'WAIT_PARTICIPANT_REGISTRATION',

    // SUZ
    'DRAFT',
    'PENDING',
    'CHECK',
    'READY_TO_SEND',
    'REJECTED',
    'SENT',
    'PROCESSED',
    'WAIT_FOR_CONTINUATION',

    // CUSTOM OMS
    'WAIT_RECEIPT',
    'RECEIPT_ACCEPTED',
    'RECEIPT_REJECTED',
  ],
};






