import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import compose from "recompose/compose";
import { translate as translateRA } from "react-admin";
import { connect } from "react-redux";
import { setReportViewType as setReportViewTypeAction } from "../../../../redux/reports/actions";

const trnKey = (key) => `pages.company/codes-report.${key}`;

const ReportViewTypeSelect = (props) => {
  const { translate, reportViewType, setReportViewType, style } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setValue = (value) => {
    handleClose();
    setReportViewType(value);
  };

  return (
    <div style={style}>
      <Button onClick={handleClick}>
        {translate(trnKey("viewTypeSelect.label"))}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          disabled={reportViewType === "pieChart"}
          onClick={() => setValue("pieChart")}
        >
          {translate(trnKey("viewTypeSelect.pieChart"))}
        </MenuItem>
        <MenuItem
          disabled={reportViewType === "list"}
          onClick={() => setValue("list")}
        >
          {translate(trnKey("viewTypeSelect.list"))}
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapStateToProps = (state) => ({
  reportViewType: state.reports.reportViewType,
});

export default compose(
  connect(mapStateToProps, {
    setReportViewType: setReportViewTypeAction,
  }),
  translateRA
)(ReportViewTypeSelect);
