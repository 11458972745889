import { parse } from 'query-string';

import history from '../core/history';
import appCore from "../appCore";

export const getSlashedPath = (path) => /\/$/.test(path) ? path : `${path}/`;

export const getUnslashedPath = (path) => path.replace(/\/$/, '')

export const fillUrl = (path, data = {}) => {
  const queryParams = parse(history.location.search);
  const params = {
    stockId: 'all',
    ...appCore.currentUrlParams,
    ...queryParams,
    ...data,
  }
  return path
    .replace(/\/(:[^/]*)/g, (match) => {
      const field = match.replace(/[/:]|(\(.*\))/g, '');
      if (field in params) {
          return `/${params[field]}`;
      }
      return null;
    })
    .replace(/({{[$\w]+}})/g, (match) => { // TODO: make single replace
      const field = match.replace(/[{}]/g, '');
      if (field in params) {
          return params[field];
      }
      return null;
    });
}
