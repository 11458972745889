import cloneResourceFromConfig from '../../../../utils/admin/cloneResourceFromConfig';
import logs from '../../../common-service/resources/logs';


export default cloneResourceFromConfig(logs.config, {
  name: 'marking/logs',
  showInMenu: false,
  listFilters: [
    { name: 'task_id', options: { alwaysOn: true}, readOnly: true },
  ],
})
