export default {
  services: {
    marking: {
      name: 'Marking',
      groups: {
        settings: 'Extra',
        input: 'Input',
        aggr: 'Aggregation',
        cancel: 'Cancelation',
      }
    }
  }
}
