import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'NOT_REGISTERED': 'Not registered',
        'APPLIED': 'Applied',
        'EMITTED': 'Emitted',
        'INTRODUCED': 'Introduced',
        'RETIRED': 'Retired',
        'DISAGGREGATION': 'Disaggregated',
        'WRITTEN_OFF': 'Written Off',
      },
    }
  }
}
