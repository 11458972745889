import React from "react";
import { Avatar, Tooltip, Box, makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ProccessIcon from "@material-ui/icons/PlayArrow";
import CancelIcon from "@material-ui/icons/Close";
import RetryIcon from "@material-ui/icons/Replay";
import UnknownIcon from "@material-ui/icons/HelpOutline";
import getValue from "../../../../../utils/getValue";

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    border: `2px solid ${theme.palette.background.default}`,
    "& + &": {
      marginLeft: -theme.spacing(1),
    },
  },
  icon: {
    fontSize: 16,
  },
  PENDING: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.background.default,
  },
  RETRY: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.background.default,
  },
  SUCCESS: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.background.default,
  },
  FAILURE: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.background.default,
  },
}));

const getJobIcon = (job) => {
  if (job.status === "RETRY") {
    return RetryIcon;
  }
  if (job.status === "PENDING") {
    return ProccessIcon;
  }
  if (job.status === "SUCCESS") {
    return CheckIcon;
  }
  if (job.status === "FAILURE") {
    return CancelIcon;
  }
  return UnknownIcon;
};

const StatusItem = (props) => {
  const { item } = props;

  const classes = useStyles();

  const classNames = [classes.root];

  if (item.status in classes) {
    classNames.push(classes[item.status]);
  }

  const Icon = getJobIcon(item);

  return (
    <Tooltip
      arrow
      title={
        <div>
          <b>{item.name}</b>
          <div>Status: {item.status}</div>
          <div>Ready: {item.ready.toString()}</div>
          {item.date_done && <div>End: {item.date_done}</div>}
        </div>
      }
    >
      <Avatar className={classNames.join(" ")}>
        <Icon className={classes.icon} />
      </Avatar>
    </Tooltip>
  );
};

const WorkflowStatusField = (props) => {
  const { record, source } = props;

  const value = getValue(record, source) || [];
  return (
    <Box component="span" display="flex">
      {value.map((item) => (
        <StatusItem key={item.id} item={item} />
      ))}
    </Box>
  );
};

WorkflowStatusField.defaultProps = {
  addLabel: true,
};

export default WorkflowStatusField;
