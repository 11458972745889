import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Member |||| Members',
      tabs: {
        info: 'Information',
        roles: 'User roles',
      },
      fields: {
        user: 'User',
        date_joined: 'Joined date',
        expiration_date: 'Expiration date',
        roles: 'User Roles',
      },
    }
  }
}