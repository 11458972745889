import React, { Fragment } from "react";
import {
  Datagrid,
  List,
  Filter,
  BulkDeleteButton,
  BulkExportButton,
  Pagination,
} from "../Admin";
import withStyles from "@material-ui/core/styles/withStyles";
import exporter from "../../utils/exporter";
import EditButton from "../EditButton";
import ShowButton from "../ShowButton";
import { CloneButton } from "../CloneButton";
import ResourceActionButton from "../ResourceActionButton";
import ResourceActionsMenu from "../ResourceActionsMenu";
import checkPermissions from "../../utils/checkPermissions";
import NotFoundPage from "../../pages/NotFoundPage";
import ResourceListToolbar from "./ResourceListToolbar";
import useExtendedFiltersIsActive from "../../hooks/useExtendedFiltersIsActive";
import ResourceBulkActionButton from "../ResourceBulkActionButton";
import { ListCardsView } from "./ListCardsView";

const listStyles = {
  controlsContainer: {
    minHeight: 34,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  actionsCell: {
    paddingTop: 2,
    paddingBottom: 2,
  },
};

const ActionsCell = (props) => {
  const {
    className,
    allowEdit,
    allowShow,
    allowClone,
    rowInlineActions,
    rowMenuActions,
    permissions,
    ...restProps
  } = props;

  return (
    <div className={className}>
      {allowEdit && checkPermissions("edit", permissions) && (
        <EditButton color="default" {...restProps} />
      )}
      {allowShow && checkPermissions("viewOne", permissions) && (
        <ShowButton color="default" {...restProps} />
      )}
      {allowClone && checkPermissions("create", permissions) && (
        <CloneButton color="default" {...restProps} />
      )}
      {rowInlineActions.map((action) =>
        action.component ? (
          <action.component {...action.props} {...restProps} />
        ) : (
          <ResourceActionButton action={action} {...restProps} />
        )
      )}
      {rowMenuActions.length > 0 && (
        <ResourceActionsMenu actions={rowMenuActions} {...restProps} />
      )}
    </div>
  );
};

export const ResourceBulkActionButtons = ({
  actions,
  allowExport,
  ...props
}) => (
  <Fragment>
    {props.children}
    {actions &&
      actions.map((action) =>
        action.component ? (
          <action.component {...action.props} {...props} />
        ) : (
          <ResourceBulkActionButton action={action} {...props} />
        )
      )}
    {allowExport && (
      <BulkExportButton {...props} color="default" exporter={exporter} />
    )}
    {props.allowDelete && <BulkDeleteButton undoable={false} {...props} />}
  </Fragment>
);

export const ResourceListFilter = (props) => (
  <Filter {...props} variant="outlined" color="default"></Filter>
);

// const EmptyPage = props => (
// <pre>{JSON.stringify(props, null, 2)}</pre>
// )

const ResourceListPage = (props) => {
  let {
    classes,
    children,
    allowEdit = false,
    allowShow = false,
    allowClone = false,
    allowCreate = false,
    allowDelete = false,
    allowExport = false,
    useCustomExport = false,
    rowActions = [],
    expand = undefined,
    filters = undefined,
    extendedFilters = undefined,
    actions = undefined,
    bulkActions = undefined,
    defaultSort = { field: "id", order: "DESC" },
    permissions,
    rowStyle,
    rowClick,
    extended,
    resource,
    basePath,
    viewOptions,
    ...restProps
  } = props;
  const isExtended = useExtendedFiltersIsActive(resource);

  const hasControls = allowEdit || allowShow || rowActions.length > 0;
  const allowExtended = extendedFilters !== undefined;

  if (allowExtended && isExtended) {
    filters = extendedFilters;
  }

  let rowInlineActions = rowActions.filter((x) => x.inline);
  let rowMenuActions = rowActions.filter((x) => !x.inline);

  if (permissions === undefined) {
    return null; // LOADING
  }

  if (!checkPermissions("view", permissions)) {
    return <NotFoundPage />;
  }

  let listComponent = undefined;
  let content = null;
  if (viewOptions && viewOptions.type === "cards") {
    listComponent = 'div';
    content = <ListCardsView {...viewOptions} />;
  } else {
    content = (
      <Datagrid expand={expand} rowStyle={rowStyle} rowClick={rowClick}>
        {children}
        {hasControls && (
          <ActionsCell
            cellClassName={classes.actionsCell}
            className={classes.controlsContainer}
            allowEdit={allowEdit}
            allowShow={allowShow}
            allowClone={allowClone}
            rowInlineActions={rowInlineActions}
            rowMenuActions={rowMenuActions}
            permissions={permissions}
          />
        )}
      </Datagrid>
    );
  }

  return (
    <List
      actions={
        <ResourceListToolbar
          actions={actions}
          allowExport={allowExport}
          allowExtended={allowExtended}
          useCustomExport={useCustomExport}
          allowCreate={allowCreate && checkPermissions("create", permissions)}
        />
      }
      filters={filters}
      bulkActionButtons={
        <ResourceBulkActionButtons
          actions={bulkActions}
          allowExport={allowExport}
          allowDelete={allowDelete && checkPermissions("delete", permissions)}
        />
      }
      exporter={exporter}
      perPage={10}
      sort={defaultSort}
      pagination={<Pagination />}
      // hasCreate={allowCreate}
      // empty={<EmptyPage />}
      empty={false}
      hasCreate={false}
      syncWithLocation
      resource={resource}
      basePath={basePath}
      component={listComponent}
      {...restProps}
    >
      {content}
    </List>
  );
};

export default withStyles(listStyles)(ResourceListPage);
