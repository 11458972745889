import { useEffect, useState } from "react";
import { useVersion } from '../components/Admin';
import request from "../network/request";

type ConnectedEncodingServiceState = 'initialazing'|'connected'|'disconnected'|'error';

const useWatchConnectedEncodingService = () => {
  const version = useVersion();
  const [value, setValue] = useState<ConnectedEncodingServiceState>('initialazing');

  useEffect(() => {
    const loadConnectedRunners = () => {
      request({
        path: `/encoding/runner/`,
        method: 'GET',
        urlParams: {
          size: 100,
          // is_connected: true,
        }
      }).then((response) => {
        const nextState = response.data.results.filter(x => x.is_connected).length > 0 ? 'connected' : 'disconnected';
        setValue(nextState);
      }).catch(() => {
        setValue('error');
      })
    }

    loadConnectedRunners();

    const bgService = setInterval(() => {
      loadConnectedRunners();
    }, 30000);


    function cleanup() {
      clearInterval(bgService);
    }

    return cleanup;
  }, [version]);

  return value;
};

export default useWatchConnectedEncodingService;
