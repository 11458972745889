import appConfig from "../appConfig";

const basePgResources = [
  'marking/order',
  'marking/order-child',
  'marking/print-request',
  'marking/print-request-child',
  'marking/input-import',
  'marking/input-import-child',
  'marking/input-fts',
  'marking/input-fts-child',
  'marking/input-self-made',
  'marking/input-self-made-child',
  'marking/input-from-contract',
  'marking/input-from-contract-child',
  'marking/cancel-codes',
  'marking/cancel-codes-child',
  'marking/aggr',
  'marking/reaggr',
  'marking/reaggr-child',
  'marking/disaggr',
  'marking/disaggr-child',
  'marking/shipment',
  'marking/shipment-child',
  'marking/shipment-cancel',
  'marking/acceptance',
  'marking/acceptance-child',
  'marking/remarking',
  'marking/remarking-child',
  'marking/returns',
  'marking/returns-child',
  'marking/transfer-local',
  'marking/transfer-local-child',
  'marking/custom-local',
  'marking/custom-local-child',
  'marking/receipt',
  'marking/receipt-child',
  'marking/task',
  'marking/logs',
];

export default () => ({
  baseResources: [
    'common/company',
    'common/user',
    'settings/company',
    'settings/stock',
    'settings/label-templates',
    'settings/encoding-server',
    'settings/userRole',
    'settings/member',
    'settings/member-roles',
    (appConfig.data.ui.enableNotifications && 'settings/notification-group-webhooks'),
    (appConfig.data.ui.enableNotifications && 'settings/notification-group-telegram'),
    (appConfig.data.ui.enableNotifications && 'settings/notification-group-email'),
    (appConfig.data.ui.enableNotifications && 'settings/notification-group'),
    'settings/scheduled-task',
    'settings/ean-info',
    'settings/productGroup',

    'report/task',
    'report/logs',
    'report/workflow',
    (appConfig.data.ui.enableBalance && 'report/balance'),
    (appConfig.data.ui.enableNotifications && 'report/notification'),

    'marking/product',
    'marking/marking-codes',
    'marking/marking-code-history',
    'marking/marking-code-document',
    'marking/task',
    'marking/document-history',
    'marking/logs',
    'marking/documents',
    'marking/documents-child',
    'marking/autobalance-codes',

  ],
  pgResources: {
    shoes: [
      ...basePgResources,
      'marking/input-remains',
      'marking/input-remains-child',
      'marking/input-from-individual',
      'marking/input-from-individual-child',
      'marking/remains-description',
      'marking/remains-description-child',
    ],
    clothes: [
      ...basePgResources,
      'marking/input-remains',
      'marking/input-remains-child',
      'marking/input-from-individual',
      'marking/input-from-individual-child',
      'marking/remains-description',
      'marking/remains-description-child',
    ],
    perfumery: [
      ...basePgResources,
      'marking/input-remains',
      'marking/input-remains-child',
      'marking/input-from-individual',
      'marking/input-from-individual-child',
      'marking/remains-description',
      'marking/remains-description-child',
    ],
    milk: [
      ...basePgResources,
      'marking/usage-report',
      'marking/usage-report-child',
      'marking/dropout-report',
      'marking/dropout-report-child',
      'marking/rollout-report',
      'marking/rollout-report-child',
      'marking/aggr-report',
      'marking/aggr-report-child',
    ],
    water: [
      ...basePgResources,
      'marking/usage-report',
      'marking/usage-report-child',
      'marking/dropout-report',
      'marking/dropout-report-child',
      'marking/aggr-report',
      'marking/aggr-report-child',
    ],
    tires: [
      ...basePgResources,
    ],
    bio: [
      ...basePgResources,
      'marking/usage-report',
      'marking/usage-report-child',
      'marking/dropout-report',
      'marking/dropout-report-child',
      'marking/aggr-report',
      'marking/aggr-report-child',
    ],
  }
})
