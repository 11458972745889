import React, { PureComponent } from 'react';
import { addField, AutocompleteInput } from 'react-admin';
import appCore from '../../appCore';

let data = null;

class TnvedSelect extends PureComponent {

  state = {
    value: '',
    choices: []
  }

  componentDidMount() {
    this.loadProducts();
  }

  loadProducts = async () => {
    const productGroup = appCore.currentUrlParams.productGroup;
    if (productGroup === null) {
      return;
    }
    if (data === null) {
      let response = null;
      try {
        response = await fetch('/files/tnved.json').then(x => x.json())
      } catch (e) {
        console.error(e);
      }
      if (response !== null) {
        for (let key in response) {
          response[key] = response[key].map(x => ({ id: x[0], name: `${x[0]} - ${x[1]}`}));
        }
        data = response;
      }
    }
    if (data !== null && productGroup in data) {
      this.setState({
        choices: data[productGroup],
      });
    }
  }

  onInput = (value) => {
    this.setState({
      value,
    })
  }

  render() {
    const {
      ...restProps
    } = this.props;
    let {
      value,
      choices,
    } = this.state;

    if (value !== '' && choices.findIndex(x => x.id === value) === -1) {
      choices = [
        { id: value, name: value },
        ...choices,
      ];
    }
    
    return (
      <AutocompleteInput
        {...restProps}
        setFilter={this.onInput}
        choices={choices}
        translateChoice={false}
        options={{
          suggestionsContainerProps: {
            style: {
              maxWidth: 500,
            }
          }
        }}
      />
    );
  }
}

export default addField(TnvedSelect);