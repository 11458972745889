export const ranges = [
  {
    value: '30m',
    getRange: () => ({
      start_time: new Date(new Date().getTime() - 3600000 / 2),
      end_time: new Date(),
      step: 60 * 5,
    }),
  },
  {
    value: '1h',
    getRange: () => ({
      start_time: new Date(new Date().getTime() - 3600000),
      end_time: new Date(),
      step: 60 * 10,
    }),
  },
  {
    value: '2h',
    getRange: () => ({
      start_time: new Date(new Date().getTime() - 3600000 * 2),
      end_time: new Date(),
      step: 10 * 10,
    }),
  },
  {
    value: '4h',
    getRange: () => ({
      start_time: new Date(new Date().getTime() - 3600000 * 4),
      end_time: new Date(),
      step: 60 * 30,
    }),
  },
  {
    value: '1d',
    getRange: () => ({
      start_time: new Date(new Date().getTime() - 3600000 * 24),
      end_time: new Date(),
      step: 60 * 60,
    }),
  },
]

export const getRangeParams = (key) => {
  const range = ranges.find(x => x.value === key);
  if (range === undefined) {
    throw new Error(`No range with key ${key}`);
  }

  return range.getRange();
}

export const combineMetrics = (metricsData) => {
  const groupedItems = {};
    for (let field in metricsData) {
      if (Array.isArray(metricsData[field])) {
        metricsData[field].forEach(x => {
          if (x.values) {
            x.values.forEach(([timeStamp, rawValue]) => {
              const value = (rawValue === 'NaN' ? null : parseFloat(rawValue))
              if (timeStamp in groupedItems) {
                groupedItems[timeStamp][field] = value;
              } else {
                groupedItems[timeStamp] = {
                  date: new Date(timeStamp * 1000).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' }),
                  [field]: value,
                }
              }
            })
          }
        })
        
      }
    }
    const result = Object.values(groupedItems);
    return result;
}