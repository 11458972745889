import resourceConfig from '../config';

export default {
  pages: {
    [resourceConfig.name]: {
      name: 'Настройка интерфейса',
      fields: {
        enableMetrics: 'Показывать метрики на главных страницах',
        enableNotifications: 'Показывать уведомления (в меню и на главной странице)',
        enableBalance: 'Показывать баланс компании в ЧЗ',
      },
    }
  }
}
