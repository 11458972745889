import React from 'react';
import QueryInput from '../QLInput';
import values from './values';


const fakeDataForAutocomplete = [];

for (let key in values) {
  values[key].forEach((x, index) => {
    if (index >= fakeDataForAutocomplete.length) {
      fakeDataForAutocomplete[index] = {}
    }
    fakeDataForAutocomplete[index][key] = x;
  })
}

const qlInputOptions = [
  { columnField: 'id', type: 'text'},
  { columnField: 'gtin', type: 'text'},
  { columnField: 'serial', type: 'text'},
  { columnField: 'cis', type: 'text'},
  { columnField: 'status', type: 'selection'},
  { columnField: 'additional_status', type: 'selection'},
  { columnField: 'printer_item.status', type: 'selection'},
  { columnField: 'external_status', type: 'selection'},
  { columnField: 'last_es_update', type: 'text'},
  { columnField: 'emission_date', type: 'text'},
  { columnField: 'emission_type', type: 'selection'},
  { columnField: 'parent', type: 'text'},
  { columnField: 'package_type', type: 'text'},
  { columnField: 'stock', type: 'text'},
  { columnField: 'order_id', type: 'text'},
  { columnField: 'code', type: 'text'},
  { columnField: 'documents.status', type: 'selection'},
  { columnField: 'documents.data.', type: 'text'},
  { columnField: 'documents.direction', type: 'selection'},
  { columnField: 'documents.external_id.', type: 'text'},
  { columnField: 'documents.external_status.', type: 'selection'},
  { columnField: 'documents.created.', type: 'text'},
];

const QLInputCodes = (props) => {
  return (
    <QueryInput
      queryOptions={qlInputOptions}
      queryData={fakeDataForAutocomplete}
      {...props}
    />
   )
}

export default QLInputCodes;
