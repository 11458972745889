import moment from 'moment';

export const pathToDictionary = (path = '') => {
  const result = {};
  const segments = path.split('/-/');
  segments.forEach(x => {
    const parts = x.split('/').filter(x => x.length > 0);
    if (parts.length === 0) {
      return;
    }
    let value = decodeURIComponent(parts[1]) || null;
    if (value) {
      try {
        const numValue = parseFloat(value);
        if (numValue === value) {
          value = numValue;
        }
      } catch(e) {}
    }
    result[parts[0]] = value;
  });

  return result;
};

export const dictionaryToPath = (data) => {
  let segments = [];
  for(let key in data) {
    segments.push(`${key}/${encodeURIComponent(data[key]) || ''}`)
  }
  return `/${segments.join('/-/')}/`
};

export const generateGUID = () => {
  /* eslint no-mixed-operators: "off" */
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const generateDocumentId = () => {
  const chars = [];
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  for (let i = 0; i < 3; i++)
    chars.push(possible.charAt(Math.floor(Math.random() * possible.length)));
	
  const date = moment();
  return `${chars.join('')}${date.format('DDD/SS-HHmmss')}`;
}
 

export const asyncTimeout = (timeout) => {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  })
}