import palette from './palette';
import animation from './animation';
import indents from './indents';
import queries from './queries';
import typography from './typography';
import mui from './mui';

export default {
  palette,
  animation,
  indents,
  queries,
  typography,
  mui,
}