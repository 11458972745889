import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'PRODUCED_IN_RF': 'Produced in RF',
        'IMPORTED_INTO_RF': 'Import into RF',
      },
    }
  }
}