import ResourceIcon from '@material-ui/icons/Archive';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import reaggrType from '../../enums/reaggrType';
import CompanyDataInput from '../../../../components/CompanyDataInput';
import aggrReaggrChild from './aggrReaggrChild';


const name = 'marking/reaggr';
const precessName = 'aggregation_reaggregation';
const apiBasePath = `/bp/processes/${precessName}/`;


export default docConfigCreator({
  name,
  icon: ResourceIcon,
  precessName,
  apiBasePath,
  childResource: aggrReaggrChild,
  allowUpdateAction: true,
  allowCreateWithGenerator: false,
  group: 'aggr',
  schema: {
    type: 'object',
    properties: {
      'data.participant_inn': {
        type: 'string',
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
        validation: {
          required: true,
        }
      },
      'data.reaggregation_type': {
        type: 'string',
        componentInput: reaggrType.Input,
        componentField: reaggrType.Field,
        validation: {
          required: true,
        }
      },
      'data.uitu': {
        type: 'string',
        validation: {
          required: true,
        }
      },
    }
  },
  createFields: [
    'data.participant_inn',
    'data.reaggregation_type',
    'data.uitu',
  ],
});
