import ResourceIcon from '@material-ui/icons/Input';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';


const name = 'marking/disaggr-child';
const parentResourceName = 'marking/disaggr';
const apiBasePath = '/bp/processes/aggregation_disaggregation/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  schema: {
    type: 'object',
    properties: {
      'data.uitu': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        }
      },
    }
  },
  listFields: [
    'data.uitu',
  ],
});