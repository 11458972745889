import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';
import request from '../../network/request';
import { fillUrl } from '../../utils/url';
import notificationRowStyle from '../../utils/ui/notificationRowStyle';
import { Button, Typography } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    flex: 1,
  },
  listItemIcon: {
    minWidth: 40,
  }
})

class LastNotificationsWidget extends Component {
  state = {
    items: [],
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.viewVersion !== prevProps.viewVersion) {
      this.loadData();
    }
  }

  loadData = async () => {
    const limit = 5;
    let response = null;
    try {
      response = await request({
        path: `/notification-service/notification/`,
        method: 'GET',
        urlParams: {
          page_size: limit,
          order: '-created',
          is_readed: false,
        }
      })
    } catch (e) {
      console.error(e);
    }

    let error = null;
    let items = [];
    if (response === null || !response.ok) {
      if (response !== null) {
        error = response.data
      }
    } else {
      items = response.data.results.slice(0, limit);
    }

    this.setState({
      items,
      error
    });
  }

  render() {
    const {
      classes,
      translate,
    } = this.props;
    const {
      items
    } = this.state;

    const allUrl = fillUrl('/c/{{companyId}}/s/company/report/notification');

    return (
      <Card className={classes.root}>
        <CardHeader
          title={translate('widgets.lastNotifications.title')}
          action={
            <Button
              size="small"
              component={Link}
              to={allUrl}
            >
              {translate('widgets.lastNotifications.showAll')}
            </Button>
          }
        />
        <List dense={true}>
          {items.map(record => {
            const url = fillUrl(`/c/{{companyId}}/s/company/report/notification/${record.id}/show`);
            return (
              <ListItem
                key={record.id}
                button={url !== null}
                disabled={url === null}
                component={url === null ? undefined : Link}
                to={url || undefined}
                style={notificationRowStyle(record)}
              >
                <ListItemText
                  style={{ 
                    display: 'flex',
                    flexDirection: 'column-reverse',
                  }}
                  primary={(
                    <Typography variant="body2">
                      {record.message}
                    </Typography>
                  )}
                  secondary={new Date(record.created).toLocaleString()}
                />
              </ListItem>
            )
          })}
        </List>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  viewVersion: state.admin.ui.viewVersion,
});

export default compose(
  connect(
    mapStateToProps,
  ),
  translate,
  withStyles(styles)
)(LastNotificationsWidget);