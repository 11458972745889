import React, { Component } from "react";
import { connect } from "react-redux";
import { useFormState } from "react-final-form";
import {
  translate,
  SimpleForm,
  Toolbar,
  SaveButton,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
  TextInput,
  NumberInput,
  required,
  minValue,
  useTranslate,
  maxValue,
} from "react-admin";
import compose from "recompose/compose";
import qs from "qs";
import { withRouter } from "react-router";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import resourceConfig from "./config";
import {
  pathToDictionary,
  generateGUID,
  asyncTimeout,
} from "../../../../utils";
import Snackbar from "../../../../components/Snackbar";
import Button from "../../../../components/Button";
import EnumInput from "../../../../components/EnumInput";
import rest from "../../../../network/rest";
import exporter, { exportFormats } from "../../../../utils/exporter";
import { Box, Card, CardContent } from "@material-ui/core";

const styles = {
  label: { width: "10em", display: "inline-block" },
  button: { margin: "1em" },
  toolbar: { background: "none", paddingLeft: 16 },
  loader: {
    marginLeft: 15,
  },
};

const FormToolbar = translate(
  ({ translate, sent, executeStateMessage, ...props }) => (
    <Toolbar style={styles.toolbar} {...props}>
      <SaveButton
        label={translate(`resources.${resourceConfig.name}.executeAction`)}
        redirect={false}
        submitOnEnter={true}
        disabled={sent}
      />
      {executeStateMessage && (
        <Box ml={2}>
          <Typography variant="body1">{executeStateMessage}</Typography>
        </Box>
      )}
    </Toolbar>
  )
);

const FormWarning = (props) => {
  const t = useTranslate();
  const { values } = useFormState();

  if (values.format === "json" || values.limit <= 30000) {
    return null;
  }

  return (
    <Snackbar
      style={{
        marginTop: 20,
        marginBottom: 20,
        maxWidth: "100%",
        color: "#222630",
      }}
      variant="warning"
      message={
        <div>
          {t(`resources.${resourceConfig.name}.csvWarinig1`)}
          <br />
          {t(`resources.${resourceConfig.name}.csvWarinig2`)}
          <br />
          {t(`resources.${resourceConfig.name}.csvWarinig3`)}
        </div>
      }
      fullWidth
    />
  );
};

class AppModule extends Component {
  defaultParams = {
    limit: 10000,
    offset: 0,
    format: "csv-semicolon",
    fileName: "export",
  };

  lastFormData;

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      key: generateGUID(), // Hack for reset form,
      executeResult: null,
      page: 0,
      params: {
        ...this.defaultParams,
      },
    };
  }

  componentDidMount = () => {
    this.checkLocation();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.checkLocation();
    }
  };

  checkLocation = () => {
    if (this.props.location.search.length < 2) {
      this.resetState();
      return;
    }
    const params = qs.parse(this.props.location.search.slice(1));
    if (!(resourceConfig.urlParamName in params)) {
      this.resetState();
      return;
    }
    if (this.state.show) {
      return;
    }
    let data = {};
    try {
      data = JSON.parse(
        pathToDictionary(params[resourceConfig.urlParamName]).params
      );
    } catch (e) {}

    if (data.resource) {
      data.fileName = data.resource.split("/").slice(-1)[0];
    }

    this.setState({
      show: true,
      page: 0,
      executeResult: null,
      params: {
        ...this.defaultParams,
        ...data,
      },
    });
  };

  resetState = () => {
    this.setState({
      show: false,
    });
    setTimeout(this.afterClose, 500);
  };

  afterClose = () => {
    this.lastFormData = undefined;
    this.setState({
      key: generateGUID(),
      executeResult: null,
      page: 0,
      params: {
        ...this.defaultParams,
      },
    });
  };

  execute = async (formData = this.state.params) => {
    const { translate } = this.props;
    const { resource, limit, offset, filter, sort, format, fileName } =
      formData;

    this.setState({
      executeResult: {
        inProgres: true,
        stateMessage: translate(`resources.${resourceConfig.name}.loadingData`),
      },
    });

    let result;
    try {
      result = await rest.export(
        resource,
        { filter, sort },
        {
          totalLimit: limit,
          offset,
        }
      );
    } catch (e) {
      console.error("Export loading data error", e);
      this.setState({
        executeResult: null,
      });
      return;
    }

    this.setState({
      executeResult: {
        inProgres: true,
        stateMessage: translate(`resources.${resourceConfig.name}.prepareFile`),
      },
    });

    await asyncTimeout(500);

    try {
      await exporter(result.data, null, null, fileName, { format });
    } catch (e) {
      console.error("Export generate file error", e);
      this.setState({
        executeResult: null,
      });
      return;
    }

    this.setState({
      executeResult: {
        inProgres: false,
        stateMessage: null,
        loadedCount: result.data.length,
        hasNext: result.hasNext,
      },
    });
  };

  loadNextPage = () => {
    const params = this.lastFormData || this.state.params;
    const getNextFileName = (fileName, currentPage) => {
      const regexp = new RegExp(`-${currentPage + 1}$`);
      if (currentPage > 0 && regexp.test(fileName)) {
        return fileName.replace(regexp, `-${currentPage + 2}`);
      }
      return `${fileName}-${currentPage + 2}`;
    };

    this.setState(
      (state) => ({
        key: generateGUID(),
        sent: false,
        page: state.page + 1,
        params: {
          ...params,
          offset: params.offset + params.limit,
          fileName: getNextFileName(params.fileName, state.page),
        },
      }),
      this.execute
    );
  };

  validate = (formData) => {
    // Using validate as onChange
    this.lastFormData = formData;
  };

  handleCloseClick = () => {
    this.props.history.goBack();
  };

  render() {
    const { translate } = this.props;

    const { key, executeResult } = this.state;

    const executing = executeResult && executeResult.inProgres;

    return (
      <Drawer
        anchor={"right"}
        open={this.state.show}
        onClose={this.handleCloseClick}
        ModalProps={{
          disablePortal: true,
        }}
      >
        <SimpleForm
          key={key}
          defaultValue={this.state.params}
          save={this.execute}
          toolbar={
            <FormToolbar
              executeStateMessage={executing && executeResult.stateMessage}
            />
          }
          saving={executing}
          style={{ width: 600, maxWidth: "100%" }}
          variant="standard"
          validate={this.validate}
        >
          <Typography variant={"title"}>
            {translate(`resources.${resourceConfig.name}.title`)}
          </Typography>

          <NumberInput
            min={1}
            max={100000}
            validate={[required(), minValue(1), maxValue(200000)]}
            step={1}
            source="limit"
            fullWidth
            label={`resources.${resourceConfig.name}.fields.limit`}
            disabled={executing}
          />

          <NumberInput
            min={0}
            step={1}
            validate={[required(), minValue(0)]}
            source="offset"
            fullWidth
            label={`resources.${resourceConfig.name}.fields.offset`}
            disabled={executing}
          />

          <EnumInput
            source="format"
            enums={exportFormats}
            validate={[required()]}
            fullWidth
            label={`resources.${resourceConfig.name}.fields.format`}
            translatePrefix="exporterFormats."
            disabled={executing}
          />

          <TextInput
            source="fileName"
            fullWidth
            label={`resources.${resourceConfig.name}.fields.fileName`}
            validate={[required()]}
            disabled={executing}
          />

          <FormWarning />

          {executeResult && !executeResult.inProgres && (
            <Card fullWidth elevation={4}>
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  {translate(`resources.${resourceConfig.name}.result.title`)}
                </Typography>
                <Typography variant="body1" component="p" gutterBottom>
                  {translate(`resources.${resourceConfig.name}.result.total`)}:{" "}
                  {executeResult.loadedCount}
                </Typography>
                {executeResult.hasNext && (
                  <>
                    <Typography variant="body1" component="p" gutterBottom>
                      {translate(
                        `resources.${resourceConfig.name}.result.hasNext`
                      )}
                    </Typography>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={this.loadNextPage}
                      >
                        {translate(
                          `resources.${resourceConfig.name}.result.loadNext`
                        )}
                      </Button>
                    </Box>
                  </>
                )}
              </CardContent>
              <div />
            </Card>
          )}
        </SimpleForm>
      </Drawer>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
  translate
  // withStyles(styles)
)(AppModule);
