import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  translate,
  SimpleForm,
  Toolbar,
  SaveButton,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import compose from 'recompose/compose';
import qs from 'qs';
import { withRouter } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import resourceConfig from './config';
import request, { dateToParam } from '../../../../network/request';
import { pathToDictionary } from '../../../../utils';
import Snackbar from '../../../../components/Snackbar';
import acceptShipmentType from '../../enums/acceptShipmentType';
import DateInput from '../../../../components/DateInput';
import FormField from '../../../../components/ui/FormField';
import appConfig from '../../../../appConfig';
import history from '../../../../core/history';
import getResourceUrl from '../../../../utils/admin/getResourceUrl';
import { withStyles } from '@material-ui/styles';

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  toolbar: { background: 'none' },
  loader: {
    marginLeft: 15,
  },
  input: {
    width: '100%',
  }
};

const trnKey = (key) => `resources.${resourceConfig.name}.${key}`;

const FormToolbar = translate(({ translate, ...props }) => (
  <Toolbar style={styles.toolbar} {...props}>
    <SaveButton
      label={translate(trnKey('executeAction'))}
      redirect={false}
      submitOnEnter={true}
    />
    {props.saving && (
      <div style={styles.loader}>
        <CircularProgress />
      </div>
    )}
  </Toolbar>
));

class AppModule extends Component {
  defaultParams = {
    document_id: null,
    acceptance_type: 'accept_all',
    acceptance_date: new Date(),
    auto_execute: true,
    products: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      error: null,
      executing: false,
      params: {
        ...this.defaultParams,
      },
    };
  }

  componentDidMount = () => {
    this.checkLocation();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.checkLocation();
    }
  };

  checkLocation = () => {
    if (this.props.location.search.length < 2) {
      this.resetState();
      return;
    }
    const params = qs.parse(this.props.location.search.slice(1));
    if (!(resourceConfig.urlParamName in params)) {
      this.resetState();
      return;
    }
    if (this.state.show) {
      return;
    }
    const data = pathToDictionary(params[resourceConfig.urlParamName]);

    this.setState({
      show: true,
      params: {
        ...this.defaultParams,
        ...data,
      },
    });
  };

  resetState = () => {
    this.setState({
      show: false,
      executing: false,
    });
    setTimeout(this.afterClose, 500);
  };

  afterClose = () => {
    this.setState({
      params: {
        ...this.defaultParams,
      },
    });
  };

  execute = async (formData) => {
    const { translate } = this.props;
    this.setState({
      executing: true,
    });

    let { document_id, acceptance_date, auto_execute, ...data } = formData;

    const urlParams = {};

    if (auto_execute) {
      urlParams.auto_execute = true;
    }

    let response = null;
    let error = null;

    try {
      response = await request({
        path: `/bp/processes/out_out/${document_id}/accept/`,
        method: 'POST',
        responseType: 'json',
        contentType: 'json',
        urlParams,
        body: {
          acceptance_date: dateToParam(acceptance_date, appConfig.data.apiDateFormat),
          ...data,
        },
      });
    } catch (e) {
      error = e;
      console.error(e);
    }

    if (response !== null && response.ok && response.data && response.data.document_id) {
      this.props.showNotification(
        trnKey('successMessage'),
        'success'
      );
      const path = getResourceUrl({
        resourceName: `marking/acceptance`,
        pageType: 'EDIT',
        id: response.data.document_id,
      });
      history.push(path);
    } else {
      if (response !== null) {
        if (response.parsedError) {
          error = response.parsedError.message;
        } else if (error === null) {
          error = translate('common.unknownError')
        }
      }
    }

    this.setState({
      executing: false,
      error,
    });
  };

  handleCloseClick = () => {
    this.props.history.goBack();
  };

  render() {
    const { classes, translate } = this.props;

    return (
      <Drawer
        anchor={'right'}
        open={this.state.show}
        onClose={this.handleCloseClick}
      >
        <SimpleForm
          variant='standard'
          defaultValue={this.state.params}
          save={this.execute}
          toolbar={<FormToolbar />}
          saving={this.state.executing}
          style={{ width: 700, maxWidth: '100%' }}
        >
          <Typography variant={'title'}>
            {translate(trnKey('title'))}
          </Typography>

          <TextInput source='document_id' fullWidth disabled label={trnKey('fields.document_id')} />

          <acceptShipmentType.Input source='acceptance_type' fullWidth label={trnKey('fields.acceptance_type')} />

          <DateInput source='acceptance_date' fullWidth label={trnKey('fields.acceptance_date')} className={classes.input} />

          <FormField
            render={({ input: { value } }) => {
              if (['accept_other', 'reject_other'].includes(value.acceptance_type)) {
                return (
                  <ArrayInput fullWidth source='products' label={trnKey('fields.products')} variant='standard'>
                    <SimpleFormIterator>
                      <TextInput source='uit_code' label={trnKey('fields.uit_code')} />
                      <TextInput source='uitu_code' label={trnKey('fields.uitu_code')} />
                      <BooleanInput source='accepted' label={trnKey('fields.accepted')} />
                    </SimpleFormIterator>
                  </ArrayInput>
                );
              }
              return null;
            }}
          />

          <BooleanInput source='auto_execute' label={trnKey('fields.auto_execute')} />

          {this.state.error && (
            <Snackbar
              style={{ marginTop: 20, marginBottom: 40, maxWidth: '100%' }}
              textStyle={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}
              showIcon={false}
              variant='error'
              message={JSON.stringify(this.state.error, null, 2)}
              fullWidth
            />
          )}
        </SimpleForm>
      </Drawer>
    );
  }
}

export default compose(
  withRouter,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
  translate,
  withStyles(styles)
)(AppModule);
