import { shallowEqual, useSelector } from 'react-redux';
import { ReduxState } from '../components/Admin';


const useQueryParams = (resource: string) => {
  const paramas = useSelector(
    (state: ReduxState) => state.router.location.query,
    shallowEqual
  );

  return paramas;
};

export default useQueryParams;
