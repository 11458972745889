import React from 'react';
import JsonViewer from '../ui/JsonViewer';


const JsonField = ({ source, record }) => (
  <JsonViewer value={record[source]} />
);

JsonField.defaultProps = {
  addLabel: true
};

export default JsonField;
