import React from "react";
import ShowProductIcon from "@material-ui/icons/Assignment";
import RefreshIcon from "@material-ui/icons/Refresh";
import CopyIcon from "@material-ui/icons/FileCopy";
import NewDocIcon from "@material-ui/icons/NoteAdd";
import { MarkingCodeIcon } from "../../../../components/ui/Icon";
import copyToClipboard from "../../../../utils/copyToClipboard";
import markingStatus from "../../enums/markingStatus";
import textCaseHelper from "../../../../utils/textCaseHelper";
import markingCodeHistory from "./markingCodeHistory";
import stock from "../../../company-service/resources/stock";
import markingCodeDocument from "./markingCodeDocument";
import request from "../../../../network/request";
import markingExtraStatus from "../../enums/markingExtraStatus";
import emissionType from "../../enums/emissionType";
import appCore from "../../../../appCore";
import printStatus from "../../enums/printStatus";
import QLInputCodes from "../../../../components/QLInputCodes";
import createDocWithGenerator from "../../modules/createDocWithGenerator";
import getResourceUrl from "../../../../utils/admin/getResourceUrl";
import { GET_LIST } from "react-admin";

const name = "marking/marking-codes";
export default {
  name,
  icon: MarkingCodeIcon,
  apiResolve: {
    path: "/bp/code/code/",
    pathListExtended: "/bp/code/search/",
    disableSorting: true,
    changeConfig: (type, params, requestConfig) => {
      if (type === GET_LIST && params && params.filter && params.filter.q) {
        const limit = requestConfig.urlParams.limit;
        const offset = requestConfig.urlParams.offset;
        const { q, ...rest } = params.filter;
        const body = {
          query: q,
          limit,
          offset,
        };
        requestConfig = {
          ...requestConfig,
          body,
          path: "/bp/code/search/",
          method: "POST",
          contentType: "json",
          urlParams: rest,
        };
      }
      return requestConfig;
    },
  },
  itemToString: (x) => x.cis || `#${x.id}`,
  schema: {
    type: "object",
    properties: {
      emission_date: {
        type: "dateTime",
      },
      status: {
        type: "string",
        componentInput: markingStatus.Input,
        componentField: markingStatus.Field,
        options: {
          textCase: textCaseHelper.PASCAL_CASE,
        },
      },
      additional_status: {
        type: "string",
        componentInput: markingExtraStatus.Input,
        componentField: markingExtraStatus.Field,
        options: {
          textCase: textCaseHelper.SNAKE_CASE,
        },
      },
      emission_type: {
        type: "string",
        componentInput: emissionType.Input,
        componentField: emissionType.Field,
        options: {
          textCase: textCaseHelper.PASCAL_CASE,
        },
      },
      stock: {
        type: "string",
        componentField: stock.Field,
        componentInput: stock.Input,
        options: {
          link: false,
        },
      },
      parent: {
        type: "string",
        // componentField: { resourceName: name, componentName: 'Field' }
      },
      "printer_item.status": {
        type: "string",
        componentInput: printStatus.Input,
        componentField: printStatus.Field,
        options: {
          textCase: textCaseHelper.PASCAL_CASE,
        },
      },
    },
  },
  listActions: [
    {
      name: `resources.${name}.actions.refreshAll.name`,
      Icon: RefreshIcon,
      handler: async () => {
        const path = `/bp/code/code/fetch_update/`;

        let response = null;
        try {
          response = await request({
            path,
            method: "POST",
          });
        } catch (e) {}

        if (response && response.ok) {
          return {
            notification: {
              type: "success",
              message: `resources.${name}.actions.refreshAll.successMessage`,
            },
          };
        }

        return {
          notification: {
            type: "error",
            message: `resources.${name}.actions.refreshAll.failureMessage`,
          },
        };
      },
    },
  ],
  bulkActions: [
    {
      name: `resources.${name}.actions.bulkUpdate.name`,
      Icon: RefreshIcon,
      handler: async (options) => {
        const { selectedIds = [] } = options;

        if (selectedIds.length === 0) {
          return;
        }

        if (
          !window.confirm(
            `Подтвердите обновление кодов (${selectedIds.length} шт.)`
          )
        ) {
          return;
        }

        let response = null;
        try {
          response = await request({
            path: "/bp/code/code/fetch_update/",
            method: "POST",
            contentType: "json",
            body: {
              ids: selectedIds,
            },
          });
        } catch (e) {}

        if (response && response.ok) {
          return {
            notification: {
              type: "success",
              message: `resources.${name}.actions.bulkUpdate.successMessage`,
            },
          };
        }

        return {
          notification: {
            type: "error",
            message: `resources.${name}.actions.bulkUpdate.failureMessage`,
          },
        };
      },
    },
    {
      name: `resources.${name}.actions.bulkCreateDoc.name`,
      Icon: NewDocIcon,
      getTransitionUrl: ({ selectedIds }) =>
        createDocWithGenerator.getTransitionUrl({
          params: {
            code_ids: selectedIds,
          },
        }),
    },
  ],
  itemActions: [
    {
      name: `resources.${name}.actions.showProduct.name`,
      order: 10,
      Icon: ShowProductIcon,
      getUrl: ({ record }) =>
        record &&
        getResourceUrl({
          resourceName: "marking/product",
          pageType: "LIST",
          params: {
            filter: {
              gtin: record.gtin,
            },
          },
        }),
    },
    {
      name: `resources.${name}.actions.refresh.name`,
      order: 20,
      Icon: RefreshIcon,
      handler: async ({ record }) => {
        const path = `/bp/code/code/${record.id}/fetch_update/`;

        let response = null;
        try {
          response = await request({
            path,
            method: "POST",
          });
        } catch (e) {}

        if (response && response.ok) {
          return {
            notification: {
              type: "success",
              message: `resources.${name}.actions.refresh.successMessage`,
            },
          };
        }

        return {
          notification: {
            type: "error",
            message: `resources.${name}.actions.refresh.failureMessage`,
          },
        };
      },
    },
  ],
  listRowActions: [
    {
      name: `resources.${name}.actions.copyCIS.name`,
      order: 30,
      Icon: CopyIcon,
      handler: ({ record }) => {
        copyToClipboard(record.cis);
        return {
          notification: {
            type: "success",
            message: `resources.${name}.actions.copyCIS.successMessage`,
          },
        };
      },
    },
    {
      name: `resources.${name}.actions.copyFullCode.name`,
      order: 40,
      Icon: CopyIcon,
      isDisabled: ({ record }) => record.code === null,
      handler: ({ record }) => {
        copyToClipboard(record.code);
        return {
          notification: {
            type: "success",
            message: `resources.${name}.actions.copyFullCode.successMessage`,
          },
        };
      },
    },
  ],
  allowList: true,
  allowCreate: false,
  allowEdit: false,
  allowShow: true,
  allowDelete: false,
  showInMenu: true,
  listDefaultSort: { field: "id", order: "DESC" },
  // showFields: [
  //   'id',
  //   'gtin',
  //   'serial',
  //   'status',
  //   'external_status',
  //   'order_id',
  //   'industry',
  //   'request',
  //   'cis',
  //   'emission_date',
  //   'emission_type',
  //   'last_es_update',
  //   'code',
  // ],
  listFields: [
    "id",
    "cis",
    "status",
    "additional_status",
    "emission_type",
    "emission_date",
    "stock",
  ],
  listFilters: [
    "status",
    "additional_status",
    { name: "additional_status__isnull", type: "boolean" },
    "cis",
    "cis__contains",
    "cis__in",
    "gtin",
    "gtin__in",
    "serial",
    "serial__in",
    "emission_type",
    { name: "emission_type__isnull", type: "boolean" },
    "documents",
    "documents__in",
    "printer_status",
    "printer_status__in",
    "printer_status__notin",
    { name: "is_package", type: "boolean" },
    { name: "is_archived", type: "boolean" },
    { name: "parent__isnull", type: "boolean" },
    { name: "presets", type: "string" },
    // { name: "q", type: "string" },
    // { name: "q", component: QLInputCodes },
  ],
  listExtendedFilters: [{ name: "q", component: QLInputCodes, alwaysOn: true }],
  showFields: {
    type: "tabbed",
    items: [
      {
        type: "tab",
        label: `resources.${name}.tabs.info`,
        items: [
          "id",
          "gtin",
          "serial",
          "cis",
          "status",
          "additional_status",
          "printer_item.status",
          "external_status",
          { name: "last_es_update", type: "dateTime" },
          "emission_date",
          "emission_type",
          {
            name: "parent",
            component: (props) => {
              const codesResource = appCore.resources.find(
                (x) => x.name === name
              );
              if (!codesResource) {
                return null;
              }
              return <codesResource.Field {...props} link="show" />;
            },
          },
          "package_type",
          "stock",
          "order_id",
          "code",
        ],
      },
      {
        type: "tab",
        label: `resources.${name}.tabs.history`,
        path: "history",
        items: [
          {
            options: {
              fullWidth: true,
            },
            component: (props) => (
              <markingCodeHistory.ManyField
                {...props}
                fullWidth
                target="item_code"
              />
            ),
          },
        ],
      },
      {
        type: "tab",
        label: `resources.${name}.tabs.documents`,
        path: "documents",
        items: [
          {
            options: {
              fullWidth: false,
            },
            component: (props) => (
              <markingCodeDocument.ManyField
                {...props}
                fullWidth
                target="code"
              />
            ),
          },
        ],
      },
      {
        type: "tab",
        label: `resources.${name}.tabs.children`,
        path: "children",
        items: [
          {
            options: {
              fullWidth: false,
            },
            component: (props) => {
              const codesResource = appCore.resources.find(
                (x) => x.name === name
              );
              if (!codesResource) {
                return null;
              }
              return (
                <codesResource.ManyField {...props} fullWidth target="parent" />
              );
            },
          },
        ],
      },
    ],
  },
};
