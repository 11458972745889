export default {
  name: 'marking/enums/usageType',
  enums: [
    'PRINTED',
    'VERIFIED',
    'USED_FOR_PRODUCTION',
    'SENT_TO_PRINTER',
    'PRINTER_LOST',
  ],
};
