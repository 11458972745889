import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RECEIPT: 'Cash register receipt',
        SALES_RECEIPT: 'Sales receipt',
        CUSTOMS_DECLARATION: 'Customs declaration for goods',
        CONSIGNMENT_NOTE: 'Packing list',
        UTD: 'Universal transfer document',
        DESTRUCTION_ACT: 'Destruction (loss / disposal) act',
        DESTRUCTION: 'Destruction (loss / disposal) act',
        OTHER: 'Other (with manual indication of the name)',
      },
    }
  }
}
