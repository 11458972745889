import resourceConfig from '../config';

export default {
  pages: {
    [resourceConfig.name]: {
      name: 'Отчет по кодам',

      welcome: 'Выберите период чтобы сформировать отчет по кодам',
      welcomeHint: 'Отчет формируется на основе кодов которые были выпущены в указанный период',
      nullKey: 'Не задан',
      requestError: 'Запрос обработан с ошибкой',
      noCodesFoundError: 'Нет кодов за данный период',
      maxCodeLimitError: 'Найдено более 100 000 кодов. Данный отчет доступен только при выборке не более чем 100 000 кодов',
      makeReport: 'Сформировать отчет',
      printReport: 'Печать',
      newReport: 'Новый отчет',
      mainCardTitle: 'Отчет по кодам',
      period: 'Период',
      totalCodes: 'Всего кодов',
      gtins: 'Товары',
      topGtins: 'Основные товары',
      allTimeSwitch: 'За все время',
      allTimePeriod: 'За все время',
      charts: {
        status: 'Статус',
        emissionType: 'Тип эмиссии',
        productGroup: 'Товарная группа',
        printerStatus: 'Статус печати',
        additionalStatus: 'Дополнительный статус',
      },
      viewTypeSelect: {
        label: 'Вид',
        pieChart: 'Графики',
        list: 'Список',
      },
      downloadMenu: {
        label: 'Скачать',
        csv: 'CSV',
        pdf: 'PDF',
      },
    }
  }
}
