import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'PRODUCT_PACKAGE': 'Product package',
        'PRODUCT': 'Product',
        'UCT_LABEL': 'UCT Label',
      },
    }
  }
}