import documentDirectionType from '../../services/marking-service/enums/documentDirectionType';
import documentExternalStatus from '../../services/marking-service/enums/documentExternalStatus';
import documentStatus from '../../services/marking-service/enums/documentStatus';

const values = {
  status: documentStatus.enumsExternal,
  external_status: documentExternalStatus.enumsExternal,
  direction: documentDirectionType.enumsExternal
}

export default values;
