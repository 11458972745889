import ResourceIcon from '@material-ui/icons/List';
import GuidField from '../../../../components/GuidField';

const name = 'settings/ean-info';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/bp/product/ean-gtin/',
  },
  itemToString: x => `#${x.id}`,
  group: 'settings',
  schema: {
    type: 'object',
    properties: {
      ean: {
        type: 'string',
        validation: {
          required: true,
        }
      },
      gtin: {
        type: 'string',
        validation: {
          required: true,
        }
      },
    }
  },
  allowList: true,
  allowCreate: true,
  allowEdit: true,
  allowShow: false,
  allowDelete: true,
  showInMenu: true,
  createFields: [
    'ean',
    'gtin',
  ],
  editFields: [
    'ean',
    'gtin',
  ],
  listFields: [
    {name: 'id', component: GuidField },
    'ean',
    'gtin',
  ]
};