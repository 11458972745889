import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Ввод в оборот (ФТС) |||| Ввод в оборот (ФТС)',
      subMenuName: 'ФТС',
      fields: {
        'data.trade_participant_inn': 'ИНН',
        'data.declaration_number': 'Регистрационный номер декларации',
        'data.declaration_date': 'Дата регистрации декларации',
      }
    }
  }
}
