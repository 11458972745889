import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Custom doc |||| Custom doc',
      fields: {
        'data._attributes': 'Document fields',
        'key': 'Field name',
        'value': 'Field value',
      }
    }
  }
}