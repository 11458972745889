export default {
  name: 'marking/enums/markingExtraStatus',
  enums: [
    'SHIPPED',
    'WAIT_SHIPMENT',
    'WAIT_TRANSFER_TO_OWNER',
    'REMARK_RETIRED',
    'RETIRED_CANCELLATION',
    'WAIT_REMARK',
    'CANCELLATION_APPLIED',
    'DONATION',
    'STATE_ENTERPRISE',
    'NO_RETAIL_USE',
    'BEYOND_EEC_EXPORT',
    'REMOTE_SALE',
    'EEC_EXPORT',
    'RETURN',
    'DAMAGE_LOSS',
    'DESTRUCTION',
    'CONFISCATION',
    'LIQUIDATION',
    'FTS_RESPOND_WAITING',
  ],
};
