import React from 'react';
import { useLocale } from 'react-admin';
import { DateInput as DateInputRA, DateTimeInput as DateTimeInputRA} from './DateInputRA';

const DateInput = (props) => {
  const locale = useLocale();
  const InputComponent = props.showTime ? DateTimeInputRA : DateInputRA;
  return (
    <InputComponent
      clearable
      {...props}
      options={{ 
        format: props.showTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY',
        ...props.options,
        clearable: true,
      }}
      providerOptions={{
        locale,
      }}
      resettable
      allowEmpty
    />
  );
}

export default DateInput;