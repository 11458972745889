import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Баланс',
      actions: {
        update: {
          name: 'Обновить баланс',
          successMessage: 'Запрос на обновление успешно отправлен',
          failureMessage: 'При запросе на обновление произошла ошибка',
        },
      },
      fields: {
        product_group: 'Товарная группа',
        balance: 'Баланс',
        updated: 'Дата обноления',
      }
    }
  }
}
