import React from "react";
import { SvgIcon } from "@material-ui/core";

export default (props) => (
  <SvgIcon {...props}>
    <path d="M0,15c-0.2-2.3,0-5,0.8-6.8c2.8,2.4,9.3,4.3,9-1c0.7,0.8,1,1.1,1.9,1.5c-0.7,1.8,1,2.4,1.4,0.7
  c0.3,0.1,0.5,0.2,0.9,0.3c-0.6,1.8,1.1,2.3,1.5,0.7c0.4,0.1,0.6,0.2,1,0.3c-0.3,1.5,1.2,1.8,1.4,0.5c2.2,0.5,6,1.1,6,3.9
  C16,15,8,15,0,15L0,15L0,15z M24,15.6c-8,0-16,0-23.9,0c0,0.4,0.1,0.8,0.3,1.3c8,0,15.5,0,23.5,0C24,16.4,24,16,24,15.6z"/>
  </SvgIcon>
);
