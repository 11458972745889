import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Отчет об агрегации',
      fields: {
        'data.participant_id': 'ИНН',
      },
    }
  }
}
