import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        IN_PROGRESS: 'Обрабатывается',
        CHECKED_OK: 'Обработан',
        CHECKED_NOT_OK: 'Обработан с ошибкой',
        PROCESSING_ERROR: 'Ошибка при обработке',
        UNDEFINED: 'Не определен',
        CANCELLED: 'Отменен',
        ACCEPTED: 'Принят',
        WAIT_ACCEPTANCE: 'Ожидает приемки',
        WAIT_PARTICIPANT_REGISTRATION: 'Ожидает регистрации получателя',

        DRAFT: 'Черновик',
        PENDING: 'Отправка',
        CHECK: 'Проверка',
        READY_TO_SEND: 'Подготовка',
        REJECTED: 'Отклонен',
        SENT: 'Отправлен',
        PROCESSED: 'Обработан',
        WAIT_FOR_CONTINUATION: 'Ожидает продолжения',

        WAIT_RECEIPT: 'Ожидание квитанции',
        RECEIPT_ACCEPTED: 'Квитанция принята',
        RECEIPT_REJECTED: 'Квитанция отклонена',
      },
    }
  }
}
