import ResourceIcon from '@material-ui/icons/ChromeReaderMode';
import remainsDescriptionChild from './remainsDescriptionChild';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import CompanyDataInput from '../../../../components/CompanyDataInput';


const name = 'marking/remains-description';
const precessName = 'ost_description';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: ResourceIcon,
  precessName,
  apiBasePath,
  childResource: remainsDescriptionChild,
  allowUpdateAction: true,
  group: 'settings',
  schema: {
    type: 'object',
    properties: {
      'data.trade_participant_inn': {
        type: 'string',
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
        validation: {
          required: true,
          format: 'inn',
        }
      },
    }
  },
  createFields: [
    'data.trade_participant_inn',
  ],
});