import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Отчет о выбытии',
      fields: {
        'data.dropout_reason': 'Причина выбытия',
      }
    }
  }
}
