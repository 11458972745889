import ResourceIcon from '@material-ui/icons/Input';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';

const name = 'marking/shipment-child';
const parentResourceName = 'marking/shipment';
const apiBasePath = '/bp/processes/out_out/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  schema: {
    type: 'object',
    properties: {
      'data.uit_code': {
        type: 'string',
        defaultValue: null,
      },
      'data.uitu_code': {
        type: 'string',
        defaultValue: null,
      },
      'data.product_description': {
        type: 'string',
        defaultValue: null,
      },
      'data.product_cost': {
        type: 'string',
        defaultValue: null,
      },
      'data.product_tax': {
        type: 'string',
        defaultValue: null,
      },
      'data.accepted': {
        type: 'boolean',
        defaultValue: true,
      },
    }
  },
  listFields: [
    'data.uit_code',
    'data.uitu_code',
    'data.product_cost',
  ],
});