import ResourceIcon from '@material-ui/icons/Input';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';
import receiptDocumentType from '../../../enums/receiptDocumentType';

const name = 'marking/receipt-child';
const parentResourceName = 'marking/receipt';
const apiBasePath = '/bp/processes/receipt_receipt/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  schema: {
    type: 'object',
    properties: {
      'data.cis': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        }
      },
      'data.product_cost': {
        type: 'number',
        defaultValue: null,
      },
      'data.primary_document_type': {
        type: 'string',
        componentInput: receiptDocumentType.Input,
        componentField: receiptDocumentType.Field,
        defaultValue: null,
      },
      'data.primary_document_number': {
        type: 'string',
        defaultValue: null,
      },
      'data.primary_document_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.primary_document_custom_name': {
        type: 'string',
        defaultValue: null,
      },
    }
  },
  listFields: [
    'data.cis',
    'data.product_cost',
    'data.primary_document_type',
    'data.primary_document_number',
    'data.primary_document_date',
    'data.primary_document_custom_name',
  ],
});
