import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'DOCUMENT_CHANGE_STATUS': 'Document Status Changed',
      },
    }
  }
}