import * as actions from './actions';


let metricsRange = '1d';
const localMetricsRange = localStorage.getItem(actions.METRICS_RANGE_STORAGE_KEY);
if (localMetricsRange) {
  metricsRange = localMetricsRange;
}
const initialState = {
  metricsRange,
};

const handlers = {};

handlers[actions.SET_METRICS_RANGE] = (state, { payload }) => ({
  ...state,
  metricsRange: payload,
});

export default (state = initialState, { type, ...payload }) => {
  if (!(type in handlers)) {
    return state;
  }

  return handlers[type](state, payload);
};
