import ResourceIcon from '@material-ui/icons/Input';
import emissionType from '../../../enums/emissionType';
import textCaseHelper from '../../../../../utils/textCaseHelper';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';
import printSelectorType from '../../../enums/printSelectorType';

const name = 'marking/print-request-child';
const parentResourceName = 'marking/print-request';
const apiBasePath = '/bp/processes/printer/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  schema: {
    type: 'object',
    properties: {
      'data.selector_type': {
        type: 'string',
        componentField: printSelectorType.Field,
        componentInput: printSelectorType.Input,
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.selector_value': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.quantity': {
        type: 'number',
        defaultValue: 1,
        validation: {
          required: true,
        }
      },
      'data.emission_type': {
        type: 'string',
        componentField: emissionType.Field,
        componentInput: emissionType.Input,
        options: {
          textCase: textCaseHelper.PASCAL_CASE,
        },
      },
    }
  },
  listFields: [
    'data.selector_type',
    'data.selector_value',
    'data.emission_type',
    'data.quantity',
  ],
});
