import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Возврат |||| Возврат',
      fields: {
        'data.trade_participant_inn': 'ИНН',
        'data.return_type': 'Тип возврата',
        'data.paid': 'Оплачено',
      }
    }
  }
}
