import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Шаблон этикетки |||| Шаблоны этикеток',
      tabs: {
        info: 'Общая информация',
        template: 'Шаблон',
      },
      fields: {
        type: 'Тип шаблона',
        template: 'Код шаблона',
        file_type: 'Формат файла',
        prerender_datamatrix: 'Предварительный рендеринг кода Data Matrix (base64)',
      }
    }
  }
}
