
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  createMuiTheme,
} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { connect } from 'react-redux';
import { defaultTheme } from '../Admin';
import lightTheme from '../../theme/lightTheme';
import darkTheme from '../../theme/darkTheme';


const RootLayout = ({ theme: themeOverride, children, ...props }) => {
  const themeProp = useRef(themeOverride);
  const [theme, setTheme] = useState(createMuiTheme(themeOverride));

  useEffect(() => {
      if (themeProp.current !== themeOverride) {
          themeProp.current = themeOverride;
          setTheme(createMuiTheme(themeOverride));
      }
  }, [themeOverride, themeProp, theme, setTheme]);

  return (
      <ThemeProvider theme={theme}>
          {children}
      </ThemeProvider>
  );
};

RootLayout.propTypes = {
  theme: PropTypes.object,
};

RootLayout.defaultProps = {
  theme: defaultTheme,
};

export default connect(
  state => ({
    theme: state.app.theme === 'dark' ? darkTheme.mui : lightTheme.mui,
  }),
  {}
)(RootLayout);
