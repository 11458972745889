import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиция - дезагрегация |||| Позиции - дезагрегаця',
      fields: {
        'data.uitu': 'SSCC',
      }
    }
  }
}