import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        COMPLETED: 'Completed',
        REQUEST_CREATED: 'Request created',
        PROCESSING: 'Processing',
        CHECKING_DOCUMENT_STATUS: 'Checkng status',
        FAILED: 'Failed',
        DRAFT: 'Draft',
        READY: 'Ready',
        WAIT_ACCEPTANCE: 'Wait Acceptance',
        EXECUTE_CANCELED: 'Sending canceled',
      },
    }
  }
}
