import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Документы по коду',
      fields: {
        doctype: 'Тип документа',
        stock: 'Склад',
      }
    }
  }
}