import { parse } from 'query-string';
import getResourceUrl from './getResourceUrl';

export default ({ parentBaseUrl, parentResourceName, tabPath = '2' }) => (props) => {
  const { parent_id } = parse(props.location.search);
  let redirectUrl = false;
  let error = null;
  if (parent_id) {
    if (parentResourceName) {
      redirectUrl = getResourceUrl({
        resourceName: parentResourceName,
        pageType: 'EDIT',
        id: parent_id,
        tabPath: tabPath,
      })
    } else if (parentBaseUrl) {
      redirectUrl = `${parentBaseUrl}/${parent_id}/${tabPath}`;
    }
  } else {
    error = 'No parent id';
  }
  
  return {
    initialValues: {
      parent_id,
    },
    redirectUrl,
    error,
  }
}