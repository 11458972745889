export default {
  horizontal: {
    main: '80px',
    medium: '40px',
    small: '20px',
  },
  vertical: {
    main: '30px',
    medium: '20px',
    small: '10px',
  }
};
