import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RETAIL_RETURN: 'Возврат при розничной реализации',
        REMOTE_SALE_RETURN: 'Возврат при дистанционном способе продажи',
      },
    }
  }
}
