import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Товар - отчет об агрегации |||| Товары - отчет об агрегации',
      fields: {
        'data.unit_serial_number': 'Код агрегации',
        'data.aggregation_unit_capacity': 'Емкость упаковки',
        'data.aggregated_items_count': 'Фактическое количество в упаковке',
        'data.sntins': 'Вложенные коды',
      }
    }
  }
}
