// import memoizee from 'memoizee';

import createHash from "./createHash";

// let index = 0;
// const memoized = memoizee(() => {
//   index += 1;
//   return `__${index}`;
// });

export default (fields = []) => (data) => {
  let values = [];
  if (fields.length === 0) {
    values = Object.values[data];
  } else {
    values = fields.map(x => data[x] || '');
  }

  // TODO: fix memoizee (возможно проблема в проверку вложенности)
  // return memoized(...values)
  return createHash(JSON.stringify(values), true);
}
