import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Компания |||| Компании',
      fields: {
        'is_testing': 'Тестовая компания',
        'info.inn': 'ИНН',
        'info.contact_person': 'Контактное лицо',
        'info.address': 'Адрес компании',
      }
    }
  }
}
