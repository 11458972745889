import React from 'react';
import getValue from '../../utils/getValue';

export default (props) => {
  const {
    source,
    record,
    emptyText,
    itemNameField,
  } = props;

  const value = getValue(record, source) || null;
  let viewValue = emptyText;
  if (value && Array.isArray(value) && value.length > 0) {
    if (itemNameField) {
      viewValue = value.map(x => x[itemNameField]).join(', ');
    } else {
      viewValue = value.join(', ');
    }
  }

  return (
    <span>{viewValue}</span>
  );
}