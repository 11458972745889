import React, { Component } from "react";
import { connect } from "react-redux";
import { Box, Card } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Title, TopToolbar, SimpleForm } from "../../components/Admin";
import PageActionButton from "../PageActionButton";

const Actions = ({ actions, data }) => (
  <TopToolbar>
    {actions &&
      actions.map((action) => (
        <PageActionButton key={action.name} action={action} data={data} />
      ))}
  </TopToolbar>
);

class FormPage extends Component {
  state = {
    loadError: null,
    loadInProgress: true,

    saveError: null,
    saveInProgress: false,

    defaultData: null,
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.viewVersion !== prevProps.viewVersion) {
      this.loadData();
    }
  }

  loadData = async () => {
    const { loadData = null } = this.props;

    if (loadData === null) {
      return null;
    }
    this.setState({
      loadInProgress: true,
    });

    let data = null;
    let error = null;
    try {
      data = await loadData();
    } catch (e) {
      error = e;
    }

    this.setState({
      loadError: error,
      loadInProgress: false,
      defaultData: data,
    });
  };

  saveData = async (data) => {
    const { saveData = null } = this.props;

    if (saveData === null) {
      return null;
    }
    this.setState({
      saveInProgress: true,
    });

    let error = null;
    let result = null;
    try {
      result = await saveData(data);
    } catch (e) {
      error = e;
    }

    if (result) {
      this.setState({
        saveError: null,
        saveInProgress: false,
        defaultData: result,
      });
    } else {
      this.setState({
        saveError: error,
        saveInProgress: false,
      });
    }
  };

  render() {
    const { pageTitle = null, children, actions } = this.props;
    const {
      // loadError,
      loadInProgress,
      defaultData,
    } = this.state;

    return (
      <div>
        {pageTitle && <Title title={pageTitle} />}
        {defaultData === null && loadInProgress && (
          <Box m={4} display="flex" justifyContent="center">
            <CircularProgress size={40} />
          </Box>
        )}
        {defaultData && (
          <>
            <Actions actions={actions} data={defaultData} />
            <Card>
              <SimpleForm
                initialValues={defaultData}
                variant="standard"
                margin="normal"
                save={this.saveData}
              >
                {children}
              </SimpleForm>
            </Card>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  viewVersion: state.admin.ui.viewVersion,
});

export default connect(mapStateToProps)(FormPage);
