import ResourceIcon from '@material-ui/icons/AccountBalance';
import LinkField from '../../../../components/LinkField';
import { CompanyCard } from './CompanyCard';

const name = 'common/company';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/cm/company/company/',
  },
  itemToString: x => x.name || `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        validation: {
          required: true,
        }
      },
      is_testing: {
        type: 'boolean',
      },
      slug: {
        type: 'string',
        validation: {
          required: true,
        }
      },
      description: {
        type: 'string',
        options: {
          multiline: true,
        }
      },
      'info.inn': {
        type: 'string',
        validation: {
          required: true,
          format: 'inn'
        },
      },
      'info.contact_person': {
        type: 'string',
        validation: {
          required: true,
        },
      },
      'info.address': {
        type: 'string',
        validation: {
          required: true,
        },
      },
    }
  },
  allowList: true,
  allowCreate: true,
  allowEdit: true,
  allowShow: false,
  allowDelete: true,
  allowExport: false,
  showInMenu: true,
  listView: {
    type: 'cards',
    ItemComponent: CompanyCard,
  },
  createFields: [
    'name',
    'slug',
    'description',
    'is_testing',
    'info.inn',
    'info.contact_person',
    'info.address',
  ],
  editFields: [
    'id',
    'slug',
    'name',
    'description',
    'info.inn',
    'info.contact_person',
    'info.address',
  ],
  showFields: [
    'id',
    'name',
    'is_testing',
    'slug',
    'description',
    'info.inn',
    'info.contact_person',
  ],
  listFields: [
    'id',
    { name: 'name', component: LinkField, url: '/c/{{id}}/s/company' },
    'is_testing',
  ],
};
