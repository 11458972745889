import React from "react";
import { SvgIcon } from "@material-ui/core";

export default (props) => (
  <SvgIcon {...props} viewBox="0 0 24 25">
    <circle cx="11" cy="11" r="9" fill="black" fill-opacity="0.6"/>
    <path d="M7.93889 12.9944L8.72222 13.7778L11.5 11L8.72222 8.22222L7.93889 9.00556L9.37222 10.4444H6V11.5556H9.37222L7.93889 12.9944ZM14.8889 6H7.11111C6.49444 6 6 6.5 6 7.11111V9.33333H7.11111V7.11111H14.8889V14.8889H7.11111V12.6667H6V14.8889C6 15.5 6.49444 16 7.11111 16H14.8889C15.5 16 16 15.5 16 14.8889V7.11111C16 6.5 15.5 6 14.8889 6Z" fill="white"/>
    <circle cx="16" cy="18" r="6.5" fill="#FAFAFA" stroke="#FAFAFA"/>
    <circle cx="16" cy="18" r="6" fill="black" fill-opacity="0.6"/>
    <path d="M18.0221 21.0333V20.4889H17.4777H17.011V20.0222H16.4665V19.4778H15.9999V19.0111H16.4665V18.4667H15.9999V18V17.5333H15.5332V18H14.9888V18.4667H14.5221V19.0111H14.9888H15.5332V19.4778H14.9888V20.0222H15.5332H15.9999V20.4889H16.4665V21.0333H15.9999V20.4889H15.5332V21.0333H14.9888V20.4889V20.0222H14.5221V19.4778H13.9777V19.0111H13.511H12.9665V18.4667H13.511V18H13.9777V17.5333H13.511V16.9889V16.5222H12.9665V15.9778V15.5111H13.511H13.9777V14.9667V14.5H13.511V14.9667H12.9665V14.5H12.4999V14.9667V15.5111V15.9778V16.5222V16.9889V17.5333V18V18.4667V19.0111V19.4778V20.0222V20.4889V21.0333V21.5H12.9665H13.511H13.9777H14.5221H14.9888H15.5332H15.9999H16.4665H17.011H17.4777H18.0221H18.4888H19.0332H19.4999V21.0333H19.0332V20.4889H18.4888V21.0333H18.0221ZM14.5221 21.0333H13.9777H13.511V20.4889H12.9665V20.0222H13.511H13.9777V20.4889H14.5221V21.0333Z" fill="white"/>
    <path d="M18.4888 19.4778H18.0221V19.0112H17.4777V18.4667H17.011V19.0112H16.4666V19.4778H17.011V20.0223H17.4777H18.0221V20.4889H18.4888V20.0223H19.0332H19.4999V19.4778H19.0332V19.0112H18.4888V19.4778Z" fill="white"/>
    <path d="M18.4888 18.4667H18.0221V19.0112H18.4888V18.4667Z" fill="white"/>
    <path d="M19.4996 18.4667H19.033V19.0112H19.4996V18.4667Z" fill="white"/>
    <path d="M17.011 18.0001H16.4666V18.4667H17.011V18.0001Z" fill="white"/>
    <path d="M18.0222 18.0001H17.4778V18.4667H18.0222V18.0001Z" fill="white"/>
    <path d="M19.0332 18.0001H18.4888V18.4667H19.0332V18.0001Z" fill="white"/>
    <path d="M18.4888 17.5334H18.0221V18.0001H18.4888V17.5334Z" fill="white"/>
    <path d="M14.9889 16.989H14.5222V17.5334V18.0001H14.9889V17.5334V16.989Z" fill="white"/>
    <path d="M19.0332 17.5334V18.0001H19.4999V17.5334V16.989H19.0332H18.4888V17.5334H19.0332Z" fill="white"/>
    <path d="M13.9777 16.989H14.5221V16.5223V15.9778H13.9777H13.511V16.5223H13.9777V16.989Z" fill="white"/>
    <path d="M15.5331 16.5223H14.9886V16.989H15.5331V16.5223Z" fill="white"/>
    <path d="M15.5333 15.9778V15.5112H14.9889H14.5222V15.9778H14.9889H15.5333Z" fill="white"/>
    <path d="M16.4667 15.9778H16H15.5333V16.5223H16V16.989H16.4667V17.5334H17.0111V16.989V16.5223V15.9778V15.5112H17.4778V14.9667V14.5001H17.0111V14.9667H16.4667V15.5112V15.9778Z" fill="white"/>
    <path d="M18.0222 15.5112H17.4778V15.9778H18.0222V15.5112Z" fill="white"/>
    <path d="M16 14.9667H15.5333V15.5112H16V14.9667Z" fill="white"/>
    <path d="M19.5 15.5112V14.9667H19.0333V14.5001H18.4889V14.9667V15.5112V15.9778H18.0222V16.5223H17.4778V16.989V17.5334H18.0222V16.989H18.4889V16.5223H19.0333H19.5V15.9778H19.0333V15.5112H19.5Z" fill="white"/>
    <path d="M15.5331 14.5001H14.9886V14.9667H15.5331V14.5001Z" fill="white"/>
    <path d="M16.4665 14.5001H15.9999V14.9667H16.4665V14.5001Z" fill="white"/>
  </SvgIcon>
);
