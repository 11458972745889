import OpenButton from './OpenButton';
import AppModule from  './AppModule';
import resourceLocales from './locales';
import resourceConfig from './config';
import getTransitionUrl from './getTransitionUrl';



export default {
    AppModule,
    OpenButton,

    getTransitionUrl: getTransitionUrl,
    name: resourceConfig.name,
    locales:  resourceLocales,
}
