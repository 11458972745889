import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import request from '../../network/request';
import BalanceCard from './BalanceCard';
import appCore from '../../appCore';


class ProductGroupBalanceWidget extends Component {
  state = {
    error: null,
    data: null,
    loading: true,
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.viewVersion !== prevProps.viewVersion) {
      this.loadData();
    }
  }

  loadData = async () => {
    let response = null;
    this.setState({
      loading: true,
    })
    const pg = appCore.getProductGroup();
    try {
      response = await request({
        path: `/cm/company/balance/${pg}/`,
        method: 'GET',
      })
    } catch (e) {
      console.error(e);
    }

    let error = null;
    let data = null;
    if (response === null || !response.ok) {
      if (response !== null) {
        error = response.data
      }
    } else {
      data = response.data;
    }

    this.setState({
      error,
      data,
      loading: false,
    });
  }

  render() {
    const {
      data,
      loading,
    } = this.state;

    return (
      <BalanceCard
        data={data}
        loading={loading}
      />
    );
  }
}

const mapStateToProps = state => ({
  viewVersion: state.admin.ui.viewVersion,
});

export default compose(
  connect(
    mapStateToProps,
  ),
)(ProductGroupBalanceWidget);
