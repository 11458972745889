import { MarkingInputIcon } from '../../../../components/ui/Icon';
import inputSelfMadeChild from './inputSelfMadeChild';
import markingType from '../../enums/markingType';
import productionType from '../../enums/productionType';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import CompanyDataInput from '../../../../components/CompanyDataInput';


const name = 'marking/input-self-made';
const precessName = 'input_self_made';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: MarkingInputIcon,
  precessName,
  apiBasePath,
  group: 'input',
  childResource: inputSelfMadeChild,
  allowUpdateAction: true,
  menuConfig: {
    text: `resources.${name}.subMenuName`,
  },
  schema: {
    type: 'object',
    properties: {
      'data.participant_inn': {
        type: 'string',
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
        validation: {
          required: false,
          format: 'inn',
        }
      },
      'data.producer_inn': {
        type: 'string',
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
        validation: {
          required: false,
          format: 'inn',
        }
      },
      'data.owner_inn': {
        type: 'string',
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
        validation: {
          required: false,
          format: 'inn',
        }
      },
      'data.marking_type': {
        type: 'string',
        componentInput: markingType.Input,
        componentField: markingType.Field,
        validation: {
          required: true,
        }
      },
      'data.production_type': {
        type: 'string',
        componentInput: productionType.Input,
        componentField: productionType.Field,
        validation: {
          required: true,
        },
      },
      'data.production_date': {
        type: 'date',
        validation: {
          required: true,
        },
      },
    }
  },
  createFields: [
    'data.participant_inn',
    'data.producer_inn',
    'data.owner_inn',
    'data.marking_type',
    'data.production_type',
    'data.production_date',
  ],
});