import React from 'react';
import { useInput } from 'ra-core';

const ShowConditionInput = (props) => {
  const {
    condition,
    Component,
    nextProps,
    ...restProps
  } = props;
  
  const {
    input: { value },
  } = useInput({});

  const shown = condition(value);
  if (!shown) {
    return null;
  }

  return (
    <Component
      {...nextProps}
      {...restProps}
    />
  );
}

export default ShowConditionInput;
