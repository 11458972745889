import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'User |||| Users',
      fields: {
        preferred_username: 'Username',
        email: 'Email',
        given_name: 'First name',
        family_name: 'Last name',
      },
    }
  }
}