import React from 'react';
import ResourceIcon from '@material-ui/icons/Input';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import { showNotification } from '../../../../../components/Admin';
import store from '../../../../../redux/store';
import childCreateOptionsCreator from '../../../../../utils/admin/childCreateOptionsCreator';
import childEditOptionsCreator from '../../../../../utils/admin/childEditOptionsCreator';
import { createSendTestNotificationActionCreator } from '../itemActionCreators';


const name = 'settings/notification-group-telegram';
const parentResourceName = 'settings/notification-group';

export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/notification-service/notification-group/{{parent_id}}/telegram/settings/',
    disableSorting: true,
    afterCreate: ({ data }) => {
      store.dispatch(
        showNotification((
          <span>
            Save connection key please:
            <b style={{ padding: '0 5px 0 10px'}}>{data.connection_key}</b>
            <CopyToClipboard text={data.connection_key} onCopy={() => window.document.body.click()}>
              <IconButton
                size="small"
                color="inherit"
              >
                <CopyIcon />
              </IconButton>
            </CopyToClipboard>
          </span>
        ), 'info', { autoHideDuration: 10000000 })
      );
    },
  },
  itemToString: x => `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      is_active: {
        type: 'boolean',
      },
      active_till: {
        type: 'dateTime',
      },
      created: {
        type: 'dateTime',
        readOnly: true,
      },
      updated: {
        type: 'dateTime',
        readOnly: true,
      },
      activated_till: {
        type: 'dateTime',
        readOnly: true,
      },
      connection_key: {
        type: 'string',
        options: {
          readOnly: true,
        }
      },
    }
  },
  allowList: true,
  allowCreate: true,
  allowEdit: true,
  allowShow: false,
  allowDelete: true,
  showInMenu: false,
  group: 'settings',
  itemActions: [
    createSendTestNotificationActionCreator({ groupType: 'telegram', resourceName: name, parentResourceName }),
  ],
  createGetOptions: childCreateOptionsCreator({ parentResourceName, tabPath: 'telegram' }),
  editGetOptions: childEditOptionsCreator({ parentResourceName, tabPath: 'telegram' }),
  createFields: [],
  editFields: [
    'id',
    'activated_till',
    'is_active',
    'active_till',
    'created',
    'updated',
  ],
  listFields: [
    'id',
    'activated_till',
    'active_till',
    'is_active',
  ],
  listFilters: [],
};
