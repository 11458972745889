import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import request from '../../network/request';
import PGCard from './PGCard';
import appCore from '../../appCore';


class ProductGroupsWidget extends Component {
  state = {
    balanceError: null,
    balanceLoading: true,
    balanceData: null,
    pgError: null,
    pgData: null,
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.viewVersion !== prevProps.viewVersion) {
      this.loadData();
    }
  }

  loadData = () => {
    this.loadBalanceData();
    this.loadPGData();
  }

  loadBalanceData = async () => {
    let response = null;
    this.setState({
      balanceLoading: true,
    });
    try {
      response = await request({
        path: `/cm/company/balance/`,
        method: 'GET',
      })
    } catch (e) {
      console.error(e);
    }

    let error = null;
    let data = null;
    if (response === null || !response.ok) {
      if (response !== null) {
        error = response.data
      }
    } else {
      data = response.data.results;
    }

    this.setState({
      balanceError: error,
      balanceData: data,
      balanceLoading: false,
    });
  }

  loadPGData = async () => {
    let response = null;
    try {
      response = await request({
        path: `/cm/company/pg/`,
        method: 'GET',
        urlParams: {
          limit: 1000,
        }
      })
    } catch (e) {
      console.error(e);
    }

    let error = null;
    let data = null;
    if (response === null || !response.ok) {
      if (response !== null) {
        error = response.data
      }
    } else {
      data = response.data.results;
    }

    this.setState({
      pgError: error,
      pgData: data,
    });
  }

  render() {
    const {
      balanceData,
      balanceLoading,
      pgData,
    } = this.state;

    return (
      <Grid container item spacing={2}>
        {appCore.productGroups.map((pg) => {
          const balance = balanceData ? balanceData.find((x) => x.product_group === pg.name) : undefined;
          const companyPG = pgData ? pgData.find((x) => x.product_group === pg.name) : undefined;

          return (
            <Grid item xs={12} lg={4}>
              <PGCard
                key={pg.name}
                pgInfo={pg}
                balance={balance}
                companyActive={companyPG ? companyPG.is_enabled : false}
                loading={balanceLoading}
              />
            </Grid>
          )
        })}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  viewVersion: state.admin.ui.viewVersion,
});

export default compose(
  connect(
    mapStateToProps,
  ),
)(ProductGroupsWidget);
