import ResourceIcon from '@material-ui/icons/Sync';
import remarkingChild from './remarkingChild';
import remarkingCause from '../../enums/remarkingCause';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import CompanyDataInput from '../../../../components/CompanyDataInput';


const name = 'marking/remarking';
const precessName = 'remark_remark';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: ResourceIcon,
  precessName,
  apiBasePath,
  childResource: remarkingChild,
  allowUpdateAction: true,
  schema: {
    type: 'object',
    properties: {
      'data.participant_inn': {
        type: 'string',
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
      },
      'data.remarking_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.remarking_cause': {
        type: 'string',
        componentInput: remarkingCause.Input,
        componentField: remarkingCause.Field,
        defaultValue: null,
      },
    }
  },
  createFields: [
    'data.participant_inn',
    'data.remarking_date',
    'data.remarking_cause',
  ],
});
