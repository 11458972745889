import * as React from 'react';
import { FunctionComponent, ReactElement } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { FieldTitle } from 'ra-core';

const useStyles = makeStyles(
    theme => ({
        label: {
            position: 'relative',
        },
        value: {
            fontFamily: theme.typography.fontFamily,
            color: 'currentColor',
            padding: `${theme.spacing(1)}px 0 ${theme.spacing(1) / 2}px`,
            border: 0,
            boxSizing: 'content-box',
            verticalAlign: 'middle',
            background: 'none',
            margin: 0, // Reset for Safari
            display: 'block',
            width: '100%',
        },
    }),
    { name: 'RaLabeled' }
);

export interface LabeledFieldProps {
    children: ReactElement;
    className?: string;
    classes?: object;
    fullWidth?: boolean;
    label?: string | ReactElement;
    isRequired?: boolean;
    [key: string]: any;
}

const LabeledField: FunctionComponent<LabeledFieldProps> = props => {
    const {
        children,
        className,
        classes: classesOverride,
        fullWidth,
        label,
        margin = 'dense',
        isRequired,
        ...rest
    } = props;

    const classes = useStyles(props);
    if (!label) {
        // @ts-ignore
        const name = children && children.type && children.type.name;

        throw new Error(
            `Cannot create label for component <${name}>: You must set either the label props.`
        );
    }
    const restProps = fullWidth ? { ...rest, fullWidth } : rest;

    return (
        <FormControl
            className={className}
            fullWidth={fullWidth}
            margin={margin}
        >
            <InputLabel shrink className={classes.label}>
                <FieldTitle
                    label={label}
                    isRequired={isRequired}
                />
            </InputLabel>
            <div className={classes.value}>
                {children && typeof children.type !== 'string'
                    ? React.cloneElement(children, restProps)
                    : children}
            </div>
        </FormControl>
    );
};

LabeledField.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
    classes: PropTypes.object,
    fullWidth: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isRequired: PropTypes.bool,
};

export default LabeledField;
