import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  translate as translateRA,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
} from "react-admin";
import { withRouter, Link } from "react-router-dom";
import Button from "../Button";
import compose from "../../utils/compose";
import TransitionLink from "../TransitionLink";

@compose(
  translateRA,
  withRouter,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  })
)
class ResourceBulkActionButton extends PureComponent {
  state = {
    loading: false,
  };

  handleAction = async () => {
    if (this.state.loading) {
      return;
    }

    const { action, showNotification, refreshView, ...props } = this.props;

    this.setState({
      loading: true,
    });
    const result = await action.handler(props);

    if (result && result.notification) {
      showNotification(result.notification.message, result.notification.type);
    }

    this.setState(
      {
        loading: false,
      },
      refreshView
    );
  };

  render() {
    let {
      action,
      disabled = false,
      selectedIds = [],
      translate,
      location,
      match,
    } = this.props;
    const { loading } = this.state;

    const nextProps = {};
    if (action.getUrl) {
      nextProps.component = Link;
      nextProps.to = action.getUrl({ selectedIds, location, match });
    } else if (action.getTransitionUrl) {
      nextProps.component = TransitionLink;
      try {
        nextProps.transitionTo = action.getTransitionUrl({ selectedIds });
      } catch (e) {
        console.warn('action.getTransitionUrl error', e);
      }
    }

    if (
      action.isDisabled &&
      typeof action.isDisabled === "function" &&
      selectedIds.length > 0
    ) {
      try {
        disabled = action.isDisabled({ selectedIds });
      } catch (e) {
        console.warn('action.isDisabled error', e);
        disabled = true;
      }
    }

    let icon;
    if (action.Icon) {
      icon = <action.Icon />
    }

    return (
      <Button
        {...nextProps}
        size="small"
        color="default"
        onClick={action.handler && this.handleAction}
        loading={loading}
        disabled={disabled}
        startIcon={icon}
      >
        {translate(action.name)}
      </Button>
    );
  }
}

export default ResourceBulkActionButton;
