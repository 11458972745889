import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Change history',
      fields: {
        document_id: 'Document ID',
        created: 'Date',
        new_data: 'New Data',
        previous_data: 'Previous Data',
      }
    }
  }
}
