
import React from 'react';
import compose from 'recompose/compose';
import {
  translate,
  SelectInput,
} from '../../../../components/Admin';
import searchInObject from '../../../../utils/searchInObject';
import appCore from '../../../../appCore';

const name = 'marking/enums/codeTemplateId';
const enums = [1, 3, 4, 5, 20, 7, 8, 9, 10, 11, 12, 14, 15, 16, 23];

const getItemText = (value, translate) => `${value} - ${translate(`resources.${name}.enums.${value}`)}`;

const ResourceField = compose(translate)(({ translate, source, record = {}, emptyText, ...otherProps }) => {
  let value = searchInObject(record, source)
  if (value) {
    value = getItemText(value, translate);
  } else if (!value && emptyText) {
    value = emptyText;
  }
  return (
    <span>
      {value}
    </span>
  );
});

ResourceField.defaultProps = {
  addLabel: true
};

const ResourceInput = compose(translate)((props) => {
  let { translate, allowEmpty = true, ...rest } = props;

  let choices = enums.map(x => ({ id: x, name: getItemText(x, translate) }));
  let currentPg = null;
  if (appCore.currentUrlParams && appCore.currentUrlParams.productGroup) {
    currentPg = appCore.productGroups.find(x => x.name === appCore.currentUrlParams.productGroup) || null;
  }

  if (currentPg !== null) {
    rest.defaultValue = currentPg.templateId;
  }

  return (
  <SelectInput
    allowEmpty={allowEmpty}
    translateChoice={false}
    {...rest}
    choices={choices}
  />
)});

const result = {
  name,
  Field: ResourceField,
  Input: ResourceInput,
};

export default result;
