import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Label template |||| Label templates',
      tabs: {
        info: 'Information',
        template: 'Template',
      },
      fields: {
        type: 'Template type',
        template: 'Template code',
        file_type: 'File extension',
        prerender_datamatrix: 'Prerender Data Matrix code (base64)',
      }
    }
  }
}
