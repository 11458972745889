import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Marking code history',
      fields: {
        parent_cis: 'Parent UITU',
        parent: 'Parent',
        task_id: 'Task ID',
      }
    }
  }
}