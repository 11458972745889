import textCaseHelper from "../../../../utils/textCaseHelper";

export default {
  name: 'marking/enums/orderPaymentType',
  externalTextCase: textCaseHelper.AS_IS,
  enums: [
    'ON_ORDER',
    'ON_INPUT',
  ],
};
