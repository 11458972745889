import React, { Fragment } from 'react';
import {
  ReferenceManyField,
  Datagrid,
  DeleteButton,
  Pagination,
} from '../../components/Admin';
import getFieldsFromSchema from './getFieldsFromSchema';
import ProxyContainer from '../../components/ProxyContainer';
import EditButton from '../../components/EditButton';
import ShowButton from '../../components/ShowButton';
import { Toolbar } from '@material-ui/core';


export default (options = {}) => {
  let {
    name,
    schema = null,
    listFields = null,
    listRowClickAction = undefined,
    referenceListFields = null,
    allowDelete = false,
    allowEdit = false,
    allowShow = false,
  } = options;

  let fields = referenceListFields || listFields;
  if (fields === null && schema !== null && schema.properties) {
    fields = Object.keys(schema.properties);
  }

  if (allowDelete || allowEdit || allowShow) {
    fields = [
      ...fields,
      {
        component: (props) => {
          const {
            basePath,
            record = null,
            parent = null,
          } = props;

          if (record === null) {
            return null;
          }

          let editUrl = undefined;
          if (allowEdit && parent !== null) {
            editUrl = `${basePath}/${record.id}?parent_id=${parent.id}`;
          }
          // TODO: check and fix show url
          return (
            <ProxyContainer
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              {...props}
              record={{
                parent_id: parent.id, // Прокидываем parent_id для удаления
                ...record,
              }}
            >
              {allowEdit && (
                <EditButton to={editUrl} />
              )}
              {allowShow && (
                <ShowButton />
              )}
              {allowDelete && (
                <DeleteButton undoable={false} redirect={false}/>
              )}
            </ProxyContainer>
          );
        }
      }
    ]
  }

  return (props) => {
    const {
      createButton: CreateButton = null,
      target,
      record,
      ...restProps
    } = props;

    return (
      <Fragment>
        {CreateButton && (
          <Toolbar
            variant="dense"
            style={{ paddingLeft: 0, paddingRight: 0, justifyContent: 'flex-end'}}
          >
            <CreateButton
              {...props}
            />
          </Toolbar>
        )}
        <ReferenceManyField
          {...restProps}
          addLabel={false}
          reference={name}
          target={target}
          record={record}
          pagination={<Pagination target={target} record={record} />}
          sort={{ field: 'created', order: 'DESC' }}
        >
          {getFieldsFromSchema({
            schema,
            fields,
            editable: false,
            Component: Datagrid,
            rowClick: listRowClickAction,
            fieldProps: {
              sortable: false,
              parent: props.record,
            }
          })}
        </ReferenceManyField>
      </Fragment>
    );
  }
}
