import textCaseHelper from "../../../../utils/textCaseHelper";

export default {
  name: 'marking/enums/acceptShipmentType',
  externalTextCase: textCaseHelper.SNAKE_CASE,
  enums: [
    'ACCEPT_ALL',
    'REJECT_ALL',
    'ACCEPT_OTHER',
    'REJECT_OTHER',
    'AUTO',
  ],
};
