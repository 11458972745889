import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ffdb79',
      main: '#FFD256',
      dark: '#e8bd47',
      contrastText: '#222630',
    },
    secondary: {
      light: '#f7f7f7',
      main: '#efefef',
      dark: '#ededed',
      contrastText: '#1d1d1d',
    },
    inactive: {
      light: 'rgb(222, 221, 226)',
      main: 'rgb(222, 221, 226)',
      dark: 'rgb(222, 221, 226)',
    },
    background: {
      main: '#fff',
      dark: '#fafafa',
      darker: '#efefef',
    },
    gray: {
      light: '#f7f7f7',
      main: '#efefef',
      dark: '#ededed',
      darker: '#b4b7bd',
      darkest: '#99a2ae',
    },
    error: {
      main25: 'rgba(206, 12, 11, 0.25)',
      main: '#ce0c0b',
    },
    success: {
      main: '#4caf50',
    }
  }
})

export default theme.palette;
