import app from './app/reducer';
import metrics from './metrics/reducer';
import adminCustom from './adminCustom/reducer';
import reports from './reports/reducer';

export default {
  app,
  metrics,
  adminCustom,
  reports,
}
