import ResourceIcon from '@material-ui/icons/Label';
import ShowCodeIcon from '@material-ui/icons/Apps';
import LoadIcon from '@material-ui/icons/CloudDownload';
import request from '../../../../network/request';
import GuidField from '../../../../components/GuidField';
import ProductStatusField from './ProductStatusField';
import ProductTnVedField from './ProductTnVedField';
import getResourceUrl from '../../../../utils/admin/getResourceUrl';

const name = 'marking/product';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/bp/product/products/',
  },
  itemToString: x => `${x.gtin} - ${x.name}` || `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        validation: {
          required: true,
        }
      },
      gtin: {
        type: 'string',
        validation: {
          required: true,
        }
      },
      data: {
        type: 'boolean',
        readOnly: true,
        component: ProductStatusField,
        options: {
          translatePrefix: `resources.${name}.statusMessages.`
        }
      },
    }
  },
  allowList: true,
  allowCreate: true,
  allowEdit: false,
  allowShow: true,
  allowDelete: false,
  showInMenu: true,
  itemActions: [
    {
      name: `resources.${name}.actions.showCodes.name`,
      Icon: ShowCodeIcon,
      getUrl: ({ record }) => record && getResourceUrl({
        resourceName: 'marking/marking-codes',
        pageType: 'LIST',
        params: {
          filter: {
            gtin: record.gtin
          }
        }
      })
    }
  ],
  listActions: [{
    name: `resources.${name}.actions.loadProducts.name`,
    Icon: LoadIcon,
    handler: async () => {
      const path = `/bp/product/search/`;

      let response = null;
      try {
        response = await request({
          path,
          method: 'POST',
        });
      } catch (e) { }

      if (response && response.ok) {
        return {
          notification: {
            type: 'success',
            message: `resources.${name}.actions.loadProducts.successMessage`
          }
        }
      }

      return {
        notification: {
          type: 'error',
          message: `resources.${name}.actions.loadProducts.failureMessage`
        }
      }
    }
  }],
  createFields: [
    'gtin',
    'name',
    'tn_ved_code_10',
    'vendor_code',
  ],
  showFields: [
    'name',
    'gtin',
    'vendor_code',
    'data.brand',
    'data.fullName',
    'tn_ved_code',
    'tn_ved_code_10',
    'data.inn',
    'data',
  ],
  listFields: [
    { name: 'id', component: GuidField },
    'gtin',
    'name',
    { name: 'tn_ved_code', component: ProductTnVedField },
    'vendor_code',
    'data',
  ],
  listFilters: [
    'gtin',
    'gtin__contains',
    'gtin__in',
    { name: 'gtin__isnull', type: 'boolean' },

    'name',
    'name__contains',
    'name__in',
    { name: 'name__isnull', type: 'boolean' },

    'tn_ved_code',
    'tn_ved_code__contains',
    'tn_ved_code__in',
    { name: 'tn_ved_code__isnull', type: 'boolean' },

    'tn_ved_code_10',
    'tn_ved_code_10__contains',
    'tn_ved_code_10__in',
    { name: 'tn_ved_code_10__isnull', type: 'boolean' },

    'vendor_code',
    'vendor_code__contains',
    'vendor_code__in',
    { name: 'vendor_code__isnull', type: 'boolean' },
  ],
};
