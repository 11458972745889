import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        PDF: '.pdf',
        HTML: '.html',
        XLSX: '.xlsx',
        CSV: '.csv',
        XML: '.xml',
        JSON: '.json',
        TXT: '.txt',
      },
    }
  }
}
