import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Лог |||| Логи',
      fields: {
        level: 'Тип',
        task_id: 'Задача',
        request_id: 'Номер Запроса',
        datetime: 'Дата',
        message: 'Сообщение',
        shortMessage: 'Сообщение',
        args: 'Аргументы',
      }
    }
  }
}