import Icon from '@material-ui/icons/History';
import getResourceUrl from "../admin/getResourceUrl";

export default ({ order = 30, name = 'documents.actions.showHistory' } = {}) => ({
  name,
  order,
  Icon,
  getUrl: ({ record }) => record && getResourceUrl({
    resourceName: 'marking/document-history',
    pageType: 'LIST',
    params: {
      filter: {
        document_id: record.id,
      }
    }
  })
})
