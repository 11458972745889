import React from 'react';
import { useInput } from 'ra-core';

const DynamicPropsInput = (props) => {
  const {
    getProps,
    Component,
    nextProps,
    ...restProps
  } = props;
  
  const {
    input: { value },
  } = useInput({});

  const dynamicProps = getProps(value);

  return (
    <Component
      {...nextProps}
      {...restProps}
      {...dynamicProps}
    />
  );
}

export default DynamicPropsInput;
