import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS } from 'react-admin';
import checkUserCompanyAndStock from '../utils/checkUserCompanyAndStock';
import request from './request';
import auth from '../auth';
import authPermissionResolve from './authPermissionResolve';

const onLogout = () => {
    // localStorage.removeItem('accesstoken');
    auth.logout();
}

export const authProviderHandle = async (type, params = null) =>  {
    return new Promise(async(resolve, reject) => {
        // console.log(type, params);
        if (type === AUTH_LOGIN) {
            const { username, password } = params;
            try {
                const response = await request({
                    apiService: 'auth',
                    path: '/passport/token/',
                    method: 'POST',
                    contentType: 'form',
                    body: {
                        grant_type: 'password',
                        client_id: 'public_client',
                        client_secret: 'public_client_secret',
                        username,
                        password,
                    }
                });

                // TODO: make with refresh token
                if (response.ok && response.data.access_token) {
                    localStorage.setItem('accesstoken', response.data.access_token);

                    // Set default company
                    await checkUserCompanyAndStock();

                    resolve();
                } else {
                    reject()
                }
            } catch(e) {
                reject(e);
            }
        } else if (type === AUTH_LOGOUT) {
            // onLogout();
            await auth.logout();
            resolve();
        } else if (type === AUTH_ERROR) {
            if (params.status === 401) {
                onLogout();
                reject();
            } else {
                resolve();
            }
        } else if (type === AUTH_GET_PERMISSIONS) {
            const permissions = authPermissionResolve(params);
            resolve(permissions);
            return;
        } else if (type === AUTH_CHECK) {
            const isAuthorized = await auth.isAutorized();
            if (isAuthorized) {
                resolve({});
                return true;
            }
            reject();
        } else {
            reject('Unkown method');
        }
    });
};

export default {
    login: (params) => authProviderHandle(AUTH_LOGIN, params),
    logout: (params) => authProviderHandle(AUTH_LOGOUT, params),
    checkAuth: (params) => authProviderHandle(AUTH_CHECK, params),
    checkError: (params) => authProviderHandle(AUTH_ERROR, params),
    getPermissions: (params) => authProviderHandle(AUTH_GET_PERMISSIONS, params),
};