import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      light: '#ffdb79',
      main: '#FFD256',
      dark: '#e8bd47',
      contrastText: '#222630',
    },
  }
})

export default theme.palette;
