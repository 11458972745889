import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Отгрузка |||| Отгрузка',
      actions: {
        accept: 'Приемка',
      },
      fields: {
        'data.document_num': 'Номер первичного документа',
        'data.document_date': 'Дата первичного документа',
        'data.receiver_inn': 'ИНН Получателя',
        'data.receiver': 'Наименование Получателя',
        'data.sender_inn': 'ИНН Отправителя',
        'data.sender': 'Наименование Отправителя',
        'data.owner_inn': 'ИНН Владельца',
        'data.owner': 'Наименование Владельца',
        'data.turnover_type': 'Вид товарооборота',
        'data.transfer_date': 'Дата отгрузки товара',
      }
    }
  }
}
