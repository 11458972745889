import ResourceIcon from '@material-ui/icons/Store';
import appCore from '../../../../appCore';
import GuidField from '../../../../components/GuidField';

const name = 'settings/stock';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/cm/stock/stock/',
    pathList: '/cm/stock/by_company/'
  },
  itemToString: x => x.name || `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        validation: {
          required: true,
        }
      },
    }
  },
  allowList: true,
  allowCreate: true,
  allowEdit: true,
  allowShow: false,
  allowDelete: true,
  showInMenu: true,
  group: 'settings',
  createFields: [
    'slug',
    'name',
  ],
  editFields: [
    'id',
    'slug',
    'name',
  ],
  showFields: [
    'id',
    'slug',
    'name',
  ],
  listFields: [
    {name: 'id', component: GuidField },
    'slug',
    'name',
  ],
  listFilters: [
    'name',
  ],
  createGetOptions: (props) => {
    let company = appCore.currentUrlParams.companyId;
    let error = null;
    if (!company) {
      error = 'Select company';
    } else {
      company = parseInt(company)
    }

    return {
      initialValues: {
        company,
      },
      error,
    }
  },
};