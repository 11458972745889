import textCaseHelper from "../../../../utils/textCaseHelper";

export default {
  name: 'marking/enums/labelTemplateType',
  externalTextCase: textCaseHelper.SNAKE_CASE,
  enums: [
    'JRXML',
    'HTML',
    'XML',
    'TEXT',
  ],
};
