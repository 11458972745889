import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import {
  translate,
  SimpleForm,
  Toolbar,
  SaveButton,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
  FileInput,
  FileField,
  TextInput,
  BooleanInput,
} from 'react-admin';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import compose from 'recompose/compose';
import qs from 'qs';
import { withRouter } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import resourceConfig from './config';
import request from '../../../../network/request';
import { pathToDictionary, generateGUID } from '../../../../utils';
import Snackbar from '../../../../components/Snackbar';
import Button from '../../../../components/Button';

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  toolbar: { background: 'none' },
  loader: {
    marginLeft: 15
  }
};

const FormToolbar = translate(({ translate, sent, onReset, ...props }) => (
  <Toolbar
    style={styles.toolbar}
    {...props}
  >
    <SaveButton
      label={translate(`resources.${resourceConfig.name}.executeAction`)}
      redirect={false}
      submitOnEnter={true}
      disabled={sent}
    />
    {sent && (
      <Button
        // size={'small'}
        color="default"
        onClick={onReset}
      >
        {translate(`resources.${resourceConfig.name}.resetAction`)}
      </Button>
    )}
  </Toolbar>
))

class AppModule extends Component {
  defaultParams = {
    document_type: '',
    file: null,
    auto_execute: false,
  }

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      sent: false,
      items: [],
      key: generateGUID(), // Hack for reset form
      params: {
        ...this.defaultParams
      },
    };
  }

  componentDidMount = () => {
    this.checkLocation();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.checkLocation();
    }
  }

  checkLocation = () => {
    if (this.props.location.search.length < 2) {
      this.resetState();
      return;
    }
    const params = qs.parse(this.props.location.search.slice(1));
    if (!(resourceConfig.urlParamName in params)) {
      this.resetState();
      return;
    }
    if (this.state.show) {
      return;
    }
    const data = pathToDictionary(params[resourceConfig.urlParamName]);

    this.setState({
      show: true,
      sent: false,
      items: [],
      params: {
        ...this.defaultParams,
        ...data
      }
    });
  }

  resetState = () => {
    this.setState({
      show: false,
    });
    setTimeout(this.afterClose, 500);
  }

  afterClose = () => {
    this.setState({
      items: [],
      sent: false,
      key: generateGUID(),
      params: {
        ...this.defaultParams
      }
    })
  }

  resetForm = () => {
    this.setState((state) => ({
      key: generateGUID(),
      sent: false,
      items: [],
      params: {
        ...state.params,
        file: null,
      }
    }))
  }

  execute = async (formData) => {
    const items = [];

    if (Array.isArray(formData.file) && formData.file.length > 0) {
      formData.file.forEach((file, index) => {
        items.push({
          id: generateGUID(),
          file,
          executing: true,
          success: false,
          error: null,
        })
      })
    } if (formData.file && formData.file.rawFile) {
      items.push({
        id: generateGUID(),
        file: formData.file,
        executing: true,
        success: false,
        error: null,
      })
    }

    window.items = items;


    this.setState({
      items,
    });

    let successCount = 0;

    const loop = async (index = 0) => {
      const item = items[index];

      let {
        document_type,
        file,
        auto_execute,
        ...data
      } = formData;

      let response = null;

      try {
        var fetchBody = new FormData();

        fetchBody.append('file', item.file.rawFile);
        for (var name in data) {
          fetchBody.append(name, data[name]);
        }

        const requestConfig = {
          path: `/bp/processes/${document_type}/load_file/`,
          body: fetchBody,
          method: 'POST',
          urlParams: {},
        };

        if (auto_execute) {
          requestConfig.urlParams.auto_execute = true;
        }

        response = await request(requestConfig)
      } catch (e) {
        console.error(e);
      }
      let error = null;

      if (response !== null && response.ok) {
        successCount += 1;
      } else if (response !== null && response.parsedError) {
        error = response.parsedError
      } else {
        error = 'Unknown error';
      }

      this.setState(state => ({
        items: state.items.map(x => {
          if (x.id === item.id) {
            x.executing = false;
            x.error = error;
            x.success = error === null;
          }
          return x;
        })
      }))

      if (index + 1 < items.length) {
        await loop(index + 1);
      }
    }

    await loop(0);

    this.props.refreshView();
    if (successCount === items.length) {
      this.props.showNotification(`resources.${resourceConfig.name}.successMessage`, 'success');
      this.props.history.goBack();
    } else {
      this.setState({
        sent: true,
      })
    }
  }

  handleCloseClick = () => {
    this.props.history.goBack();
  }

  render() {
    const {
      translate
    } = this.props;

    const {
      items,
      sent,
      key,
    } = this.state;

    let executing = items.filter(x => x.executing).length > 0;

    return (
      <Drawer
        anchor={'right'}
        open={this.state.show}
        onClose={this.handleCloseClick}
        ModalProps={{
          disablePortal: true,
        }}
      >
        <SimpleForm
          key={key}
          defaultValue={this.state.params}
          save={this.execute}
          toolbar={<FormToolbar sent={sent} onReset={this.resetForm} />}
          saving={executing}
          style={{ width: 600, maxWidth: '100%' }}
        >
          <Typography
            variant={'title'}
          >
            {translate(`resources.${resourceConfig.name}.title`)}
          </Typography>

          <TextInput
            source="document_type"
            fullWidth
            disabled
            label={`resources.${resourceConfig.name}.fields.document_type`}
          />

          <FileInput
            source="file"
            multiple
            label={`resources.${resourceConfig.name}.fields.file`}
            disabled={executing}
          >
            <FileField source="src" title="title" />
          </FileInput>


          <List>
            {items.map((item, index) => (
              <ListItem divider={index < items.length - 1}>
                <ListItemIcon>
                  {item.executing && (
                    <CircularProgress size={25} />
                  )}
                  {item.success && (
                    <SuccessIcon style={{ fontSize: 25, color: '#4caf50' }} />
                  )}
                  {item.error && (
                    <ErrorIcon style={{ fontSize: 25 }} color="error" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={item.file.title}
                  secondary={item.error && (
                    <Snackbar
                      style={{ maxWidth: '100%', marginTop: 5 }}
                      textStyle={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}
                      showIcon={false}
                      variant="error"
                      message={JSON.stringify(item.error, null, 2)}
                      fullWidth
                    />
                  )}
                />
              </ListItem>
            ))}
          </List>

          <BooleanInput
            fullWidth
            source={'auto_execute'}
          />

        </SimpleForm>
      </Drawer>
    )
  }
}

export default compose(
  withRouter,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
  translate,
  // withStyles(styles)
)(AppModule);
