import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        KM_SPOILED: 'Spoiled',
        KM_LOST: 'Lost',
        KM_DESTROYED: 'Destroyed',
      },
    }
  }
}
