import ResourceIcon from '@material-ui/icons/List';
import GuidField from '../../../../components/GuidField';
import JsonField from '../../../../components/JsonField';

const name = 'marking/document-history';
const apiBasePath = '/bp/document/document-change-history/';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: apiBasePath,
    disableSorting: true,
  },
  itemToString: x => `#${x.id}`,
  schema: {
    type: 'object',
    properties: {

    }
  },
  allowList: true,
  allowCreate: false,
  allowEdit: false,
  allowShow: false,
  allowDelete: false,
  showInMenu: false,
  group: 'reports',
  listExpandFields: [
    { name: 'new_data', component: JsonField, options: { fullWidth: true }},
    { name: 'previous_data', component: JsonField, options: { fullWidth: true }},
  ],
  listFilters: [
    { name: 'document_id', options: { alwaysOn: true}, readOnly: true, },
  ],
  listDefaultSort: { field: 'datetime', order: 'DESC' },
  listFields: [
    { name: 'id', component: GuidField, sortable: false, },
    { name: 'document_id', component: GuidField, sortable: false },
    { name: 'created', type: 'dateTime' },
    { name: 'previous_data' },
    { name: 'new_data' },
  ],
};
