import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Balance',
      actions: {
        update: {
          name: 'Update balance',
          successMessage: 'Update balance request success',
          failureMessage: 'Update balance request failed',
        },
      },
      fields: {
        product_group: 'Product group',
        balance: 'Balance',
        updated: 'Updated date',
      }
    }
  }
}
