import ResourceIcon from '@material-ui/icons/Input';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';
import cancellationReason from '../../../enums/cancellationReason';


const name = 'marking/cancel-codes-child';
const parentResourceName = 'marking/cancel-codes';
const apiBasePath = '/bp/processes/cancel_not_applied/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  schema: {
    type: 'object',
    properties: {
      'data.uit': {
        type: 'string',
        defaultValue: null,
      },
      'data.uitu': {
        type: 'string',
        defaultValue: null,
      },
      'data.cancellation_reason': {
        type: 'string',
        componentInput: cancellationReason.Input,
        componentField: cancellationReason.Field,
        defaultValue: null,
      },
    }
  },
  listFields: [
    'data.uit',
    'data.uitu',
    'data.cancellation_reason',
  ],
});
