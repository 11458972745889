import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Экспорт',
      title: 'Экспорт',
      executeAction: 'Запустить',
      errorMessage: 'Ошибка',
      successMessage: 'Успех',
      loadingData: 'Выгрузка данных...',
      prepareFile: 'Формирование файла...',
      csvWarinig1: 'Данный способ экспорта использует формирование файла в барузере.',
      csvWarinig2: 'Вкладка браузера может зависнуть при использовании формата CSV и большого количества записей.',
      csvWarinig3: 'Рекомендуется уменьшить лимит до 30 000 или использовать формат JSON',
      fields: {
        limit: 'Лимит',
        offset: 'Сдвиг',
        format: 'Формат файла',
        fileName: 'Наименование файла',
      },
      result: {
        title: 'Экспорт завершен',
        total: 'Выгружно записей',
        hasNext: 'В выборке имеются еще записи',
        loadNext: 'Следующая партия',
      },
    }
  }
}
