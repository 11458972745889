import React from 'react';
import deepmerge from 'deepmerge';
import compose from 'recompose/compose';
import {
  translate,
  AutocompleteInput,
  SelectArrayInput,
  SelectInput,
} from '../../components/Admin';
import ChipList from '../../components/ChipList';
import searchInObject from '../searchInObject';
import textCaseHelper from '../textCaseHelper';
import { Typography } from '@material-ui/core';

// type EnumResource = {
//   name: string,
//   enums: string[],
//   Field: any,
//   ArrayField: any,
//   Input: any,
//   ArrayInput: any,
// }

// export default (resourceConfig, options = {}): EnumResource => {
export default (resourceConfig, options = {}) => {
  const data = deepmerge(resourceConfig, options);
  const enumTextCase = data.enumTextCase || textCaseHelper.CONSTANT_CASE;
  const externalTextCase = data.externalTextCase || null;

  let enumsExternal = [];
  if (Array.isArray(data.enums)) {
    if (externalTextCase === null) {
      enumsExternal = [...data.enums];
    } else {
      enumsExternal = data.enums.map(x => textCaseHelper.changeCase(externalTextCase, x));
    }
  }


  if (!data.getTranslateKey) {
    data.getTranslateKey = (value) => `resources.${resourceConfig.name}.enums.${textCaseHelper.changeCase(enumTextCase, value)}`;
  }

  const ResourceField = data.Field || compose(translate)(({ translate, source, record = {}, emptyText, ...otherProps }) => {
    const value = searchInObject(record, source)
    let text = value;
    if (typeof value === 'string') {
      text = textCaseHelper.changeCase(enumTextCase, value);
    }
    if (text) {
      text = translate(data.getTranslateKey(text), { smart_count: 1 });
    } else if (emptyText) {
      text = emptyText;
    }

    if (data.FieldTextComponent) {
      return (
        <data.FieldTextComponent
          text={text}
          value={value}
          record={record}
          source={source}
        />
      )
    }

    return (
      <Typography component="span" variant="body2">
        {text}
      </Typography>
    );
  });

  ResourceField.defaultProps = {
    addLabel: true
  };

  const ResourceArrayField = compose(translate)(({ translate, source, record = {}, ...otherProps }) => {
    let value = searchInObject(record, source)

    // TODO: support enumTextCase
    return (
      <ChipList
        items={value && value.map(x => (translate(data.getTranslateKey(x))))}
      />
    );
  });
  ResourceArrayField.defaultProps = {
    addLabel: true
  };

  const ResourceInput = data.Input || compose(translate)((props) => {
    let {
      translate,
      allowEmpty = true,
      textCase = null,
      availableValues = null,
      showUnavailableValues = true,
      autocomplete = false,
      ...rest
    } = props;

    let choices = data.enums;
    if (availableValues !== null && !showUnavailableValues) {
      if (showUnavailableValues) {
        choices = data.enums.map(x => {
          x.disabled = !availableValues.includes(x);
          return x;
        });
      } else {
      }
      choices = data.enums.filter(x => availableValues.includes(x));
    }
    let SelectComponent = autocomplete ? AutocompleteInput : SelectInput;

    textCase = textCase || externalTextCase;

    return (
    <SelectComponent
      allowEmpty={allowEmpty}
      translateChoice={false}
      {...rest}
      choices={choices.map(x => ({
          id: textCase === null ? x : textCaseHelper.changeCase(textCase, x),
          name: translate(data.getTranslateKey(x)),
          disabled: (availableValues === null || !showUnavailableValues) ? false : !availableValues.includes(x),
      }))}
    />
  )});

  const ResourceArrayInput = compose(translate)((props) => {
    let { translate, textCase = null, ...rest } = props;
    textCase = textCase || externalTextCase;

    return (
      <SelectArrayInput
        allowEmpty
        translateChoice={false}
        {...rest}
        choices={data.enums.map(x => ({
            id: textCase === null ? x : textCaseHelper.changeCase(textCase, x),
            name: translate(data.getTranslateKey(x)),
        }))}
      />
    );
  });

  const result = {
    ...data,
    enumsExternal,
    Field: ResourceField,
    ArrayField: ResourceArrayField,
    Input: ResourceInput,
    ArrayInput: ResourceArrayInput,
  };

  return result;
}
