import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Disaggregation',
      fields: {
        'data.participant_id': 'INN',
      },
    }
  }
}