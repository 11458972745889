import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Уведомление |||| Уведомления',
      actions: {
        sendTest: {
          name: 'Отправить тестовое уведомление',
          successMessage: 'Запрос успешно отправлен',
          failureMessage: 'Запрос обработался с ошибкой',
        }
      },
      fields: {
        message_types: 'Типы сообщений',
        all_types: 'Все типы',
      }
    }
  }
}
