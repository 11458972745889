import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Задача |||| Задачи',
      actions: {
        showLogs: 'Показать логи'
      },
      fields: {
        request: 'Данные запроса',
        response: 'Данные ответа',
        document_process: 'Тип документа',
        document_id: 'ID документа',
        status: 'Статус',
      }
    }
  }
}