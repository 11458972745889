import * as React from "react";
import { Card, Box, Typography, Tooltip, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { fillUrl } from "../../utils/url";
import { useTranslate } from "ra-core";


const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 52,
    flex: "1",
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  main: {
    overflow: "inherit",
    padding: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .icon": {
      color: theme.palette.text.secondary,
    },
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
}));

// Если меняется, надо менять и перевод widgets.productGroups.balanceLastUpdateWarning
const balanceUpdateWarningDiff = 1000 * 60 * 60 * 24 * 5; // 5 days

const BalanceView = (props) => {
  const { info, loading } = props;
  const t = useTranslate();
  let balanceMessage = null;
  let infoMessage;
  let hasWarning;
  if (info) {
    try {
      balanceMessage = new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
      }).format(info.balance);
    } catch {
      balanceMessage = `${info.balance}₽`;
    }
    if (info.updated) {
      const date = new Date(info.updated);
      infoMessage = `${t('widgets.productGroups.balanceUpdatedPrefix')}: ${date.toLocaleString()}`;
      if (new Date().getTime() - date.getTime() > balanceUpdateWarningDiff) {
        hasWarning = true;
        infoMessage = (
          <span>
            {t('widgets.productGroups.balanceLastUpdateWarning')}
            <br />
            {infoMessage}
          </span>
        );
      }
    } else {
      hasWarning = true;
    }
  } else if (loading) {
    balanceMessage = <CircularProgress size={13} />
  } else {
    balanceMessage = t('widgets.productGroups.balanceUnknown');
    hasWarning = true;
  }

  return (
    <span
      style={hasWarning ? {
        color: "#e57373",
      } : undefined}
    >
      {infoMessage ? (
        <Tooltip title={infoMessage}>
          <span>{balanceMessage}</span>
        </Tooltip>
      ) : (
        balanceMessage
      )}
    </span>
  );
};

const PGCard = (props) => {
  const { pgInfo, balance, companyActive, loading } = props;
  const t = useTranslate();
  const classes = useStyles(props);
  let to;
  if (companyActive) {
    to = fillUrl(`/c/{{companyId}}/pg/${pgInfo.name}/st/all/s/marking`);
  }
  const WrapComponent = to ? Link : "span";
  const rootClasses = [classes.card];
  if (!companyActive) {
    rootClasses.push(classes.disabled);
  }
  return (
    <Card className={rootClasses.join(" ")}>
      <WrapComponent to={to}>
        <div className={classes.main}>
          <Box width="3em" className="icon">
            <pgInfo.icon fontSize="large" />
          </Box>
          <Box textAlign="right">
            <Typography variant="h6">
              {t(`productGroups.${pgInfo.name}.name`)}
            </Typography>
            <Typography color="textSecondary">
              <BalanceView info={balance} loading={loading} />
            </Typography>
          </Box>
        </div>
      </WrapComponent>
    </Card>
  );
};

export default PGCard;
