import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'OWN_PRODUCTION': 'Собственное производство',
        'CONTRACT_PRODUCTION': 'Производство по договору',
      },
    }
  }
}
