import React, { memo, useMemo } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ChartPieCard from "../../../../components/ChartPieCard";

const chartOptions = {
  xName: "date",
  syncId: "metricsWidgets",
};

const convertToCharts = (data) => {
  const result = [];
  for (let key in data) {
    result.push({
      name: key,
      value: data[key],
    });
  }

  return result;
};

const convertToList = (data, limit = null) => {
  let result = [];
  for (let key in data) {
    result.push({
      name: key,
      value: data[key],
    });
  }

  result = result.sort((a, b) => b.value - a.value);

  if (limit !== null && limit > 0 && result.length > limit) {
    const other = result.splice(limit - 1);
    const otherCount = other.length;
    let otherSum = 0;
    other.forEach((x) => {
      otherSum += x.value;
    });

    result.push({
      name: `Other (${otherCount})`,
      value: otherSum,
    });
  }

  return result;
};

const ReportDataCard = memo((props) => {
  const {
    viewType = "list", // list, topList, pieChart
    title,
    data,
  } = props;

  const prettyData = useMemo(() => {
    if (viewType === "pieChart") {
      return convertToCharts(data);
    }
    const limit = viewType === 'topList' ? 10 : null;
    return convertToList(data, limit)
  }, [data, viewType]);

  if (viewType === "pieChart") {
    return (
      <ChartPieCard
        title={title}
        chartItems={prettyData}
        chartOptions={chartOptions}
      />
    );
  }

  return (
    <Card>
      <CardHeader title={title} />
      <List dense={true}>
        {prettyData.map((item) => (
          <ListItem key={item.name}>
            <ListItemText
              style={{ flexShrink: 0, flexGrow: 1 }}
              primary={item.name}
            />
            <ListItemText
              style={{
                flexShrink: 1,
                flexGrow: 0,
                textAlign: "right",
              }}
              secondary={item.value}
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
});

export default ReportDataCard;
