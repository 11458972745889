import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Позиция - реагрегация |||| Позиции - реагрегаця',
      fields: {
        'data.uit_uitu': 'КИ/КИТУ',
      }
    }
  }
}