import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Код маркировки |||| Коды маркировки',
      actions: {
        refreshAll: {
          name: 'Обновить коды',
          successMessage: 'Запрос на обновление кодов успешно отправлен',
          failureMessage: 'При запросе на обновление кодов произошла ошибка',
        },
        copyCIS: {
          name: 'Скопировать КИ/КИТУ',
          successMessage: 'КИ/КИТУ успешно скопирован в буфен обмена',
        },
        copyFullCode: {
          name: 'Скопировать КМ',
          successMessage: 'КМ успешно скопирован в буфен обмена',
        },
        refresh: {
          name: 'Обновить',
          successMessage: 'Запрос на обновление успешно отправлен',
          failureMessage: 'При запросе на обновление произошла ошибка',
        },
        bulkUpdate: {
          name: 'Обновить',
          successMessage: 'Запрос на обновление успешно отправлен',
          failureMessage: 'При запросе на обновление произошла ошибка',
        },
        bulkCreateDoc: {
          name: 'Создать документ',
        },
        showProduct: {
          name: 'Карточка товара'
        },
      },
      tabs: {
        info: 'Информация',
        documents: 'Документы',
        history: 'История изменений',
        children: 'Коды в упаковке',
      },
      fields: {
        'gtin': 'GTIN',
        'serial': 'Серийный номер',
        'status': 'Статус',
        'external_status': 'Внешний статус',
        'order_id': 'ID Внешнего заказа',
        'cis': 'КИ/КИТУ',
        'emission_date': 'Дата эмиссии',
        'emission_type': 'Тип эмиссии',
        'last_es_update': 'Дата последнего обновления',
        'code': 'Полный код маркировки',
        'itemprinterstatus.status': 'Принт статус',
        'stock': 'Склад',
        'package_type': 'Тип упаковки',
        'parent': 'Агрегат',
        'additional_status': 'Доп. статус',
        'printer_item.status': 'Статус для печати',

        'printer_status': 'Статус печати',
        'is_package': 'Упаковка',
        'is_archived': 'В архиве',

        'parent__isnull': 'Не в упаковке',
        'documents': 'По документу',
        'q': 'MHQL',
        'presets': 'Пресеты',
      }
    }
  }
}
