import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        '1': 'Обувные товары',
        '3': 'Табачная продукция (блоки)',
        '4': 'Табачная продукция (пачки)',
        '5': 'Лекарственные препараты для медицинского применения',
        '20': 'Молочная продукция',
        '7': 'Шины и покрышки пневматические резиновые новые',
        '8': 'Фотокамеры (кроме кинокамер), фотовспышки и лампы-вспышки',
        '9': 'Духи и туалетная вода',
        '10': 'Предметы одежды, белье постельное, столовое, туалетное и кухонное',
        '11': 'Велосипеды и велосипедные рамы',
        '12': 'Кресла коляски',
        '14': 'Альтернативная табачная продукция (GS1)',
        '15': 'Альтернативная табачная продукция (non GS1)',
        '16': 'Упакованная вода',
        '23': 'Бады',
      },
    }
  }
}
