import Icon from '@material-ui/icons/SettingsBackupRestore';
import request from '../../network/request';

export default ({ apiBasePath, order = 10, name = 'documents.actions.bulkRenew.name' }) => ({
  name,
  order,
  Icon,
  handler: async (options) => {
    const {
      selectedIds = [],
    } = options;

    if (selectedIds.length === 0) {
      return;
    }

    if (!window.confirm(`Подтвердите сброс документов (${selectedIds.length} шт.)`)) {
      return;
    }

    let successCount = 0;

    const promisses = selectedIds.map(async (id) => {
      const path = `${apiBasePath}${id}/renew/?force=true`;
      try {
        await request({
          path,
          method: 'POST',
        });
        successCount += 1;
      } catch(e) {}
    })

    await Promise.all(promisses);

    if (successCount === selectedIds.length) {
      return {
        notification: {
          type: 'success',
          message: 'documents.actions.bulkRenew.successMessage'
        }
      }
    }

    return {
      notification: {
        type: 'error',
        message: 'documents.actions.bulkRenew.failureMessage'
      }
    }

  }
})
