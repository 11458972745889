import React, { memo } from 'react';
import {
  Pagination as RAPagination,
  useListContext,
  useTranslate,
} from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import { Button } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import PaginationFunctionsMenu from './PaginationFunctionsMenu';


const PaginationActions = memo((props) => {
  let listProps = {};
  if (props.backIconButtonProps && props.backIconButtonProps.actionComponentProps) {
    // Использоваение backIconButtonProps это костыль, так как по другому было не прокинуть пропсы
    listProps = props.backIconButtonProps.actionComponentProps;
  }
  const { page, perPage, total, setPage, resource, filterValues } = useListContext();
  const translate = useTranslate();
  const totalPages = Math.ceil(total / perPage) || 1;

  const additionalParams = {};
  if (listProps.target === 'parent_id' && listProps.record) {
    // TODO: не очень хорошая реализация. Лучше бы переделать
    additionalParams.parent_id = listProps.record.id;
  }

  return (
    <Toolbar>
      <PaginationFunctionsMenu
        resource={resource}
        params={{
          filter: {
            ...filterValues,
            ...additionalParams,
          }
        }}
      />
      <Button
        color="default"
        key="prev"
        onClick={() => setPage(page - 1)}
        disabled={page <= 1}
      >
        <ChevronLeft />
        {translate('ra.navigation.prev')}
      </Button>
      <Button
        color="default"
        key="next"
        onClick={() => setPage(page + 1)}
        disabled={page >= totalPages}
      >
        {translate('ra.navigation.next')}
        <ChevronRight />
      </Button>
    </Toolbar>
  );
});


const Pagination = memo((props) => (
  <RAPagination
    rowsPerPageOptions={[10, 25, 50]}
    ActionsComponent={PaginationActions}
    backIconButtonProps={{
      // Это костыль, так как по другому пропсы в paginationActions не прокинуть
      actionComponentProps: {
        ...props,
      }
    }}
    nextIconButtonProps={{
      color: 'default'
    }}
    color="default"
    {...props}
  />
));

export default Pagination;
