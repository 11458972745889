import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Приемка',
      title: 'Приемка отгрузки',
      executeAction: 'Отправить',
      successMessage: 'Успех',
      fields: {
        acceptance_type: 'Тип приемки',
        acceptance_date: 'Дата приемки',
        products: 'Товары',
        uit_code: 'Код товара',
        uitu_code: 'Код транспортной упаковки',
        accepted: 'Товар принят',
        auto_execute: 'Отправить документ',
      },
    }
  }
}
