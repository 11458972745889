import SettingsIcon from '@material-ui/icons/Album';
import locales from './locales';
import uiConfigurationPage from './pages/uiConfigurationPage';

import company from './resources/company';
import user from './resources/user';
import logs from './resources/logs';
import exportDialog from './modules/exportDialog';

const resources = [
  company,
  user,
  logs,
];

const modules = [
  exportDialog,
]

export default {
  name: 'common',
  locales,
  resources: [
    ...resources,
    ...modules,
  ],
  pages: [
    uiConfigurationPage,
  ],
  icon: SettingsIcon,
};
