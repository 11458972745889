import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Документ |||| Все документы',
      fields: {
        extra_name: 'Название',
      },
    }
  }
}
