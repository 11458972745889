import GuidField from '../../../../../components/GuidField';
import markingStatus from '../../../enums/markingStatus';
import stock from '../../../../company-service/resources/stock';
import markingExtraStatus from '../../../enums/markingExtraStatus';

const name = 'marking/marking-code-history';
const apiBasePath = '/bp/code/code-history/';
export default {
  name,
  apiResolve: {
    path: apiBasePath,
  },
  itemToString: x => `#${x.id}`,
  isResource: true,
  listFields: [
    { name: 'created', type: 'dateTime' },
    { name: 'status', component: markingStatus.Field },
    { name: 'additional_status', component: markingExtraStatus.Field },
    { name: 'stock_id', component: stock.Field, options: { link: false } },
    // 'parent_cis',
    'parent',
    { name: 'task_id', component: GuidField },
  ],
};