import React, { PureComponent } from 'react';
import {
  Show,
  SimpleShowLayout,
  TabbedShowLayout,
  TopToolbar,
} from 'react-admin';
import ResourceActionButton from '../ResourceActionButton';
import checkPermissions from '../../utils/checkPermissions';
import NotFoundPage from '../../pages/NotFoundPage';

const ShowActions = ({ actions, data }) => (
  <TopToolbar>
    {actions && actions.map(action => (
      <ResourceActionButton key={action.name} action={action} record={data} />
    ))}
  </TopToolbar>
);

class ResourceShowPage extends PureComponent {
  render() {
    const {
      children,
      withTabs = false,
      actions = null,
      permissions,
      ...restProps
    } = this.props;

    const ShowComponent = withTabs ? TabbedShowLayout : SimpleShowLayout;


    if (permissions === undefined) {
      return null; // LOADING
    }

    if (!checkPermissions('viewOne', permissions)) {
      return (
        <NotFoundPage />
      )
    }

    return (
      <Show
        {...restProps}
        actions={<ShowActions actions={actions} />}
      >
        <ShowComponent
          variant="standard"
          margin="normal"
        >
          {children}
        </ShowComponent>
      </Show>
    );
  }
}

export default ResourceShowPage;