import React, { Component } from "react";
import { WithPermissions as WithPermissionsRA } from '../Admin'

class WithPermissions extends Component {
  shouldComponentUpdate(next) {
    const prev = this.props;
    if (prev.match.url !== next.match.url) {
      return true;
    }
    return false;
  }
  render () {
    return (
      <WithPermissionsRA
        {...this.props}
      />
    )
  }
}

export default WithPermissions;
