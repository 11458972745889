import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Item - dropout report |||| Items - dropout report',
      fields: {
        'data.code': 'Full marking code',
      }
    }
  }
}
