import React from 'react';
import { translate } from 'react-admin';
import getValue from '../../../../../utils/getValue';

const ConnectionStatusField = (props) => {
  const {
    source,
    record,
    translatePrefix = '',
    translate,
  } = props;

  const value = getValue(record, source) || '';
  const color = value ? '#26a69a' : '#ef5350';
  const text = value ? 'connected' : 'disconnected';
  return (
    <span style={{ color }}>
      {translate(`${translatePrefix}${text}`)}
    </span>
  );
}

ConnectionStatusField.defaultProps = {
  addLabel: true
};

export default translate(ConnectionStatusField);
