import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'История изменений',
      fields: {
        document_id: 'ID документа',
        created: 'Дата',
        new_data: 'Новые данные',
        previous_data: 'Старые данные',
      }
    }
  }
}
