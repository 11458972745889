import * as actions from './actions';

let theme = 'light';
const localTheme = localStorage.getItem(actions.THEME_STORAGE_KEY);
if (localTheme) {
    theme = localTheme;
}
const initialState = {
  theme,
};

const handlers = {};

handlers[actions.SET_THEME] = (state, { payload }) => ({
  ...state,
  theme: payload,
});

export default (state = initialState, { type, ...payload }) => {
  if (!(type in handlers)) {
    return state;
  }

  return handlers[type](state, payload);
};
