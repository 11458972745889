import textCaseHelper from "../../../../utils/textCaseHelper";

export default {
  name: 'marking/enums/emissionType',
  externalTextCase: textCaseHelper.AS_IS,
  enums: [
    'FOREIGN',
    'LOCAL',
    'REMAINS',
    'CROSSBORDER',
    'REMARK',
  ],
};
