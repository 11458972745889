import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Emission order |||| Emission orders',
      actions: {
        getReplyCSV: 'Download codes in CSV',
      },
      tabs: {
        status: 'Order status',
        info: 'Order info',
        products: 'Products',
      },
      fields: {
        'status': 'Status',
        'created': 'Created date',
        'extra.name': 'Name',
        'external_id': 'External ID',

        'data.contact_person': 'Contact person',
        'data.release_method_type': 'Release method type',
        'data.remains_available': 'Remains available',
        'data.remains_import': 'Remains import',
        'data.create_method_type': 'Create method type',
        'data.production_order_id': 'Production order id',
        'data.contract_number': 'Contract number',
        'data.contract_date': 'Contract date',
        'data.print_status': 'Print status',
        'data.service_provider_id': 'Service provider ID',
        'data.payment_type': 'Payment type',
      },
    }
  }
}
