import ResourceCreatePage from '../../components/ResourceCreatePage';
import getFieldsFromSchema from './getFieldsFromSchema';


export default (options = {}) => {
  let {
    schema = null,
    createFields = null,
    allowCreate = true,
    allowEdit = true,
    allowShow = true,
    allowDelete = true,
    allowList = true,
    createGetOptions,
  } = options;

  if (!allowCreate || schema === null) {
    return null;
  }

  const initialValues = {};
  if (schema && schema.properties) {
    for (let key in schema.properties) {
      if ('defaultValue' in schema.properties[key]) {
        initialValues[key] = schema.properties[key].defaultValue;
      }
    }
  }

  let withTabs = false;
  if (createFields && createFields.type) {
    if (createFields.type === 'tabbed') {
      withTabs = true;
    }
    createFields = createFields.items;
  }

  return (props) => (
    getFieldsFromSchema({
      schema,
      fields: createFields,
      Component: ResourceCreatePage,
      validate: true,
      editable: true,
      allowCreate,
      allowEdit,
      allowShow,
      allowDelete,
      allowList,
      initialValues,
      withTabs,
      getOptions: createGetOptions,
      ...props
    })
  )
}
