import React from 'react';
import NightIcon from '@material-ui/icons/Brightness4';
import DayIcon from '@material-ui/icons/Brightness7';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { setTheme } from '../../redux/app/actions';
import { Tooltip } from '@material-ui/core';
import { translate } from 'react-admin';

const styles = {
};

const ThemeSelect = (props) => {
  const {
    theme,
    setTheme,
    translate,
  } = props;


  const toggleTheme = (val) => {
    if (theme === 'dark') {
      setTheme('light');
    } else {
      setTheme('dark');
    }
  }

  return (
    <Tooltip title={translate('themeSelect.tooltip')}>
      <IconButton
        onClick={toggleTheme}
        color="inherit"
      >
        {theme === 'light' ? (
          <NightIcon />
        ) : (
          <DayIcon />
        )}
      </IconButton>
    </Tooltip>
  );
}

const mapStateToProps = state => ({
  theme: state.app.theme,
});

export default compose(
  connect(
    mapStateToProps,
    {
      setTheme
    }
  ),
  translate,
  withStyles(styles)
)(ThemeSelect);
