import ResourceIcon from '@material-ui/icons/People';
import GuidField from '../../../../components/GuidField';

const name = 'common/user';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/passport/user/',
    idField: 'sub',
    disableSorting: true,
  },
  itemToString: x => `${x.given_name} ${x.family_name} (${x.email})`,
  schema: {
    type: 'object',
    properties: {
    }
  },
  allowList: true,
  allowCreate: false,
  allowEdit: false,
  allowShow: false,
  allowDelete: false,
  showInMenu: true,
  group: 'settings',
  listFields: [
    { name: 'id', component: GuidField },
    'preferred_username',
    'email',
    'given_name',
    'family_name',
  ],
  listFilters: [],
};