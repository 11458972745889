import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Отмена отгрузки |||| Отмена отгрузки',
      fields: {
        'data.participant_inn': 'ИНН',
        'data.shipment_number': 'Идентификатор отгрузки',
      }
    }
  }
}
