import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Print request |||| Print requests',
      actions: {
        printPDF: 'Print PDF',
        printCSV: 'Print CSV',
        print: 'Print',
      },
    }
  }
}
