import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Reaggregation',
      fields: {
        'data.participant_id': 'INN',
        'data.reaggregation_type': 'Operation',
        'data.uitu': 'SSCC',
      },
    }
  }
}