export const SET_REPORT_PERIOD = 'reports/SET_REPORT_PERIOD';
export const SET_REPORT_VIEW_TYPE = 'reports/SET_REPORT_VIEW_TYPE';

export const setReportPeriod = (payload) => {
  return { type: SET_REPORT_PERIOD, payload }
};

export const setReportViewType = (payload) => {
  return { type: SET_REPORT_VIEW_TYPE, payload }
};
