import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';

import { getI18nProvider } from '../../i18n';
import authProvider from '../../network/authProvider';
import restProvider from '../../network/rest';
import App from '../App';
import history from '../../core/history';
import appCore from '../../appCore';
import store from '../../redux/store';
import routes from '../../routes';
import globalLoader from '../../utils/ui/globalLoader';


window.appCore = appCore;
window.appHistory = history;

class RootContainer extends PureComponent {
  locale = 'ru';
  customRoutes = [];

  constructor(props) {
    super(props);
    const locale = localStorage.getItem('locale');
    if (locale) {
      this.locale = locale;
    }
    this.i18nProvider = getI18nProvider();


    this.customRoutes = [
      ...routes,
    ];
    appCore.routes.forEach(route => {
      this.customRoutes.push(
        <Route {...route} />
      )
    });
  }

  componentDidMount() {
    globalLoader.hide();
  }

  render() {
    return (
      <Provider
        store={store}
      >
        <App
          dataProvider={restProvider}
          authProvider={authProvider}
          history={history}
          resources={appCore.resources}
          i18nProvider={this.i18nProvider}
          locale={this.locale}
          routes={this.customRoutes}
        />
      </Provider>
    );
  }
}

export default RootContainer;