import ResourceIcon from '@material-ui/icons/Input';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';
import packType from '../../../enums/packType';

const name = 'marking/input-fts-child';
const parentResourceName = 'marking/input-fts';
const apiBasePath = '/bp/processes/input_fts/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  schema: {
    type: 'object',
    properties: {
      'data.cis': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        }
      },
      'data.pack_type': {
        type: 'string',
        componentInput: packType.Input,
        componentField: packType.Field,
        defaultValue: 'UNIT',
      },
      'data.color': {
        type: 'string',
        defaultValue: null,
      },
      'data.product_size': {
        type: 'string',
        defaultValue: null,
      },
      // 'data.children': {
      //   type: 'string',
      //   defaultValue: null,
      //   options: {
      //     multiline: true,
      //   }
      // },
    }
  },
  listFields: [
    'data.cis',
    'data.pack_type',
  ],
});
