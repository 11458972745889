import getFieldsFromSchema from './getFieldsFromSchema';
import FormPage from '../../components/FormPage';


export default (options = {}) => {
  const {
    name,
    schema,
    fields,
    pageTitle = name,
    ...restOptions
  } = options;
  return (props) => (
    getFieldsFromSchema({
      schema,
      fields,
      pageTitle,
      Component: FormPage,
      validate: true,
      editable: true,
      ...restOptions
    })
  );
}