import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Shipment |||| Shipment',
      actions: {
        accept: 'Acceptance',
      },
      fields: {
        'meta.status': 'Status',
        'meta.created': 'Created date',
        'meta.direction': 'Direction',
        'meta.extra.name': 'Name',
        'meta.external_id': 'External ID',
        'meta.external_status': 'External status',
        'meta.last_es_update': 'Last update',
      }
    }
  }
}
