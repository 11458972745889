import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Уведомление |||| Уведомления',
      actions: {
        setAsReaded: {
          name: 'Отметить как прочитанное',
          successMessage: 'Запрос успешно отправлен',
          failureMessage: 'Запрос не выполнен',
        },
        bulkSetAsReaded: {
          name: 'Отметить все как прочитанные',
          successMessage: 'Запрос успешно отправлен',
          failureMessage: 'Запрос не выполнен',
        },
      },
      fields: {
        message_type: 'Тип сообщения',
        created_at: 'Дата создания',
        message: 'Сообщение',
        short_message: 'Сообщение',
        is_readed: 'Прочитано',
      },
    }
  }
}