import ResourceIcon from '@material-ui/icons/Input';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';


const name = 'marking/input-remains-child';
const parentResourceName = 'marking/input-remains';
const apiBasePath = '/bp/processes/input_remains/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  schema: {
    type: 'object',
    properties: {
      'data.ki': {
        type: 'string',
        defaultValue: null,
      },
      'data.kitu': {
        type: 'string',
        defaultValue: null,
      },
    }
  },
  listFields: [
    'data.ki',
    'data.kitu',
  ],
});