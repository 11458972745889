import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Custom doc item |||| Custom doc items',
      fields: {
        'data.code': 'UIT/UITU',
      },
    }
  }
}