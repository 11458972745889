import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Aggregation',
      fields: {
        'data.participant_id': 'INN',
        'data.aggregation_units': 'Aggregation units',
        'unit_serial_number': 'SSCC',
        'sntins': 'Items',
        'cis': 'UIT/UITU',
      },
    }
  }
}