import React from 'react';
import { Link as MuiLink } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import appCore from '../../appCore';

export default (props) => {
  const {
    source,
    record,
    url,
  } = props;

  const text = record[source] || '';
  const fullUrl = url.replace(/({{[$\w]+}})/g, (match) => {
    const field = match.replace(/[{}]/g, '');
    if (field in record) {
      return record[field];
    }
    if (field in appCore.currentUrlParams) {
      return appCore.currentUrlParams[field];
    }
    return null;
  });

  return (
    <MuiLink
      to={fullUrl}
      component={RouterLink}
      color="textPrimary"
      underline="always"
    >
      <span>{text}</span>
    </MuiLink>
  );
}