import appConfig from "../appConfig";

export default (name, options = {}) => {
  const {
    path = '',
  } = options;

  let baseUrl = appConfig.data.apiBaseUrl;
  if (appConfig.data.services && name in appConfig.data.services) {
    baseUrl = appConfig.data.services[name].baseUrl;
  }

  return `${baseUrl}${path}`;
}