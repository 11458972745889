import * as actions from './actions';

const createResource = () => ({
  list: {
    params: {
      extended: false,
    },
  }
});

const initialState = {
  resources: {},
};

const handlers = {};

handlers[actions.SET_RESOURCE_LIST_PARAM] = (state, { resource, param, value = null }) => {
  const nextState = { ...state };
  if (!(resource in nextState)) {
    nextState[resource] = createResource();
  }
  nextState[resource].list.params[param] = value;
  return nextState;
};

export default (state = initialState, { type, ...payload }) => {
  if (!(type in handlers)) {
    return state;
  }

  return handlers[type](state, payload);
};
