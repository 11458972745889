import ResourceIcon from '@material-ui/icons/AccountBalance';


const name = 'settings/company';
export default {
  name,
  path: name,
  icon: ResourceIcon,

  menuConfig: {
    name: name,
    text: `pages.${name}.name`,
    icon: ResourceIcon,
    group: 'settings',
  }
};
