import React from 'react';
import {
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from '../../components/Admin';


export default (options = {}) => {
  let {
    name,
  } = options;

  return (props) => (
    <ReferenceArrayField
        reference={name}
        {...props}
    >
        <SingleFieldList>
            <ChipField source={'id'} />
        </SingleFieldList>
    </ReferenceArrayField>
  )
}