import React from 'react';
import {
  ReferenceArrayInput,
  SelectArrayInput,
} from '../../components/Admin';


export default (options = {}) => {
  let {
    name,
  } = options;

  return (props) => (
    <ReferenceArrayInput 
      reference={name} 
      {...props}
    >
      <SelectArrayInput optionText={'id'} />
    </ReferenceArrayInput>
  )
}