import ResourceIcon from '@material-ui/icons/ViewColumn';
import CodeInput from '../../../../components/CodeInput';
import GuidField from '../../../../components/GuidField';
import textCaseHelper from '../../../../utils/textCaseHelper';
import labelTemplateFileType from '../../enums/labelTemplateFileType';
import labelTemplateType from '../../enums/labelTemplateType';

const name = 'settings/label-templates';

const getFields = ({ showId = false } = {}) => ({
  type: 'tabbed',
  items: [{
    type: 'tab',
    label: `resources.${name}.tabs.info`,
    items: [
      { name: 'id', show: showId },
      { name: 'file_type', readOnly: true },
      'name',
      'slug',
      'description',
      'default',
    ]
  },{
    type: 'tab',
    label: `resources.${name}.tabs.template`,
    items: [
      'type',
      'prerender_datamatrix',
      'template',
    ]
  }]
})

const mappingFileTypeToEnableTypes = {
  'pdf': ['JRXML', 'HTML'],
  'html': ['HTML'],
  'xlsx': ['XML', 'TEXT'],
  'csv': ['TEXT'],
  'xml': ['XML'],
  'json': ['TEXT'],
  'txt': ['TEXT'],
};

const getEnableTypesByFileType = (type) => {
  if (type in mappingFileTypeToEnableTypes) {
    return mappingFileTypeToEnableTypes[type];
  }
  return mappingFileTypeToEnableTypes['*'];
}

const mappingTemplateTypeToCodeLanguage = {
  'jrxml': 'xml',
  'xml': 'xml',
  'html': 'html',
  '*': 'text',
};

const getCodeLanguageByTemplateType = (type) => {
  if (type in mappingTemplateTypeToCodeLanguage) {
    return mappingTemplateTypeToCodeLanguage[type];
  }
  return mappingTemplateTypeToCodeLanguage['*'];
}

export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/printer/template/',
    mapping: {
      outputItem: (item) => {
        if (item.id || item.type) {
          // Если это редактирование или тип шаблона установлен в ручную
          return item;
        }
        if (item.file_type in mappingFileTypeToEnableTypes) {
          const textCase = labelTemplateType.externalTextCase || textCaseHelper.AS_IS;
          const value = mappingFileTypeToEnableTypes[item.file_type][0];
          item.type = textCaseHelper.changeCase(textCase, value);
        }
        return item;
      }
    }
  },
  itemToString: x => x.name || `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        validation: {
          required: true,
        }
      },
      slug: {
        type: 'string',
        validation: {
          required: true,
        }
      },
      description: {
        type: 'string',
        options: {
          multiline: true,
        }
      },
      default: {
        type: 'boolean',
        defaultValue: false,
      },
      prerender_datamatrix: {
        type: 'boolean',
        defaultValue: false,
      },
      file_type: {
        type: 'string',
        componentInput: labelTemplateFileType.Input,
        componentField: labelTemplateFileType.Field,
        validation: {
          required: true,
        },
      },
      type: {
        type: 'string',
        componentInput: labelTemplateType.Input,
        componentField: labelTemplateType.Field,
        validation: {
          required: true,
        },
        options: {
          dynamicProps: (data) => ({availableValues: getEnableTypesByFileType(data.file_type) })
        },
      },
      template: {
        type: 'string',
        componentInput: CodeInput,
        defaultValue: '<!-- Paste template code here -->',
        validation: {
          required: true,
        },
        options: {
          fullWidth: true,
          fullSize: true,
          dynamicProps: (data) => ({codeLanguage: getCodeLanguageByTemplateType(data.type) })
        },
      },
    }
  },
  allowList: true,
  allowCreate: true,
  allowEdit: true,
  allowShow: false,
  allowDelete: true,
  allowClone: true,
  showInMenu: true,
  group: 'settings',
  createFields: [
    'name',
    'slug',
    'description',
    'file_type',
    'default',
  ],
  editFields: getFields({ showId: true }),
  showFields: getFields({ showId: true }),
  listFields: [
    {name: 'id', component: GuidField },
    'name',
    'slug',
    'file_type',
    'type',
    'default',
  ]
};
