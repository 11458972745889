import ResourceIcon from '@material-ui/icons/List';
import GuidField from '../../../../components/GuidField';
import getResourceUrl from '../../../../utils/admin/getResourceUrl';
import JsonField from '../../../../components/JsonField';
import request from '../../../../network/request';
import { fillUrl } from '../../../../utils/url';


const name = 'marking/task';
const apiBasePath = '/bp/document/document/{{document_id}}/task/';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: apiBasePath,
    disableSorting: true,
  },
  itemToString: x => `#${x.id}`,
  schema: {
    type: 'object',
    properties: {

    }
  },
  allowList: true,
  allowCreate: false,
  allowEdit: false,
  allowShow: false,
  allowDelete: false,
  showInMenu: false,
  group: 'reports',
  listRowActions: [{
    name: `resources.${name}.actions.showLogs`,
    inline: true,
    getUrl: ({ record }) => record && getResourceUrl({
      resourceName: 'marking/logs',
      pageType: 'LIST',
      params: {
        filter: {
          task_id: record.id,
        }
      }
    })
  }, {
    name: `resources.${name}.actions.cancel.name`,
    isDisabled: ({ record }) => !record || record.status !== 'requested',
    handler: async ({ record, translate }) => {
      const path = fillUrl(`${apiBasePath}${record.id}/cancel/`, {
        document_id: record.document_id,
      });

      const confirmed = window.confirm(translate(`resources.${name}.actions.cancel.confirmMessage`));
      if (!confirmed) {
        return {
          notification: {
            type: 'cancel',
            message: `resources.${name}.actions.cancel.requestCanceledMessage`
          }
        };
      }

      let response = null;
      try {
        response = await request({
          path,
          method: 'POST',
        });
      } catch(e) {}

      if (response && response.ok) {
        return {
          notification: {
            type: 'success',
            message: `resources.${name}.actions.cancel.successMessage`,
          }
        }
      }

      return {
        notification: {
          type: 'error',
          message: `resources.${name}.actions.cancel.failureMessage`,
        }
      }
    }
  }],
  listExpandFields: [
    { name: 'finished', type: 'dateTime' },
    { name: 'request', component: JsonField, options: { fullWidth: true }},
    { name: 'response', component: JsonField, options: { fullWidth: true }},
  ],
  listFilters: [
    { name: 'document_id', options: { alwaysOn: true}, readOnly: true, },
  ],
  listDefaultSort: { field: 'datetime', order: 'DESC' },
  listFields: [
    { name: 'id', component: GuidField, sortable: false, },
    { name: 'document_id', component: GuidField, sortable: false },
    { name: 'status', sortable: false },
    { name: 'name' },
    { name: 'created', type: 'dateTime' },
  ],
};
