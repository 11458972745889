export default {
  services: {
    company: {
      name: 'Компания',
      groups: {
        settings: 'Настройки',
        reports: 'Информация',
      }
    }
  }
}
