import React, { useCallback, useMemo } from "react";
import { useState } from "react";
import { Typography, Link as MuiLink } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import { getInitials } from "../../../../../utils/initials";

const useStyles = makeStyles((theme) => ({
  unactive: {
    pointerEvents: "none",
  },
  card: {
    height: 210,
    width: "100%",
  },
  cardUnactive: {
    backgroundColor: "rgba(0,0,0,0.04)",
    boxShadow: "none",
  },
  header: {
    paddingBottom: 0,
  },
  headerInfo: {
    padding: theme.spacing(1),
    textAlign: "right",
  },
  slug: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  testing: {
    marginTop: 6,
    fontSize: '14px',
    lineHeight: '16px',
  },
  avatar: {
    width: 64,
    height: 64,
    fontSize: "24px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  avatarTesting: {
    backgroundColor: theme.palette.grey[300],
  },
  inn: {
    fontSize: 13,
    fontWeight: 400,
    marginBottom: 4,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  name: {
    display: "box",
    maxHeight: 48,
    fontSize: 20,
    lineHeight: "24px",
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
  },
  address: {
    marginTop: 16,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

export const CompanyCard = ({ record }: { record: any }) => {
  let {
    id,
    name,
    is_testing,
    is_active,
    slug,
    info: {
      address,
      inn = "0000000000",
    } = {
      address: undefined,
      inn: undefined,
    },
  } = record;

  const classes = useStyles();
  const [elevation, setElevation] = useState(1);

  const onMouseEnter = useCallback(() => {
    setElevation(3);
  }, [setElevation]);

  const onMouseLeave = useCallback(() => {
    setElevation(1);
  }, [setElevation]);

  const initials = useMemo(() => {
    return getInitials(name, {
      max: 2,
      escapeQuotes: true,
    })
  }, [name]);

  const cardClasses = [classes.card];
  if (!is_active) {
    cardClasses.push(classes.cardUnactive);
  }
  const avatarClasses = [classes.avatar];
  if (is_testing) {
    avatarClasses.push(classes.avatarTesting);
  }

  return (
    <MuiLink
      component={Link}
      to={`/c/${id}/s/company`}
      underline="none"
      onMouseEnter={is_active && onMouseEnter}
      onMouseLeave={is_active && onMouseLeave}
      className={!is_active && classes.unactive}
    >
      <Card
        className={cardClasses.join(" ")}
        elevation={is_active ? elevation : 0}
      >
        <CardHeader
          className={classes.header}
          avatar={
            <Avatar aria-label="recipe" className={avatarClasses.join(" ")}>
              {initials}
            </Avatar>
          }
          action={
            <div className={classes.headerInfo}>
              <Typography variant="caption" className={classes.slug}>{slug}</Typography>
              {is_testing && (
                <Typography
                  variant="button"
                  color="textSecondary"
                  className={classes.testing}
                  component="div"
                >
                  ● TEST
                </Typography>
              )}
            </div>
          }
        />
        <CardContent>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            component="div"
            className={classes.inn}
          >
            ИНН {inn}
          </Typography>
          <Typography variant="h6" component="div" className={classes.name}>
            {name}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            component="div"
            className={classes.address}
          >
            {address}
          </Typography>
        </CardContent>
      </Card>
    </MuiLink>
  );
};
