import React from 'react';
import ResourceIcon from '@material-ui/icons/Notifications';
import GuidField from '../../../../components/GuidField';
import notificationMessageType from '../../enums/notificationMessageType';
import notificationGroupTelegram from './notificationGroupTelegram';
import createAddByParrentButton from '../../../../utils/admin/createAddByParrentButton';
import notificationGroupEmail from './notificationGroupEmail';
import notificationGroupWebhooks from './notificationGroupWebhooks';


const name = 'settings/notification-group';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/notification-service/notification-group/',
    disableSorting: true,
    mapping: {
      outputItemAfter: (output) => {
        if (output && output.message_types) {
          output.message_types = output.message_types.map(x => ({ message_type: x }));
        } else {
          output.message_types = [];
        }
        return output;
      },
      inputItemAfter: (input) => {
        if (input && input.message_types) {
          input.message_types = input.message_types.map(x => x.message_type);
        } else {
          input.message_types = [];
        }
        return input;
      },
    },
  },
  itemToString: x => `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      all_types: {
        type: 'boolean',
        defaultValue: false,
      },
      message_types: {
        type: 'array',
        componentField: notificationMessageType.ArrayField,
        componentInput: notificationMessageType.ArrayInput,
      },
    }
  },
  allowList: true,
  allowCreate: true,
  allowEdit: true,
  allowShow: false,
  allowDelete: true,
  showInMenu: true,
  group: 'settings',
  createFields: [
    'message_types',
    'all_types',
  ],
  editFields: {
    type: 'tabbed',
    items: [{
      type: 'tab',
      label: `resources.${name}.tabs.info`,
      items: [
        'id',
        'message_types',
        'all_types',
      ]
    },{
      type: 'tab',
      label: `resources.${name}.tabs.telegram`,
      path: 'telegram',
      items: [{
        options: {
          fullWidth: true,
        },
        component: (props) => (
          <notificationGroupTelegram.ManyField
            {...props}
            fullWidth
            target="parent_id"
            createButton={createAddByParrentButton({
              resourceName: notificationGroupTelegram.name,
            })}
          />
        )
      }]
    },{
      type: 'tab',
      label: `resources.${name}.tabs.email`,
      path: 'email',
      items: [{
        options: {
          fullWidth: true,
        },
        component: (props) => (
          <notificationGroupEmail.ManyField
            {...props}
            fullWidth
            target="parent_id"
            createButton={createAddByParrentButton({
              resourceName: notificationGroupEmail.name,
            })}
          />
        )
      }]
    },{
      type: 'tab',
      label: `resources.${name}.tabs.webhooks`,
      path: 'webhooks',
      items: [{
        options: {
          fullWidth: true,
        },
        component: (props) => (
          <notificationGroupWebhooks.ManyField
            {...props}
            fullWidth
            target="parent_id"
            createButton={createAddByParrentButton({
              resourceName: notificationGroupWebhooks.name,
            })}
          />
        )
      }]
    }]
  },
  listFields: [
    { name: 'id', component: GuidField },
    'message_types',
    'all_types',
  ],
};
