import ResourceIcon from '@material-ui/icons/Note';
import usageReportChild from './usageReportChild';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import usageType from '../../enums/usageType';
import cisType from '../../enums/cisType';


const name = 'marking/usage-report';
const precessName = 'usage_report';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: ResourceIcon,
  precessName,
  apiBasePath,
  childResource: usageReportChild,
  schema: {
    type: 'object',
    properties: {
      'data.usage_type': {
        type: 'string',
        defaultValue: null,
        componentInput: usageType.Input,
        componentField: usageType.Field,
        validation: {
          required: true,
        },
      },
      'data.capacity': {
        type: 'number',
        defaultValue: null,
        options: {
          forProductGroups: ['milk'],
        },
      },
      'data.used_in_production': {
        type: 'number',
        defaultValue: 0,
      },
      'data.production_date': {
        type: 'date',
        defaultValue: null,
        validation: {
          required: true,
        },
        options: {
          forProductGroups: ['bio'],
        },
      },
      'data.expiration_date': {
        type: 'date',
        defaultValue: null,
        // validation: {
        //   required: true,
        // },
        options: {
          forProductGroups: ['bio', 'water'],
        },
      },
      'data.exp_date': {
        type: 'date',
        defaultValue: null,
        options: {
          forProductGroups: ['milk'],
        },
      },
      'data.exp_datetime': {
        type: 'dateTime',
        defaultValue: null,
        options: {
          forProductGroups: ['milk'],
        },
      },
      'data.cis_type': {
        type: 'string',
        defaultValue: 'UNIT',
        componentInput: cisType.Input,
        componentField: cisType.Field,
        options: {
          forProductGroups: ['milk'],
        },
      },
    }
  },
  createFields: [
    'data.usage_type',
    'data.used_in_production',
    'data.cis_type',
    'data.capacity',
    'data.production_date',
    'data.expiration_date',
    'data.exp_date',
    'data.exp_datetime',
  ],
});
