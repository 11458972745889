import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Acceptance',
      title: 'Accept Shipment',
      executeAction: 'Submit',
      successMessage: 'Success',
      fields: {
        acceptance_type: 'Acceptance type',
        acceptance_date: 'Acceptance date',
        products: 'Products',
        uit_code: 'Product code',
        uitu_code: 'Transport packaging code',
        accepted: 'Accepted',
        auto_execute: 'Send document',
      },
    }
  }
}
