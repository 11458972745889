
import React from 'react';
import compose from 'recompose/compose';
import {
  translate,
  SelectInput,
} from '../../../../components/Admin';
import searchInObject from '../../../../utils/searchInObject';
import appCore from '../../../../appCore';
import textCaseHelper from '../../../../utils/textCaseHelper';


const getPGTranslateKey = (pg) => `productGroups.${pg}.name`;
const getItemText = (item, translate) => translate(getPGTranslateKey(item.name), item.name);

const ResourceField = compose(translate)(({ translate, source, record = {}, emptyText, ...otherProps }) => {
  let value = searchInObject(record, source)
  const pg = appCore.productGroups.find(x => x.name === value) || null;
  if (pg) {
    value = getItemText(pg, translate);
  } else if (!value && emptyText) {
    value = emptyText;
  }
  return (
    <span>
      {value}
    </span>
  );
});

ResourceField.defaultProps = {
  addLabel: true
};

const ResourceInput = compose(translate)((props) => {
  let { translate, allowEmpty = true, ...rest } = props;

  let choices = appCore.productGroups.map(x => ({ id: x.name, name: getItemText(x, translate) }));

  return (
  <SelectInput
    allowEmpty={allowEmpty}
    translateChoice={false}
    {...rest}
    choices={choices}
  />
)});

const result = {
  name: 'marking/enums/productGroupEnum',
  Field: ResourceField,
  Input: ResourceInput,
  enumTextCase: textCaseHelper.AS_IS,
  externalTextCase: textCaseHelper.AS_IS,
  getTranslateKey: getPGTranslateKey,
};

export default result;
