import ResourceIcon from '@material-ui/icons/Dashboard';
import Dashboard from "./Dashboard";

const name = 'marking/dashboard';
export default {
  name,
  path: name,
  icon: ResourceIcon,
  PageComponent: Dashboard,

  menuConfig: {
    name: name, 
    text: 'dashboard',
    icon: ResourceIcon,
  }
};