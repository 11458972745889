import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
// import { KeycloakProvider } from '@react-keycloak/web'
import appConfig from './appConfig';
import RootContainer from './components/RootContainer/RootContainer';
// import checkUserCompanyAndStock from './utils/checkUserCompanyAndStock';
import './index.css';
import './services';
import appCore from './appCore';
import errorTracker from './utils/errorTracker';
import auth from './auth'
import downloadPdfList from './utils/supportHelpers/downloadPdfList';

window.supportUtils = {
  downloadPdfList,
}

appConfig.loadConfig().then(async () => {
  // Инициализируем сентри
  errorTracker.init();

  // Инициализируем кейклок
  const authorized = await auth.init();
  if (!authorized) {
    // Редирект на логин
    auth.login();
    return;
  }

  appCore.init();
  // await checkUserCompanyAndStock();
  ReactDOM.render(
    <RootContainer />,
    document.getElementById('root')
  );
});
