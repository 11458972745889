import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Описание остатков |||| Описание остатков',
      fields: {
        'meta.status': 'Статус',
        'meta.created': 'Дата создания'
      }
    }
  }
}