import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import url from 'url';
import WithPermissions from '../WithPermissions';
import Layout from '../Layout';
import Menu from '../../components/Menu';
import appCore from '../../appCore';
import NotFoundPage from '../../pages/NotFoundPage';
import { getSlashedPath, fillUrl } from '../../utils/url';
import checkPermissions from '../../utils/checkPermissions';

class AppServiceContainer extends Component {

  cachedRoutes = null;
  cachedMenuItems = null;

  constructor(props) {
    super(props);

    this.updateAppCoreUrlParams(props);
  }

  get serviceName() {
    return this.props.match.params.serviceName;
  }

  get service() {
    return appCore.services.find(x => x.name === this.serviceName) || null;
  }

  componentDidMount() {
    this.updateAppCoreUrlParams();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      this.updateAppCoreUrlParams();
    }
  }

  shouldComponentUpdate(next) {
    const prev = this.props;
    if (prev.match.url !== next.match.url) {
      return true;
    }
    const prevPermissions = prev.permissions ? prev.permissions.join('') : null;
    const nextPermissions = next.permissions ? next.permissions.join('') : null;
    if (prevPermissions !== nextPermissions) {
      return true;
    }
    return false;
  }

  updateAppCoreUrlParams = (props = this.props) => {
    appCore.updateCurrentUrlParams(this.props.match.params);
  }

  getRoutes = () => {
    if (this.cachedRoutes !== null) {
      return this.cachedRoutes;
    }
    const routes = [];

    const servicePath = `${this.props.match.url}`;

    const {
      companyId = null,
      productGroup = null,
      stockId = null,
    } = this.props.match.params;
    const defaultAuthParams = {
      serviceName: this.service.name,
      companyId,
      productGroup,
      stockId,
    };

    if (this.service.pages) {
      this.service.pages.forEach(page => {
        routes.push({
          exact: true,
          path: `${servicePath}/${page.path}`,
          render: (routeProps) => (
            <WithPermissions
              component={page.PageComponent}
              authParams={{
                ...defaultAuthParams,
                resource: page.resourceName,
              }}
              resource={page.resourceName}
              {...routeProps}
            />
          ),
        });
      })
    }

    this.service.resources.forEach(resource => {
      const basePath = `${servicePath}/${resource.name}`;
      if (resource.allowList) {
        routes.push({
          exact: true,
          path: basePath,
          hasCreate: resource.allowCreate,
          render: (routeProps) => (
            <WithPermissions
              component={resource.List}
              basePath={basePath}
              resource={resource.name}
              id={decodeURIComponent(
                (routeProps.match).params
                  .id
              )}
              authParams={{
                ...defaultAuthParams,
                resource: resource.name,
              }}
              {...routeProps}
            />
          )
        })
      }
      if (resource.allowCreate) {
        routes.push({
          exact: true,
          path: `${basePath}/create`,
          render: (routeProps) => (
            <WithPermissions
              component={resource.Create}
              basePath={basePath}
              resource={resource.name}
              authParams={{
                ...defaultAuthParams,
                resource: resource.name,
              }}
              {...routeProps}
            />
          )
        })
      }
      if (resource.allowShow) {
        routes.push({
          exact: false,
          path: `${basePath}/:id/show`,
          render: routeProps => (
            <WithPermissions
              component={resource.Show}
              basePath={basePath}
              resource={resource.name}
              id={decodeURIComponent(
                (routeProps.match).params
                  .id
              )}
              authParams={{
                ...defaultAuthParams,
                resource: resource.name,
              }}
              {...routeProps}
            />
          )
        })
      }
      if (resource.allowEdit) {
        routes.push({
          exact: false,
          path: `${basePath}/:id`,
          render: routeProps => (
            <WithPermissions
              component={resource.Edit}
              basePath={basePath}
              resource={resource.name}
              id={decodeURIComponent(
                (routeProps.match).params
                  .id
              )}
              authParams={{
                ...defaultAuthParams,
                resource: resource.name,
              }}
              {...routeProps}
            />
          )
        })
      }
    });

    this.cachedRoutes = routes
    return this.cachedRoutes;
  }


  sortMenuItems = (items) => {
    return items.sort(({ order: orderA = 0 }, { order: orderB = 0 }) => {
      return orderA - orderB;
    })
  }

  getMenuItems = () => {
    if (this.cachedMenuItems !== null) {
      return this.cachedMenuItems;
    }

    const {
      permissions
    } = this.props;
    const menuItems = [];
    const menuGroups = [];
    if (this.service.menuGroups) {
      this.service.menuGroups.forEach(x => {
        menuGroups.push({
          ...x,
          icon: x.icon || null,
          items: [],
        });
      })
    }

    const addMenuItem = (item) => {
      if (item.group) {
        const group = menuGroups.find(x => x.name === item.group);
        if (group) {
          group.items.push(item);
          return;
        }
      }
      menuItems.push(item)
    }

    if (this.serviceName === 'marking') {
      addMenuItem({
        exact: true,
        name: 'back',
        type: 'back',
        text: 'Назад к компании',
        url: fillUrl('/c/{{companyId}}/s/company'),
      });
    }

    if (this.service.pages) {
      this.service.pages.filter(x => x.menuConfig).forEach(page => {
        addMenuItem({
          exact: true,
          name: page.menuConfig.name,
          text: page.menuConfig.text,
          group: page.menuConfig.group,
          url: url.resolve(getSlashedPath(this.props.match.url), page.path),
          icon: page.icon,
        });
      })
    }

    this.service.resources.forEach(resource => {
      if (!resource.menuConfig|| !checkPermissions(resource.name, permissions)) {
        return;
      }
      addMenuItem({
        name: `resources.${resource.name}.name`,
        url: url.resolve(getSlashedPath(this.props.match.url), resource.name),
        icon: resource.icon,
        group: resource.group,
        ...resource.menuConfig,
      })
    })

    if (this.serviceName === 'company') {
      appCore.productGroups.forEach((x, index) => {
        addMenuItem({
          exact: true,
          name: x.name,
          text: `productGroups.${x.name}.name`,
          disabled: !x.active,
          icon: x.icon,
          order: 10 + index,
          url: fillUrl(`/c/{{companyId}}/pg/${x.name}/st/all/s/marking`),
          separate: index === 0,
        });
      })
    }

    menuGroups.forEach(x => {
      if (x.items.length > 0) {
        x.items = this.sortMenuItems(x.items)
        addMenuItem(x);
      }
    })


    this.cachedMenuItems = this.sortMenuItems(menuItems);

    return this.cachedMenuItems;
  }

  render() {
    const {
      permissions
    } = this.props;

    if (permissions === undefined) {
      return null; // LOADING
    }

    if (this.service === null || permissions.length === 0) {
      // TODO: return 404 page or redirect
      return (
        <NotFoundPage />
      );
    }

    const routes = this.getRoutes();
    const menuItems = this.getMenuItems();

    let menu = null;
    if (menuItems.length > 1) {
      menu = (
        <Menu
          items={menuItems}
        />
      );
    }
    return (
      <div>
        <Layout
          appBarProps={{
            showStockSelect: this.serviceName === 'marking',
            showCompanySelect: this.serviceName === 'company', // || this.serviceName === 'marking',
            showCompanyStatus: ['marking','company'].includes(this.serviceName),
          }}
          menu={menu}
        >
          <Switch>
            {routes.map(route => (
              <Route {...route} key={route.path} />
            ))}
            <Redirect to={routes[0].path} path={this.props.match.path} exact />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Layout>
      </div>
    );
  }
}

export default AppServiceContainer;
