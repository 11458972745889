import ResourceIcon from '@material-ui/icons/Input';
import childCreateOptionsCreator from '../../../../../utils/admin/childCreateOptionsCreator';
import childEditOptionsCreator from '../../../../../utils/admin/childEditOptionsCreator';
import userRole from '../../userRole';


const name = 'settings/member-roles';
const parentResourceName = 'settings/member';

export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/cm/company/members/{{parent_id}}/roles/',
    idField: 'pk',
  },
  itemToString: x => x.name || `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        componentInput: userRole.Input,
        options: {
          optionValue: 'name',
        },
        validation: {
          required: true,
        }
      }
    }
  },
  allowList: true,
  allowCreate: true,
  allowEdit: false,
  allowShow: false,
  allowDelete: true,
  showInMenu: false,
  group: 'settings',
  createFields: [
    'name',
  ],
  listFields: [
    'id',
    'name',
  ],
  listFilters: [],
  createGetOptions: childCreateOptionsCreator({ parentResourceName, tabPath: 'roles' }),
  editGetOptions: childEditOptionsCreator({ parentResourceName, tabPath: 'roles' }),
};
