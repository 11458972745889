import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Print',
      title: 'Print document',
      executeAction: 'Submit',
      errorMessage: 'Error',
      successMessage: 'Success',
    }
  }
}