export default {
  name: 'marking/enums/packType',
  enums: [
    'UNIT',
    'LEVEL1',
    'LEVEL2',
    'LEVEL3',
    'LEVEL4',
    'LEVEL5',
    'BUNDLE',
    'АТК',
    'SET',
  ],
};
