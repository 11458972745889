import ResourceIcon from '@material-ui/icons/Delete';
import dropoutReport from './dropoutReportChild';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import dropoutReasonType from '../../enums/dropoutReasonType';


const name = 'marking/dropout-report';
const precessName = 'dropout_report';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: ResourceIcon,
  precessName,
  apiBasePath,
  childResource: dropoutReport,
  schema: {
    type: 'object',
    properties: {
      'data.dropout_reason': {
        type: 'string',
        defaultValue: null,
        componentInput: dropoutReasonType.Input,
        componentField: dropoutReasonType.Field,
        validation: {
          required: true,
        },
      },
    }
  },
  createFields: [
    'data.dropout_reason',
  ],
});
