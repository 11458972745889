export default {
  root: {
    paddingBottom: 20,
  },
  icon: { 
    minWidth: 40
  },
  menuItem: {
    minHeight: 38,
    paddingLeft: 16,
    borderRadius: '0 25px 25px 0',
  },
  menuSubItemFull: {
    minHeight: 38,
    paddingLeft: 28,
    borderRadius: '0 25px 25px 0',
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  menuSubItemShort: {
    minHeight: 38,
    paddingLeft: 16,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    borderRadius: '0 25px 25px 0',
  }
};