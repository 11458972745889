import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RETAIL: 'Розничная продажа',
        EEC_EXPORT: 'Экспорт в страны ЕАЭС',
        BEYOND_EEC_EXPORT: 'Экспорт за пределы стран ЕАЭС',
        RETURN: 'Возврат физическому лицу',
        BY_SAMPLES: 'Продажа по образцам',
        DISTANCE: 'Дистанционный способ продажи',
        DAMAGE_LOSS: 'Утрата или повреждение',
        DESTRUCTION: 'Уничтожение',
        CONFISCATION: 'Конфискация',
        LIQUIDATION: 'Ликвидация предприятия',
        ENTERPRISE_USE: 'Использование для собственных нужд предприятия',
      },
    }
  }
}
