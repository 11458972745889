import download from 'downloadjs';
import request from '../../network/request';

const downloadPdfList = (items, template, autoDownload = true) => {
  const result = {
    errors: [],
    success: [],
  };

  result.downloadAll = () => {
    result.success.forEach(x => x.downloadItem())
  }

  let curentIndex = 0;
  const proccessItem = async () => {
    if (items.length - 1 < curentIndex || !items[curentIndex]) {
      console.log('FINISH', `total: ${items.length}, success: ${result.success.length}, error: ${result.errors.length}`, result);
      return;
    }
    const {id, name} = items[curentIndex];

    let response = null;
    let error = null;
    try {
      response = await request({
        path: `/printer/${id}/pdf/${template}/`,
        method: 'GET',
        responseType: 'blob',
      })
    } catch (e) {
      error = e || 'request error';
    }

    if (response === null || !response.ok) {
      if (response !== null) {
        error = response.data || 'reponse error';
      }

      result.errors.push({id, name, error});
    } else {
      const downloadItem = () => {
        let mimeType = 'application/pdf';
        download(response.data, `REPLY_${name}.pdf`, mimeType);
      }

      if (autoDownload) {
        downloadItem();
      }

      result.success.push({id, name, downloadItem});
    }

    curentIndex += 1;
    proccessItem();
  }

  proccessItem();


  // let responsesCount = 0;
  // items.forEach(async ({id, name}) => {
  //   let response = null;
  //   let error = null;
  //   try {
  //     response = await request({
  //       path: `/printer/${id}/pdf/${template}/`,
  //       method: 'GET',
  //       responseType: 'blob',
  //     })
  //   } catch (e) {
  //     error = e || 'request error';
  //   }

  //   if (response === null || !response.ok) {
  //     if (response !== null) {
  //       error = response.data || 'reponse error';
  //     }

  //     result.errors.push({id, name, error});
  //   } else {
  //     const downloadItem = () => {
  //       let mimeType = 'application/pdf';
  //       download(response.data, `${name}.pdf`, mimeType);
  //     }
  //     downloadItem();
  //     console.log('Success', id, name);

  //     result.success.push({id, name, downloadItem});
  //   }

  //   responsesCount += 1;
  //   if (responsesCount === items.length) {
  //     console.log('FINISH', `total: ${items.length}, success: ${result.success.length}, error: ${result.errors.length}`, result);
  //   }
  // });

  return result;
}

export default downloadPdfList;
