import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'FOREIGN': 'Import',
        'LOCAL': 'Produced',
        'REMAINS': 'Remains',
        'CROSSBORDER': 'Crossborder',
        'REMARK': 'Remark',
      },
    }
  }
}