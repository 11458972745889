import React from 'react';
import { Typography } from '@material-ui/core';

import {
  TextInput,
  TextField,
  NumberInput,
  NumberField,
  BooleanInput,
  BooleanField,
  DateField,
  required,
  minValue,
  maxValue,
  minLength,
  maxLength,
  number,
  email,
  regex,
  choices,
} from '../../components/Admin';
import DateInput from '../../components/DateInput';
import DynamicPropsField from '../../components/DynamicPropsField';
import DynamicPropsInput from '../../components/DynamicPropsInput';
import ShowConditionField from '../../components/ShowConditionField';
import ShowConditionInput from '../../components/ShowConditionInput';
import appCore from '../../appCore';
import Tr from '../../components/Tr';

const getComponent = (data, editable) => {
  let result = data;
  if (typeof data === 'object' && data.resourceName) {
    const resource = appCore.resources.find(x => x.name === data.resourceName) || null;
    if (resource !== null) {
      if (data.componentName && resource[data.componentName]) {
        result = resource[data.componentName];
      } else if (editable && resource.Input) {
        result = resource.Input
      } else if (!editable && resource.Field) {
        result = resource.Field
      } else {
        result = resource;
      }
    }
  }

  return result;
}

export default (data = {}) => {
  let {
    name,
    type = 'string',
    validation = null,
    editable = false,
    readOnly = false,
    component = null,
    componentInput = null,
    componentField = null,
    options = {},
    ...restProps
  } = data;

  const result = {
    Component: null,
    props: {
      key: name,
      emptyText: '—',
      ...restProps,
      ...options,
      source: name
    }
  }

  if (name === 'id') {
    readOnly = true;
  }
  
  if (editable && readOnly) {
    result.props.disabled = true;
  }

  if (component !== null) {
    result.Component = component;
  } else if (editable && componentInput !== null) {
    result.Component = getComponent(componentInput, editable);
  } else if (!editable && componentField !== null) {
    result.Component = getComponent(componentField, editable);
  } else if (type === 'string') {
    result.Component = editable ? TextInput : TextField;
  } else if (type === 'number') {
    result.Component = editable ? NumberInput : NumberField;
  } else if (type === 'boolean') {
    result.Component = editable ? BooleanInput : BooleanField;
  } else if (type === 'date') {
    result.Component = (editable && !readOnly) ? DateInput : DateField;
    if (options && options.format) {
      result.props.options = {
        ...result.props.options,
        format: options.format,
      }
      delete result.props.format;
    }
  } else if (type === 'dateTime') {
    result.Component = (editable && !readOnly) ? DateInput : DateField;
    result.props.showTime = true;
  } else if (type === 'subtitle') {
    result.Component = Typography;
    result.props.variant = 'h5';
    result.props.children = (
      <Tr value={options.text} />
    )
  }

  const validationConds = [];
  if (type === 'number') {
    validationConds.push(number())
  }
  if (validation !== null) {
    if (validation.required) {
      validationConds.push(required())
    }
    if (validation.min) {
      validationConds.push(minValue(validation.min))
    }
    if (validation.max) {
      validationConds.push(maxValue(validation.max))
    }
    if (validation.minLength) {
      validationConds.push(minLength(validation.minLength))
    }
    if (validation.maxLength) {
      validationConds.push(maxLength(validation.maxLength))
    }
    if (validation.email) {
      validationConds.push(email())
    }
    if (validation.regex) {
      validationConds.push(regex(validation.regex))
    }
    if (validation.choices) {
      validationConds.push(choices(validation.choices))
    }
    if (validation.format === 'inn') {
      validationConds.push(regex(/^[\d+]{10,12}$/, 'validationErrors.inn'))
    }
  }

  if (validationConds.length > 0) {
    result.props.validate = validationConds;
  }

  // Оборачиваем в компоненту которая будет передавать динамические пропсы
  if (options.dynamicProps) {
    const nextProps = result.props;
    result.props = {
      nextProps,
      Component: result.Component,
      getProps: options.dynamicProps,
      source: name, // For adding label
    }
    result.Component = editable ? DynamicPropsInput : DynamicPropsField;
  }
  

  // Добавляем условаие если поле с ограничениями по товарным группам
  if (options && options.forProductGroups) {
    const pgCondition = () => {
      const pg = appCore.currentUrlParams.productGroup;
      return pg && options.forProductGroups.includes(pg)
    }
    if (options.showCondition) {
      const showCondition = options.showCondition;
      options.showCondition = (...args) => pgCondition() && showCondition(...args);
    } else {
      options.showCondition = pgCondition;
    }
  }

  // Оборачиваем в компоненту которая будет рендрить по условию
  if (options.showCondition) {
    const nextProps = result.props;
    result.props = {
      nextProps,
      Component: result.Component,
      condition: options.showCondition,
      source: name, // For adding label
    }
    result.Component = editable ? ShowConditionInput : ShowConditionField;
  }

  return result;
}