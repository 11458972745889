import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        SALE: 'Sale',
        COMMISSION: 'Commission',
        AGENT: 'Agent',
        CONTRACT: 'Contract',
      },
    }
  }
}