import ResourceIcon from '@material-ui/icons/Security';


const name = 'settings/company-credentinal';
export default {
  name,
  path: name,
  icon: ResourceIcon,

  menuConfig: {
    name: name, 
    text: `pages.${name}.name`,
    icon: ResourceIcon,
    group: 'settings',
  }
};