import deepmerge from 'deepmerge';
import { createMuiTheme } from '@material-ui/core';
import baseTheme from '../baseTheme';

const palette = baseTheme.palette;

const theme = createMuiTheme(deepmerge({ palette }, {
  palette: {
  }
}));

export default theme.palette;
