import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  ResponsiveContainer,
  Legend,
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';
import ChartRangeSelect from './ChartRangeSelect';
import { Toolbar } from '@material-ui/core';


const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length > 0) {
    return (
      <div style={{
        padding: '8px 16px',

        background: '#323440',
        boxShadow: '4px 8px 16px rgba(26, 25, 32, 0.76)',
        borderRadius: '8px',

        fontSize: '12px',
        lineHeight: '16px',
      }}>
        {payload.map((item) => (
          <div key={item.dataKey} style={{ color: item.stroke }}>
            {item.name} : {item.value}{(item.unit || '')}
          </div>
        ))}
      </div>
    );
  }

  return null;
};

const renderLegend = (props) => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 10,
    }}>
      {
        props.payload.map((entry) => (
          <div key={entry.dataKey} style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '24px',

            fontSize: '12px',
            lineHeight: '16px',
          }}>
            <div style={{ 
              backgroundColor: entry.color,
              width: '20px',
              height: '4px',
              marginRight: '16px',
              borderRadius: '20px',
            }} />
            {entry.value}
          </div>
        ))
      }
    </div>
  );
}

const renderDot = (props) => {
  return (
    <foreignObject
      x={props.cx - 10}
      y={props.cy - 10}
      width={20}
      height={20}
      style={{ color: props.fill }}
    >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      
        height: '100%',
      }}>
        <div style={{
          width: '4px',
          height: '4px',
          borderRadius: '10px',
          backgroundColor: 'currentColor',
          boxShadow: '0px 2px 8px currentColor',
        }}/>
      </div>
    </foreignObject>
  );
}

const renderXTick = (props) => {
  const isLast = props.index === props.visibleTicksCount - 1;
  return (
  <foreignObject
    x={isLast ? props.x - props.width : props.x}
    y={props.y}
    width={props.width}
    height={props.height}
  >
    <div style={{
      fontSize: '10px',
      lineHeight: '16px',
      textAlign: (isLast ? 'right' : 'left')
    }}>{props.payload.value}</div>
  </foreignObject>
)};

const renderYTick = (props) => (
  <foreignObject
    x={0}
    y={props.y - 8}
    width={65}
    height={props.height}
  >
    <div style={{
      textAlign: 'right',
      fontSize: '10px',
      lineHeight: '16px',
    }}>{props.payload.value}</div>
  </foreignObject>
);


const styles = (theme) => ({
  root: {
    flex: 1,
  },
  listItemIcon: {
    minWidth: 40,
  }
})

class ChartCard extends Component {
  render() {
    const {
      classes,
      title,
      chartItems = [],
      chartOptions = {},
      actions = [],
    } = this.props;

    const {
      xName,
      syncId = undefined,
      yMetrics = [],
    } = chartOptions;

    return (
      <Card className={classes.root}>
        <CardHeader
          title={title}
          action={
            <Toolbar>
              {(actions.length > 0 && actions)}
              &nbsp;&nbsp;&nbsp;
              <ChartRangeSelect />
            </Toolbar>
          }
        />
        <ResponsiveContainer height={300}>
          <AreaChart
            data={chartItems}
            margin={{
              top: 12, right: 24, left: 12, bottom: 12,
            }}
            syncId={syncId}
          >
            <CartesianGrid stroke="#323440" horizontal={false} />
            <XAxis
              dataKey={xName}
              tickLine={false}
              tick={renderXTick}
              axisLine={false}
            />
            <YAxis
              tickLine={false}
              tick={renderYTick}
              axisLine={{ stroke: '#4D4F5C' }}
            />
            <Tooltip content={<CustomTooltip />} cursor={{ stroke: '#A7ADBF', strokeWidth: 1, strokeDasharray: '3 3' }} />
            <Legend content={renderLegend} />
            {yMetrics.map(item => (
              <Area
                kye={item.field}
                type="monotone"
                name={item.name}
                dataKey={item.field}
                stackId={item.stackId}
                stroke={item.color}
                fill={item.color}
                dot={false}
                activeDot={renderDot}
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </Card>
    );
  }
}

export default withStyles(styles)(ChartCard);
