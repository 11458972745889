import deepmerge from "deepmerge";
import englishMessages from "ra-language-english";

export default deepmerge(englishMessages, {
  '—': '—', // Fix for emptyText
  ra: {
    navigation: {
      // page_range_info: '%{offsetBegin}-%{offsetEnd} из %{total}',
      page_range_info: "%{offsetBegin}-%{offsetEnd}",
    },
  },
  list: {
    simpleSearch: "Simple Search",
    extendedSearch: "Extended Search",
  },
  actions: {
    create: "Create",
    createAndGoToEdit: "Create and continue edit",
    createAndGoToShow: "Create and show",
    createAndGoToCreate: "Create and add another",
    save: "Save",
    saveAndGoToEdit: "Save and continue edit",
    saveAndGoToShow: "Save and show",
  },
  pagination: {
    actions: {
      getCount: {
        name: "Get count",
        successMessage: "Total count for current selection: %{total}",
        failureMessage: "Count request failed",
      },
      getCountNoCache: {
        name: "Get count without cache (slow)",
        successMessage: "Total count for current selection: %{total}",
        failureMessage: "Count request failed",
      },
    },
  },
  pos: {
    title: "Marking service",
    language: "Language",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark",
    },
    dashboard: {
      welcome: {
        title: "Welcome to marking services",
        subtitle: "",
      },
    },
    login: {
      hint: "Marking hub",
    },
  },
  widgets: {
    metrics: {
      rangeInputLabel: "Period",
      chartSetInputLabel: "Set type",
      ranges: {
        "30m": "30 minutes",
        "1h": "60 minutes",
        "2h": "2 hours",
        "4h": "4 hours",
        "1d": "24 hours",
      },
    },
    lastDocs: {
      title: "Last documents",
    },
    lastNotifications: {
      title: "Notifications",
      showAll: "Show all",
    },
    documentsMetrics: {
      title: "Documents Metrics (Beta)",
      sets: {
        amounts: "Counts",
        events: "Events",
      },
    },
    codesMetrics: {
      title: "Codes Metrics (Beta)",
      sets: {
        amounts: "Counts",
        events: "Events",
      },
    },
    documentsCount: {
      title: "Documents",
    },
    codesCount: {
      title: "Marking Codes",
    },
    productsCount: {
      title: "Products",
    },
    productGroups: {
      balanceUpdatedPrefix: 'Updated',
      balanceUnknown: 'Balance unknown',
      balanceLastUpdateWarning: 'Last updated more than 5 days ago',
    },
    productGroupBalance: {
      title: 'Balance',
      balanceUpdatedPrefix: 'Updated',
      balanceUnknown: 'No data',
      balanceLastUpdateWarning: 'Last updated more than 5 days ago',
    },
  },
  resources: {},
  companySelect: {
    label: "Company",
    unknownCompany: "Unknown company",
    editCompanies: "Edit companies",
  },
  stockSelect: {
    label: "Stock",
    allStocks: "All stocks",
    unknownStock: "Unknown stock",
    editStocks: "Edit stocks",
  },
  meSelect: {
    tooltip: "Change theme",
  },
  languageSelect: {
    tooltip: "Change language",
  },
  menu: {
    serviceApiDoc: "API documentation",
    profile: "Profile",
    uiConfig: "UI Config",
  },
  validationErrors: {
    inn: "Invalid INN",
  },
  documents: {
    tabs: {
      documentStatus: "Status",
      documentInfo: "Data",
      documentChildren: "Children",
    },
    actions: {
      send: {
        name: "Send",
        successMessage: "Send request success",
        failureMessage: "Send request failed",
      },
      refresh: {
        name: "Refetch",
        successMessage: "Refetch request success",
        failureMessage: "Refetch request failed",
        cancelMessage: "Request canceld",
      },
      renew: {
        name: "Renew",
        successMessage: "Renew request success",
        failureMessage: "Renew request failed",
      },
      bulkRenew: {
        name: "Renew",
        successMessage: "Renew request success",
        failureMessage: "Renew request failed",
      },
      bulkSend: {
        name: "Send",
        successMessage: "All documents sent successfully",
        failureMessage: "Send documents reqesut failed",
      },
      bulkUpdate: {
        name: "Update",
        successMessage: "Request for updating documents sent",
        failureMessage: "Update documents reqesut failed",
      },
      docDownloadJSON: {
        name: "Download JSON",
      },
      showLogs: "Tasks",
      showHistory: "Change history",
      showCodes: "Codes",
      createNextDoc: "New document",
    },
    childGenerator: {
      sectionTitle: "Auto completion of positions",
      fields: {
        toggle: "Use automatic sampling codes",
        query: "MHQL query",
        amount: "Amount",
        amountHint: "Set -1 To choose all found codes",
      },
    },
  },
  common: {
    id: "ID",
    status: "Status",
    created: "Created date",
    external_id: "External ID",
    external_status: "External status",
    name: "Name",
    info: "Information",
    quantity: "Quantity",
    default: "Default",
    slug: "Slug",
    description: "Description",
    vsd_number: "VSD Number",

    _aggregated_count: "Subchildren amount",

    query: "MHQL Query",
    unknownError: "Unknown error",
  },
  productGroups: {
    clothes: {
      name: "Clothes",
    },
    shoes: {
      name: "Shoes",
    },
    tobacco: {
      name: "Tobacco",
    },
    perfumery: {
      name: "Perfumery",
    },
    tires: {
      name: "Tires",
    },
    electronics: {
      name: "Electronics",
    },
    water: {
      name: "Water",
    },
    pharma: {
      name: "Pharma",
    },
    milk: {
      name: "Milk",
    },
    bicycle: {
      name: "Bicycle",
    },
    wheelchairs: {
      name: "Wheelchairs",
    },
    bio: {
      name: "Bio",
    },
  },
  companyStatusMenu: {
    label: "Status",
    encodingRunner: {
      label: "Encoding server",
      initialazing: "Checking",
      connected: "Server connected",
      disconnected: "Server disconnected",
      error: "Request error",
    },
  },
  dateRange: {
    Apply: "Apply",
    Today: "Today",
    Yesterday: "Yesterday",
    "This Week": "This Week",
    "Last Week": "Last Week",
    "This Month": "This Month",
    "Last Month": "Last Month",
    "days up to today": "days up to today",
    "days starting today": "days starting today",
  },
  LogField: {
    request: "HTTP Request",
    requestUrl: "URL",
    requestMethod: "Method",
    requestBody: "Body",
    response: "Response",
    responseStatusCode: "Status Code",
    responseBody: "Body",
    messageJson: "Message (JSON)",
    message: "Message",
  },
  exporterFormats: {
    "csv-semicolon": "CSV (semicolon)",
    "csv-comma": "CSV (comma)",
    "csv-tab": "CSV (tab)",
    json: "JSON",
  },
  filters: {
    __in: '| list',
    __notin: '| not in the list',
    __isnull: '| not specified',
    __contains: '| contains',
    __lt: '| less',
    __gt: '| more',
    __lte: '| less or equal',
    __gte: '| more or equal',
  },
});
