import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Notification |||| Notifications',
      actions: {
        sendTest: {
          name: 'Send Test Notifications',
          successMessage: 'Request successfully sent',
          failureMessage: 'The request was processed with an error',
        }
      },
      fields: {
        message_types: 'Message types',
        all_types: 'All types',
      }
    }
  }
}
