import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        SHIPPED: 'Shipped',
        WAIT_SHIPMENT: 'Wait shipment',
        WAIT_TRANSFER_TO_OWNER: 'Wait transfer to owner',
        REMARK_RETIRED: 'Remark retired',
        RETIRED_CANCELLATION: 'Retired cancellation',
        WAIT_REMARK: 'Wait remark',
        CANCELLATION_APPLIED: 'Cancellation applied',
        DONATION: 'Donation',
        STATE_ENTERPRISE: 'State enterprise',
        NO_RETAIL_USE: 'No retail use',
        BEYOND_EEC_EXPORT: 'Beyond eec export',
        REMOTE_SALE: 'Remote sale',
        EEC_EXPORT: 'Eec export',
        RETURN: 'Return',
        DAMAGE_LOSS: 'Damage loss',
        DESTRUCTION: 'Destruction',
        CONFISCATION: 'Confiscation',
        LIQUIDATION: 'Liquidation',
        FTS_RESPOND_WAITING: 'Fts Respond Waiting',
      },
    }
  }
}
