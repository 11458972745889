import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        UNIT: 'Единица товара',
        BUNDLE: 'Комплект',
        GROUP: 'Групповая потребительская упаковка',
      },
    }
  }
}
