import * as actions from './actions';


const initialState = {
  reportPeriod: [new Date(), new Date()],
  reportViewType: 'pieChart',
};

const handlers = {};

handlers[actions.SET_REPORT_PERIOD] = (state, { payload }) => ({
  ...state,
  reportPeriod: payload,
});

handlers[actions.SET_REPORT_VIEW_TYPE] = (state, { payload }) => ({
  ...state,
  reportViewType: payload,
});

export default (state = initialState, { type, ...payload }) => {
  if (!(type in handlers)) {
    return state;
  }

  return handlers[type](state, payload);
};
