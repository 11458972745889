import ResourceIcon from '@material-ui/icons/Input';
import childCreateOptionsCreator from '../../../../../utils/admin/childCreateOptionsCreator';
import childEditOptionsCreator from '../../../../../utils/admin/childEditOptionsCreator';
import { createSendTestNotificationActionCreator } from '../itemActionCreators';


const name = 'settings/notification-group-webhooks';
const parentResourceName = 'settings/notification-group';

export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/notification-service/notification-group/{{parent_id}}/webhooks/settings/',
    disableSorting: true,
  },
  itemToString: x => `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      url: {
        type: 'string',
        validation: {
          required: true,
        }
      },
      is_active: {
        type: 'boolean',
      },
      active_till: {
        type: 'dateTime',
      },
      created: {
        type: 'dateTime',
        readOnly: true,
      },
      updated: {
        type: 'dateTime',
        readOnly: true,
      },
    }
  },
  allowList: true,
  allowCreate: true,
  allowEdit: true,
  allowShow: false,
  allowDelete: true,
  showInMenu: false,
  group: 'settings',
  itemActions: [
    createSendTestNotificationActionCreator({ groupType: 'webhooks', resourceName: name, parentResourceName }),
  ],
  createGetOptions: childCreateOptionsCreator({ parentResourceName, tabPath: 'webhooks' }),
  editGetOptions: childEditOptionsCreator({ parentResourceName, tabPath: 'webhooks' }),
  createFields: [
    'url',
    'is_active',
    'active_till',
  ],
  editFields: [
    'id',
    'url',
    'is_active',
    'active_till',
    'created',
    'updated',
  ],
  listFields: [
    'id',
    'url',
    'is_active',
    'active_till',
  ],
  listFilters: [],
};
