import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Параметр запроса на печать |||| Параметры запроса на печать',
      fields: {
        'data.selector_type': 'Тип выборки',
        'data.selector_value': 'Значение выборки',
        'data.emission_type': 'Тип эмиссии',
      }
    }
  }
}
