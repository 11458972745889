import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Import',
      title: 'Import document',
      executeAction: 'Submit',
      resetAction: 'Reset form',
      errorMessage: 'Error',
      successMessage: 'Success',
      fields: {
        document_type: 'Document type',
        file: 'File',
        auto_execute: 'Send documents automatically',
      },
    }
  }
}
