import React from 'react';
import QueryInput from '../QLInput';
import values from './values';


const fakeDataForAutocomplete = [];

for (let key in values) {
  values[key].forEach((x, index) => {
    if (index >= fakeDataForAutocomplete.length) {
      fakeDataForAutocomplete[index] = {}
    }
    fakeDataForAutocomplete[index][key] = x;
  })
}

const qlInputOptions = [
  { columnField: 'document_id', type: 'text'},
  { columnField: 'external_id', type: 'text'},
  { columnField: 'status', type: 'selection'},
  { columnField: 'external_status', type: 'selection'},
  { columnField: 'data.', type: 'text'},
  { columnField: 'additional_data.', type: 'text'},
  { columnField: 'extra.', type: 'text'},
  { columnField: 'hot_additional_data.', type: 'text'},
  { columnField: 'created', type: 'text'},
  { columnField: 'direction', type: 'selection'},
  { columnField: 'doctype', type: 'text'},
  { columnField: 'last_es_update', type: 'text'},
  { columnField: 'position', type: 'text'},
  { columnField: 'product_group', type: 'text'},
  { columnField: 'stock', type: 'text'},
];

const QLInputDocuments = (props) => {
  return (
    <QueryInput
      queryOptions={qlInputOptions}
      queryData={fakeDataForAutocomplete}
      {...props}
    />
   )
}

export default QLInputDocuments;
