import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Dropout report',
      fields: {
        'data.dropout_reason': 'Dropout reason',
      }
    }
  }
}
