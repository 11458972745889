import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'PRODUCTION': 'Производство',
        'IMPORT': 'Импорт',
        'REMAINS': 'Остатки',
        'CROSSBORDER': 'Ввезен в РФ из стран ЕАЭС',
        'REMARK': 'Перемаркировка',
      },
    }
  }
}
