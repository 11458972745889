import resourceConfig from '../config';

export default {
  pages: {
    [resourceConfig.name]: {
      name: 'Codes report',

      welcome: 'Select a period to form codes report',
      welcomeHint: 'The report is formed on the basis of codes that were issued in the specified period.',
      nullKey: 'Not set',
      requestError: 'Request Proced with Error',
      noCodesFoundError: 'No codes for this period',
      maxCodeLimitError: 'More than 100,000 codes have been found.This report is available only when sampling not more than 100,000 codes.',
      makeReport: 'Create report',
      printReport: 'Print',
      newReport: 'New report',
      mainCardTitle: 'Report info',
      period: 'Period',
      totalCodes: 'Total codes',
      gtins: 'Goods',
      topGtins: 'Top Goods',
      allTimeSwitch: 'For all the time',
      allTimePeriod: 'For all the time',
      charts: {
        status: 'Status',
        emissionType: 'Emission type',
        productGroup: 'Product Group',
        printerStatus: 'Print status',
        additionalStatus: 'Additional status',
      },
      viewTypeSelect: {
        label: 'View',
        pieChart: 'Graphics',
        list: 'List',
      },
      downloadMenu: {
        label: 'Download',
        csv: 'CSV',
        pdf: 'PDF',
      },
    }
  }
}
