import * as React from "react";
import {
  Card,
  Box,
  Typography,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import BalanceIcon from "@material-ui/icons/AccountBalanceWallet";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "ra-core";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 52,
    flex: "1",
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  main: {
    overflow: "inherit",
    padding: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .icon": {
      color: theme.palette.text.secondary,
    },
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
}));

// Если меняется, надо менять и перевод widgets.productGroupBalance.balanceLastUpdateWarning
const balanceUpdateWarningDiff = 1000 * 60 * 60 * 24 * 5; // 5 days

const BalanceCard = (props) => {
  const { data, loading } = props;
  const t = useTranslate();
  const classes = useStyles(props);

  let balanceMessage = null;
  let updatedMessage = null;
  let updatedWarning = null;

  if (data) {
    try {
      balanceMessage = new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
      }).format(data.balance);
    } catch {
      balanceMessage = `${data.balance}₽`;
    }
    if (data.updated) {
      const date = new Date(data.updated);
      updatedMessage = `${t(
        "widgets.productGroupBalance.balanceUpdatedPrefix"
      )} ${date.toLocaleString()}`;
      if (new Date().getTime() - date.getTime() > balanceUpdateWarningDiff) {
        updatedWarning = t("widgets.productGroupBalance.balanceLastUpdateWarning");
      }
    }
  } else if (loading) {
    balanceMessage = <CircularProgress size={20} />;
  } else {
    balanceMessage = t("widgets.productGroupBalance.balanceUnknown");
  }

  return (
    <Card className={classes.card}>
      <div className={classes.main}>
        <Box width="3em" className="icon">
          <BalanceIcon fontSize="large" />
        </Box>
        <Box textAlign="right">
          <Typography color="textSecondary">{t("widgets.productGroupBalance.title")}</Typography>
          <Typography variant="h6">{balanceMessage}</Typography>
          {updatedMessage && !updatedWarning && (
            <Typography color="textSecondary">{updatedMessage}</Typography>
          )}
          {updatedMessage && updatedWarning && (
            <Tooltip title={updatedWarning}>
              <Typography
                style={{
                  color: "#e57373",
                }}
              >
                {updatedMessage}
              </Typography>
            </Tooltip>
          )}
        </Box>
      </div>
    </Card>
  );
};

export default BalanceCard;
