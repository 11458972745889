import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Cancel codes |||| Cancel codes',
      subMenuName: 'Not applied',
    }
  }
}
