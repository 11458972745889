import React, { memo, useRef } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
// import ProjectSelect from '../ProjectSelect';
import { Typography, IconButton, Tooltip, useMediaQuery } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import MuiAppBar from '@material-ui/core/AppBar';
import { useDispatch } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import ProfileIcon from '@material-ui/icons/Person';
import UIConfigIcon from '@material-ui/icons/Web';
import { toggleSidebar } from 'ra-core';
import { translate, LoadingIndicator } from '../Admin';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import ThemeSelect from '../ThemeSelect';
import UserMenu from '../UserMenu';
import CompanySelect from '../CompanySelect';
import StockSelect from '../StockSelect';
import appCore from '../../appCore';
import auth from '../../auth';
import CompanyStatusMenu from '../CompanyStatusMenu';
import useWatchTitleHack from '../../hooks/useWatchTitleHack';

const styles = {
  menuButton: {
    marginLeft: -20,
  },
  userMenuWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  userMenuItem: {
    marginLeft: 10
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
};

const AppBar = memo(({ translate, classes, open, withMenu, showStockSelect, showCompanySelect, showCompanyStatus, ...props }) => {
  const titleRef = useRef();
  useWatchTitleHack(titleRef)
  let productGroup = null;
  let companyId = null;
  // TODO make hook
  if (appCore.currentUrlParams.productGroup) {
    productGroup = appCore.currentUrlParams.productGroup;
  }
  if (appCore.currentUrlParams.companyId) {
    companyId = appCore.currentUrlParams.companyId;
  }


  const dispatch = useDispatch();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <MuiAppBar
      position="fixed"
      color="secondary"
    >
      <Toolbar
        variant={isXSmall ? 'regular' : 'dense'}
        // disableGutters
      >
        {withMenu && (
          <Tooltip
            title={translate(
              open
                ? 'ra.action.close_menu'
                : 'ra.action.open_menu',
              {
                _: 'Open/Close menu',
              }
            )}
            enterDelay={500}
          >
            <IconButton
              color="inherit"
              onClick={() => dispatch(toggleSidebar())}
              className={classes.menuButton}
            >
              <MenuIcon
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
          </Tooltip>
        )}
        <Typography
          className={classes.title}
          variant="h6"
          color="inherit"
          ref={titleRef}
        >
          {productGroup !== null && (
            <span>{translate(`productGroups.${productGroup}.name`)} | </span>
          )}
          <span id="react-admin-title" />
        </Typography>
        {showStockSelect && (
          <StockSelect />
        )}
        {showCompanySelect && (
          <CompanySelect />
        )}
        {showCompanyStatus && (
          <CompanyStatusMenu key={companyId} />
        )}
        <LanguageSelect />
        <ThemeSelect />
        <LoadingIndicator />
        <UserMenu>
          <MenuItem
            component={'a'}
            href={auth.getAccountUrl()}
          >
            <ListItemIcon>
              <ProfileIcon fontSize="small" />
            </ListItemIcon>
            {translate('menu.profile')}
          </MenuItem>
          <MenuItem
            component={Link}
            to="/s/common/settings/ui-config"
          >
            <ListItemIcon>
              <UIConfigIcon fontSize="small" />
            </ListItemIcon>
            {translate('menu.uiConfig')}
          </MenuItem>
        </UserMenu>
      </Toolbar>
    </MuiAppBar>
  );
})

export default withStyles(styles)(translate(AppBar));
