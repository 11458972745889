import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'PRINTED': 'Напечатан',
        'VERIFIED': 'Нанесение КМ подтверждено',
        'USED_FOR_PRODUCTION': 'Использовано для производства',
        'SENT_TO_PRINTER': 'Отправлено на принтер',
        'PRINTER_LOST': 'Принтер утерян',
      },
    }
  }
}
