import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Перемещение |||| Перемещение',
      fields: {
        'meta.status': 'Статус',
        'meta.created': 'Дата создания',
        'data.stock': 'Склад',
        'data.stock_slug': 'Псевдоним склада',
      }
    }
  }
}
