import ResourceIcon from '@material-ui/icons/Receipt';
import receiptChild from './receiptChild';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import CompanyDataInput from '../../../../components/CompanyDataInput';
import receiptActionType from '../../enums/receiptActionType';
import receiptDocumentType from '../../enums/receiptDocumentType';


const name = 'marking/receipt';
const precessName = 'receipt_receipt';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: ResourceIcon,
  precessName,
  apiBasePath,
  childResource: receiptChild,
  allowUpdateAction: true,
  schema: {
    type: 'object',
    properties: {
      'data.inn': {
        type: 'string',
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
        validation: {
          required: true,
        },
      },
      'data.action': {
        type: 'string',
        componentInput: receiptActionType.Input,
        componentField: receiptActionType.Field,
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.action_date': {
        type: 'date',
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.document_type': {
        type: 'string',
        componentInput: receiptDocumentType.Input,
        componentField: receiptDocumentType.Field,
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.document_number': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.document_date': {
        type: 'date',
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.primary_document_custom_name': {
        type: 'string',
        defaultValue: null,
      },
      'data.kkt_number': {
        type: 'string',
        defaultValue: null,
      },
    }
  },
  createFields: [
    'data.inn',
    'data.action',
    'data.action_date',
    'data.document_type',
    'data.document_number',
    'data.document_date',
    'data.primary_document_custom_name',
    'data.kkt_number',
  ],
});
