import { MarkingCodeIcon } from '../../../../components/ui/Icon';
import markingStatus from '../../../marking-service/enums/markingStatus';
import textCaseHelper from '../../../../utils/textCaseHelper';
import stock from '../stock';
import request from '../../../../network/request';
import emissionType from '../../../marking-service/enums/emissionType';
import printStatus from '../../../marking-service/enums/printStatus';
import ProductsSelect from '../../../marking-service/resources/order/orderChild/ProductsSelect';
import CodeInput from '../../../../components/CodeInput';
import productGroupEnum from '../../enums/productGroupEnum';

const name = 'marking/autobalance-codes';
export default {
  name,
  icon: MarkingCodeIcon,
  apiResolve: {
    path: '/bp/code/auto-balance/',
    // disableSorting: true,
    mapping: {
      outputItemAfter: (output) => {
        if (output && output.order_data) {
          try {
            output.order_data = JSON.parse(output.order_data);
          } catch {
            throw new Error('Order_data JSON invalid')
          }
        } else {
          output.order_data = {};
        }

        if (output && output.order_child_data) {
          try {
            output.order_child_data = JSON.parse(output.order_child_data);
          } catch {
            throw new Error('Order_child_data JSON invalid')
          }
        } else {
          output.order_child_data = {};
        }

        return output;
      },
      inputItemAfter: (input) => {
        if (input && input.order_data) {
          input.order_data = JSON.stringify(input.order_data, null, 2);
        } else {
          input.order_data = '{}';
        }

        if (input && input.order_child_data) {
          input.order_child_data = JSON.stringify(input.order_child_data, null, 2);
        } else {
          input.order_child_data = '{}';
        }

        return input;
      },
    },
  },
  itemToString: x => `#${x.id}`,
  group: 'settings',
  schema: {
    type: 'object',
    properties: {
      product_group: {
        type: 'string',
        componentInput: productGroupEnum.Input,
        componentField: productGroupEnum.Field,
      },
      gtin: {
        type: 'string',
        componentInput: ProductsSelect,
        validation: {
          required: true,
        },
      },
      quantity_min: {
        type: 'number',
      },
      quantity_max: {
        type: 'number',
      },
      stock: {
        type: 'string',
        componentInput: stock.Input,
        componentField: stock.Field,
      },
      emission_type: {
        type: 'string',
        componentInput: emissionType.Input,
        componentField: emissionType.Field,
      },
      code_status: {
        type: 'string',
        componentInput: markingStatus.Input,
        componentField: markingStatus.Field,
        options: {
          textCase: textCaseHelper.PASCAL_CASE,
        }
      },
      printer_status: {
        type: 'string',
        componentInput: printStatus.Input,
        componentField: printStatus.Field,
        options: {
          textCase: textCaseHelper.PASCAL_CASE,
        }
      },
      timeout: {
        type: 'number',
      },
      // auto_execute: {
      //   type: 'boolean',
      // },
      is_active: {
        type: 'boolean',
      },
      order_data: {
        type: 'string',
        componentInput: CodeInput,
        defaultValue: `{
    "create_method_type": "SELF_MADE",
    "release_method_type": "PRODUCTION"
}`,
        options: {
          fullWidth: true,
          codeLanguage: 'json',
        },
      },
      order_child_data: {
        type: 'string',
        componentInput: CodeInput,
        defaultValue: `{
    "cis_type": "UNIT",
    "template_id": "10"
}`,
        options: {
          fullWidth: true,
          codeLanguage: 'json',
        },
      },
    }
  },
  itemActions: [
    {
      name: `resources.${name}.actions.execute.name`,
      order: 10,
      handler: async ({ record }) => {
        const path = `/bp/code/auto-balance/${record.id}/execute/`;

        let response = null;
        try {
          response = await request({
            path,
            method: 'POST',
          });
        } catch (e) { }

        if (response && response.ok) {
          return {
            notification: {
              type: 'success',
              message: `resources.${name}.actions.execute.successMessage`
            }
          }
        }

        return {
          notification: {
            type: 'error',
            message: `resources.${name}.actions.execute.failureMessage`
          }
        }
      }
    }
  ],
  allowList: true,
  allowCreate: true,
  allowEdit: true,
  allowShow: false,
  allowDelete: true,
  allowClone: true,
  showInMenu: true,
  listDefaultSort: { field: 'created', order: 'DESC' },
  listFields: [
    'id',
    'product_group',
    'gtin',
    { name: 'quantity', component: ({ record }) => `${record.quantity_min || 0} - ${record.quantity_max || 0}` },
    'stock',
    'emission_type',
    'code_status',
    'printer_status',
    'is_active',
  ],
};
