import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Return |||| Return',
      fields: {
      }
    }
  }
}