import React, {
  Component,
  createElement,
  cloneElement,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import {
  withStyles,
  createStyles,
} from '@material-ui/core/styles';
import compose from 'recompose/compose';
import {
  AppBar as DefaultAppBar,
  Sidebar as DefaultSidebar,
  Menu as DefaultMenu,
  Notification as DefaultNotification,
  Error as DefaultError,
  ComponentPropType
} from 'react-admin';


const styles = theme =>
  createStyles({
      root: {
          display: 'flex',
          flexDirection: 'column',
          zIndex: 1,
          minHeight: '100vh',
          backgroundColor: theme.palette.background.default,
          position: 'relative',
          minWidth: 'fit-content',
          width: '100%',
      },
      appFrame: {
          display: 'flex',
          flexDirection: 'column',
          [theme.breakpoints.up('xs')]: {
              marginTop: 59, // Header height
          },
          [theme.breakpoints.down('xs')]: {
              marginTop: theme.spacing(7),
          },
      },
      contentWithSidebar: {
          display: 'flex',
          flexGrow: 1,
      },
      content: {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          flexBasis: 0,
          padding: theme.spacing(3),
          paddingTop: theme.spacing(1),
          paddingLeft: 0,
          [theme.breakpoints.up('xs')]: {
              paddingLeft: 5,
          },
          [theme.breakpoints.down('sm')]: {
              padding: 0,
          },
      },
      contentWithoutSidebar: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexBasis: 0,
        padding: theme.spacing(3),
        paddingTop: theme.spacing(1),
        maxWidth: 1200,
        margin: '0 auto',
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 5,
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
      }
  });

const sanitizeRestProps = ({
  staticContext,
  history,
  location,
  match,
  ...props
}) => props;

class Layout extends Component {
  state = { hasError: false, errorMessage: null, errorInfo: null };

  constructor(props) {
      super(props);
      /**
       * Reset the error state upon navigation
       *
       * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
       */
      props.history.listen(() => {
          if (this.state.hasError) {
              this.setState({ hasError: false });
          }
      });
  }

  componentDidCatch(errorMessage, errorInfo) {
      this.setState({ hasError: true, errorMessage, errorInfo });
  }

  render() {
      const {
          appBar,
          appBarProps,
          children,
          classes,
          className,
          customRoutes,
          error,
          dashboard,
          logout,
          menu,
          notification,
          open,
          sidebar,
          title,
          ...props
      } = this.props;
      const { hasError, errorMessage, errorInfo } = this.state;

      let sidebarContent = null;
      if (menu && sidebar) {
        sidebarContent = createElement(sidebar, {
          children: cloneElement(menu, {
              logout,
              hasDashboard: !!dashboard,
          }),
        });
      }

      return (
          <div
              className={classnames('layout', classes.root, className)}
              {...sanitizeRestProps(props)}
          >
              <div className={classes.appFrame}>
                  {createElement(appBar, { title, open, withMenu: sidebarContent !== null, logout, ...appBarProps })}
                  <main className={classes.contentWithSidebar}>
                      {sidebarContent}
                      <div className={sidebarContent === null ? classes.contentWithoutSidebar : classes.content}>
                          {hasError
                              ? createElement(error, {
                                    error: errorMessage,
                                    errorInfo,
                                    title,
                                })
                              : children}
                      </div>
                  </main>
                  {createElement(notification)}
              </div>
          </div>
      );
  }
}

Layout.propTypes = {
  appBar: ComponentPropType,
  appBarProps: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  classes: PropTypes.object,
  className: PropTypes.string,
  customRoutes: PropTypes.array,
  dashboard: ComponentPropType,
  error: ComponentPropType,
  history: PropTypes.object.isRequired,
  logout: PropTypes.element,
  menu: ComponentPropType,
  notification: ComponentPropType,
  open: PropTypes.bool,
  sidebar: ComponentPropType,
  title: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  appBar: DefaultAppBar,
  appBarProps: {},
  error: DefaultError,
  menu: DefaultMenu,
  notification: DefaultNotification,
  sidebar: DefaultSidebar,
};

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
});

const EnhancedLayout = compose(
  connect(
      mapStateToProps,
      {} // Avoid connect passing dispatch in props
  ),
  withRouter,
  withStyles(styles)
)(Layout);

export default EnhancedLayout;
