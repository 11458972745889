import GuidField from '../../../../../components/GuidField';
import documentStatus from '../../../enums/documentStatus';
import documentProcessType from '../../../enums/documentProcessType';
import stock from '../../../../company-service/resources/stock';
import history from '../../../../../core/history';
import getDocumentUrlByDoctype from '../../../../../utils/documents/getDocumentUrlByDoctype';

const name = 'marking/marking-code-document';
const apiBasePath = '/bp/document/document/';
export default {
  name,
  apiResolve: {
    path: apiBasePath,
    idField: 'document_id',
    baseFilter: () => ({
      is_process: true,
    })
  },
  itemToString: x => `#${x.id}`,
  isResource: true,
  listRowClickAction: (id, url, item) => {
    const path = getDocumentUrlByDoctype(item.doctype, item.document_id);
    if (path === null) {
      alert('Unknown document type');
      return;
    }
    history.push(path);
  },
  listFields: [
    { name: 'id', component: GuidField },
    { name: 'doctype', component: documentProcessType.Field },
    { name: 'created', type: 'date' },
    { name: 'status', component: documentStatus.Field },
    { name: 'stock', component: stock.Field, options: { link: false, }},
  ],
};