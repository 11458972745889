import React from 'react';
import { translate as translateRA } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

// import { ranges } from '../../utils/ui/metrics';
import { setReportPeriod as setReportPeriodAction} from '../../../../redux/reports/actions';
import DateRangeInput from '../../../../components/DateRangeInput';

let lastReportPeriod = null;
const ReportRangeSelect = (props) => {
  const {
    disabled,
    setReportPeriod,
    reportPeriod,
    label,
    translate,
  } = props;

  const isAllTime = reportPeriod === null;

  const handleChangeRange = (value) => {
    setReportPeriod(value);
  };

  const handleChangeAllTime = (event) => {
    if (event.target.checked) {
      lastReportPeriod = reportPeriod;
      setReportPeriod(null);
    } else if (lastReportPeriod !== null) {
      setReportPeriod(lastReportPeriod);
    } else {
      setReportPeriod([new Date(), new Date()]);
    }
  }

  return (
    <div>
      <DateRangeInput
        disabled={isAllTime || disabled}
        value={reportPeriod || lastReportPeriod}
        onChange={handleChangeRange}
        label={label}
      />
      <FormControlLabel
        control={
          <Switch
            disabled={disabled}
            checked={isAllTime}
            onChange={handleChangeAllTime}
            name="allTime"
            color="primary"
          />
        }
        label={translate('pages.company/codes-report.allTimeSwitch')}
      />
    </div>
  );
}


const mapStateToProps = state => ({
  reportPeriod: state.reports.reportPeriod,
});

export default compose(
  connect(
    mapStateToProps,
    {
      setReportPeriod: setReportPeriodAction,
    }
  ),
  translateRA,
)(ReportRangeSelect);
