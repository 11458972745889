import React, { Component, Fragment } from "react";
import { Title } from "react-admin";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import {
  translate as translateRA,
  showNotification as showNotificationAction,
} from "react-admin";
import { withStyles, createStyles } from "@material-ui/core/styles";
import moment from "moment";
import config from "./config";
import { Button, Toolbar, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import rest from "../../../../network/rest";
import compose from "../../../../utils/compose";
import ReportRangeSelect from "./ReportRangeSelect";
import printElement from "../../../../utils/printElement";
import markingStatus from "../../../marking-service/enums/markingStatus";
import printStatus from "../../../marking-service/enums/printStatus";
import emissionType from "../../../marking-service/enums/emissionType";
import productGroupEnum from "../../enums/productGroupEnum";
import markingExtraStatus from "../../../marking-service/enums/markingExtraStatus";
import ReportDataCard from "./ReportDataCard";
import ReportViewTypeSelect from "./ReportViewTypeSelect";
import ReportDownloadMenu from "./ReportDownloadMenu";

const styles = (theme) =>
  createStyles({
    root: {
      marginTop: "1em",
    },
    welcome: {
      marginTop: "1em",
      height: 500,
      maxHeight: "80wh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    welcomeTitle: {
      width: "100%",
      maxWidth: 700,
      marginBottom: 100,
    },
    welcomeHint: {
      marginTop: 100,
    },
    welcomeToolbar: {
      width: "100%",
      maxWidth: 500,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  });

const trnKey = (key) => `pages.${config.name}.${key}`;

const translateKeys = (translate, data, getTranslateKey) => {
  for (let key in data) {
    const newKey =
      key === "-"
        ? translate(trnKey("nullKey"))
        : translate(getTranslateKey(key));
    if (newKey !== key) {
      data[newKey] = data[key];
      delete data[key];
    }
  }
};

const processItemCount = (dict, value, additionalCound = 1) => {
  if (value === null || value === undefined) {
    value = "-";
  } else {
    value = value.toString();
  }
  if (value in dict) {
    dict[value] = dict[value] + additionalCound;
  } else {
    dict[value] = additionalCound;
  }
};

const mapStateToProps = (state) => ({
  reportViewType: state.reports.reportViewType,
  reportPeriod: state.reports.reportPeriod,
});

@compose(
  translateRA,
  connect(mapStateToProps, {
    showNotification: showNotificationAction,
  }),
  withStyles(styles)
)
class CodesReportPage extends Component {
  state = {
    isCreating: false,
    data: null,
  };

  createReport = async () => {
    if (this.state.isCreating) {
      return;
    }
    const { showNotification, reportPeriod, translate } = this.props;

    this.setState({
      isCreating: true,
    });

    const resource = "marking/marking-codes";
    const filter = {};

    let periodTitle = translate(trnKey("allTimePeriod"));
    if (reportPeriod !== null) {
      const startDate = reportPeriod[0];
      const endDate = reportPeriod[1];
      filter.emission_date__gte = moment(startDate).format(
        "yyyy-MM-DDT00:00:00"
      );
      filter.emission_date__lte = moment(endDate).format("yyyy-MM-DDT23:59:59");
      periodTitle = `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
    }

    let response = null;
    let error = null;
    try {
      response = await rest.getList(resource, {
        filter,
        pagination: {
          perPage: 1,
          page: 1,
        },
        urlParams: {
          with_count: true,
          force_count: true,
        },
      });
    } catch (e) {
      error = e;
    }
    if (
      error !== null ||
      response === null ||
      typeof response.total !== "number"
    ) {
      showNotification(error || translate(trnKey("requestError")), "error");
      this.setState({
        isCreating: false,
      });
      return;
    }

    if (response.total === 0) {
      showNotification(translate(trnKey("noCodesFoundError")), "error");
      this.setState({
        isCreating: false,
      });
      return;
    }

    if (response.total > 100000) {
      showNotification(translate(trnKey("maxCodeLimitError")), "error");
      this.setState({
        isCreating: false,
      });
      return;
    }

    const allData = await rest.getList(resource, {
      filter,
      pagination: {
        perPage: 1000,
      },
    });

    const result = {
      totalCount: response.total,
      period: periodTitle,
      gtins: {},
      topGtins: [],
      statuses: {},
      statusesChart: null,
      printerStatuses: {},
      printerStatusesChart: null,
      productGroups: {},
      productGroupsChart: null,
      stocks: {},
      stocksChart: null,
      emissionTypes: {},
      emissionTypesChart: null,
      additionalStatuses: {},
      additionalStatusesChart: null,
    };

    allData.data.forEach((x) => {
      processItemCount(result.gtins, x.gtin);
      processItemCount(result.statuses, x.status);
      processItemCount(
        result.printerStatuses,
        x.printer_item ? x.printer_item.status : "-"
      );
      processItemCount(result.productGroups, x.product_group);
      processItemCount(result.stocks, x.stock);
      processItemCount(result.emissionTypes, x.emission_type);
      processItemCount(result.additionalStatuses, x.additional_status);
    });

    translateKeys(translate, result.statuses, markingStatus.getTranslateKey);
    translateKeys(
      translate,
      result.printerStatuses,
      printStatus.getTranslateKey
    );
    translateKeys(
      translate,
      result.emissionTypes,
      emissionType.getTranslateKey
    );
    translateKeys(
      translate,
      result.productGroups,
      productGroupEnum.getTranslateKey
    );
    translateKeys(
      translate,
      result.additionalStatuses,
      markingExtraStatus.getTranslateKey
    );

    this.setState({
      isCreating: false,
      data: result,
    });
  };

  resetReport = () => {
    this.setState({
      data: null,
    });
  };

  printReport = () => {
    printElement("#codes-report-print-content");
  };

  render() {
    const { translate, classes, reportViewType } = this.props;
    const { data, isCreating } = this.state;

    return (
      <Fragment>
        <Title title={`pages.${config.name}.name`} />

        {data === null && (
          <div className={classes.welcome}>
            <Typography
              className={classes.welcomeTitle}
              variant="h3"
              align="center"
              color="textPrimary"
            >
              {translate(trnKey("welcome"))}
            </Typography>
            <div className={classes.welcomeToolbar}>
              <ReportRangeSelect
                label={translate(trnKey("period"))}
                disabled={isCreating}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={this.createReport}
                disabled={isCreating}
              >
                {translate(trnKey("makeReport"))}
              </Button>
            </div>
            <Typography
              className={classes.welcomeHint}
              variant="body2"
              align="center"
              color="textSecondary"
            >
              {translate(trnKey("welcomeHint"))}
            </Typography>
          </div>
        )}
        {data !== null && (
          <div className={classes.root}>
            <Toolbar disableGutters>
              <ReportViewTypeSelect style={{ marginLeft: "auto" }} />
              <ReportDownloadMenu data={data} />
              <Button
                variant="contained"
                color="primary"
                onClick={this.resetReport}
              >
                {translate(trnKey("newReport"))}
              </Button>
            </Toolbar>

            <div id="codes-report-print-content">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={2} spacing={2}>
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <Card>
                        <CardHeader
                          title={translate(trnKey("mainCardTitle"))}
                        />
                        <List dense={true}>
                          <ListItem>
                            <ListItemText
                              style={{ flexShrink: 0, flexGrow: 1 }}
                              primary={translate(trnKey("period"))}
                            />
                            <ListItemText
                              style={{
                                flexShrink: 1,
                                flexGrow: 0,
                                textAlign: "right",
                              }}
                              secondary={data.period}
                            />
                          </ListItem>

                          <ListItem>
                            <ListItemText
                              style={{ flexShrink: 0, flexGrow: 1 }}
                              primary={translate(trnKey("totalCodes"))}
                            />
                            <ListItemText
                              style={{
                                flexShrink: 1,
                                flexGrow: 0,
                                textAlign: "right",
                              }}
                              secondary={data.totalCount.toLocaleString()}
                            />
                          </ListItem>
                        </List>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <ReportDataCard
                        viewType="topList"
                        title={translate(trnKey("topGtins"))}
                        data={data.gtins}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={8} xl={10} spacing={2}>
                  <Grid container item spacing={2}>
                    <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                      <ReportDataCard
                        viewType={reportViewType}
                        title={translate(trnKey("charts.status"))}
                        data={data.statuses}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                      <ReportDataCard
                        viewType={reportViewType}
                        title={translate(trnKey("charts.emissionType"))}
                        data={data.emissionTypes}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                      <ReportDataCard
                        viewType={reportViewType}
                        title={translate(trnKey("charts.productGroup"))}
                        data={data.productGroups}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                      <ReportDataCard
                        viewType={reportViewType}
                        title={translate(trnKey("charts.printerStatus"))}
                        data={data.printerStatuses}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} lg={6} xl={4}>
                      <ReportDataCard
                        viewType={reportViewType}
                        title={translate(trnKey("charts.additionalStatus"))}
                        data={data.additionalStatuses}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default CodesReportPage;
