import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'MANUAL': 'Ручной',
      },
    }
  }
}
