import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        ACCEPT_ALL: 'Accept all',
        REJECT_ALL: 'Reject all',
        ACCEPT_OTHER: 'Accept other',
        REJECT_OTHER: 'Reject other',
        AUTO: 'Auto',
      },
    }
  }
}
