const processes = {
  'receipt__receipt': 'receipt',
  'accept__accept': 'acceptance',
  'input__from_contract': 'input-from-contract',
  'input__from_individual': 'input-from-individual',
  'input__import': 'input-import',
  'input__remains': 'input-remains',
  'input__self_made': 'input-self-made',
  'input__fts': 'input-fts',
  'order__request': 'order',
  'print__request': 'print-request',
  'ost__description': 'remains-description',
  'remark__remark': 'remarking',
  'out__out': 'shipment',
  'out__out_cancel': 'shipment-cancel',
  // 'transfer/local': 'transfer-local',
  'transfer__local__request': 'transfer-local',
  'report__aggregation': 'aggr-report',
  'aggregation': 'aggr',
  'reaggregation': 'reaggr',
  'disaggregation': 'disaggr',
  'returns__return': 'returns',
  'report__usage': 'usage-report',
  'report__rollout': 'rollout-report',
  'report__dropout': 'dropout-report',
  'cancel__applied': '',
  'cancel__not_applied': 'cancel-codes',
  'local__custom': 'custom-local',
};

export const allProcessNames = Object.keys(processes);

export const getDocNameByProcess = (process) => {
  if (process in processes) {
    return processes[process];
  }
  return process;
}

export const getResourceNameByDoctype = (doctype) => {
  if (doctype in processes) {
    return processes[doctype];
  }
  return null;
}
