import React from "react";
import useExtendedFiltersIsActive from "../../hooks/useExtendedFiltersIsActive";
import { useTranslate, useListContext } from "../Admin";
import Button from "../Button";

const ToggleExtendedSearchButton = ({ basePath }) => {
  const translate = useTranslate();

  const listContext = useListContext();
  const isExtended = useExtendedFiltersIsActive(listContext.resource);

  const toggle = () => {
    if (isExtended) {
      listContext.setFilters({});
    } else {
      listContext.setFilters({ extended_search: true });
    }
  }

  return (
    <Button
      size="small"
      color="default"
      onClick={toggle}
    >
      {translate(isExtended ? "list.simpleSearch" : "list.extendedSearch")}
    </Button>
  );
};

export default ToggleExtendedSearchButton;
