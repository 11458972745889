import { MarkingInputIcon } from '../../../../components/ui/Icon';
import inputRemainsChild from './inputRemainsChild';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import CompanyDataInput from '../../../../components/CompanyDataInput';


const name = 'marking/input-remains';
const precessName = 'input_remains';
const apiBasePath = `/bp/processes/${precessName}/`;


export default docConfigCreator({
  name,
  icon: MarkingInputIcon,
  precessName,
  apiBasePath,
  group: 'input',
  childResource: inputRemainsChild,
  allowUpdateAction: true,
  menuConfig: {
    text: `resources.${name}.subMenuName`,
  },
  schema: {
    type: 'object',
    properties: {
      'data.trade_participant_inn': {
        type: 'string',
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
        validation: {
          format: 'inn',
        }
      },
    }
  },
  createFields: [
    'data.trade_participant_inn',
  ],
});