import { MarkingInputIcon } from '../../../../components/ui/Icon';
import inputFromIndividual from './inputFromIndividualChild';
import markingType from '../../enums/markingType';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';


const name = 'marking/input-from-individual';
const precessName = 'input_from_individual';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: MarkingInputIcon,
  precessName,
  apiBasePath,
  group: 'input',
  childResource: inputFromIndividual,
  allowUpdateAction: true,
  menuConfig: {
    text: `resources.${name}.subMenuName`,
  },
  schema: {
    type: 'object',
    properties: {
      'data.participant_inn': {
        type: 'string',
        validation: {
          format: 'inn',
        }
      },
      'data.marking_type': {
        type: 'string',
        componentInput: markingType.Input,
        componentField: markingType.Field,
        validation: {
          required: true,
        },
      },
    }
  },
  createFields: [
    'data.participant_inn',
    'data.marking_type',
  ],
});