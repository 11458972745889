import React, { Component, Fragment } from 'react';
import { Title } from 'react-admin';

import LastDocumentsWidget from '../LastDocumentsWidget';

const styles = {
    singleCol: { maxWidth: 500 },
    widgetItem: {
        marginTop: '1em',
    }
};

class Dashboard extends Component {
    state = {};

    render() {
        return (
            <Fragment>
                <Title title={'pos.title'} />
                
                <div style={styles.singleCol}>
                    <div style={styles.widgetItem}>
                        <LastDocumentsWidget />
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Dashboard;
