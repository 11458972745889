import React from 'react';
import createListPage from "./createListPage";
import createResourceTitle from "./createResourceTitle";
import createCreatePage from "./createCreatePage";
import createEditPage from "./createEditPage";
import createShowPage from "./createShowPage";
import createReferenceField from "./createReferenceField";
import createReferenceArrayField from "./createReferenceArrayField";
import createReferenceInput from "./createReferenceInput";
import createReferenceArrayInput from "./createReferenceArrayInput";
import deepmerge from 'deepmerge';
import createReferenceManyField from './createReferenceManyField';

const sortActions = ({ order: orderA = 10000 }, { order: orderB = 10001 }) => orderA - orderB;

const baseConfig = {
  editActions: [],
  showActions: [],
  listRowActions: [],
}

export default (...configuration) => {
  const config = deepmerge.all([baseConfig, ...configuration]);
  const data = deepmerge.all([baseConfig, config]);

  if (data.itemActions && data.itemActions.length !== 0) {
    // Combine item actions with item action by page
    data.itemActions.forEach(x => {
      data.listRowActions.push(x);
      data.editActions.push(x);
      data.showActions.push(x);
    })
  }
  if (data.listRowActions.length > 0) {
    data.listRowActions = data.listRowActions.sort(sortActions);
  }
  if (data.editActions.length > 0) {
    data.editActions = data.editActions.sort(sortActions);
  }
  if (data.showActions.length > 0) {
    data.showActions = data.showActions.sort(sortActions);
  }

  const ResourceList = createListPage(data);

  const ResourceTitle = createResourceTitle(data)

  const ResourceCreate = createCreatePage(data);

  const ResourceEdit = createEditPage({
      ...data,
      title: (<ResourceTitle />),
  });

  const ResourceShow = createShowPage({
      ...data,
      title: (<ResourceTitle />),
  });

  const ResourceReferenceField = createReferenceField(data);

  ResourceReferenceField.defaultProps = {
    addLabel: true
  };

  const ResourceReferenceArrayField = createReferenceArrayField(data);

  ResourceReferenceArrayField.defaultProps = {
    addLabel: true
  };

  const ResourceReferenceInput = createReferenceInput(data);

  const ResourceReferenceArrayInput = createReferenceArrayInput(data);

  const ResourceReferenceManyField = createReferenceManyField(data);

  const result = {
    ...data,
    name: data.name,
    Icon: data.icon,
    List: ResourceList,
    Title: ResourceTitle,
    Create: ResourceCreate,
    Edit: ResourceEdit,
    Show: ResourceShow,
    Field: ResourceReferenceField,
    ArrayField: ResourceReferenceArrayField,
    Input: ResourceReferenceInput,
    ArrayInput: ResourceReferenceArrayInput,
    ManyField: ResourceReferenceManyField,

    locales:  data.locales,

    restProviderResolve: null,
    showInMenu: null,
    resourceConfig: null,
    config: config,
  };

  if (data.apiResolve) {
    result.restProviderResolve = {
      ...data.apiResolve,
      name: data.name,
      apiService: data.apiResolve.serviceName || null,
      basePath: data.apiResolve.path || null,
      mapping: data.apiResolve.mapping || null,
      schema: data.schema || null,
    }
  }

  if (data.showInMenu) {
    result.menuConfig = {
      icon: data.icon,
      ...result.menuConfig,
    }
  }

  if (ResourceList || ResourceEdit || ResourceCreate || ResourceShow || data.isResource) {
    result.resourceConfig = {
      name: data.name,
      list: ResourceList,
      edit: ResourceEdit,
      create: ResourceCreate,
      show: ResourceShow,
      icon: data.icon,
    }
  }

  return result;
}
