import React from 'react';

const ShowConditionField = (props) => {
  const {
    condition,
    Component,
    nextProps,
    ...restProps
  } = props;

  const shown = condition(restProps.record);
  if (!shown) {
    return null;
  }

  return (
    <Component
      {...nextProps}
      {...restProps}
    />
  );
}

ShowConditionField.defaultProps = {
  addLabel: true
};

export default ShowConditionField;
