import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'AVAILABLE': 'Available',
        'NOT_AVAILABLE': 'Not available',
        'RESERVED': 'Reserved',
      },
    }
  }
}
