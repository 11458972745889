import React from "react";
import { SvgIcon } from "@material-ui/core";

export default (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M17.7778 20.6667V19.1111H16.2222H14.8889V17.7778H13.3333V16.2222H12V14.8889H13.3333V13.3333H12V12V10.6667H10.6667V12H9.11111V13.3333H7.77778V14.8889H9.11111H10.6667V16.2222H9.11111V17.7778H10.6667H12V19.1111H13.3333V20.6667H12V19.1111H10.6667V20.6667H9.11111V19.1111V17.7778H7.77778V16.2222H6.22222V14.8889H4.88889H3.33333V13.3333H4.88889V12H6.22222V10.6667H4.88889V9.11111V7.77778H3.33333V6.22222V4.88889H4.88889H6.22222V3.33333V2H4.88889V3.33333H3.33333V2H2V3.33333V4.88889V6.22222V7.77778V9.11111V10.6667V12V13.3333V14.8889V16.2222V17.7778V19.1111V20.6667V22H3.33333H4.88889H6.22222H7.77778H9.11111H10.6667H12H13.3333H14.8889H16.2222H17.7778H19.1111H20.6667H22V20.6667H20.6667V19.1111H19.1111V20.6667H17.7778ZM7.77778 20.6667H6.22222H4.88889V19.1111H3.33333V17.7778H4.88889H6.22222V19.1111H7.77778V20.6667Z" fill="currentColor"/>
    <path d="M19.1116 16.2222H17.7783V14.8889H16.2228V13.3333H14.8894V14.8889H13.3339V16.2222H14.8894V17.7778H16.2228H17.7783V19.1111H19.1116V17.7778H20.6672H22.0005V16.2222H20.6672V14.8889H19.1116V16.2222Z" fill="currentColor"/>
    <path d="M19.111 13.3333H17.7777V14.8889H19.111V13.3333Z" fill="currentColor"/>
    <path d="M21.9992 13.3333H20.6659V14.8889H21.9992V13.3333Z" fill="currentColor"/>
    <path d="M14.8894 12H13.3339V13.3333H14.8894V12Z" fill="currentColor"/>
    <path d="M17.7781 12H16.2225V13.3333H17.7781V12Z" fill="currentColor"/>
    <path d="M20.6663 12H19.1107V13.3333H20.6663V12Z" fill="currentColor"/>
    <path d="M19.111 10.6667H17.7777V12H19.111V10.6667Z" fill="currentColor"/>
    <path d="M9.11104 9.1111H7.77771V10.6667V12H9.11104V10.6667V9.1111Z" fill="currentColor"/>
    <path d="M20.6663 10.6667V12H21.9996V10.6667V9.1111H20.6663H19.1107V10.6667H20.6663Z" fill="currentColor"/>
    <path d="M6.22237 9.11112H7.77793V7.77778V6.22223H6.22237H4.88904V7.77778H6.22237V9.11112Z" fill="currentColor"/>
    <path d="M10.6663 7.77779H9.11072V9.11112H10.6663V7.77779Z" fill="currentColor"/>
    <path d="M10.6666 6.22223V4.8889H9.11104H7.77771V6.22223H9.11104H10.6666Z" fill="currentColor"/>
    <path d="M13.3335 6.22222H12.0002H10.6669V7.77778H12.0002V9.11111H13.3335V10.6667H14.8891V9.11111V7.77778V6.22222V4.88889H16.2224V3.33333V2H14.8891V3.33333H13.3335V4.88889V6.22222Z" fill="currentColor"/>
    <path d="M17.7781 4.8889H16.2225V6.22223H17.7781V4.8889Z" fill="currentColor"/>
    <path d="M12.0002 3.33334H10.6669V4.8889H12.0002V3.33334Z" fill="currentColor"/>
    <path d="M22.0003 4.88889V3.33333H20.667V2H19.1114V3.33333V4.88889V6.22222H17.7781V7.77778H16.2225V9.11111V10.6667H17.7781V9.11111H19.1114V7.77778H20.667H22.0003V6.22222H20.667V4.88889H22.0003Z" fill="currentColor"/>
    <path d="M10.6663 2H9.11072V3.33333H10.6663V2Z" fill="currentColor"/>
    <path d="M13.3332 2H11.9999V3.33333H13.3332V2Z" fill="currentColor"/>
  </SvgIcon>
);
