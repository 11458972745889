import React from 'react';
import { translate } from  'react-admin';
import Button from '@material-ui/core/Button';

import TransitionLink from '../../../../components/TransitionLink';
import { dictionaryToPath } from '../../../../utils';
import resourceConfig from './config';

const OpenButton = ({ params, translate, type, ...props }) => {
  const urlPatams = {
    ...params
  }

  let disabled = false;
  let label = `resources.${resourceConfig.name}.openAction`;

  if (type === 'pdf') {
    label = `resources.${resourceConfig.name}.openActionPdf`;
  } else if (type === 'csv') {
    label = `resources.${resourceConfig.name}.openActionCsv`;
  }


  if (props.record && type) {
    if (type === 'pdf' && props.record.pdfPath) {
      urlPatams.path = props.record.pdfPath;
    } else if (type === 'csv' && props.record.csvPath) {
      urlPatams.path = props.record.csvPath;
    } else {
      disabled = true;
    }
    
  }
  return (
    <Button
      size={'small'}
      color="default"
      {...props}
      component={TransitionLink}
      disabled={disabled}
      transitionTo={{
        params: {
          [resourceConfig.urlParamName]: dictionaryToPath(urlPatams)
        }
      }}
    >
      {translate(label)}
    </Button>
)
};


export default translate(OpenButton);
