import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RECEIPT: 'Кассовый чек',
        SALES_RECEIPT: 'Товарный чек',
        CONSIGNMENT_NOTE: 'Товарная накладная',
        UTD: 'Универсальный передаточный документ',
        OTHER: 'Прочее (с указанием наименования вручную)',
      },
    }
  }
}
