import React from 'react';
import ResourceIcon from '@material-ui/icons/Description';
import { ArrayInput, SimpleFormIterator, TextInput } from '../../../../components/Admin';
import customLocalChild from './customLocalChild';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';


const name = 'marking/custom-local';
const precessName = 'local_custom';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: ResourceIcon,
  precessName,
  apiBasePath,
  childResource: customLocalChild,
  showExternalStatus: false,
  showExternalId: false,
  allowShowLogsAction: false,
  allowRenewAction: true,
  apiResolve: {
    mapping: {
      outputItemAfter: (output) => {
        if (output.data && output.data._attributes) {
          const _attributes = output.data._attributes;
          delete output.data._attributes;
          _attributes.forEach(({ key, value }) => {
            output.data[key] = value;
          });
        }
        return output;
      },
      inputItemAfter: (input) => {
        const _attributes = [];
        if (input.data) {
          for (let key in input.data) {
            _attributes.push({
              key,
              value: input.data[key],
            });
          }
          delete input.data;
        }
        input.data = {
          _attributes
        }
        return input;
      },
    }
  },
  schema: {
    type: 'object',
    properties: {
      'data._attributes': {
        type: 'object',
        options: {
          fullWidth: true,
        },
        componentInput: (props) => (
          <ArrayInput {...props} fullWidth source="data._attributes">
              <SimpleFormIterator>
                <TextInput source="key" />
                <TextInput source="value" />
              </SimpleFormIterator>
          </ArrayInput>
        ),
      }
    }
  },
  createFields: [
    'data._attributes',
  ],
});
