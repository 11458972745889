import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RETAIL: 'Retail',
        EEC_EXPORT: 'Export to the EAEU countries',
        BEYOND_EEC_EXPORT: 'Export outside the EAEU countries',
        RETURN: 'Return to natural person',
        BY_SAMPLES: 'Sales by samples',
        DISTANCE: 'Distance selling',
        DAMAGE_LOSS: 'Loss or damage',
        DESTRUCTION: 'Destruction',
        CONFISCATION: 'Confiscation',
        LIQUIDATION: 'Liquidation of an enterprise',
        ENTERPRISE_USE: 'Use for own needs of the enterprise',
      },
    }
  }
}
