import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        SHIPPED: 'Отгружен',
        WAIT_SHIPMENT: 'Ожидает отгрузку',
        WAIT_TRANSFER_TO_OWNER: 'Ожидает передачу собственнику',
        REMARK_RETIRED: 'Перемаркирован',
        RETIRED_CANCELLATION: 'Списан',
        WAIT_REMARK: 'Товар ожидает перемаркировку',
        CANCELLATION_APPLIED: 'Списан после нанесения на товар',
        DONATION: 'Безвозмездная передача товара',
        STATE_ENTERPRISE: 'Приобретение гос.предприятием',
        NO_RETAIL_USE: 'Использование для собственных нужд покупателем',
        BEYOND_EEC_EXPORT: 'Экспорт за пределы стран ЕАЭС',
        REMOTE_SALE: 'Продажа по образцам, дистанционный способ продажи',
        EEC_EXPORT: 'Экспорт в страны ЕАЭС',
        RETURN: 'Возврат физическому лицу',
        DAMAGE_LOSS: 'Утрата или повреждение',
        DESTRUCTION: 'Уничтожение',
        CONFISCATION: 'Конфискация',
        LIQUIDATION: 'Ликвидация предприятия',
        FTS_RESPOND_WAITING: 'FTS ожидание',
      },
    }
  }
}
