import { getDocNameByProcess, allProcessNames } from '../../../../utils/documents/mapDocProcess';
import textCaseHelper from '../../../../utils/textCaseHelper';

export default {
  name: 'marking/enums/documentProcessType',
  getTranslateKey: (value) => `resources.marking/${getDocNameByProcess(value)}.name`,
  enumTextCase: textCaseHelper.AS_IS,
  enums: allProcessNames,
};

