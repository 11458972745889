export default (condition, permissions = []) => {
  // return true;
  let allow = false;
  if (typeof condition === 'string') {
    allow = permissions.indexOf(condition) !== -1;
    if (!allow) {
      allow = permissions.indexOf('*') !== -1;
    }
  }
  // TODO: add queries
  return allow;
}