import documentDirectionType from '../../services/marking-service/enums/documentDirectionType';
import documentExternalStatus from '../../services/marking-service/enums/documentExternalStatus';
import documentStatus from '../../services/marking-service/enums/documentStatus';
import emissionType from '../../services/marking-service/enums/emissionType';
import markingExtraStatus from '../../services/marking-service/enums/markingExtraStatus';
import markingStatus from '../../services/marking-service/enums/markingStatus';
import printStatus from '../../services/marking-service/enums/printStatus';

const values = {
  status: markingStatus.enumsExternal,
  additional_status: markingExtraStatus.enumsExternal,
  'printer_item.status': printStatus.enumsExternal,
  emission_type: emissionType.enumsExternal,
  'documents.status': documentStatus.enumsExternal,
  'documents.direction': documentDirectionType.enumsExternal,
  'documents.external_status': documentExternalStatus.enumsExternal,
}

export default values;
