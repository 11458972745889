import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'LP_INTRODUCE_GOODS_AUTO': 'Production RF',
        'LP_GOODS_IMPORT_AUTO': 'Import',
      },
    }
  }
}