import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'SELF_MADE': 'Самостоятельно',
        'CEM': 'ЦЭМ',
        'CM': 'Контрактное производство',
        'CL': 'Логистический склад',
        'CA': 'Комиссионная площадка',
      },
    }
  }
}