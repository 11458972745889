import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Товар - отчет о выбытии |||| Товары - отчет о выбытии',
      fields: {
        'data.code': 'Полный код маркировки',
      }
    }
  }
}
