import qs from 'qs';
import appCore from '../../appCore';
import { fillUrl } from '../url';

export default ({
  resourceName,
  pageType, // LIST | CREATE | EDIT | SHOW
  id = null,
  params = null,
  tabPath = null,
}) => {
  let subPath = '';
  if (pageType === 'CREATE') {
    subPath = '/create';
  } else if (pageType === 'EDIT') {
    subPath = `/${id}`
    if (tabPath) {
      subPath = `${subPath}/${tabPath}`;
    }
  } else if (pageType === 'SHOW') {
    subPath = `/${id}/show`
  }

  let query = '';
  if (params !== null) {
    if (pageType === 'LIST' && params.filter) {
      // FOR REACT ADMIN
      params.filter = JSON.stringify(params.filter);
    }
    query = `?${qs.stringify(params)}`;
  }


  let servicePath = '/';
  let resource = appCore.resources.find(x => x.name === resourceName) || null;
  if (resource !== null) {
    if (resource.serviceName === 'marking') {
      servicePath = fillUrl('/c/{{companyId}}/pg/{{productGroup}}/st/{{stockId}}/s/marking/');
    } else if (resource.serviceName === 'company') {
      servicePath = fillUrl('/c/{{companyId}}/s/company/')
    }
  }


  return [servicePath, resourceName, subPath, query].join('');
}