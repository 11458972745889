import ResourceIcon from '@material-ui/icons/Input';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';

const name = 'marking/input-from-individual-child';
const parentResourceName = 'marking/input-from-individual';
const apiBasePath = '/bp/processes/input_from_individual/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  schema: {
    type: 'object',
    properties: {
      'data.uit': {
        type: 'string',
        validation: {
          required: true,
        }
      },
    }
  },
  listFields: [
    'data.uit',
  ],
});