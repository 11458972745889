import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Фоновая задача |||| Фоновые задачи',
      actions: {
        execute: {
          name: 'Запустить',
          successMessage: 'Запрос на запуск успешно отправлен',
          failureMessage: 'При запросе на запуск произошла ошибка',
        },
      },
      fields: {
        name: 'Название',
        task_name: 'Задача',
        enabled: 'Включено',
        data: 'Параметры задачи (JSON)',
        crontab: 'Периодичность (JSON Crontab)',
        last_run_at: 'Последний запуск',
        total_run_count: 'Всего запусков',
        one_off: 'Одиночный запуск',
        'meta.product_group': 'Товарная группа',
      },
    }
  }
}
