import { MarkingCodeIcon } from '../../../../components/ui/Icon';


const name = 'company/codes-report';
export default {
  name,
  path: name,
  icon: MarkingCodeIcon,

  menuConfig: {
    name: name,
    text: `pages.${name}.name`,
    icon: MarkingCodeIcon,
    group: 'reports',
  }
};
