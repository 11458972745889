export default {
  name: 'marking/enums/remarkDocumentType',
  enums: [
    'RECEIPT',
    'SALES_RECEIPT',
    'CONSIGNMENT_NOTE',
    'UTD',
    'OTHER',
  ],
};

