import Icon from '@material-ui/icons/Apps';
import getResourceUrl from "../admin/getResourceUrl";

export default ({ order = 20, name = 'documents.actions.showCodes' } = {}) => ({
  name,
  order,
  Icon,
  isDisabled: ({ record }) => {
    return record.status === 'draft';
  },
  getUrl: ({ record }) => record && getResourceUrl({
    resourceName: 'marking/marking-codes',
    pageType: 'LIST',
    params: {
      filter: {
        documents: record.id
      }
    }
  })
})
