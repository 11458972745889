import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Task |||| Tasks',
      actions: {
        showLogs: 'Show logs',
        cancel: {
          name: 'Cancel',
          confirmMessage: 'Cancellation of the task does not guarantee the completion of the process. Proceed?',
          requestCanceledMessage: 'Request canceled',
          successMessage: 'The request is processed successfully',
          failureMessage: 'The request was processed with an error',
        },
      },
      fields: {
        request: 'Request data',
        response: 'Response data',
        document_process: 'Document type',
        document_id: 'Document ID',
        status: 'Status',
      }
    }
  }
}
