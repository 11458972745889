import ResourceIcon from "@material-ui/icons/CheckCircle";
import GuidField from "../../../../components/GuidField";
import JsonField from "../../../../components/JsonField";
import WorkflowStatusField from "./WorkflowStatusField";

const runStructureToFlat = (
  runStructure = [],
  workflowStructure = [],
  taskInfo = {}
) => {
  const result = [];
  const loop = (item, parent = null) => {
    let id = null;
    let child = null;
    if (typeof item[0] === "string") {
      id = item[0];
    } else if (Array.isArray(item[0])) {
      child = item[0];
    }
    if (typeof item[1] === "string") {
      id = item[1];
    } else if (Array.isArray(item[1])) {
      child = item[1];
    }
    if (id) {
      let info = taskInfo[id] || {
        ready: null,
        status: null,
        date_done: null,
      };
      if (info.date_done === 'None') {
        info.date_done = null;
      }
      result.push({
        id,
        name: null,
        fullName: null,
        parent,
        ...info,
      });
    }
    if (child) {
      loop(child, id || parent);
    }
  };
  loop(runStructure);

  result.forEach((x, index) => {
    if (
      workflowStructure[index] &&
      typeof workflowStructure[index][0] === "string"
    ) {
      x.fullName = workflowStructure[index][0];
      x.name = x.fullName.split(".").slice(-1)[0] || x.fullName;
    }
  });

  return result;
};

const name = "report/workflow";
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: "/workflow-service/task/workflow/",
    disableSorting: true,
    mapping: {
      inputItem: (item) => {
        const { run_structure, workflow_structure, task_info } = item;
        const structure = runStructureToFlat(
          run_structure,
          workflow_structure,
          task_info
        );
        let base_operator_name = null;
        if (structure[0] && structure[0].name) {
          base_operator_name = structure[0].name;
        }
        return {
          ...item,
          base_operator_name,
          structure,
        };
      },
    },
  },
  itemToString: (x) => `#${x.id}`,
  schema: {
    type: "object",
    properties: {},
  },
  allowList: true,
  allowCreate: false,
  allowEdit: false,
  allowShow: false,
  allowDelete: false,
  showInMenu: true,
  group: "reports",
  listExpandFields: [
    {
      name: "structure",
      component: JsonField,
      options: { fullWidth: true },
    },
    { name: "task_info", component: JsonField, options: { fullWidth: true } },
    {
      name: "run_structure",
      component: JsonField,
      options: { fullWidth: true },
    },
    {
      name: "workflow_structure",
      component: JsonField,
      options: { fullWidth: true },
    },
  ],
  listFilters: [],
  listFields: [
    { name: "id", component: GuidField, sortable: false },
    { name: "created_at", type: "dateTime", sortable: false },
    { name: "base_operator_name", type: "string", sortable: false },
    { name: "structure", component: WorkflowStatusField },
  ],
};
