import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Сотрудник |||| Сотрудник',
      tabs: {
        info: 'Информация',
        roles: 'Роли',
      },
      fields: {
        user: 'Пользователь',
        date_joined: 'Дата добавления',
        expiration_date: 'Активен до',
        roles: 'Роли',
      },
    }
  }
}