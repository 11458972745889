import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Product Group |||| Product Groups',
      fields: {
        product_group: 'Product group',
        is_enabled: 'Enabled',
      }
    }
  }
}
