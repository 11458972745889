import React from "react";
import { CreateButton } from "../Admin";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import ResourceActionButton from "../ResourceActionButton";
import ToggleExtendedSearchButton from "../ToggleExtendedSearchButton";
import exportDialog from "../../services/common-service/modules/exportDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "auto",
  },
}));

const ResourceListToolbar = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  translate,
  children,
  actions,
  allowCreate,
  allowExport,
  allowExtended,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Toolbar
      disableGutters
      classes={{
        root: classes.root,
      }}
    >
      {bulkActions &&
        React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {children}
      {actions &&
        actions.map((action) =>
          action.component ? (
            <action.component {...action.props} />
          ) : (
            <ResourceActionButton action={action} />
          )
        )}
      {allowExtended && <ToggleExtendedSearchButton basePath={basePath} />}
      {allowExport && (
        <exportDialog.OpenButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          exporter={exporter}
          color="default"
        />
      )}
      {allowCreate && (
        <CreateButton basePath={basePath} variant="contained" color="primary" />
      )}
    </Toolbar>
  );
};

export default ResourceListToolbar;
