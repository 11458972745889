import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Перемаркировка |||| Перемаркировка',
      fields: {
        'data.participant_inn': 'ИНН',
        'data.remarking_date': 'Дата перемаркировки',
        'data.remarking_cause': 'Причина перемаркировки',
      }
    }
  }
}
