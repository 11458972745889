import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Product |||| Products',
      actions: {
        loadProducts: {
          name: 'Load products',
          successMessage: 'Request sent',
          failureMessage: 'Request failed',
        },
        showCodes: {
          name: 'Show codes',
        },
      },
      fields: {
        name: 'Name',
        gtin: 'GTIN',
        'data.brand': 'Brand',
        'data.fullName': 'Full name',
        tn_ved_code: 'TN VED code',
        tn_ved_code_10: 'TN VED code 10',
        'data.inn': 'INN',
        'data': 'Status',
      },
      statusMessages: {
        notSync: 'Product is not synchronized with GISMT',
        isPublished: 'Product card published',
        notPublished: 'Product card in the "%{status}" status',
        isReadyForMarking: 'product is ready for labeling',
        notReadyForMarking: 'Product is not ready for labeling',
        isSigned: 'Product card signed',
        notSigned: 'Product card not signed',
        isTurnover: 'Product is ready for turnover',
        notTurnover: 'Product is nor ready for turnover',
      },
    }
  }
}
