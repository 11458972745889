import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import AceEditor from "react-ace";
import 'ace-builds/webpack-resolver';
import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/theme-monokai";
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import { addField, FieldTitle } from '../Admin';


const styles = (theme) => {
  const basePadding = theme.spacing(2);
  return {
    editor: {
      height: '300px !important',
      width: '100% !important',
      marginTop: basePadding,
      fontFamily: 'monospace',
    },
    editorFullSize: {
      height: '500px !important',
      width: `calc(100% + ${basePadding * 2}px) !important`,
      margin: `0 -${basePadding}px -${basePadding}px`,
      fontFamily: 'monospace',
    },
  };
};

class CodeInput extends Component {
  onChange = (code) => {
    this.props.input.onChange({
      target: {
        value: code
      }
    })
  }

  render() {
    const {
      theme,
      classes,
      codeLanguage = null,
      fullSize = false,
      label,
      source,
      resource,
      isRequired,
    } = this.props;

    const aceProps = {
      mode: codeLanguage,
      theme: theme === 'dark' ? 'monokai' : 'xcode',
      tabSize: 2,
      showPrintMargin: false,
      value: this.props.input.value,
      onChange: this.onChange,
      editorProps: { $blockScrolling: true },
    };

    if (fullSize) {
      return (
        <AceEditor
          {...aceProps}
          className={classes.editorFullSize}
        />
      )
    }


    return (
      <FormControl {...this.props}>
        <InputLabel variant="standard" focused shrink>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
        <InputBase
          margin="dense"
          inputComponent={AceEditor}
          inputProps={{
            ...aceProps,
            className: classes.editor,
          }}
        />
        {/* <FormHelperText>We'll never share your email.</FormHelperText> */}
      </FormControl>
    );
  }
}

const mapStateToProps = state => ({
  theme: state.app.theme,
});

const CodeInputConnected = compose(
  connect(mapStateToProps),
  withStyles(styles),
)(CodeInput);

CodeInputConnected.defaultProps = {
  addLabel: true
};


export default addField(CodeInputConnected);
