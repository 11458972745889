import React from 'react';
import { Tooltip } from "@material-ui/core";
import textCaseHelper from "../../../../utils/textCaseHelper";
import { Warning } from '@material-ui/icons';

export default {
  name: 'marking/enums/documentStatus',
  externalTextCase: textCaseHelper.SNAKE_CASE,
  enums: [
    'DRAFT',
    'REQUEST_CREATED',
    'COMPLETED',
    // 'PROCESSING',
    // 'CHECKING_DOCUMENT_STATUS',
    'FAILED',
    'READY',
    'WAIT_ACCEPTANCE',
    'EXECUTE_CANCELED',
  ],
  FieldTextComponent: ({ value, text, record, source }) => {
    let errorMessage = null;
    if (record.last_error_messages && record.last_error_messages.length > 0) {
      errorMessage = record.last_error_messages.join('; ');
    }

    if (errorMessage) {
      return (
        <Tooltip title={errorMessage} placement="right" arrow interactive>
          <span style={{
            textDecoration: 'underline',
            cursor: 'help',
            display: 'inline-flex',
            alignItems: 'center',
          }}>
            {text}
            <Warning style={{
              paddingLeft: '0.2em',
              color: '#e57373',
            }} />
          </span>
        </Tooltip>
      )
    }


    return text
  }
};
