import html2canvas from 'html2canvas';
import printJS from 'print-js';

export default (elem) => {
  if (typeof elem === 'string') {
    elem = document.querySelector(elem);
  }

  html2canvas(elem).then(canvas => {
    const dataUrl = canvas.toDataURL('image/png');
    printJS(dataUrl, 'image')
});
}
