import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        UNIT: 'Item unit',
        LEVEL1: '1st level packaging',
        LEVEL2: '2nd level packaging',
        LEVEL3: '3rd level packaging',
        LEVEL4: '4th level packaging',
        LEVEL5: 'Level 5 packaging',
        BUNDLE: 'Bundle',
        АТК: 'Aggregated customs code',
        SET: 'Set',
      },
    }
  }
}
