import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  ResponsiveContainer,
  Tooltip,
  PieChart,
  Pie,
} from 'recharts';
import { Toolbar } from '@material-ui/core';


const styles = (theme) => ({
  root: {
    flex: 1,
  },
  listItemIcon: {
    minWidth: 40,
  }
})

const renderCustomizedLabel = (props) => {
  const { cx, x, y, name, value } = props;

  return (
    <text x={x} y={y} fill={"#0000008a"} textAnchor={x > cx ? 'start' : 'end'} font-size="13" dominantBaseline="central">
      {name} ({value})
    </text>
  );
};

class ChartPieCard extends Component {
  render() {
    const {
      classes,
      title,
      chartItems = [],
      actions = [],
    } = this.props;

    return (
      <Card className={classes.root}>
        <CardHeader
          title={title}
          action={
            <Toolbar>
              {(actions.length > 0 && actions)}
            </Toolbar>
          }
        />
        <ResponsiveContainer height={300}>
          <PieChart
            margin={{
              top: 12, right: 24, left: 12, bottom: 12,
            }}
          >
            <Pie
              dataKey="value"
              nameKey="name"
              // isAnimationActive={false}
              data={chartItems}
              cx="50%"
              cy="50%"
              outerRadius={80}
              paddingAngle={5}
              innerRadius={35}
              fill="#ffd255"
              label={renderCustomizedLabel}
            >
            </Pie>

            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Card>
    );
  }
}

export default withStyles(styles)(ChartPieCard);
