import React, { PureComponent } from 'react';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';


const styles = {
  wrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  buttonOnLoading: {
    opacity: '50%',
    pointerEvents: 'none',
  },
  loader: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  }
}

@withStyles(styles)
class Button extends PureComponent {
  render() {
    const {
      loading,
      classes,
      ...buttonProps
    } = this.props;

    return (
      <div className={classes.wrapper}>
        <MuiButton className={loading && classes.buttonOnLoading} {...buttonProps} loading={loading} />
        {loading && (
          <CircularProgress className={classes.loader} size={24} />
        )}
      </div>
    );
  }
}

export default Button;
