import AggrIcon from '@material-ui/icons/Archive';
import SettingsIcon from '@material-ui/icons/More';
import CancelIcon from '@material-ui/icons/Delete';

import Icon from './Icon';
import locales from './locales';
import { MarkingInputIcon } from '../../components/ui/Icon';

import product from './resources/product';
import markingCodes from './resources/markingCodes';
import order from './resources/order';
import orderChild from './resources/order/orderChild';
import printRequest from './resources/printRequest';
import printRequestChild from './resources/printRequest/printRequestChild';
import inputRemains from './resources/inputRemains';
import inputRemainsChild from './resources/inputRemains/inputRemainsChild';
import inputImport from './resources/inputImport';
import inputImportChild from './resources/inputImport/inputImportChild';
import inputSelfMade from './resources/inputSelfMade';
import inputSelfMadeChild from './resources/inputSelfMade/inputSelfMadeChild';
import inputFromContract from './resources/inputFromContract';
import inputFromContractChild from './resources/inputFromContract/inputFromContractChild';
import inputFromIndividual from './resources/inputFromIndividual';
import inputFromIndividualChild from './resources/inputFromIndividual/inputFromIndividualChild';

import itemCreateMethodType from './enums/itemCreateMethodType';
import releaseMethodType from './enums/releaseMethodType';
import serialNumberType from './enums/serialNumberType';
import markingType from './enums/markingType';
import productionType from './enums/productionType';
import certDocumentType from './enums/certDocumentType';
import documentStatus from './enums/documentStatus';
import genderType from './enums/genderType';
import remainsReleaseMethodType from './enums/remainsReleaseMethodType';
import remainsDescription from './resources/remainsDescription';
import remainsDescriptionChild from './resources/remainsDescription/remainsDescriptionChild';
import turnoverType from './enums/turnoverType';
import codeTemplateId from './enums/codeTemplateId';
import shipment from './resources/shipment';
import shipmentOutChild from './resources/shipment/shipmentOutChild';
import acceptance from './resources/acceptance';
import acceptanceChild from './resources/acceptance/acceptanceChild';
import shipmentCancel from './resources/shipmentCancel';
import remarkingCause from './enums/remarkingCause';
import remarking from './resources/remarking';
import remarkingChild from './resources/remarking/remarkingChild';
import transferLocal from './resources/transferLocal';
import transferLocalChild from './resources/transferLocal/transferLocalChild';
import importDocument from './modules/importDocument';
import createDocWithGenerator from './modules/createDocWithGenerator';
import printDialog from './modules/printDialog';
import emissionType from './enums/emissionType';
import codeExternalStatus from './enums/codeExternalStatus';
import receipt from './resources/receipt';
import receiptChild from './resources/receipt/receiptChild';
import printStatus from './enums/printStatus';
import markingStatus from './enums/markingStatus';
import documentDirectionType from './enums/documentDirectionType';

import dashboardPage from './pages/dashboardPage';
import setMenuOrder from '../../utils/setMenuOrder';
import eanInfo from './resources/eanInfo';
import aggr from './resources/aggr';
import reaggrType from './enums/reaggrType';
import aggrReaggr from './resources/aggrReaggr';
import aggrDisaggr from './resources/aggrDisaggr';
import markingCodeHistory from './resources/markingCodes/markingCodeHistory';
import markingCodeDocument from './resources/markingCodes/markingCodeDocument';
import markingExtraStatus from './enums/markingExtraStatus';
import task from './resources/task';
import logs from './resources/logs';
import aggrReaggrChild from './resources/aggrReaggr/aggrReaggrChild';
import aggrDisaggrChild from './resources/aggrDisaggr/aggrDisaggrChild';
import returns from './resources/returns';
import returnsChild from './resources/returns/returnsChild';
import returnsType from './enums/returnsType';
import returnsDocumentType from './enums/returnsDocumentType';
import documentExternalStatus from './enums/documentExternalStatus';
import printSelectorType from './enums/printSelectorType';
import inputFTS from './resources/inputFTS';
import inputFTSChild from './resources/inputFTS/inputFTSChild';
import usageReport from './resources/usageReport';
import usageReportChild from './resources/usageReport/usageReportChild';
import dropoutReport from './resources/dropoutReport';
import dropoutReportChild from './resources/dropoutReport/dropoutReportChild';
// import rolloutReport from './resources/rolloutReport';
// import rolloutReportChild from './resources/rolloutReport/rolloutReportChild';
import usageReportType from './enums/usageReportType';
import usageType from './enums/usageType';
import rolloutDocumentFormat from './enums/rolloutDocumentFormat';
import rolloutProducedProductionType from './enums/rolloutProducedProductionType';
import cisType from './enums/cisType';
import customLocal from './resources/customLocal';
import customLocalChild from './resources/customLocal/customLocalChild';
import document from './resources/document';
import cancellationReason from './enums/cancellationReason';
import cancelCodes from './resources/cancelCodes';
import cancelCodesChild from './resources/cancelCodes/cancelCodesChild';
import receiptActionType from './enums/receiptActionType';
import receiptDocumentType from './enums/receiptDocumentType';
import packType from './enums/packType';
import remarkDocumentType from './enums/remarkDocumentType';
import acceptShippment from './modules/acceptShippment';
import acceptShipmentType from './enums/acceptShipmentType';
import dropoutReasonType from './enums/dropoutReasonType';
import orderPaymentType from './enums/orderPaymentType';
import documentHistory from './resources/documentHistory';
import documentChild from './resources/documentChild';
import aggrReport from './resources/aggrReport';
import aggrReportChild from './resources/aggrReport/aggrReportChild';

const modules = [
  importDocument,
  createDocWithGenerator,
  printDialog,
  acceptShippment,
]

const resources = [
  setMenuOrder(product, 10),
  setMenuOrder(markingCodes, 20),
  markingCodeHistory,
  markingCodeDocument,
  setMenuOrder(order, 30),
  orderChild,
  setMenuOrder(usageReport, 35),
  usageReportChild,
  setMenuOrder(dropoutReport, 36),
  dropoutReportChild,
  // setMenuOrder(rolloutReport, 36),
  // rolloutReportChild,
  setMenuOrder(printRequest, 40),
  printRequestChild,
  inputImport,
  inputImportChild,
  inputFTS,
  inputFTSChild,
  inputSelfMade,
  inputSelfMadeChild,
  inputFromContract,
  inputFromContractChild,
  inputFromIndividual,
  inputFromIndividualChild,
  inputRemains,
  inputRemainsChild,
  aggrReport,
  aggrReportChild,
  aggr,
  aggrReaggr,
  aggrReaggrChild,
  aggrDisaggr,
  aggrDisaggrChild,
  setMenuOrder(shipment, 60),
  shipmentOutChild,
  setMenuOrder(shipmentCancel, 70),
  setMenuOrder(acceptance, 80),
  acceptanceChild,
  setMenuOrder(remarking, 90),
  remarkingChild,
  setMenuOrder(returns, 100),
  returnsChild,
  setMenuOrder(transferLocal, 110),
  transferLocalChild,
  setMenuOrder(receipt, 120),
  receiptChild,
  setMenuOrder(customLocal, 130),
  customLocalChild,
  setMenuOrder(remainsDescription, 140),
  remainsDescriptionChild,
  cancelCodes,
  cancelCodesChild,
  document,
  documentChild,
  eanInfo,
  task,
  documentHistory,
  logs,

  packType,
  remarkDocumentType,
  cancellationReason,
  itemCreateMethodType,
  releaseMethodType,
  serialNumberType,
  markingType,
  markingStatus,
  codeTemplateId,
  productionType,
  certDocumentType,
  documentStatus,
  genderType,
  remainsReleaseMethodType,
  turnoverType,
  remarkingCause,
  emissionType,
  codeExternalStatus,
  printStatus,
  documentDirectionType,
  reaggrType,
  markingExtraStatus,
  returnsType,
  returnsDocumentType,
  documentExternalStatus,
  printSelectorType,
  usageReportType,
  usageType,
  rolloutDocumentFormat,
  rolloutProducedProductionType,
  cisType,
  receiptActionType,
  receiptDocumentType,
  acceptShipmentType,
  dropoutReasonType,
  orderPaymentType,
];

const pages = [
  dashboardPage,
]

const name = 'marking';

export default {
  name,
  locales,
  resources: [
    ...resources,
    ...modules,
  ],
  pages,
  icon: Icon,
  menuGroups: [{
    name: 'settings',
    text: `services.${name}.groups.settings`,
    icon: SettingsIcon,
    order: 1000,
  }, {
    name: 'input',
    text: `services.${name}.groups.input`,
    icon: MarkingInputIcon,
    order: 45,
  }, {
    name: 'aggr',
    text: `services.${name}.groups.aggr`,
    icon: AggrIcon,
    order: 50,
  }, {
    name: 'cancel',
    text: `services.${name}.groups.cancel`,
    icon: CancelIcon,
    order: 125,
  }],
};
