import React from 'react';
import ResourceIcon from '@material-ui/icons/Archive';
import { ArrayInput, SimpleFormIterator, TextInput } from '../../../../components/Admin';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import CompanyDataInput from '../../../../components/CompanyDataInput';


const name = 'marking/aggr';
const precessName = 'aggregation_aggregation';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: ResourceIcon,
  group: 'aggr',
  apiBasePath,
  precessName,
  allowCreateWithGenerator: false,
  apiResolve: {
    mapping: {
      outputItemAfter: (output) => {
        if (output.data.aggregation_units) {
          output.data.aggregation_units.forEach((unit) => {
            if (unit.sntins) {
              unit.sntins = unit.sntins.map(x => x.cis);
            }
            if (!unit.aggregation_type) {
              unit.aggregation_type = 'AGGREGATION';
            }
          })
        }
        return output;
      },
      inputItemAfter: (input) => {
        if (input.data.aggregation_units) {
          input.data.aggregation_units.forEach((unit) => {
            if (unit.sntins) {
              unit.sntins = unit.sntins.map(x => ({ cis: x }));
            }
          })
        }
        return input;
      },
    }
  },
  schema: {
    type: 'object',
    properties: {
      'data.participant_id': {
        type: 'string',
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
        validation: {
          required: true,
        }
      },
      'data.aggregation_units': {
        type: 'array',
        validation: {
          required: true,
        },
        options: {
          multiline: true,
        }
      },
    }
  },
  createFields: [
    'data.participant_id',
    {
      name: 'data.aggregation_units',
      options: {
        fullWidth: true,
      },
      component: (props) => (
        <ArrayInput {...props} fullWidth source="data.aggregation_units">
            <SimpleFormIterator>
              <TextInput source="unit_serial_number" />
              <ArrayInput source="sntins">
                  <SimpleFormIterator>
                    <TextInput source="cis" />
                  </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
        </ArrayInput>
      ),
    },
  ],
});
