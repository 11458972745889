import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Товар - отгрузка |||| Товары - отгрузка',
      fields: {
        'data.uit_code': 'Код товара',
        'data.uitu_code': 'Код транспортной упаковки',
        'data.product_description': 'Наименование продукта',
        'data.product_cost': 'Цена (в копейках)',
        'data.product_tax': 'Сумма НДС (в копейках)',
        'data.accepted': 'Принят',
      }
    }
  }
}
