// in src/App.js
import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import withContext from 'recompose/withContext';
import { AuthContext, DataProviderContext, TranslationProvider, Resource } from 'react-admin';


// your app components
import RootLayout from '../RootLayout';
import WithPermissions from '../WithPermissions';
// import LoginPage from '../LoginPage';
import AppServiceContainer from '../AppServiceContainer';
import NotFoundPage from '../../pages/NotFoundPage';

const App = ({ dataProvider, authProvider, history, i18nProvider, resources, menu }) => {
  return (
    <RootLayout>
      <AuthContext.Provider value={authProvider}>
        <DataProviderContext.Provider value={dataProvider}>
          <TranslationProvider
            i18nProvider={i18nProvider}
          >
            <ConnectedRouter history={history}>
              {resources.filter(x => x.AppModule).map(({ name, AppModule }) => (
                <AppModule
                  key={name}
                />
              ))}
              {resources.filter(x => x.resourceConfig).map(res => (
                <Resource
                  key={res.name}
                  name={res.name}
                  intent="registration"
                />
              ))}
              <Switch>
                {/* <Route exact path="/login" component={LoginPage} /> */}
                <Route
                  path="/c/:companyId/pg/:productGroup/st/:stockId/s/:serviceName(marking)"
                  render={(routerProps) => (
                    <WithPermissions
                      key={routerProps.match.url}
                      component={AppServiceContainer}
                      authParams={{
                        companyId: routerProps.match.params.companyId,
                        productGroup: routerProps.match.params.productGroup,
                        stockId: routerProps.match.params.stockId,
                        serviceName: routerProps.match.params.serviceName,
                      }}
                      {...routerProps}
                    />
                  )}
                />
                <Route
                  path="/c/:companyId/s/:serviceName(company)"
                  render={(routerProps) => (
                    <WithPermissions
                      key={routerProps.match.url}
                      component={AppServiceContainer}
                      authParams={{
                        companyId: routerProps.match.params.companyId,
                        serviceName: routerProps.match.params.serviceName,
                      }}
                      {...routerProps}
                    />
                  )}
                />
                <Route
                  path="/s/:serviceName(common)"
                  render={(routerProps) => {
                    return (
                      <WithPermissions
                        key={routerProps.match.url}
                        component={AppServiceContainer}
                        authParams={{
                          serviceName: routerProps.match.params.serviceName,
                        }}
                        {...routerProps}
                      />
                    )
                  }}
                />
                {/* <Redirect to="/s/common" path="/" exact/> */}
                <Redirect to="/s/common/common/company" path="/" exact/>
                <Route path="*" component={NotFoundPage} />
              </Switch>
            </ConnectedRouter>
          </TranslationProvider>
        </DataProviderContext.Provider>
      </AuthContext.Provider>
    </RootLayout>
  );
}

export default withContext()(App);