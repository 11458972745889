import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'ЭЦП сервер |||| ЭЦП серверы',
      connectionStatus: {
        connected: 'Подключен',
        disconnected: 'Отключен',
      },
      actions: {
        test: {
          name: 'Проверить',
          successMessage: 'Проверка прошла успешно',
          failureMessage: 'Проверка не пройдена: "%{error}"',
        },
        ping: {
          name: 'Пинг',
          successMessage: 'Пинг запрос успешно отправлен',
          failureMessage: 'Пинг запрос вернулся с ошибкой: "%{error}"',
        },
      },
      fields: {
        is_connected: 'Статус',
      }
    }
  }
}
