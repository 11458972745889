import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        SALE: 'Продажа',
        COMMISSION: 'Комиссия',
        AGENT: 'Агент',
        CONTRACT: 'Контракт',
      },
    }
  }
}
