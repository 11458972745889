import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import withStyles from '@material-ui/core/styles/withStyles';
import { translate } from 'react-admin';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';
import request from '../../network/request';
import { getDocNameByProcess } from '../../utils/documents/mapDocProcess';
import GuidField from '../GuidField';
import documentStatus from '../../services/marking-service/enums/documentStatus';
import documentProcessType from '../../services/marking-service/enums/documentProcessType';
import { fillUrl } from '../../utils/url';
import documentRowStyle from '../../utils/ui/documentRowStyle';
import appCore from '../../appCore';

const styles = (theme) => ({
  root: {
    flex: 1,
  },
  listItemIcon: {
    minWidth: 40,
  }
})

class LastDocumentsWidget extends Component {
  state = {
    items: [],
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.viewVersion !== prevProps.viewVersion) {
      this.loadData();
    }
  }

  loadData = async () => {
    let response = null;
    try {
      response = await request({
        path: `/bp/document/document/`,
        method: 'GET',
        urlParams: {
          page_size: 10,
          is_process: true
        }
      })
    } catch (e) {
      console.error(e);
    }

    let error = null;
    let items = [];
    if (response === null || !response.ok) {
      if (response !== null) {
        error = response.data
      }
    } else {
      items = response.data.results;
    }

    this.setState({
      items,
      error
    });
  }

  render() {
    const {
      classes,
      translate,
      showProductGroups,
    } = this.props;
    const {
      items
    } = this.state;
    return (
      <Card className={classes.root}>
        <CardHeader title={translate('widgets.lastDocs.title')} />
        <List dense={true}>
          {items.map(record => {
            const url = fillUrl(`/c/{{companyId}}/pg/${record.product_group || '{{productGroup}}'}/st/{{stockId}}/s/marking/marking/${getDocNameByProcess(record.doctype)}/${record.document_id}`);
            let productGroup = null;

            if (showProductGroups) {
              productGroup = appCore.productGroups.find(x => x.name === record.product_group) || null;
            }

            return (
              <ListItem
                key={record.id}
                button={url !== null}
                disabled={url === null}
                component={url === null ? undefined : Link}
                to={url || undefined}
                style={documentRowStyle(record)}
              >
                {(showProductGroups && productGroup) && (
                  <ListItemIcon className={classes.listItemIcon}>
                    <productGroup.icon />
                  </ListItemIcon>
                )}
                <ListItemText
                  style={{ flexShrink: 0, flexGrow: 1 }}
                  primary={<documentProcessType.Field record={record} source="doctype" />}
                  secondary={(
                    <span>
                      #<GuidField record={record} source="document_id"/>
                    </span>
                  )}
                />
                <ListItemText
                  style={{ flexShrink: 1, flexGrow: 0, textAlign: 'right' }}
                  primary={<documentStatus.Field record={record} source="status" />}
                  secondary={new Date(record.created).toLocaleString()}
                />
              </ListItem>
            )
          })}
        </List>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  viewVersion: state.admin.ui.viewVersion,
});

export default compose(
  connect(
    mapStateToProps,
  ),
  translate,
  withStyles(styles)
)(LastDocumentsWidget);