import React from 'react';
import {
  ReferenceInput,
  SelectInput,
} from '../../components/Admin';


export default (options = {}) => {
  let {
    name,
    itemToString,
  } = options;

  return (props) => {
    const {
      optionValue,
      ...restProps
    } = props;
    return (
      <ReferenceInput 
        reference={name}
        perPage={100}
        {...restProps}
      >
        <SelectInput
          optionText={itemToString || 'id'}
          optionValue={optionValue}
        />
      </ReferenceInput>
    )
  }
}
