import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Autobalance codes |||| Autobalance codes',
      actions: {
        execute: {
          name: 'Run',
          successMessage: 'Run request success',
          failureMessage: 'Run request failed',
        },
      },
      fields: {
      }
    }
  }
}
