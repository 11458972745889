import React from 'react';
import {
  TextField,
  ReferenceField,
} from '../../components/Admin';
import { Typography } from '@material-ui/core';
import getValue from '../getValue';


export default (options = {}) => {
  let {
    name,
    itemToString = null,
  } = options;

  if (itemToString === null) {
    return (props) => (
      <ReferenceField
          reference={name}
          {...props}
      >
        <TextField source={'id'} />
      </ReferenceField>
    )
  }

  const Fied = ({ record, emptyText }) => (
    <Typography component="span" variant="body2">
      {itemToString(record)}
    </Typography>
  )
  return (props) => {
    const value = getValue(props.record, props.source);
    if (value) {
      return (
        <ReferenceField
            reference={name}
            {...props}
        >
          <Fied />
        </ReferenceField>
      );
    }

    return (
      <Typography component="span" variant="body2">
        {props.emptyText || ''}
      </Typography>
    )
  }
}