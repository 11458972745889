import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Товар - вывод из оборота |||| Товары - вывод из оборота',
      fields: {
        'data.cis': 'КИ',
        'data.product_cost': 'Цена за единицу',
      }
    }
  }
}
