import React, { PureComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import {
  translate as translateRA,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
} from 'react-admin';
import compose from '../../utils/compose';
import ActionMenuItem from './ActionMenuItem';


const ITEM_HEIGHT = 48;

@compose(
  translateRA,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
)
class ResourceActionsMenu extends PureComponent {

  state = {
    anchorEl: null,
    loading: false,
  }

  handleOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  }

  handleAction = async(action) => {
    if (this.state.loading) {
      return;
    }

    const {
      actions,
      showNotification,
      refreshView,
      translate,
      ...props
    } = this.props;

    this.setState({
      loading: true,
    });

    const result = await action.handler({
      translate,
      ...props
    });

    if (result && result.notification) {
      showNotification(translate(result.notification.message), result.notification.type);
    }

    this.setState({
      loading: false,
      anchorEl: null,
    }, refreshView);
  }

  render() {
    const {
      actions,
      record,
    } = this.props;
    const {
      anchorEl,
      loading,
    } = this.state;

    const isOpen = Boolean(anchorEl);
    const paperStyle = {
      maxHeight: ITEM_HEIGHT * 7.5,
      width: 200,
    };

    if (loading) {
      paperStyle.background = '#ddd';
      paperStyle.pointerEvents = 'none';
    }
    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          size="small"
          onClick={this.handleOpen}
        >
          {loading ? (
            <CircularProgress size={20}/>
          ) : (
            <MoreVertIcon />
          )}
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={isOpen}
          onClose={this.handleClose}
          PaperProps={{
            style: paperStyle,
          }}
        >
          {actions.map(action => (
            <ActionMenuItem
              key={action.name}
              action={action}
              record={record}
              onClick={action.handler && this.handleAction}
            />
          ))}
        </Menu>
      </div>
    )
  }
}

export default ResourceActionsMenu;
