import ResourceIcon from '@material-ui/icons/Input';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';
import returnsDocumentType from '../../../enums/returnsDocumentType';
import certDocumentType from '../../../enums/certDocumentType';

const name = 'marking/returns-child';
const parentResourceName = 'marking/returns';
const apiBasePath = '/bp/processes/returns_return/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  schema: {
    type: 'object',
    properties: {
      'data.ki': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.primary_document_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.primary_document_number': {
        type: 'string',
        defaultValue: null,
      },
      'data.primary_document_type': {
        type: 'string',
        defaultValue: null,
        componentInput: returnsDocumentType.Input,
        componentField: returnsDocumentType.Field,
      },
      'data.primary_document_custom_name': {
        type: 'string',
        defaultValue: null,
        options: {
          showCondition: (data) => data.data && data.data.primary_document_type === 'OTHER',
        }
      },
      'data.certificate_date': {
        type: 'date',
        defaultValue: null,
      },
      'data.certificate_number': {
        type: 'string',
        defaultValue: null,
      },
      'data.certificate_type': {
        type: 'string',
        defaultValue: null,
        componentInput: certDocumentType.Input,
        componentField: certDocumentType.Field,
      },
      'data.paid': {
        type: 'boolean',
        defaultValue: false,
      },
    }
  },
  createFields: [
    'data.ki',
    'data.primary_document_date',
    'data.primary_document_number',
    'data.primary_document_type',
    'data.primary_document_custom_name',
    'data.certificate_date',
    'data.certificate_number',
    'data.certificate_type',
    'data.paid',
  ],
});