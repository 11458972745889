import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Create Document',
      title: 'Creating a new document',
      invalid: 'Invalid query parameters',
      cancelAction: 'Cancel',
    }
  }
}
