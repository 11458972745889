import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Notification |||| Notifications',
      actions: {
        setAsReaded: {
          name: 'Set as read',
          successMessage: 'Request sent successfully',
          failureMessage: 'Request failed',
        },
        bulkSetAsReaded: {
          name: 'Set all as read',
          successMessage: 'Request sent successfully',
          failureMessage: 'Request failed',
        },
      },
      fields: {
        message_type: 'Message Type',
        created_at: 'Created At',
        message: 'Message',
        short_message: 'Message',
        is_readed: 'Is Read',
      },
    }
  }
}