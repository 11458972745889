import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        CIS: 'КИ/КИТУ',
        GTIN: 'GTIN',
        EAN: 'EAN',
        DOCUMENT_ID: 'Документ',
        VENDOR_CODE: 'Код поставщика',
        CODE_ID: 'ID Кода',
      },
    }
  }
}
