import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Заказ на эмиссию |||| Заказы на эмиссию',
      actions: {
        getReplyCSV: 'Скачать коды в CSV',
      },
      tabs: {
        status: 'Статус заказа',
        info: 'Данные заказа',
        products: 'Товары',
      },
      fields: {
        'data.contact_person': 'Контактное лицо',
        'data.release_method_type': 'Способ выпуска товаров в оборот',
        'data.remains_available': 'Произведен (Остатки)',
        'data.remains_import': 'Импорт (Остатки)',
        'data.create_method_type': 'Способ изготавления СИ',
        'data.production_order_id': 'Идентификатор производственного заказа',
        'data.contract_number': 'Номер договора с оператором',
        'data.contract_date': 'Дата договора с оператором',
        'data.print_status': 'Статус печати КМ',
        'data.service_provider_id': 'Идентификатор сервис провайдера',
        'data.payment_type': 'Способ оплаты',
      },
    }
  }
}
