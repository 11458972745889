import ResourceIcon from '@material-ui/icons/Archive';
import docConfigCreator from '../../../../utils/documents/docConfigCreator';
import CompanyDataInput from '../../../../components/CompanyDataInput';
import aggrReportChild from './aggrReportChild';


const name = 'marking/aggr-report';
const precessName = 'aggregation_report';
const apiBasePath = `/bp/processes/${precessName}/`;

export default docConfigCreator({
  name,
  icon: ResourceIcon,
  group: 'aggr',
  apiBasePath,
  precessName,
  allowCreateWithGenerator: false,
  childResource: aggrReportChild,
  schema: {
    type: 'object',
    properties: {
      'data.participant_id': {
        type: 'string',
        componentInput: CompanyDataInput,
        options: {
          companyField: 'info.inn',
        },
        validation: {
          required: true,
        }
      },
    }
  },
  createFields: [
    'data.participant_id',
  ],
});
