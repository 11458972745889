import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Товар - перемаркировка |||| Товары - перемаркировка',
      fields: {
        'data.last_uin': 'Предыдущий КИ',
        'data.new_uin': 'Новый КИ',
        'data.remarking_date': 'Дата перемаркировки',
        'data.remarking_cause': 'Причина перемаркировки',
      }
    }
  }
}
