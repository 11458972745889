import request from '../../../../network/request';
import { fillUrl } from '../../../../utils/url';

export const createSendTestNotificationActionCreator = ({ groupType, parentResourceName }: { groupType: string; resourceName: string; parentResourceName: string; }) => ({
  name: `resources.${parentResourceName}.actions.sendTest.name`,
  order: 10,
  handler: async ({ record }) => {
    const path = fillUrl(`/notification-service/notification-group/{{parent_id}}/${groupType}/settings/${record.id}/send_test_notification/`);

    let response = null;
    try {
      response = await request({
        path,
        method: 'POST',
      });
    } catch (e) { }

    if (response && response.ok && response.data === 'ok') {
      return {
        notification: {
          type: 'success',
          message: `resources.${parentResourceName}.actions.sendTest.successMessage`
        }
      }
    }

    return {
      notification: {
        type: 'error',
        message: `resources.${parentResourceName}.actions.sendTest.failureMessage`
      }
    }
  }
})
