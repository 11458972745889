import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'PRINTED': 'Printed',
        'VERIFIED': 'Verified',
        'USED_FOR_PRODUCTION': 'Used for production',
        'SENT_TO_PRINTER': 'Sent to printer',
        'PRINTER_LOST': 'Printer lost',
      },
    }
  }
}
