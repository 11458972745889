import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Свободный документ |||| Свободный документ',
      fields: {
        'data._attributes': 'Поля документа',
        'key': 'Название поля',
        'value': 'Значение поля',
      }
    }
  }
}