import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Агрегация',
      fields: {
        'data.participant_id': 'ИНН',
        'data.aggregation_units': 'Агрегаты',
        'unit_serial_number': 'SSCC',
        'sntins': 'Состав агрегата',
        'cis': 'КИ/КИТУ',
      },
    }
  }
}