import React from "react";
import { SvgIcon } from "@material-ui/core";

export default (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M5.8125 4.3C5.7199 4.30002 5.62874 4.32238 5.54709 4.3651C5.46545 4.40783 5.39585 4.46959 5.34447 4.54492L3.26156 7.6H15.375L17.625 4.3H5.8125Z" />
    <path d="M3 22.45C3 22.5959 3.05926 22.7358 3.16475 22.8389C3.27024 22.9421 3.41332 23 3.5625 23H15.375V8.7H3V22.45ZM6.9375 12H11.4375C11.5867 12 11.7298 12.0579 11.8352 12.1611C11.9407 12.2642 12 12.4041 12 12.55C12 12.6959 11.9407 12.8358 11.8352 12.9389C11.7298 13.0421 11.5867 13.1 11.4375 13.1H6.9375C6.78832 13.1 6.64524 13.0421 6.53975 12.9389C6.43426 12.8358 6.375 12.6959 6.375 12.55C6.375 12.4041 6.43426 12.2642 6.53975 12.1611C6.64524 12.0579 6.78832 12 6.9375 12ZM6.9375 15.3H11.4375C11.5867 15.3 11.7298 15.3579 11.8352 15.4611C11.9407 15.5642 12 15.7041 12 15.85C12 15.9959 11.9407 16.1358 11.8352 16.2389C11.7298 16.3421 11.5867 16.4 11.4375 16.4H6.9375C6.78832 16.4 6.64524 16.3421 6.53975 16.2389C6.43426 16.1358 6.375 15.9959 6.375 15.85C6.375 15.7041 6.43426 15.5642 6.53975 15.4611C6.64524 15.3579 6.78832 15.3 6.9375 15.3Z" />
    <path d="M21 7.6L18.8636 4.46665L16.727 7.6H21Z" />
    <path d="M16.5 23H20.4375C20.5114 23 20.5845 22.9858 20.6528 22.9582C20.7211 22.9306 20.7831 22.8901 20.8353 22.839C20.8876 22.7879 20.929 22.7273 20.9573 22.6605C20.9855 22.5938 21 22.5222 21 22.45V8.7H16.5V23Z" />
    <path d="M18.75 1.55C18.75 1.47776 18.7355 1.40622 18.7073 1.33947C18.679 1.27272 18.6376 1.21208 18.5853 1.16099C18.5331 1.10991 18.4711 1.0694 18.4028 1.04178C18.3345 1.01415 18.2614 0.999957 18.1875 1H5.8125C5.73862 0.999957 5.66545 1.01415 5.59719 1.04178C5.52892 1.0694 5.46689 1.10991 5.41465 1.16099C5.36241 1.21208 5.32098 1.27272 5.29273 1.33947C5.26447 1.40622 5.24996 1.47776 5.25 1.55V3.2H18.75V1.55Z" />
  </SvgIcon>
);
