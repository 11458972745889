import ResourceIcon from '@material-ui/icons/Notifications';
import GuidField from '../../../../components/GuidField';
import request from '../../../../network/request';
import notificationRowStyle from '../../../../utils/ui/notificationRowStyle';
import notificationMessageType from '../../enums/notificationMessageType';

const name = 'report/notification';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/notification-service/notification/',
    mapping: {
      inputItem: ({ message = '', ...data }) => ({ 
        short_message: (message && message.length > 200) ? `${message.slice(0, 190)}...` : message, 
        message,
        ...data
      }),
    }
  },
  itemToString: x => `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      message_type: {
        type: 'string',
        componentField: notificationMessageType.Field,
        componentInput: notificationMessageType.Input,
      },
      message: {
        type: 'string',
        options: {
          fullWidth: true,
        },
      },
      created: {
        type: 'dateTime',
      },
      is_readed: {
        type: 'boolean',
      },
    }
  },
  allowList: true,
  allowCreate: false,
  allowEdit: false,
  allowShow: true,
  allowDelete: false,
  showInMenu: true,
  group: 'reports',
  listDefaultSort: { field: 'created', order: 'DESC' },
  listRowStyle: notificationRowStyle,
  showFields: [
    'id',
    'message_type',
    'created',
    'is_readed',
    'message',
  ],
  listFilters: [
    'message_type',
    'is_readed',
  ],
  listFields: [
    { name: 'id', component: GuidField },
    'created',
    'short_message',
    'is_readed',
  ],
  itemActions: [{
    name: `resources.${name}.actions.setAsReaded.name`,
    isDisabled: ({ record }) => record.is_readed,
    handler: async ({ record }) => {
      const path = '/notification-service/notification/set_is_readed/';

      let response = null;
      try {
        response = await request({
          path,
          method: 'POST',
          contentType: 'json',
          body: {
            notifications: [record.id],
          }
        });
      } catch (e) { }

      if (response && response.ok) {
        return {
          notification: {
            type: 'success',
            message: `resources.${name}.actions.setAsReaded.successMessage`
          }
        }
      }

      return {
        notification: {
          type: 'error',
          message: `resources.${name}.actions.setAsReaded.failureMessage`
        }
      }
    }
  }],
  bulkActions: [{
    name: `resources.${name}.actions.bulkSetAsReaded.name`,
    handler: async (options) => {
      const {
        selectedIds = [],
      } = options;

      if (selectedIds.length === 0) {
        return;
      }

      const path = '/notification-service/notification/set_is_readed/';

      let response = null;
      try {
        response = await request({
          path,
          method: 'POST',
          contentType: 'json',
          body: {
            notifications: selectedIds,
          }
        });
      } catch (e) { }

      if (response && response.ok) {
        return {
          notification: {
            type: 'success',
            message: `resources.${name}.actions.bulkSetAsReaded.successMessage`
          }
        }
      }

      return {
        notification: {
          type: 'error',
          message: `resources.${name}.actions.bulkSetAsReaded.failureMessage`
        }
      }
    }
  }],
};
