import createEnumResource from '../../../../utils/admin/createEnumResource';
import locales from './locales';
import config from './config';

export default createEnumResource(config, { locales });

// export default {
//   ...config,
//   locales,
// };
