import React, { Component } from 'react';
import { connect } from 'react-redux';
import download from 'downloadjs';
import {
  translate,
  SimpleForm,
  Toolbar,
  SaveButton,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
  TextInput,
  SelectInput,
  BooleanInput,
} from 'react-admin';
import compose from 'recompose/compose';
import qs from 'qs';
import { withRouter } from 'react-router';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import resourceConfig from './config';
import print from '../../../../utils/print';
import request from '../../../../network/request';
import { pathToDictionary } from '../../../../utils';
import Snackbar from '../../../../components/Snackbar';
import { restProviderHandle } from '../../../../network/rest';
import labelTemplateFileType from '../../../company-service/enums/labelTemplateFileType';

const styles = {
  label: { width: '10em', display: 'inline-block' },
  button: { margin: '1em' },
  toolbar: { background: 'none' },
  loader: {
    marginLeft: 15
  }
};

const FormToolbar = translate(({ translate, ...props }) => (
  <Toolbar
    style={styles.toolbar}
    {...props}
  >
    <SaveButton
      label={translate(`resources.${resourceConfig.name}.executeAction`)}
      redirect={false}
      submitOnEnter={true}
    />
    {props.saving &&
      <div style={styles.loader}>
        <CircularProgress />
      </div>
    }
  </Toolbar>
))

class AppModule extends Component {
  defaultParams = {
    document_id: null,
    template: null,
    format: 'pdf',
  }

  lastFormData = { ...this.defaultParams };

  allTemplates = [];

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      error: null,
      executing: false,
      templateNames: [],
      allTemplates: [],
      params: {
        ...this.defaultParams
      }
    };
  }

  componentDidMount = () => {
    this.checkLocation();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      this.checkLocation();
    }
  }

  checkLocation = () => {
    if (this.props.location.search.length < 2) {
      this.resetState();
      return;
    }
    const params = qs.parse(this.props.location.search.slice(1));
    if (!(resourceConfig.urlParamName in params)) {
      this.resetState();
      return;
    }
    if (this.state.show) {
      return;
    }
    const data = pathToDictionary(params[resourceConfig.urlParamName]);

    this.loadTemplates();

    this.setState({
      show: true,
      params: {
        ...this.defaultParams,
        ...data
      }
    });
  }

  resetState = () => {
    this.setState({
      show: false,
      executing: false,
    });
    setTimeout(this.afterClose, 500);
  }

  afterClose = () => {
    this.setState({
      params: {
        ...this.defaultParams
      }
    })
  }

  loadTemplates = async() => {
    const templates = await restProviderHandle('GET_LIST', 'settings/label-templates', { pagination: { perPage: 200, page: 1 } });

    if (templates.data) {
      this.setState({
        allTemplates: templates.data,
      }, this.updateActiveTemplates)
    }
  }

  updateActiveTemplates = () => {
    const { allTemplates } = this.state;
    const format = this.lastFormData.format;
    if (allTemplates == null || allTemplates.length === 0) {
      return;
    }
    const activeTemplates = allTemplates.filter(x => x.file_type === format);
    this.setState({
      templateNames: activeTemplates,
    })
  }

  execute = async (formData) => {
    this.setState({
      executing: true
    });

    let {
      document_id,
      format,
      template,
      force_update,
      to_printer,
    } = formData;

    const urlParams = {}

    if (force_update) {
      urlParams.force_update = true;
    }
    let response = null;
    try {
      let path = `/printer/render/${document_id}/`;
      if (template) {
        path = `${path}${template}/`
      } else {
        urlParams.template_type = format;
      }

      // if (format === 'pdf') { // Use old method for print
      //   path = `/printer/${document_id}/pdf/${template ? `${template}/` : ''}`;
      //   // path = `/printer/${document_id}/${format}/${(format === 'pdf' && template) ? `${template}/` : ''}`;
      // }

      response = await request({
        path,
        method: 'GET',
        responseType: 'blob',
        urlParams,
      })
    } catch (e) {
      console.error(e);
    }
    let error = null;
    if (response === null || !response.ok) {
      this.props.showNotification(`resources.${resourceConfig.name}.errorMessage`, 'error');
      if (response !== null) {
        error = response.parsedError
      }
    } else {
      let mimeType = 'text';
      if (format === 'pdf') {
        mimeType = 'application/pdf';
      } else if (format === 'csv') {
        mimeType = 'text/csv';
      }

      if (to_printer && format === 'pdf') {
        print(response.data, format)
      } else {
        download(response.data, `${document_id}.${format}`, mimeType);
      }
      this.props.showNotification(`resources.${resourceConfig.name}.successMessage`, 'success');
      this.props.refreshView();
    }


    this.setState({
      executing: false,
      error
    });
  }

  handleCloseClick = () => {
    this.props.history.goBack();
  }

  validate = (formData) => { // Using validate as onChange
    const formatWasChanged = this.lastFormData.format !== formData.format;
    this.lastFormData = formData;
    if (formatWasChanged) {
      this.updateActiveTemplates();
    }

    const errors = {};

    if (!formData.document_id) {
      errors.document_id = true;
    }
    if (!formData.format) {
      errors.format = true;
    }
    if (!formData.template) {
      errors.template = true;
    }

    return errors;
  }

  render() {
    const {
      translate
    } = this.props;
    const {
      allTemplates,
      templateNames,
    } = this.state;

    return (
      <Drawer
        anchor={'right'}
        open={this.state.show}
        onClose={this.handleCloseClick}
      >
        <SimpleForm
          defaultValue={this.state.params}
          save={this.execute}
          toolbar={<FormToolbar />}
          saving={this.state.executing}
          style={{ width: 600, maxWidth: '100%' }}
          validate={this.validate}
        >
          <Typography
            variant={'title'}
          >
            {translate(`resources.${resourceConfig.name}.title`)}
          </Typography>

          <TextInput source="document_id" fullWidth disabled/>

          <labelTemplateFileType.Input
            source="format"
            fullWidth
          />


          {allTemplates.length === 0 ? (
            <TextInput
              source={'template'}
              fullWidth
            />
          ) : (
            <SelectInput
              fullWidth
              source={'template'}
              choices={templateNames}
              optionText="name"
              optionValue="slug"
              translateChoice={false}
            />
          )}

          <BooleanInput
            fullWidth
            source={'force_update'}
          />

          <BooleanInput
            fullWidth
            source={'to_printer'}
          />

          {this.state.error && (
            <Snackbar
              style={{ marginTop: 20, marginBottom: 40, maxWidth: '100%' }}
              textStyle={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}
              showIcon={false}
              variant="error"
              message={JSON.stringify(this.state.error, null, 2)}
              fullWidth
            />
          )}
        </SimpleForm>
      </Drawer>
    )
  }
}

export default compose(
  withRouter,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
  translate,
  // withStyles(styles)
)(AppModule);
