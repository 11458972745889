import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import getResourceUrl from '../admin/getResourceUrl';


export default (options = {}) => {
  let {
    resourceName,
    label = 'common.create',
  } = options;

  return ({ record }) => (
    <Button
      variant="raised"
      component={Link}
      to={getResourceUrl({
        resourceName,
        pageType: 'CREATE',
        params: {
          parent_id: record.id,
        }
      })}
      label={label}
      title={label}
    >
      <AddIcon />
    </Button>
  )
}