import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import SectionSeparator from '../ui/SectionTitle';
import SectionTitle from '../ui/SectionSeparator';
import JsonViewer from '../ui/JsonViewer';
import { TextField } from '../Admin';
import LabeledField from '../ui/LabeledField';



const styles = {
  text: {
    whiteSpace: 'pre-line',
  }
};

const LogField = ({ classes, theme, source, record, ...props }) => {
  let value = record[source];
  let isRequest = false;
  let isJson = true;
  if (typeof value === 'string') {
    try {
      value = JSON.parse(value)
      if ('method' in value && 'url' in value) {
        isRequest = true;
      }
    } catch(e) {
      isJson = false;
    }
  }

  if (isRequest) {
    return (
      <div>
        <SectionSeparator />
        <SectionTitle label="LogField.request" />


        <LabeledField fullWidth label="LogField.requestUrl">
          <TextField record={value} source="url" />
        </LabeledField>

        <LabeledField fullWidth label="LogField.requestMethod">
          <TextField record={value} source="method" />
        </LabeledField>

        {value.body && (
          <LabeledField fullWidth label="LogField.requestBody">
            <JsonViewer value={value.body} />
          </LabeledField>
        )}

        {value.response && (
          <Fragment>
            <SectionSeparator />
            <SectionTitle label="LogField.response" />
            {value.response.status_code && (
              <LabeledField label="LogField.responseStatusCode">
                <TextField record={value} source="response.status_code" />
              </LabeledField>
            )}
            {value.response.body && (
              <LabeledField fullWidth label="LogField.responseBody">
                <JsonViewer value={value.response.body} />
              </LabeledField>
            )}
          </Fragment>
        )}

      </div>
    );
  }

  if (isJson && typeof value === 'object') {
    return (
      <LabeledField fullWidth label="LogField.messageJson">
        <JsonViewer value={value} />
      </LabeledField>
    );
  }

  return (
    <LabeledField fullWidth label="LogField.message">
      <Typography component="span" variant="body2" className={classes.text}>
        {value.toString()}
      </Typography>
    </LabeledField>
  );
};


const LogFieldWithStyles = withStyles(styles)(LogField);

LogFieldWithStyles.defaultProps = {
  addLabel: false
};

export default LogFieldWithStyles;
