import React, { PureComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/BarChart';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import {
  translate as translateRA,
  showNotification as showNotificationAction,
  refreshView as refreshViewAction,
} from 'react-admin';
import compose from '../../../../utils/compose';
import PaginationFunctionsMenuItem from './PaginationFunctionsMenuItem';
import rest from '../../../../network/rest';


const getCountActionCreator = ({ noCache = false }) => ({
  name: noCache ? 'pagination.actions.getCountNoCache.name' : 'pagination.actions.getCount.name',
  handler: async ({ resource, params }) => {
    const nextParams = {
      ...params,
      pagination: {
        perPage: 1,
        page: 1,
      },
      urlParams: {
        ...params.urlParams,
        with_count: true,
      }
    };

    if (noCache) {
      nextParams.urlParams.force_count = true;
    }

    let response = null;
    try {
      response = await rest.getList(resource, nextParams)
    } catch(e) {}
    if (response === null || typeof response.total !== 'number') {
      return {
        notification: {
          type: 'error',
          message: noCache ? 'pagination.actions.getCountNoCache.failureMessage' : 'pagination.actions.getCount.failureMessage',
        }
      }
    }
    return {
      notification: {
        type: 'success',
        message: noCache ? 'pagination.actions.getCountNoCache.successMessage' : 'pagination.actions.getCount.successMessage',
        translateParams: {
          total: response.total.toLocaleString(),
        }
      }
    }
  }
})

const ITEM_HEIGHT = 48;

@compose(
  translateRA,
  connect(null, {
    showNotification: showNotificationAction,
    refreshView: refreshViewAction,
  }),
)
class PaginationFunctionsMenu extends PureComponent {

  state = {
    anchorEl: null,
    loading: false,
  }

  actions = [
    getCountActionCreator({}),
    getCountActionCreator({ noCache: true }),
  ]

  handleOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  }

  handleAction = async(action) => {
    if (this.state.loading) {
      return;
    }

    const {
      showNotification,
      refreshView,
      translate,
      ...props
    } = this.props;

    this.setState({
      loading: true,
    });

    const result = await action.handler(props);

    if (result && result.notification) {
      let trParams = result.notification.translateParams || {};
      showNotification(translate(result.notification.message, trParams), result.notification.type);
    }

    this.setState({
      loading: false,
      anchorEl: null,
    }, refreshView);
  }

  render() {
    const {
      record,
    } = this.props;
    const {
      anchorEl,
      loading,
    } = this.state;

    const isOpen = Boolean(anchorEl);
    const paperStyle = {
      maxHeight: ITEM_HEIGHT * 4.5,
      width: 280,
    };

    if (loading) {
      paperStyle.background = '#ddd';
      paperStyle.pointerEvents = 'none';
    }
    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          size="small"
          onClick={this.handleOpen}
        >
          {loading ? (
            <CircularProgress size={20}/>
          ) : (
            <MenuIcon />
          )}
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={isOpen}
          onClose={this.handleClose}
          PaperProps={{
            style: paperStyle,
          }}
        >
          {this.actions.map(action => (
            <PaginationFunctionsMenuItem
              key={action.name}
              action={action}
              record={record}
              onClick={action.handler && this.handleAction}
            />
          ))}
        </Menu>
      </div>
    )
  }
}

export default PaginationFunctionsMenu;
