import ResourceIcon from '@material-ui/icons/Input';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';

const name = 'marking/transfer-local-child';
const parentResourceName = 'marking/transfer-local';
const apiBasePath = '/bp/processes/transfer/local/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  schema: {
    type: 'object',
    properties: {
      'data.code': {
        type: 'string',
        validation: {
          reqired: true,
        }
      },
    }
  },
  listFields: [
    'data.code',
  ],
});