import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Дезагрегация',
      fields: {
        'data.participant_id': 'ИНН',
      },
    }
  }
}