import qs from "qs";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE,
  UPDATE_MANY,
  CREATE,
  DELETE,
  DELETE_MANY,
} from "react-admin";
import get from "lodash/get";
import set from "lodash/set";
import history from "../core/history";
import request, { dateToParam } from "./request";
import appConfig from "../appConfig";
import { getPagingDataFromResponse } from "./helpers";

let resourceResolver = {};

window.qs = qs;
export const registerResource = (res) => {
  resourceResolver[res.name] = res;
};

export const restProviderHandle = async (type, resource, params) => {
  // console.log('REST', type, resource, params);
  let basePath = `/${resource}`;
  let requestConfig = {};
  let urlParams = {};
  if (params.urlParams) {
    urlParams = {
      ...urlParams,
      ...params.urlParams,
    }
  }
  const resolver =
    resource in resourceResolver ? resourceResolver[resource] : null;
  if (resolver !== null) {
    if ("basePath" in resolver) {
      basePath = resolver.basePath;
    }
    if ("urlParams" in resolver) {
      urlParams = {
        ...urlParams,
        ...resolver.urlParams,
      };
    }
    if ("apiService" in resolver) {
      requestConfig.apiService = resolver.apiService;
    }

    if (type === "CREATE" && resolver.pathCreate) {
      basePath = resolver.pathCreate;
    } else if (type === "GET_ONE" && resolver.pathGet) {
      basePath = resolver.pathGet;
    } else if (type === "UPDATE" && resolver.pathEdit) {
      basePath = resolver.pathEdit;
    } else if (type === "DELETE" && resolver.pathDelete) {
      basePath = resolver.pathDelete;
    } else if (type === "GET_LIST" && resolver.pathList) {
      basePath = resolver.pathList;
    }

    basePath = basePath.replace(/({{[$\w]+}})/g, (match) => {
      const field = match.replace(/[{}]/g, "");
      if (params.data && field in params.data) {
        return params.data[field];
      }
      if (params.previousData && field in params.previousData) {
        // Испрользуется при удалении
        return params.previousData[field];
      }
      if (params.filter && field in params.filter) {
        return params.filter[field];
      }
      if (field in params) {
        return params[field];
      }
      if (field === params.target) {
        return params.id;
      }
      if (history.location.search && history.location.search.includes(field)) {
        const queryParams = qs.parse(history.location.search.replace("?", ""));
        if (field in queryParams) {
          return queryParams[field];
        }
      }
    });
  }

  if (type === CREATE || type === UPDATE) {
    requestConfig.method = type === CREATE ? "POST" : "PATCH";
    requestConfig.contentType = 'json';

    let data = { ...params.data };
    if (resolver !== null && resolver.schema && resolver.schema.properties) {
      for (let key in resolver.schema.properties) {
        const field = resolver.schema.properties[key];
        let val = get(data, key);

        if (
          field.type === "string" &&
          val !== undefined &&
          val !== null &&
          typeof val !== "string"
        ) {
          val = val.toString();
        } else if (field.type === "date" && val !== null && val !== undefined) {
          const format = field.format || appConfig.data.apiDateFormat;
          val = dateToParam(val, format);
        } else if (
          field.type === "dateTime" &&
          val !== null &&
          val !== undefined
        ) {
          const format = field.format || appConfig.data.apiDateTimeFormat;
          val = dateToParam(val, format);
        }
        set(data, key, val);
      }
    }
    if (resolver !== null && resolver.mapping) {
      if (resolver.mapping.outputItem) {
        data = resolver.mapping.outputItem(data);
      }
      if (resolver.mapping.outputItemAfter) {
        data = resolver.mapping.outputItemAfter(data);
      }
    }

    requestConfig.body = data;
  }

  if (type === DELETE) {
    basePath = `${basePath}${params.id}/`;
    requestConfig.method = "DELETE";
  }
  if (type === DELETE_MANY) {
    throw new Error(`${DELETE_MANY} method is not supported`);
  }
  if (type === GET_ONE || type === UPDATE) {
    basePath = `${basePath}${params.id}/`;
  }
  if (type === GET_LIST || type === GET_MANY_REFERENCE) {
    basePath = `${basePath}`;

    const isExtended = false;
    if (params.filter && params.filter.extended_search) {
      delete params.filter.extended_search;
    }
    // let isExtended = Boolean(params.filter && params.filter.extended_search);

    // if (isExtended && !params.filter.query) {
    //   // Сейчас в API query обязательный параметр для расширенного поиска. Надо будет убрать
    //   isExtended = false;
    // }

    if (isExtended) {
      requestConfig.method = "POST";
      if (resolver.pathListExtended) {
        basePath = resolver.pathListExtended;
      }
      const body = {
        ...params.filter,
      };

      // Параметр extended_search испульзуется внутри для определния режима расширенного поиска
      delete body.extended_search;

      if (params.pagination) {
        body.limit = params.pagination.perPage;
        body.offset = params.pagination.perPage * (params.pagination.page - 1);
      }
      requestConfig.urlParams = {};
      requestConfig.contentType = 'json';
      requestConfig.body = body;
    } else {
      if (params.pagination) {
        if ('limit' in params.pagination) {
          urlParams.limit = params.pagination.limit;
          urlParams.offset = params.pagination.offset || 0;
        } else {
          // Old paging (старый пока храним так как не все ресурсы в апи на него переписаны)
          urlParams.page_size = params.pagination.perPage;
          urlParams.page = params.pagination.page;

          // Offset paging
          urlParams.limit = params.pagination.perPage;
          urlParams.offset =
            params.pagination.perPage * (params.pagination.page - 1);
        }
      }
      if (params.sort && params.sort.field && params.sort.order) {
        urlParams.ordering = `${
          params.sort.order.toLowerCase() === "desc" ? "-" : ""
        }${params.sort.field}`;
      }
      if (resolver && resolver.disableSorting) {
        delete urlParams.ordering;
      }
      if (type === GET_MANY_REFERENCE) {
        if (basePath.includes(`{{${params.target}}}`)) {
          basePath = `${basePath}`.replace(`{{${params.target}}}`, params.id);
        } else {
          urlParams[params.target] = params.id;
        }
      }
      const filter = {};
      let inputFilters = {};
      if (resolver && resolver.baseFilter) {
        inputFilters = {
          ...inputFilters,
          ...resolver.baseFilter(),
        };
      }
      if (params.filter) {
        inputFilters = {
          ...inputFilters,
          ...params.filter,
        };
      }
      for (let key in inputFilters) {
        let value = inputFilters[key];
        if (Array.isArray(value)) {
          value = value.join(",");
        }
        filter[key] = value;
      }

      if (resolver !== null && (resolver.schema || resolver.filters)) {
        for (let key in filter) {
          let paramConfig = null;
          if (resolver.filters && key in resolver.filters) {
            paramConfig = resolver.filters[key];
          } else if (resolver.schema && key in resolver.schema) {
            paramConfig = resolver.schema[key];
          }

          if (paramConfig !== null) {
            if (paramConfig.type === "date") {
              filter[key] = dateToParam(filter[key]);
            } else if (
              paramConfig.type === "string" &&
              filter[key] !== undefined &&
              filter[key] !== null &&
              typeof filter[key] !== "string"
            ) {
              filter[key] = filter[key].toString();
            }
          }
        }
      }

      urlParams = {
        ...urlParams,
        ...filter,
      };
    }
  }

  if (type !== DELETE && type !== DELETE_MANY && params.ids) {
    let idField = 'id';
    if (resolver !== null && resolver.idField) {
      idField = resolver.idField;
    }
    urlParams[`${idField}__in`] = params.ids.join(",");
  }

  let result = null;

  if (resolver !== null && resolver.getList && type === GET_LIST) {
    return await resolver.getList(params);
  } else if (resolver !== null && resolver.getList && type === GET_MANY) {
    return await resolver.getMany(params);
  } else if (
    resolver !== null &&
    resolver.getList &&
    type === GET_MANY_REFERENCE
  ) {
    return await resolver.getList(params);
  } else if (resolver !== null && resolver.getAllItems) {
    const allItems = await resolver.getAllItems(params);
    if (type === GET_LIST || type === GET_MANY_REFERENCE || type === GET_MANY) {
      result = {
        data: allItems,
        total: allItems.length,
      };
    }
    return result;
  }
  requestConfig.urlParams = urlParams;
  requestConfig.path = basePath;
  requestConfig.responseType = "json";

  if (resolver && resolver.changeConfig) {
    requestConfig = resolver.changeConfig(type, params, requestConfig);
  }

  try {
    const response = await request(requestConfig);

    if (!response.ok) {
      const error = response.parsedError;
      throw error;
    }
    if (type === GET_LIST || type === GET_MANY || type === GET_MANY_REFERENCE) {
      result = getPagingDataFromResponse(response, params.pagination);
    } else if (type === GET_MANY) {
      result = {
        data: response.data.content,
      };
    } else if (type === DELETE) {
      result = {
        data: {
          id: params.id,
          deleted: response.data,
        },
      };
    } else {
      result = {
        data: response.data,
      };
    }

    if (resolver) {
      if (resolver.afterCreate && type === CREATE) {
        resolver.afterCreate(result);
      }
      if (resolver.afterUpdate && type === UPDATE) {
        resolver.afterUpdate(result);
      }
      if (resolver.afterDelete && type === DELETE) {
        resolver.afterDelete(result);
      }
    }
  } catch (e) {
    throw e;
  }

  const processItem = (item) => {
    if (resolver !== null && resolver.idField) {
      item.id = item[resolver.idField];
    }
    if (resolver.mapping) {
      if (resolver.mapping.inputItem) {
        item = resolver.mapping.inputItem(item);
      }
      if (resolver.mapping.inputItemAfter) {
        item = resolver.mapping.inputItemAfter(item);
      }
    }
    return item;
  };

  // Process each item
  if (Array.isArray(result.data)) {
    result.data = result.data.map(processItem);
  } else {
    result.data = processItem(result.data);
  }

  return result;
};

// eslint-disable-next-line
const exportAll = async (resource, params, options = {}) => {
  const result = {
    data: [],
    total: null,
    hasNext: null,
  };
  let {
    totalLimit = 10000,
    limit = Math.min(1000, totalLimit),
    offset = 0,
  } = options;

  const loop = async () => {
    const loopParams = {
      ...params,
      pagination: {
        limit,
        offset,
      },
    };
    let data = null;
    try {
      data = await restProviderHandle(GET_LIST, resource, loopParams);
    } catch (e) {}

    if (data === null) {
      return;
    }

    result.data = [...result.data, ...data.data];
    result.hasNext = data.hasNext;

    if (data.hasNext && result.data.length < totalLimit) {
      offset = offset + limit;
      if (result.data.length + limit > totalLimit) {
        limit = totalLimit - result.data.length;
      }
      return await loop();
    }
  };

  await loop();

  return result;
};

export default {
  getList: (resource, params) => {
    if (params.pagination.perPage === 1000) {
      return exportAll(resource, params);
    }
    return restProviderHandle(GET_LIST, resource, params);
  },
  getOne: (resource, params) => restProviderHandle(GET_ONE, resource, params),
  getMany: (resource, params) => restProviderHandle(GET_MANY, resource, params),
  getManyReference: (resource, params) =>
    restProviderHandle(GET_MANY_REFERENCE, resource, params),
  update: (resource, params) => restProviderHandle(UPDATE, resource, params),
  updateMany: (resource, params) =>
    restProviderHandle(UPDATE_MANY, resource, params),
  create: (resource, params) => restProviderHandle(CREATE, resource, params),
  delete: (resource, params) => restProviderHandle(DELETE, resource, params),
  deleteMany: async (resource, params) => {
    const promises = params.ids.map((id) =>
      restProviderHandle(DELETE, resource, { id })
    );

    const response = await Promise.allSettled(promises).then((responses) => {
      const deletedItems = responses.filter(
        (x) => x.status === "fulfilled" && x.value.data.deleted
      );
      const data = deletedItems.map((x) => x.value.data.id);
      return {
        data,
      };
    });
    return response;
  },
  export: exportAll,
};
