import { shallowEqual, useSelector } from 'react-redux';
import { ReduxState } from '../components/Admin';


const useExtendedFiltersIsActive = (resource: string) => {
  const extendedSearch = useSelector(
    (state: ReduxState) => state.admin.resources[resource].list.params.filter.extended_search,
    shallowEqual
  );
  const isExtended = Boolean(extendedSearch);

  return isExtended;
};

export default useExtendedFiltersIsActive;
