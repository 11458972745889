import { colors } from '@material-ui/core';


const notificationRowStyle = (record, index, defaultStyle = {}) => {
  const saturate = 300;
  let color = colors.grey[saturate];
  let borderWidth = 5;

  if (!record.is_readed) {
    color = colors.blue[saturate]
  }

  return {
      ...defaultStyle,
      boxShadow: `inset ${borderWidth}px 0 0 ${color}`,
  }
}

export default notificationRowStyle;