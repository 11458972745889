import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import ContentCreate from '@material-ui/icons/Create';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { linkToRecord, Record } from 'ra-core';
import { IconButton } from '@material-ui/core';

const EditButton = ({
    basePath = '',
    label = 'ra.action.edit',
    record,
    icon = defaultIcon,
    ...rest
}) => (
    <IconButton
        component={Link}
        size="small"
        color="default"
        to={linkToRecord(basePath, record && record.id)}
        onClick={stopPropagation}
        {...rest}
    >
        {icon}
    </IconButton>
);

const defaultIcon = <ContentCreate />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

interface Props {
    basePath?: string;
    record?: Record;
    icon?: ReactElement;
}

// @ts-ignore
export type EditButtonProps = Props & ButtonProps & MuiButtonProps;

EditButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
};

export default EditButton;
