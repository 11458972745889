import React from 'react';
import { translate } from  'react-admin';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/icons/NoteAdd';

import TransitionLink from '../../../../components/TransitionLink';
import { dictionaryToPath } from '../../../../utils';
import resourceConfig from './config';

const OpenButton = ({ params, translate, type, ...props }) => {
  const urlPatams = {
    ...params
  }

  let disabled = false;
  const label = `resources.${resourceConfig.name}.openAction`;

  return (
    <Button
      size={'small'}
      color="default"
      {...props}
      component={TransitionLink}
      disabled={disabled}
      transitionTo={{
        params: {
          [resourceConfig.urlParamName]: dictionaryToPath(urlPatams)
        }
      }}
      startIcon={<Icon />}
    >
      {translate(label)}
    </Button>
)
};


export default translate(OpenButton);
