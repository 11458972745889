import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        UNIT: 'Unit',
        BUNDLE: 'Bundle',
        GROUP: 'Group',
      },
    }
  }
}