import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Item - aggregation report |||| Items - aggregation report',
      fields: {
        'data.unit_serial_number': 'Aggreagation Unit code',
        'data.aggregation_unit_capacity': 'Aggregation Unit Capacity',
        'data.aggregated_items_count': 'Aggregated Items Count',
        'data.sntins': 'Nested codes',
      }
    }
  }
}
