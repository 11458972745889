const XXXS = 320;
const XXS = 375;
const XS = 576;
const SM = 768;
const MD = 1024;
const LG = 1280;
const XL = 1440;
const XXL = 1920;
const XXXL = 3840;

const getWidthBasedQuery = ({ min = null, max = null }) => {
  return `@media only screen${min !== null ? ` and (min-width: ${min}px)`: ''}${max !== null ? ` and (max-width: ${max - 0.02}px)`: ''}`;
};

export default {
  xxxsDevicesDown: getWidthBasedQuery({ max: XXXS }),
  xxxsDevicesOnly: getWidthBasedQuery({ max: XXXS }),

  xxsDevicesUp: getWidthBasedQuery({ min: XXXS }),
  xxsDevicesDown: getWidthBasedQuery({ max: XXS }),
  xxsDevicesOnly: getWidthBasedQuery({ min: XXXS, max: XXS }),

  xsDevicesUp: getWidthBasedQuery({ min: XXS }),
  xsDevicesDown: getWidthBasedQuery({ max: XS }),
  xsDevicesOnly: getWidthBasedQuery({ min: XXS, max: XS }),

  smDevicesUp: getWidthBasedQuery({ min: XS }),
  smDevicesDown: getWidthBasedQuery({ max: SM }),
  smDevicesOnly: getWidthBasedQuery({ min: XS, max: SM }),

  mdDevicesUp: getWidthBasedQuery({ min: SM }),
  mdDevicesDown: getWidthBasedQuery({ max: MD }),
  mdDevicesOnly: getWidthBasedQuery({ min: SM, max: MD }),

  lgDevicesUp: getWidthBasedQuery({ min: MD }),
  lgDevicesDown: getWidthBasedQuery({ max: LG }),
  lgDevicesOnly: getWidthBasedQuery({ min: MD, max: LG }),

  xlDevicesUp: getWidthBasedQuery({ min: LG }),
  xlDevicesDown: getWidthBasedQuery({ max: XL }),
  xlDevicesOnly: getWidthBasedQuery({ min: LG, max: XL }),

  xxlDevicesUp: getWidthBasedQuery({ min: XL }),
  xxlDevicesDown: getWidthBasedQuery({ max: XXL }),
  xxlDevicesOnly: getWidthBasedQuery({ min: XL, max: XXL }),

  xxxlDevicesUp: getWidthBasedQuery({ min: XXL }),
  xxxlDevicesDown: getWidthBasedQuery({ max: XXXL }),
  xxxlDevicesOnly: getWidthBasedQuery({ min: XXL, max: XXXL }),

  xxxxlDevicesUp: getWidthBasedQuery({ min: XXXL }),
  xxxxlDevicesOnly: getWidthBasedQuery({ min: XXXL }),

  XXXS,
  XXS,
  XS,
  SM,
  MD,
  LG,
  XL,
  XXL,
  XXXL
};