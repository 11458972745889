import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Автобаланс кодов |||| Автобаланс кодов',
      actions: {
        execute: {
          name: 'Выполнить проверку',
          successMessage: 'Запрос на проверку автобаланса успешно отправлен',
          failureMessage: 'При запросе на проверку автобаланса произошла ошибка',
        },
      },
      fields: {
        product_group: 'Товарная группа',
        gtin: 'GTIN',
        quantity_min: 'Минимальное кол-во',
        quantity_max: 'Максимальное кол-во',
        stock: 'Склад',
        emission_type: 'Тип эмиссии',
        code_status: 'Статус кода',
        printer_status: 'Статус печати',
        timeout: 'Таймаут',
        auto_execute: 'Выполнить сразу',
        is_active: 'Активен',
        order_data: 'Данные заказа',
        order_child_data: 'Данные позиции заказа',
      }
    }
  }
}