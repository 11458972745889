import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      openAction: 'Импорт',
      title: 'Импорт документа',
      executeAction: 'Отправить',
      resetAction: 'Сбросить',
      errorMessage: 'Ошибка',
      successMessage: 'Успех',
      fields: {
        document_type: 'Тип документа',
        file: 'Файл',
        auto_execute: 'Отправить документы автоматически',
      },
    }
  }
}
