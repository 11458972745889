import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        RECEIPT: 'Receipt',
        SALES_RECEIPT: 'Sales receipt',
        OTHER: 'Other',
      },
    }
  }
}