import ResourceEditPage from '../../components/ResourceEditPage';
import getFieldsFromSchema from './getFieldsFromSchema';


export default (options = {}) => {
  let {
    schema = null,
    editFields = null,
    editActions = null,
    allowCreate = true,
    allowEdit = true,
    allowShow = true,
    allowDelete = true,
    allowList = true,
    title,
    editGetOptions,
  } = options;

  if (!allowEdit || schema === null) {
    return null;
  }

  let withTabs = false;
  if (editFields && editFields.type) {
    if (editFields.type === 'tabbed') {
      withTabs = true;
    }
    editFields = editFields.items;
  }

  return (props) => (
    getFieldsFromSchema({
      schema,
      fields: editFields,
      Component: ResourceEditPage,
      validate: true,
      editable: true,
      allowCreate,
      allowShow,
      allowDelete,
      allowList,
      actions: editActions,
      title,
      withTabs,
      getOptions: editGetOptions,
      ...props
    })
  )
}