export default {
  services: {
    marking: {
      name: 'Марировка',
      groups: {
        settings: 'Дополнительно',
        input: 'Ввод в оборот',
        aggr: 'Агрегация',
        cancel: 'Списание',
      }
    }
  }
}
