const loaderMinTime = 2000;

const hide = () => {
  const handler = () => {
    try {
      document.body.classList.add('_loader-hiding');
      setTimeout(() => {
        document.body.classList.add('_loaded');
        document.body.classList.remove('_loader-hiding');
      }, 500)
    } catch(e) {}
  }

  const currentDate = Date.now();
  if (window.openedTimestamp && currentDate < window.openedTimestamp + loaderMinTime) {
    setTimeout(handler, window.openedTimestamp + loaderMinTime - currentDate);
  } else {
    handler();
  }
}

export default {
  hide,
};
