import React, { Component } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { translate as translateRA } from "react-admin";
import { downloadCSV } from 'react-admin';
import printElement from "../../../../utils/printElement";


const trnKey = (key) => `pages.company/codes-report.${key}`;

@translateRA
class ReportDownloadMenu extends Component {
  state = {
    anchorEl: null,
    processing: false,
  }

  setAnchorEl = (anchorEl) => {
    this.setState({ anchorEl });
  }

  handleClick = (event) => {
    this.setAnchorEl(event.currentTarget);
  };

  handleClose = () => {
    this.setAnchorEl(null);
  };

  downloadPDF = () => {
    printElement("#codes-report-print-content");
    this.handleClose();
  }

  strToCSVCell = (str, sub = false) => {
    const prefix = sub ? '— ' : '';
    return `"${prefix}${str}"`;
  }

  dataToCsvRows = (data, title) => {
    const csvRows = [];

    csvRows.push([]);
    csvRows.push([]);
    csvRows.push([this.strToCSVCell(`${title.toUpperCase()}:`)]);

    for(let name in data) {
      csvRows.push([
        this.strToCSVCell(name, true),
        this.strToCSVCell(String(data[name])),
      ]);
    }
    return csvRows;
  }

  downloadCSV = () => {
    const { data, translate } = this.props;

    const csvRows = [
      [
        this.strToCSVCell(translate(trnKey('period'))),
        this.strToCSVCell(data.period),
      ],
      [
        this.strToCSVCell(translate(trnKey('totalCodes'))),
        this.strToCSVCell(data.totalCount.toLocaleString()),
      ],
      ...this.dataToCsvRows(data.gtins, translate(trnKey("gtins"))),
      ...this.dataToCsvRows(data.statuses, translate(trnKey("charts.status"))),
      ...this.dataToCsvRows(data.emissionTypes, translate(trnKey("charts.emissionType"))),
      ...this.dataToCsvRows(data.productGroups, translate(trnKey("charts.productGroup"))),
      ...this.dataToCsvRows(data.printerStatuses, translate(trnKey("charts.printerStatus"))),
      ...this.dataToCsvRows(data.additionalStatuses, translate(trnKey("charts.additionalStatus"))),
    ];

    let maxCells = Math.max(...csvRows.map(x => x.length));

    const csv = csvRows
      .map(row => {
        // Добавляем пустые ячейки
        const additionalCells = maxCells - row.length;
        if (additionalCells > 0) {
          for (let i = 0; i < additionalCells; i++) {
            row.push('');
          }
        }
        // Добавляем закрывющий разделитель
        row.push('');
        return row.join(';');
      })
      .join('\n');

    downloadCSV(csv, 'Codes report');

    this.handleClose();
  }

  render() {
    const { style, translate } = this.props;
    return (
      <div style={style}>
        <Button onClick={this.handleClick}>
          {translate(trnKey("downloadMenu.label"))}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.downloadCSV}>
            {translate(trnKey("downloadMenu.csv"))}
          </MenuItem>
          <MenuItem onClick={this.downloadPDF}>
            {translate(trnKey("downloadMenu.pdf"))}
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default ReportDownloadMenu;
