import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-admin';
import { colors } from '@material-ui/core';
import compose from 'recompose/compose';
import ChartCard from '../ChartCard';
import request from '../../network/request';
import { combineMetrics, getRangeParams } from '../../utils/ui/metrics';
import ChartSetSelect from '../ChartCard/ChartSetSelect';


class CodesMetricsWidget extends Component {
  state = {
    chartSetId: 'amounts',
    chartItems: [],
  }

  chartSets = [{
    id: 'amounts',
    metrics: [
      {
        field: 'notRegistered',
        name: 'Not registered',
        color: '#e57373',
        stackId: 'codeStatus',
        requestPath: 'code_info_total',
        requestStatuses: ['NotRegistered'],
      },
      {
        field: 'emitted',
        name: 'Emitted',
        color: '#fff176',
        stackId: 'codeStatus',
        requestPath: 'code_info_total',
        requestStatuses: ['Emitted'],
      },
      {
        field: 'applied',
        name: 'Applied',
        color: '#ffb74d',
        stackId: 'codeStatus',
        requestPath: 'code_info_total',
        requestStatuses: ['Applied'],
      },
      {
        field: 'introduced',
        name: 'Introduced',
        color: '#81c784',
        stackId: 'codeStatus',
        requestPath: 'code_info_total',
        requestStatuses: ['Introduced'],
      },
      {
        field: 'retired',
        name: 'Retired',
        color: '#9575cd',
        stackId: 'codeStatus',
        requestPath: 'code_info_total',
        requestStatuses: ['Retired'],
      },
      {
        field: 'disaggregation',
        name: 'Disaggregation',
        color: '#7986cb',
        stackId: 'codeStatus',
        requestPath: 'code_info_total',
        requestStatuses: ['Disaggregation'],
      },
      {
        field: 'writtenOff',
        name: 'writtenOff',
        color: '#f06292',
        stackId: 'codeStatus',
        requestPath: 'code_info_total',
        requestStatuses: ['WrittenOff'],
      },
    ]
  }, {
    id: 'events',
    metrics: [
      {
        field: 'created',
        name: 'Created',
        color: colors.grey[300],
        requestPath: 'code_model_inserts_total',
      },
      {
        field: 'updated',
        name: 'Updated',
        color: colors.amber[300],
        requestPath: 'code_model_updates_total',
      },
      {
        field: 'deleted',
        name: 'Deleted',
        color: colors.red[300],
        requestPath: 'code_model_deletes_total',
      },
    ]
  }]

  chartOptions = {
    xName: 'date',
    syncId: 'metricsWidgets',
  }


  chartOptions = {
    xName: 'date',
    syncId: 'metricsWidgets',
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.viewVersion !== prevProps.viewVersion
      || this.props.metricsRange !== prevProps.metricsRange
      || this.state.chartSetId !== prevState.chartSetId
    ) {
      this.loadData();
    }
  }

  loadData = async () => {
    const {
      metricsRange,
    } = this.props;
    const {
      chartSetId,
    } = this.state;

    const metricsData = {};
    const chartSet = this.chartSets.find(x => x.id === chartSetId);

    const promisses = chartSet.metrics.map(async ({ field, requestStatuses, requestPath }) => {
      try {
        const path = `/bp/metrics/${requestPath}/`;
        const rangeParams = getRangeParams(metricsRange);
        const { data } = await request({
          path,
          method: 'POST',
          contentType: 'json',
          body: {
            ...rangeParams,
            labels__status: requestStatuses || [],
            labels__additional_status: [],
          }
        });

        if (data) {
          metricsData[field] = data;
        }
      } catch (e) { }
    })

    await Promise.all(promisses);

    const result = combineMetrics(metricsData);
    this.setState({
      chartItems: result,
    });
  }

  onChangeChartSet = (chartSetId) => {
    this.setState({
      chartSetId
    });
  }

  render() {
    const {
      translate,
    } = this.props;
    const {
      chartItems,
      chartSetId,
    } = this.state;
    const chartSet = this.chartSets.find(x => x.id === chartSetId);

    return (
      <ChartCard
        title={translate('widgets.codesMetrics.title')}
        chartItems={chartItems}
        chartOptions={{
          ...this.chartOptions,
          yMetrics: chartSet.metrics,
        }}
        actions={[
          <ChartSetSelect
            value={chartSetId}
            items={this.chartSets.map(x => ({ value: x.id }))}
            translatePrefix={'widgets.codesMetrics.sets.'}
            onChange={this.onChangeChartSet}
          />
        ]}
      />
    );
  }
}

const mapStateToProps = state => ({
  metricsRange: state.metrics.metricsRange,
  viewVersion: state.admin.ui.viewVersion,
});

export default compose(
  connect(
    mapStateToProps,
  ),
  translate,
)(CodesMetricsWidget);
