import React from 'react';
import ResourceIcon from '@material-ui/icons/People';
import { ArrayInput, SimpleFormIterator, TextInput } from '../../../../components/Admin';
import user from '../../../common-service/resources/user';
import memberRoles from './memberRoles';
import createAddByParrentButton from '../../../../utils/admin/createAddByParrentButton';

const name = 'settings/member';
export default {
  name,
  icon: ResourceIcon,
  apiResolve: {
    path: '/cm/company/members/',
    idField: 'user',
  },
  itemToString: x => x.name || `#${x.id}`,
  schema: {
    type: 'object',
    properties: {
      user: {
        type: 'string',
        componentField: user.Field,
        componentInput: user.Input,
        options: {
          link: false,
        },
        validation: {
          required: true,
        }
      },
      expiration_date: {
        type: 'dateTime',
        options: {
        }
      },
      date_joined: {
        type: 'dateTime',
        readOnly: true,
        options: {
        }
      },
      permissions: {
        type: 'array',
        validation: {
          required: true,
        },
        componentInput: (props) => (
          <ArrayInput {...props} fullWidth source="permissions">
              <SimpleFormIterator>
                <TextInput source="permission" />
              </SimpleFormIterator>
          </ArrayInput>
        ),
      },
    }
  },
  allowList: true,
  allowCreate: true,
  allowEdit: true,
  allowShow: false,
  allowDelete: true,
  showInMenu: true,
  group: 'settings',
  createFields: [
    'user',
    'expiration_date',
  ],
  editFields: {
    type: 'tabbed',
    items: [{
      type: 'tab',
      label: `resources.${name}.tabs.info`,
      items: [
        { name: 'user', readOnly: true },
        'expiration_date',
        'date_joined',
      ]
    },{
      type: 'tab',
      label: `resources.${name}.tabs.roles`,
      path: 'roles',
      items: [{
        options: {
          fullWidth: true,
        },
        component: (props) => (
          <memberRoles.ManyField
            {...props}
            fullWidth
            target="parent_id"
            createButton={createAddByParrentButton({
              resourceName: memberRoles.name,
            })}
          />
        )
      }]
    }]
  },
  listFields: [
    'user',
    'date_joined',
    'expiration_date',
  ],
  listFilters: [],
};
