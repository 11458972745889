import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'OWN_PRODUCTION': 'Own Production',
      },
    }
  }
}