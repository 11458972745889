import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'NOT_REGISTERED': 'Не зарегистрирован',
        'APPLIED': 'Нанесен',
        'EMITTED': 'Эмитирован',
        'INTRODUCED': 'Введен в оборот',
        'RETIRED': 'Выбыл',
        'DISAGGREGATION': 'Дезагрегрегирован',
        'WRITTEN_OFF': 'Списан',
      },
    }
  }
}
