import {
  FormControl,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
} from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import React from "react";
import ReactFilterBox from "react-filter-box";
import "react-filter-box/lib/react-filter-box.css";
import QLAutocomplete from "./QLAutocomplete";
import { addField, translate as translateRA } from "../Admin";
import compose from "../../utils/compose";

const styles = (theme) => ({
  errorButton: {
    color: `${theme.palette.error.main} !important`,
  },
  successButton: {
    color: `${theme.palette.success.main} !important`,
  },
  root: {
    width: "calc(100vw - 760px)",
    "& .react-filter-box": {
      marginTop: 0,
      marginBottom: 0,
      verticalAlign: "inherit",
      border: "none",
      height: "auto",
      padding: 0,
      minWidth: "200px",

      overflowY: "hidden",
      fontSize: "inherit",
      lineHeight: "inherit",
      color: "#inherit",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: "transparent",
      boxShadow: "none",
      transition: "none",
    },

    "& .CodeMirror": {
      fontSize: "inherit",
      fontFamily: "inherit",
      height: "1.1876em;",
      padding: "10.5px 14px 10.5px",
      background: "transparent",
    },
    "& .CodeMirror-focused": {
      background: "transparent",
    },
    "& .react-codemirror2": {
      height: "100%",
      fontSize: "inherit",
    },
    "& .ReactCodeMirror": {
      height: "100%",
      fontSize: "inherit",
    },
    "& .CodeMirror-lines": { padding: 0 },

    "& .cm-category": {
      color: "#2196F3",
    },
    "& .cm-operator": {
      color: "#9E9E9E",
    },
    "& .cm-value": {
      color: "#E91E63",
    },
    "& .react-filter-box.focus": {
      borderColor: "none",
      outline: "0",
      boxShadow: "none",
    },
    "& .react-filter-box.error": {
      outline: "0",
      boxShadow: "none",
    },
    "& .react-filter-box.error.focus": {
      boxShadow: "none",
    },
  },
});

const WrappedReactFilterBox = (props) => {
  const { filterBoxProps, onBlur, onFocus, inputRef, onChange, value } = props;
  React.useImperativeHandle(
    inputRef,
    () => ({
      focus: () => {},
      blur: () => {},
      value,
    }),
    []
  );

  const _onChange = (value, state) => {
    onChange({
      target: {
        value: {
          value,
          isValid: !state.isError,
        },
      },
    });
  };
  return (
    <ReactFilterBox
      ref={inputRef}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={_onChange}
      query={value}
      {...filterBoxProps}
    />
  );
};

@compose(translateRA, withStyles(styles))
class QLInput extends React.Component {
  constructor(props) {
    super(props);

    const { queryData, queryOptions } = props;

    this.customAutoComplete = new QLAutocomplete(queryData, queryOptions);
    this.state = {
      value: props.input.value || "",
      isValid: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.input.value !== prevProps.input.value &&
      this.props.input.value !== this.state.value
    ) {
      this.setState({
        value: this.props.input.value,
        isValid: true,
      });
    }
  }

  onChange = (event) => {
    const data = event.target.value;
    if (typeof data === "string") {
      this.setState({
        value: data,
        isValid: true,
      });
      return;
    }
    const { value, isValid } = data;
    if (value === this.state.value && isValid === this.state.isValid) {
      return;
    }
    this.setState({
      value,
      isValid,
    });
  };

  submit = () => {
    const {
      input: { onChange },
    } = this.props;
    const { value } = this.state;
    onChange(value);
  };

  render() {
    let {
      label,
      input,
      classes,
      queryData,
      queryOptions,
      source,
      resource,
      translate,
    } = this.props;
    const { value, isValid } = this.state;
    let iconClassName = null;
    if (!isValid) {
      iconClassName = classes.errorButton;
    } else if (value !== input.value) {
      iconClassName = classes.successButton;
    }

    if (label === undefined && source && resource) {
      label = `resources.${resource}.fields.${source}`;
    }
    label = translate(label);

    return (
      <FormControl margin="dense" variant="outlined" className={classes.root}>
        <InputLabel>{label}</InputLabel>
        <OutlinedInput
          // inputComponent={WrappedReactFilterBox}
          multiline
          label={label}
          onChange={this.onChange}
          value={value}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Sumit"
                onClick={this.submit}
                disabled={!isValid && value !== input.value}
                edge="end"
                classes={{
                  root: iconClassName,
                }}
              >
                {isValid ? <CheckCircleIcon /> : <ErrorIcon />}
              </IconButton>
            </InputAdornment>
          }
          inputProps={{
            filterBoxProps: {
              style: { width: "100%" },
              data: queryData,
              options: queryOptions,
              autoCompleteHandler: this.customAutoComplete,
            },
          }}
        />
        <FormHelperText />
      </FormControl>
    );
  }
}

export default addField(QLInput);
