import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Списание кодов |||| Списание кодов',
      subMenuName: 'Не в обороте',
      field: {
        'data.trade_participant_inn': 'ИНН',
        'data.cancellation_reason': 'Причина списания',
        'data.cancellation_doc_number': 'Номер первичного документа на списание',
        'data.cancellation_doc_date': 'Дата списания',
      }
    }
  }
}
