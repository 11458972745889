import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'HTML': 'HTML',
        'JRXML': 'Jasper Report (jrxml)',
        'XML': 'XML',
        'TEXT': 'Текст',
      },
    }
  }
}
