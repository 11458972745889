import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { Button } from 'react-admin';
import getResourceUrl from '../admin/getResourceUrl';
import docCreateOptionsCreator from './docCreateOptionsCreator';
import docEditOptionsCreator from './docEditOptionsCreator';


export default (config) => {
  let {
    // General
    name,
    itemToString = x => `#${x.id}`,
    apiResolve = {},

    allowList = true,
    allowCreate = true,
    allowEdit = true,
    allowShow = false,
    allowDelete = true,
    allowClone = false,
    showInMenu = false,

    allowAgregation = false,

    // Custom for documents
    parentResourceName,
    apiBasePath,
    addByParentButtonText = 'documents.addChild',

    listFields = [],

    ...rest
  } = config;

  apiResolve = {
    path: apiBasePath,
    pathCreate: `${apiBasePath}create/`,
    pathEdit: '/bp/document/document_child/',
    pathDelete: '/bp/document/document_child/',
    pathGet: '/bp/document/document_child/',
    ...apiResolve,
  }
  apiResolve.mapping = {
    outputItem: ({ data, extra }) => ({ data, extra }),
    ...(apiResolve.mapping || {}),
  }

  if (allowAgregation) {
    listFields.push({
      name: '_aggregated_count',
      type: 'number',
    })
    apiResolve.mapping.inputItem = (data) => {
      data._aggregated_count = 0;
      if (data.data_list) {
        data._aggregated_count = data.data_list.length
      }
      return data;
    }
  }

  const result = {
    name,
    itemToString,
    apiResolve,

    allowList,
    allowCreate,
    allowEdit,
    allowShow,
    allowDelete,
    allowClone,
    showInMenu,

    listFields,

    AddByParentButton: ({ record }) => (
      <Button
        variant="raised"
        component={Link}
        to={getResourceUrl({
          resourceName: name,
          pageType: 'CREATE',
          params: {
            parent_id: record.id,
          }
        })}
        label={addByParentButtonText}
        title={addByParentButtonText}
      >
        <AddIcon />
      </Button>
    ),

    createGetOptions: docCreateOptionsCreator({ parentResourceName }),
    editGetOptions: docEditOptionsCreator({ parentResourceName }),

    ...rest
  };

  return result;
}
