import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Task |||| Tasks',
      actions: {
        showLogs: 'Show logs'
      },
      fields: {
        request: 'Request data',
        response: 'Response data',
        document_process: 'Document type',
        document_id: 'Document ID',
        status: 'Status',
      }
    }
  }
}