export default {
  name: 'marking/enums/receiptActionType',
  enums: [
    'RETAIL',
    'EEC_EXPORT',
    'BEYOND_EEC_EXPORT',
    'RETURN',
    'BY_SAMPLES',
    'DISTANCE',
    'DAMAGE_LOSS',
    'DESTRUCTION',
    'CONFISCATION',
    'LIQUIDATION',
    'ENTERPRISE_USE',
  ],
};
