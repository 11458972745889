import ResourceIcon from '@material-ui/icons/Input';
import docChildConfigCreator from '../../../../../utils/documents/docChildConfigCreator';

const name = 'marking/aggr-report-child';
const parentResourceName = 'marking/aggr-report';
const apiBasePath = '/bp/processes/aggregation_report/{{parent_id}}/children/';

export default docChildConfigCreator({
  name,
  icon: ResourceIcon,
  parentResourceName,
  apiBasePath,
  allowAgregation: true,
  apiResolve: {
    mapping: {
      outputItemAfter: (output) => {
        if (output && output.data && output.data.sntins) {
          output.data.sntins = output.data.sntins
            .split('\n')
            .map(x => x.trim())
            .filter(x => x.length > 0);
        } else {
          output.data.sntins = null;
        }
        return output;
      },
      inputItemAfter: (input) => {
        if (input && input.data) {
          if (input.data.sntins) {
            input.data.sntins = input.data.sntins.join('\n');
          } else {
            input.data.sntins = null;
          }
        }
        return input;
      },
    }
  },
  schema: {
    type: 'object',
    properties: {
      'data.unit_serial_number': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.aggregation_unit_capacity': {
        type: 'number',
        defaultValue: null,
        validation: {
          required: true,
        },
      },
      'data.aggregated_items_count': {
        type: 'number',
        defaultValue: null,
      },
      'data.sntins': {
        type: 'string',
        defaultValue: null,
        validation: {
          required: true,
        },
        options: {
          multiline: true,
        }
      },
    }
  },
  listFields: [
    'data.unit_serial_number',
    'data.aggregation_unit_capacity',
    'data.aggregated_items_count',
  ],
});
