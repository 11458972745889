import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import { ListSubheader, Divider, ListItemText, ListItem, Typography, ListItemIcon } from '@material-ui/core';
import { translate } from 'react-admin';
import request from '../../network/request';
import { fillUrl } from '../../utils/url';
import appCore from '../../appCore';

class StockSelect extends Component {
  constructor(props) {
    super(props);

    let stockId = appCore.currentUrlParams.stockId || null;
    if (stockId === 'all') {
      stockId = null;
    }

    this.state = {
      loading: true,
      stocks: [],
      anchorEl: null,
      stockId,
    }
  }

  componentDidMount() {
    this.load();
  }

  load = async () => {
    let stocks = [];
    // let response = null;
    try {
      const stocksResponse = await request({
        path: '/cm/stock/by_company/',
        method: 'GET',
      })
      if (stocksResponse !== null && stocksResponse.ok) {
        stocks = stocksResponse.data.results || stocksResponse.data;
      }
    } catch (e) {
      console.error(e);
    }
    this.setState((state) => {
      let stockId = state.stockId;
      const existStock = stocks.find(x => x.id === stockId);

      if (existStock === null) {
        stockId = null;
      }

      return {
        stockId,
        loading: false,
        stocks,
      };
    });
  }
  
  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  
  getStockLabel = () => {
    const {
      translate,
    } = this.props;
    if (this.state.stockId === null) {
      return translate('stockSelect.allStocks');
    }
    const stock = this.state.stocks.find(x => x.id === this.state.stockId) || null;

    if (stock !== null) {
      return stock.name;
    }

    return translate('stockSelect.unknownStock');
  }

  render() {
    const {
      translate
    } = this.props;
    const {
      loading,
      stocks,
      stockId,
    } = this.state;

    if (stocks.length === 0 && loading) {
      return '...';
    }

    return (
      <div>
        <ListItem
          button
          dense
          alignItems="center"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
          color="inherit"
          style={{ paddingTop: 0, paddingBottom: 0, justifyContent: 'space-between', textAlign: 'right' }}
        >
          <ListItemText
            disableTypography
            primary={(
              <Typography variant="caption" display="block">
                {translate('stockSelect.label')}
              </Typography>
            )}
            secondary={(
              <Typography variant="body2" display="block">
                {this.getStockLabel()}
              </Typography>
            )}
          />
          <ListItemIcon style={{ justifyContent: 'flex-end', minWidth: 35 }}>
            <ExpandMoreIcon />
          </ListItemIcon>
        </ListItem>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <ListSubheader>{translate('stockSelect.label')}</ListSubheader>
          <MenuItem
            disabled={stockId === null}
            component={Link}
            to={fillUrl('/c/{{companyId}}/pg/{{productGroup}}/st/all/s/{{serviceName}}')}
          >
            {translate('stockSelect.allStocks')}
          </MenuItem>
          {stocks.map(stock => (
            <MenuItem
              key={stock.id}
              selected={stock.id === stockId}
              component={Link}
              to={fillUrl(`/c/{{companyId}}/pg/{{productGroup}}/st/${stock.id}/s/{{serviceName}}`)}
            >
              {stock.name}
            </MenuItem>
          ))}
          
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          <MenuItem
            component={Link}
            to={fillUrl('/c/{{companyId}}/s/company/settings/stock')}
          >
            <ListItemIcon style={{ minWidth: 35 }}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              primary={translate('stockSelect.editStocks')}
            />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default translate(StockSelect);
