import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        'AVAILABLE': 'Доступен для печати',
        'NOT_AVAILABLE': 'Недоступен для печати',
        'RESERVED': 'Зарезевирован',
      },
    }
  }
}
