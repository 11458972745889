import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      name: 'Товарная группа |||| Товарные группы',
      fields: {
        product_group: 'Товарная группа',
        is_enabled: 'Включено',
      }
    }
  }
}
