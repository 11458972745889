import resourceConfig from '../config';

export default {
  resources: {
    [resourceConfig.name]: {
      enums: {
        MALE: 'Мужской',
        FEMALE: 'Женский',
        BABY: 'Детский',
        UNISEX: 'Унисекс',
      },
    }
  }
}
